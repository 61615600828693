import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseForm, FilterItem, ModuleKeywords, RiskMethodologyImpactFactorDto } from '@shared/classes';
import { ViewModeService } from '@shared/services';

@Component({
  selector: 'app-risk-methodology-impact-factor-item-form',
  templateUrl: './risk-methodology-impact-factor-item-form.component.html',
  styleUrl: './risk-methodology-impact-factor-item-form.component.scss',
})
export class RiskMethodologyImpactFactorItemFormComponent extends BaseForm<RiskMethodologyImpactFactorDto> implements OnInit {
  constructor(public viewModeService: ViewModeService) {
    super(viewModeService, ModuleKeywords.RiskMethodologyImpactFactor);
  }

  extraFilters: FilterItem[] = [];

  ngOnInit(): void {
    // this.extraFilters = [{ property: 'impactValue', operation: 'IN', value: this.popupOptions ? this.popupOptions.map(x => x.code) : [] },];
    // this.formGroup.controls.impactFactorItemsDto.valueChanges.subscribe(x => {
    //   this.setFilters(x);
    // })
  }

  tempOptionsChange(event) {
    // this.setFilters(event);
  }

  // setFilters(list) {
  //   if (list?.length) {
  //     this.extraFilters = [{
  //       left: { property: 'impactValue', operation: 'IN', value: this.popupOptions ? this.popupOptions.map(x => x.code) : [] },
  //       right: { property: 'code', operation: 'IN', value: list?.map(y => y.code) },
  //       operand: 'OR',
  //     },];

  //   } else {
  //     this.extraFilters = [{ property: 'impactValue', operation: 'IN', value: this.popupOptions ? this.popupOptions.map(x => x.code) : [] },];
  //   }
  // }

  getData() {
    let formData: RiskMethodologyImpactFactorDto = { ...this.formGroup.getRawValue() }
    // formData.impactFactorItems = formData?.impactFactorItemsDto?.map((item, index) => item.code);

    // if (formData.impactFactorItemsDto) {
    //   delete formData.impactFactorItemsDto;
    // }
    return this.currentViewMode == 'create' ?
      this.getDataKeyValueFormat(formData)
      : this.getChangedFormValues(formData).updateItems;
  }

  setData(data: any) {
    this.formGroup.patchValue({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      // impactFactorItemsDto: new FormControl(null),
      description: new FormControl(null),
    });
  }
}
