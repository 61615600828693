import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  BaseForm,
  DataTypeEnum,
  FilterItem,
  IDynamicComponent,
  ModuleKeywords,
  RiskItem,
  RiskItemDto,
  RiskMethodology,
  RiskMethodologyImpactValue,
  RiskMethodologyLikelihoodValue,
  RiskProject,
  getEnumOptions,
  humanizeCasedString,
  isNullObj
} from '@shared/classes';
import { ImpactFactorWithValueSelectorComponent } from '@shared/components/selectors/impact-factor-with-value-selector/impact-factor-with-value-selector.component';
import { ViewModeService } from '@shared/services';
import { RiskMethodologyImpactFactorOptionMappingService } from '@shared/services/mappings/risk/risk-methodology-impact-factor-option-mapping.service';
import { EntityDataService } from 'app/modules/entity-module/entity/entity-data.service';
import { cloneDeep, find, maxBy, omit } from 'lodash-es';
import { combineLatest, startWith, takeUntil } from 'rxjs';
import { RiskStatementDataService } from '../../services/data/risk-statement-data.service';

@Component({
  selector: 'app-risk-item-form',
  templateUrl: './risk-item-form.component.html',
  styleUrls: ['./risk-item-form.component.scss'],
})
export class RiskItemFormComponent extends BaseForm<RiskItemDto> implements OnInit, AfterViewInit {
  optionsFields: IDynamicComponent[] = [];
  impactList: RiskMethodologyImpactValue[] = [];
  likelihoodList: RiskMethodologyLikelihoodValue[] = [];
  impactFactorOptionToValueMap = {};
  inheritedRiskAcceptanceList: any[] = getEnumOptions(RiskItem.InheritedRiskAcceptanceEnum);
  residualRiskAcceptanceList: any[] = getEnumOptions(RiskItem.ResidualRiskAcceptanceEnum);
  strategyList: any[] = getEnumOptions(RiskItem.StrategyEnum);
  acceptStratigy = RiskItem.StrategyEnum.ExceptionalAcceptance;
  exceptionalStatus = RiskItem.StateEnum.ExceptionalAcceptance;
  strategyFilteredList = [
    RiskItem.StrategyEnum.Avoid,
    RiskItem.StrategyEnum.Mitigate,
    RiskItem.StrategyEnum.Transfer,
  ].map((x) => {
    return { label: humanizeCasedString(x), value: x };
  });
  @Input() reassessment: boolean = false;

  @Input() hideActions: boolean = false;
  extraOptionsFilters: FilterItem[] = [];

  private _riskMeth: RiskMethodology = null;
  @Input() set riskMeth(riskMeth: RiskMethodology) {
    if (riskMeth) {
      this._riskMeth = riskMeth;
      this.impactList = riskMeth.impactValuesDto;


      for (let i = 0; i < this.impactList?.length; i++) {
        const element = this.impactList[i];
        for (let j = 0; j < element?.riskMethodologyImpactFactorOption?.length; j++) {
          const option = element?.riskMethodologyImpactFactorOption[j];
          this.impactFactorOptionToValueMap[option?.code] = element;

        }
      }
      if (this.formGroup?.controls?.riskMethodologyImpactFactorOption) {
        this.formGroup?.controls?.riskMethodologyImpactFactorOption?.patchValue(this.formGroup?.controls?.riskMethodologyImpactFactorOption?.value);
      }
      const listOfOptionsCodes = [];
      Object.keys(this.impactFactorOptionToValueMap).map(x => {
        listOfOptionsCodes.push(x);
      });

      this.extraOptionsFilters = [{ property: 'code', operation: 'IN', value: listOfOptionsCodes },];

      this.likelihoodList = riskMeth.likelihoodValuesDto;
      this.optionsFields = [
        {
          componentType: ImpactFactorWithValueSelectorComponent,
          options: {
            label: 'Impact Factor',
            labelList: ['Impact Factor', 'Impact Factor Value', 'Risk Impact Value'],
            name: 'factorVal',
            control: new FormControl(null, Validators.required),
            inputOptions: {
              dropDownInput: {
                multi: false,
                showClear: true,
                optionLabel: 'label',
                optionValue: undefined,
                items: [],
                // items: riskMeth?.impactFactorsDto,
                badgeView: false,
                appendTo: 'body',
              },
            },
            dataType: DataTypeEnum.Text,
            showLabelInViewMode: false,
          },
          // viewModeOnly:true
        },
      ];
    }
  }

  get riskMeth() {
    return this._riskMeth;
  }

  answersControl: FormControl = new FormControl();

  get residualFieldsViewMode() {
    return this.fieldViewMode == 'edit' ? (this.data?.status == 'RESPONSE' ? 'edit' : 'view') : this.fieldViewMode;
  }

  get impactFactorsViewMode() {
    // return this.fieldViewMode == 'edit'
    //   ? this.data?.status == 'APPROVED' || this.data?.status == 'SUBMITTED'
    //     ? 'view'
    //     : 'edit'
    //   : this.fieldViewMode;
    return this.fieldViewMode == 'edit' ? (this.data?.status == 'DRAFT' ? 'edit' : 'view') : this.fieldViewMode;
  }

  factorsColumn;

  constructor(
    public viewModeService: ViewModeService,
    private entityService: EntityDataService,
    public riskMethodologyImpactFactorOptionsMappingService: RiskMethodologyImpactFactorOptionMappingService,
    private riskStatementService: RiskStatementDataService
  ) {
    super(viewModeService, ModuleKeywords.RiskItem);
    this.factorsColumn = riskMethodologyImpactFactorOptionsMappingService?.tableFields?.find(x => x.key == 'impactFactor');
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl({ value: '', disabled: false }, Validators.required),
      description: new FormControl({ value: '', disabled: false }),
      riskStatement: new FormControl({ value: null, disabled: false }),
      category: new FormControl({ value: null, disabled: true }),
      approver: new FormControl({ value: null, disabled: false }, Validators.required),
      assignToAssetOwners: new FormControl(true),
      owner: new FormControl({ value: null, disabled: false }, Validators.required),
      riskNotes: new FormControl({ value: '', disabled: false }),
      riskAssessment: new FormControl({ value: null, disabled: false }),
      riskAssessmentId: new FormControl({ value: null, disabled: false }),
      inheritedRiskImpactDto: new FormControl({ value: null, disabled: false }, Validators.required),
      inheritedLikelihoodDto: new FormControl({ value: null, disabled: false }),
      residualLikelihoodDto: new FormControl({ value: null, disabled: true }),
      residualImpactDto: new FormControl({ value: null, disabled: true }),
      inheritedRisk: new FormControl({ value: null, disabled: true }),
      residualImpact: new FormControl({ value: null, disabled: true }),
      residualRisk: new FormControl({ value: null, disabled: true }),
      comments: new FormControl(null),
      project: new FormControl(null),
      entityId: new FormControl(null, Validators.required),
      controls: new FormControl({ value: null, disabled: true }),
      strategy: new FormControl({ value: null, disabled: true }),
      acceptors: new FormControl({ value: null, disabled: true }, Validators.required),
      inheritedRiskAcceptance: new FormControl({ value: null, disabled: true }),
      residualRiskAcceptance: new FormControl({ value: null, disabled: true }),
      riskThreats: new FormControl(null),
      riskVulnerabilities: new FormControl(null),
      impactFactors: new FormControl(null),
      riskMethodologyImpactFactorOption: new FormControl(null, [Validators.required, Validators.minLength(1)]),
      // riskImpactObjectControl: new FormControl(null),
      // factorsRepeaterControl: new FormControl(null, [Validators.required, Validators.minLength(1)]),
    });
  }
  getCellThreshold(meth: RiskMethodology, cellValue) {
    let threshold = find(meth?.thresholdValuesDto, function (o) {
      return o.max >= cellValue && o.min <= cellValue;
    });

    if (threshold != undefined) return threshold.name;
    else return '';
  }

  filter(data, func) {
    return Object.keys(data)
      .filter((key) => func(data[key]))
      .reduce((res, key) => Object.assign(res, { [key]: data[key] }), {});
  }

  getData() {
    const tempForm = cloneDeep(this.formGroup.getRawValue());

    const formData = omit(cloneDeep(this.formGroup.getRawValue()),
      'inheritedRiskImpactDto',
      'inheritedLikelihoodDto',
      'residualLikelihoodDto',
      'residualImpactDto',
      'riskMethodologyImpactFactorOption');

    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat({
        ...this.filter(formData, (key) => key !== 'factorsRepeaterControl' && key !== 'riskImpactObjectControl'),
        entityId: this.formGroup?.getRawValue()?.entityId?.code,
        project: this.formGroup?.getRawValue()?.project?.code,
        residualClassification: this.getCellThreshold(
          this.riskMeth,
          this.formGroup?.controls?.residualRisk?.getRawValue()
        ),
        inheritedClassification: this.getCellThreshold(
          this.riskMeth,
          this.formGroup?.controls?.inheritedRisk?.getRawValue()
        ),
        inheritedRiskImpact: tempForm.inheritedRiskImpactDto?.code,
        inheritedLikelihood: tempForm.inheritedLikelihoodDto?.code,
        residualLikelihood: tempForm.residualLikelihoodDto?.code,
        residualImpact: tempForm.residualImpactDto?.code,
        impactFactorOptions: tempForm.riskMethodologyImpactFactorOption?.map(x => x.code),
        methodology: this.riskMeth?.code,
        // inheritedRisk:{...this.formGroup.getRawValue().inheritedRisk,factors:this.factorsRepeaterControl.value},
        // residualRisk: tempForm.residualImpactDto?.code,
      })
      : this.getChangedFormValues({
        ...this.filter(formData, (key) => key !== 'factorsRepeaterControl' && key !== 'riskImpactObjectControl'),
        entityId: this.formGroup?.getRawValue()?.entityId?.code,
        project: this.formGroup?.getRawValue()?.project?.code,
        residualClassification: this.getCellThreshold(
          this.riskMeth,
          this.formGroup?.controls?.residualRisk?.getRawValue()
        ),
        inheritedClassification: this.getCellThreshold(
          this.riskMeth,
          this.formGroup?.controls?.inheritedRisk?.getRawValue()
        ),
        inheritedRiskImpact: tempForm.inheritedRiskImpactDto?.code,
        inheritedLikelihood: tempForm.inheritedLikelihoodDto?.code,
        residualLikelihood: tempForm.residualLikelihoodDto?.code,
        residualImpact: tempForm.residualImpactDto?.code,
        impactFactorOptions: tempForm.riskMethodologyImpactFactorOption?.map(x => x.code)
        // inheritedRisk:{...this.formGroup.getRawValue().inheritedRisk,factors:this.factorsRepeaterControl.value},
        // residualRisk:{...this.formGroup.getRawValue().residualRisk,factors:this.factorsRepeaterControl.value},
      }).updateItems;
  }

  ngOnInit(): void {
    this.riskValueSubscriber();
    this.formGroup?.controls?.riskMethodologyImpactFactorOption?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      const listOfValues = value?.filter(x => this.impactFactorOptionToValueMap[x?.code])?.map(x => this.impactFactorOptionToValueMap[x?.code]);

      let a: any = maxBy(listOfValues, 'value');
      if (a && (this.data?.status == 'DRAFT' || this.data?.status == 'SUBMITTED')) {
        this.impactList = this.impactList.map(x => { return { ...x, disabled: x?.value < a?.value } });
      }
      // this.formGroup.controls.riskImpactObjectControl.patchValue(a);
      this.formGroup.controls.inheritedRiskImpactDto.patchValue(a);
    });
    // this.formGroup?.controls?.factorsRepeaterControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((values) => {
    //   let a: any = maxBy(values, 'factorVal.value.value.impactValueDto.value');
    //   a = { ...a?.factorVal };

    //   const patchVal = a?.value?.value?.code
    //     ? {
    //       value: a?.value?.value?.impactValueDto ?? null,
    //       code: a?.value?.value?.impactValue ?? null,
    //       name: a?.value?.value?.name ?? null,
    //       description: a?.value?.value?.description ?? null,

    //     }
    //     : null;
    //   this.formGroup.controls.impactFactors.patchValue(
    //     values?.map((item) => {
    //       return {
    //         riskMethodologyImpactFactor: item?.factorVal?.factor?.code,
    //         riskMethodologyImpactFactorOption: item?.factorVal?.value?.code,
    //         riskMethodologyImpactValue: item?.factorVal?.value.value.impactValue
    //       };
    //     }) ?? null);
    //   this.riskImpactObjectControl.patchValue(patchVal?.value);
    //   this.formGroup.controls.inheritedRiskImpactDto.patchValue({ label: a?.factor?.label, code: a?.factor?.code });
    // });
  }


  setData(data: any) {
    this.formGroup.patchValue({
      ...data,
      project: data?.project ? { code: data?.project } : null,
      entityId: data?.entityId ? { code: data?.entityId } : null,
    });
    this.data = {
      ...data,
      project: data?.project ? { code: data?.project } : null,
      entityId: data?.entityId ? { code: data?.entityId } : data?.entityId,
    };
    let factors = data?.impactFactors?.map((item) => {
      let selectedValue;
      return {
        factorVal: {
          factor: {
            label: item.riskMethodologyImpactFactorDto?.label,
            items: item.riskMethodologyImpactFactorDto?.impactFactorItemsDto?.map((ifItem) => {
              return {
                label: ifItem.label,
                value: ifItem,
              };
            }),
            code: item.riskMethodologyImpactFactorDto?.code,
          },
          value: {
            label: item.riskMethodologyImpactFactorOptionDto?.label,
            value: item.riskMethodologyImpactFactorOptionDto,
            code: item.riskMethodologyImpactFactorOptionDto?.code,
          },
        },
      };
    });

    this.formGroup?.controls?.name?.patchValue(data?.name ?? null);
    // this.formGroup?.controls?.factorsRepeaterControl.patchValue(factors ?? []);

    if (this.data?.status && this.data?.status != 'DRAFT') {
      this.formGroup?.disable();
      this.formGroup?.controls?.riskVulnerabilities?.enable();
      this.formGroup?.controls?.riskThreats?.enable();
    }
    // if (this.data?.status != 'APPROVED' && this.data?.status != 'SUBMITTED') {
    //   // this.formGroup?.controls?.factorsRepeaterControl?.enable();
    //   this.formGroup?.controls?.riskMethodologyImpactFactorOption.enable();
    // } else {
    //   // this.formGroup?.controls?.factorsRepeaterControl?.disable();
    //   this.formGroup?.controls?.riskMethodologyImpactFactorOption.disable();
    // }
    if (this.viewModeService.viewMode == 'create' || this.data?.status == 'DRAFT' || this.data?.status == 'SUBMITTED') {
      this.formGroup?.controls?.riskMethodologyImpactFactorOption.enable();
      this.formGroup?.controls?.inheritedRiskImpactDto?.enable();
      this.formGroup?.controls?.inheritedLikelihoodDto?.enable();
    } else {
      this.formGroup?.controls?.riskMethodologyImpactFactorOption.disable();
      this.formGroup?.controls?.inheritedRiskImpactDto?.disable();
      this.formGroup?.controls?.inheritedLikelihoodDto?.disable();
    }

    if (this.data?.status && this.data?.status == 'RESPONSE') {
      this.formGroup.controls.strategy.enable();
    }
    if (this.data?.type == 'RISK_PROJECT') {
      this.formGroup.controls.project.disable();
    }
    this.formGroup?.controls?.riskNotes?.enable();

  }



  riskValueSubscriber() {
    this.formGroup.controls.strategy.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: RiskItem.StrategyEnum) => {
        if (res == 'MITIGATE') {
          this.formGroup.controls.residualLikelihoodDto.enable();

        } else {
          this.formGroup.controls.residualLikelihoodDto.disable();
        }
        if (this.data.status == 'RESPONSE' && (res == 'MITIGATE' || res == 'TRANSFER')) {
          this.formGroup.controls.controls.enable();
        } else if (this.data.status == 'RESPONSE' && res == 'EXCEPTIONAL_ACCEPTANCE') {
          this.formGroup.controls.acceptors.enable();
        } else {
          this.formGroup?.controls?.controls?.patchValue(null);
          this.formGroup?.controls?.controls?.updateValueAndValidity();
          this.formGroup?.controls?.controls?.disable();
        }
      });
    if (this.viewModeService.viewMode == 'create') {
      this.formGroup.controls.riskStatement.valueChanges
        .subscribe((res) => {

          this.riskStatementService.getByIdOrCode(res).subscribe(statement => {
            this.formGroup.controls.category.patchValue(statement?.data?.category ?? null);
          });
        });
    }

    this.formGroup.controls.controls.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      if (isNullObj(res)) {
        this.formGroup.controls.residualImpactDto.disable();
        this.formGroup.controls.residualLikelihoodDto.disable();
      } else {
        if (this.data.status == 'RESPONSE') {
          this.formGroup.controls.residualImpactDto.enable();
          this.formGroup.controls.residualLikelihoodDto.enable();
        }
      }
    });
    combineLatest([
      this.formGroup.controls.inheritedLikelihoodDto.valueChanges.pipe(
        startWith(this.formGroup.controls.inheritedLikelihoodDto.value)
      ),
      this.formGroup?.controls?.inheritedRiskImpactDto.valueChanges.pipe(
        startWith(this.formGroup?.controls?.inheritedRiskImpactDto.value)
      ),
    ])
      .pipe(
        takeUntil(this.destroy$)
        // ,filter(([inheritedLikelihood, inheritedRiskImpact]) => inheritedLikelihood && inheritedRiskImpact)
      )
      .subscribe(([inheritedLikelihood, inheritedRiskImpact]) => {
        // let a:any = maxBy(inheritedRiskImpact, "value.value");
        const irValue = inheritedLikelihood && inheritedRiskImpact ? inheritedLikelihood?.value * (inheritedRiskImpact?.value?.value ?? inheritedRiskImpact?.value) : null;
        this.formGroup.get('inheritedRisk').setValue(irValue);
        if (this.viewModeService.viewMode == 'create' &&
          !this.formGroup.controls.controls.value &&
          (this.data?.strategy != 'MITIGATE' ||
            (!isNullObj(this.formGroup?.controls?.stratgey?.value) &&
              this.formGroup?.controls?.stratgey?.value != 'MITIGATE'))
        ) {
          // this.formGroup.get('residualImpact').setValue(inheritedRiskImpact ? inheritedRiskImpact?.code : null);
          this.formGroup.get('residualImpactDto').setValue(inheritedRiskImpact ? inheritedRiskImpact : null);
          this.formGroup.get('residualLikelihoodDto').setValue(inheritedLikelihood ? inheritedLikelihood : null);
        }
      });
    combineLatest([
      this.formGroup.controls.residualLikelihoodDto.valueChanges.pipe(
        startWith(this.formGroup.controls.residualLikelihoodDto.value)
      ),
      this.formGroup.controls.residualImpactDto.valueChanges.pipe(
        startWith(this.formGroup.controls.residualImpactDto.value)
      ),
    ])
      .pipe(
        takeUntil(this.destroy$)
        // ,filter(([inheritedLikelihood, inheritedRiskImpact]) => inheritedLikelihood && inheritedRiskImpact)
      )
      .subscribe(([inheritedLikelihood, inheritedRiskImpact]) => {

        const rrValue = inheritedLikelihood && inheritedRiskImpact ? (inheritedLikelihood?.value * (inheritedRiskImpact?.value?.value ?? inheritedRiskImpact?.value)) : null;
        this.formGroup.get('residualRisk').setValue(rrValue);

      });
    // combineLatest([
    //   this.formGroup.controls.residualImpact.valueChanges.pipe(startWith(this.formGroup.controls.residualImpact.value)),
    //   this.formGroup.controls.residualLikelihood.valueChanges.pipe(
    //     startWith(this.formGroup.controls.residualLikelihood.value)
    //   ),
    // ])
    //   .pipe(
    //     takeUntil(this.destroy$)
    //     // ,filter(([residualImpact, residualLikelihood]) => residualImpact && residualLikelihood)
    //   )
    //   .subscribe(([residualImpact, residualLikelihood]) => {
    //     // let a:any = maxBy(residualImpact, "value.value");

    //     this.formGroup
    //       .get('residualRisk')
    //       .setValue(residualImpact && residualLikelihood ? residualImpact?.value?.value * residualLikelihood?.value : null);
    //   });
    this.formGroup?.controls?.inheritedRisk?.valueChanges.subscribe((res) => {
      if (this.riskMeth) {
        if (res) {
          this.formGroup?.controls?.inheritedRiskAcceptance?.patchValue(
            res > this.riskMeth?.acceptableRisk
              ? RiskItem.InheritedRiskAcceptanceEnum.NotAcceptable
              : RiskItem.InheritedRiskAcceptanceEnum.Acceptable
          );
        } else {
          this.formGroup?.controls?.inheritedRiskAcceptance?.patchValue(null);
        }
      } else {
        //IDK WHAT TO DO
        // this.formGroup?.controls?.inheritedRiskAcceptance?.patchValue(null);
      }
    });
    this.formGroup?.controls?.residualRisk?.valueChanges.subscribe((res) => {
      if (this.riskMeth) {
        if (res) {
          this.formGroup?.controls?.residualRiskAcceptance?.patchValue(
            res > this.riskMeth?.acceptableRisk
              ? RiskItem.ResidualRiskAcceptanceEnum.NotAcceptable
              : RiskItem.ResidualRiskAcceptanceEnum.Acceptable
          );
        } else {
          this.formGroup?.controls?.residualRiskAcceptance?.patchValue(null);
        }
      } else {
        //IDK WHAT TO DO
        // this.formGroup?.controls?.residualRiskAcceptance?.patchValue(null);
      }
    });
    this.formGroup.controls.project.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((data: RiskProject) => {
      if ((false && this.data?.type == 'MANUAL') || !this.data?.type) {
        let nameList = [];
        if (data?.name) nameList.push(data?.name);
        if (this.formGroup?.getRawValue()?.entityId?.name) nameList.push(this.formGroup?.getRawValue()?.entityId?.name);
        const namePatch = nameList.length > 0 ? nameList.join(' - ') : this.data?.name || null;

        this.formGroup.patchValue({
          //name: namePatch,//this.formGroup?.getRawValue().name,
          description: data?.description ?? this.formGroup?.getRawValue().description,
          riskStatement: data?.riskStatement ?? this.formGroup?.getRawValue().riskStatement,
          riskAssessment: data?.riskAssessment ?? this.formGroup?.getRawValue().riskAssessment,
          riskAssessmentId: data?.riskAssessment ?? this.formGroup?.getRawValue().riskAssessmentId,
          // category:data?.riskStatement?.category,
        });
        // if (data) {
        //     this.formGroup?.controls.riskStatement.disable();
        // } else {
        //     this.formGroup?.controls.riskStatement.enable();
        // }
        // if (data) {
        //   this.formGroup?.controls.riskAssessmentId.disable();
        // } else {
        //   this.formGroup?.controls.riskAssessmentId.enable();
        // }
        // if (data) {
        //   this.formGroup?.controls.riskAssessment.disable();
        // } else {
        //   this.formGroup?.controls.riskAssessment.enable();
        // }
      }
    });

    this.formGroup.controls.entityId.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if ((false && this.data?.type == 'MANUAL') || !this.data?.type) {
        let nameList = [];
        if (this.formGroup?.getRawValue()?.project?.name) nameList.push(this.formGroup?.getRawValue()?.project?.name);
        if (data?.name) nameList.push(data?.name);
        const namePatch = nameList.length > 0 ? nameList.join(' - ') : this.data?.name || null;

        //this.formGroup.patchValue({ name: namePatch })//this.formGroup?.getRawValue().name,
        // if(nameList.length > 0){
        //     this.formGroup.patchValue({
        //         name: nameList.join('-'),
        //     });
        // }
        // else{
        //     this.formGroup.patchValue({
        //         name: this.data?.name || null,//this.formGroup?.getRawValue().name,
        //     })
        // }
      }
    });
    // this.formGroup.valueChanges
    // .pipe(takeUntil(this.destroy$))
    // .subscribe((data) => {
    //     if (data?.inheritedLikelihood && data?.inheritedRiskImpact){
    //         this.formGroup.patchValue({inheritedRisk: data?.inheritedLikelihood?.value * data?.inheritedRiskImpact?.value},{onlySelf:true,emitEvent:false})
    //     }else{
    //         this.formGroup.patchValue({inheritedRisk:null},{onlySelf:true,emitEvent:false})
    //     }
    //     if (data?.residualImpact && data?.residualLikelihood){
    //         this.formGroup.patchValue({residualRisk: data?.residualLikelihood?.value * data?.residualImpact?.value},{onlySelf:true,emitEvent:false})
    //     }else{
    //         this.formGroup.patchValue({residualRisk:null},{onlySelf:true,emitEvent:false})
    //     }
    // });
  }
}
