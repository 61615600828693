import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { LocaleService } from '@core/services/locale/locale.service';
import {
  BaseForm,
  DataTypeEnum,
  IAction,
  IDynamicComponent,
  LanguageDto,
  getModuleKeywordByCode,
} from '@shared/classes';
import { BasicInputComponent } from '@shared/components/ui/input-fields/basic-input/basic-input.component';
import { TextEditorComponent } from '@shared/components/ui/input-fields/text-editor/text-editor.component';
import {
  BaseFieldDefinitionsService,
  BaseRequestControllerService,
  ToastService,
  ViewModeService,
} from '@shared/services';
import { LanguageDataService } from 'app/modules/global-configuration/services/data/language-data.service';
import { omit } from 'lodash-es';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-field-translations',
  templateUrl: './field-translations.component.html',
  styleUrl: './field-translations.component.scss',
})
export class FieldTranslationsComponent extends BaseForm<any> implements OnInit {
  dataService: BaseRequestControllerService<any>;
  mappingService: BaseFieldDefinitionsService;
  itemCode: string;
  options: any;
  label: string = 'Field';
  placeholder: string = 'Enter Field';
  fieldKey: string = 'name';
  textFieldType: DataTypeEnum.Text | DataTypeEnum.LongText = DataTypeEnum.Text;

  listOfTranslatableFields = [BasicInputComponent, TextEditorComponent];
  textFields: IDynamicComponent[] = [];
  oldTextFields: IDynamicComponent[] = [];
  newTextFields: IDynamicComponent[] = [];
  viewOnlyForm: FormGroup = new FormGroup({});
  allLanguages: LanguageDto[] = [];
  reloadLanguagesAction: IAction = {
    id: 1,
    label: 'Reload Translations',
    buttonType: 'button',
    command: this.loadAllLanguages.bind(this),
    icon: 'pi pi-refresh',
    tooltipText: 'Reload Translations',
    color: 'secondary',
  };
  constructor(
    public viewContainerRef: ViewContainerRef,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public toast: ToastService,
    public viewModeService: ViewModeService,
    public localeService: LocaleService,
    public languageDataService: LanguageDataService
  ) {
    super(viewModeService, getModuleKeywordByCode(config?.data?.itemCode) as any);
    this.initForms();
    this.dataService = this.config?.data?.dataService;
    this.mappingService = this.config?.data?.mappingService;
    this.label = this.config?.data?.label ?? this.label;
    this.placeholder = this.config?.data?.placeholder ?? this.placeholder;
    this.fieldKey = this.config?.data?.fieldKey ?? this.fieldKey;
    this.itemCode = this.config?.data?.itemCode ?? this.itemCode;
    // this.formData = this.config?.data?.formData ?? this.data;
    this.textFieldType = this.config?.data?.textFieldType ?? this.textFieldType;
    this.options = this.config?.data?.options ?? this.options;
    this.loadAllLanguages();

    // this.formModuleKeyword = getModuleKeywordByCode(this.config?.data?.itemCode) as any;
  }

  ngOnInit(): void {
    this.submitActionSubscriber();
  }
  submitActionSubscriber() {
    this.formSubmit.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      console.log(data);
      if (this.data) {
        this.dataService
          .updateTranslationsFields(this.itemCode, {
            translationBodies: Object.entries(omit(data, '_field')).map(([key, value]) => {
              return {
                langCode: key,
                translation: {
                  [this.fieldKey]: value,
                },
              };
            }),
          })
          .subscribe((res) => {
            this.ref.close(true);
          });
      } else {
        this.dataService
          .addTranslation(this.itemCode, {
            langCode: data?.langCode,
            translation: { ...omit(data, 'langCode') },
          })
          .subscribe((res) => {
            this.ref.close(true);
          });
      }
    });
  }
  getData() {
    return { ...this.formGroup.getRawValue() };
  }

  setData(data: any) {
    this.formGroup.patchValue({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      _field: new FormControl(null),
    });
  }
  initForms() {
    this.viewOnlyForm = new FormGroup({
      originalLanguage: new FormControl({ value: null, disabled: true }),
    });
    this.initFormStructure();
  }
  initDynamicFields() {
    this.textFields = this.allLanguages
      ?.filter((x) => {
        const ret = !!x?.langCode;
        if (ret) {
          const key = x?.langCode;
          // this.viewOnlyForm.addControl(key, new FormControl({ value: this.originalData?.[key], disabled: true }));
          this.formGroup.addControl(key, new FormControl(this.data?.[key]));
        }
        return ret;
      })
      ?.map((field) => {
        return {
          componentType: this.textFieldType == DataTypeEnum.LongText ? TextEditorComponent : BasicInputComponent,
          options: {
            inputOptions: {
              textEditorInput: {
                advanced: true,
              },
            },
            label: field?.langCode,
            name: field?.langCode,
            control: this.formGroup?.controls?.[field?.langCode],
            dataType: this.textFieldType,
            showLabelInViewMode: true,
          },
          viewModeOnly: false,
        } as IDynamicComponent;
      });
    // this.oldTextFields = this.textFields.map((x) => {
    //   return {
    //     ...x,
    //     options: { ...x.options, control: this.viewOnlyForm?.controls?.[x?.options?.name], showLabelInViewMode: true },
    //   } as IDynamicComponent;
    // });
    // this.viewOnlyForm.controls.originalLanguage.patchValue(this.originalData?.originalLanguage);
    // this.newTextFields = this.textFields.map((x) => {
    //   return {
    //     ...x,
    //     options: { ...x.options, control: this.formGroup?.controls?.[x?.options?.name], showLabelInViewMode: true },
    //   } as IDynamicComponent;
    // });
  }
  onCancel() {
    this.ref.close(null);
  }
  loadAllLanguages() {
    let sub = this.languageDataService.downloadAllLanguagesWithFiles().subscribe({
      next: (res) => {
        console.log(res);
        this.allLanguages = res;
        this.dataService.getByIdOrCode(this.itemCode).subscribe({
          next: (res) => {
            const translationsFields = {};
            translationsFields['_field'] = res?.data?.[this.fieldKey];
            this.allLanguages.forEach((lang) => {
              translationsFields[lang.langCode] = res?.data?.translations?.[lang.langCode]?.[this.fieldKey] || null;
            });
            this.formData = translationsFields;
            this.initDynamicFields();
            sub.unsubscribe();
          },
        });
      },
      error: (err) => {
        sub.unsubscribe();
      },
    });
  }
  get isTextEditor() {
    return this.textFieldType == DataTypeEnum.LongText;
  }
}
