<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12">
      <app-basic-input
        [label]="moduleFieldString + '.name.label' | translate: 'Name'"
        [placeholder]="moduleFieldString + '.name.placeholder' | translate: 'Name'"
        [control]="formGroup?.controls?.name"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>
    <div class="col-12">
      <app-target-code-selector
        [label]="moduleFieldString + '.issues.label' | translate: 'Issues'"
        [placeholder]="moduleFieldString + '.issues.placeholder' | translate: 'Search Issues'"
        [control]="formGroup?.controls?.issues"
        [viewMode]="fieldViewMode"
        [multi]="true"
        [optionLabel]="'name'"
        [optionValue]="'code'"
        [targetTypes]="['ISSUE']"
        [extraFilters]="extraFilters"
      >
      </app-target-code-selector>
    </div>
    <div class="col-12 md:col-6">
      <!-- <app-entity-category-tree-selector
      [label]="'Owner'"
      [categoryList]="categoryList"
      [placeholder]="'Search Entities'"
      [control]="formGroup?.controls?.owner" [multi]="false"
      [selectedEntities]="[data?.owner]"
      [viewMode]="fieldViewMode"
      [dropDown]="true">
    </app-entity-category-tree-selector> -->
      <app-user-and-group-selector
        [label]="moduleFieldString + '.owner.label' | translate: 'Owner'"
        [placeholder]="moduleFieldString + '.owner.placeholder' | translate: 'Search...'"
        [principleMode]="true"
        [control]="formGroup?.controls?.owner"
        [optionValue]="undefined"
        [multi]="false"
        [viewMode]="fieldViewMode"
      >
      </app-user-and-group-selector>
    </div>

    <div class="col-12 md:col-6">
      <!-- <app-entity-category-tree-selector
    [label]="'Approvers'"
    [categoryList]="categoryList"
    [placeholder]="'Search Entities'"
    [control]="formGroup?.controls?.approvers"
    [selectedEntities]="data?.approvers"
    [multi]="true"
    [viewMode]="fieldViewMode"
    [dropDown]="true"
    [useResponsibilityMode]="true"
    [dataType]="dataTypeEnum.ResponsibilityWithAllOneMode"
    >
  </app-entity-category-tree-selector> -->
      <app-user-and-group-tree-selector
        [label]="moduleFieldString + '.approvers.label' | translate: 'Approvers'"
        [placeholder]="moduleFieldString + '.approvers.placeholder' | translate: 'Search...'"
        [control]="formGroup?.controls?.approvers"
        [multi]="true"
        [selectedEntities]="data?.approvers"
        [viewMode]="fieldViewMode"
        [useResponsibilityMode]="true"
        [principleModeSelection]="'SELECTION'"
      >
      </app-user-and-group-tree-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-date-input
        [label]="moduleFieldString + '.startDate.label' | translate: 'Start Date'"
        [placeholder]="'2022-11-22'"
        [name]="'startDate'"
        [showIcon]="true"
        [control]="formGroup?.controls?.startDate"
        [viewMode]="fieldViewMode"
        [maxDate]="formGroup?.controls?.dueDate?.value || null"
      ></app-date-input>
    </div>

    <div class="col-12 md:col-6">
      <app-date-input
        [label]="moduleFieldString + '.dueDate.label' | translate: 'Due Date'"
        [placeholder]="'2022-11-22'"
        [name]="'dueDate'"
        [showIcon]="true"
        [control]="formGroup?.controls?.dueDate"
        [viewMode]="fieldViewMode"
        [minDate]="formGroup?.controls?.startDate?.value || null"
      ></app-date-input>
    </div>
    @if (editMode && actualDatesFormGroup?.controls?.actualStartDate) {
      <div class="col-12 md:col-6">
        <app-date-input
          [label]="moduleFieldString + '.actualStartDate.label' | translate: 'Actual Start Date '"
          [placeholder]="'2022-11-22'"
          [name]="'actualStartDate'"
          [showIcon]="false"
          [control]="actualDatesFormGroup?.controls?.actualStartDate"
          [viewMode]="'view'"
          [canKeepValue]="false"
        ></app-date-input>
      </div>
    }
    @if (editMode && actualDatesFormGroup?.controls?.actualCloseDate) {
      <div class="col-12 md:col-6">
        <app-date-input
          [label]="moduleFieldString + '.actualCloseDate.label' | translate: 'Actual Close Date '"
          [placeholder]="'2022-11-22'"
          [name]="'actualCloseDate'"
          [showIcon]="false"
          [control]="actualDatesFormGroup?.controls?.actualCloseDate"
          [viewMode]="'view'"
          [canKeepValue]="false"
        ></app-date-input>
      </div>
    }

    <!-- <div class="col-12 md:col-6">
<label>Progress </label>

<ngx-slider
  [options]="options"
  [(value)]="progress"
></ngx-slider>

</div> -->
    @if (editMode) {
      <div class="col-12">
        <div class="field">
          <span class="flex flex-row md:flex-nowrap flex-wrap gap-1 w-full align-items-center">
            <label [class]="'md:w-12rem md:max-w-12rem md:min-w-12rem input-field-label '">
              {{ moduleFieldString + '.progress.label' | translate: 'Progress' }}
            </label>
            <app-generic-progress-bar-view [data]="data?.progress"></app-generic-progress-bar-view>
          </span>
        </div>
        <!-- <app-p-slider-input
          [name]="'progress'"
          [label]="moduleFieldString + '.progress.label' | translate: 'Progress '"
          [placeholder]="moduleFieldString + '.progress.placeholder' | translate: 'Enter Progress Number'"
          [control]="formGroup?.controls?.progress"
          [viewMode]="fieldViewMode"
          [valueSuffix]="'%'"
        ></app-p-slider-input> -->
      </div>
    }
    <div class="col-12 md:col-12">
      <app-text-editor
        [name]="'description'"
        [label]="moduleFieldString + '.description.label' | translate: 'Description'"
        [placeholder]="moduleFieldString + '.description.placeholder' | translate: 'Enter Text...'"
        [control]="formGroup?.controls?.description"
        [viewMode]="fieldViewMode"
        [advanced]="true"
      ></app-text-editor>
    </div>
  </div>
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create') {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create') {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
