import { Component, Input, OnInit } from '@angular/core';
import { JWTTokenService } from '@core/services/JWT-token/jwttoken.service';
import { LocaleService } from '@core/services/locale/locale.service';
import { AcknowledgmentDto, DynamicComponentBase, FilterItem, IAction, ITableLoadEvent, ModuleKeywords, PermissionActions, humanizeCasedString, isNullObj } from '@shared/classes';
import { BaseFormPopupComponent } from '@shared/components/misc/base-form-popup/base-form-popup.component';
import { AppDialogService, PathResolverService } from '@shared/services';
import { FormMapperService } from '@shared/services/mappings/form-mapper.service';
import { AppInjector } from 'app/app-injector';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-add-reorder-table',
  templateUrl: './add-reorder-table.component.html',
  styleUrl: './add-reorder-table.component.scss'
})
export class AddReorderTableComponent extends DynamicComponentBase implements OnInit {

  cols;
  @Input() addLast: boolean = true;
  @Input() disableAdd: boolean = false;
  @Input() onlyLastisntViewMode: boolean = false;

  @Input() colsFilter: String[];
  tableEvent: ITableLoadEvent = new ITableLoadEvent();;
  loading = false;
  readonly defaultSort = [];

  @Input() set moduleKeyword(val: ModuleKeywords) {
    this._moduleKeyword = val;
  }
  private _moduleKeyword: ModuleKeywords;
  get moduleKeyword() {
    return this._moduleKeyword;
  }

  @Input() set customSort(sort: String[]) {
    this._customSort = sort;
  }
  private _customSort: String[] = [];
  get customSort() {
    return this._customSort;
  }

  @Input() set extraFilters(filters: FilterItem[]) {
    this._extraFilters = filters;
  }
  private _extraFilters: FilterItem[] = [];
  get extraFilters() {
    return this._extraFilters;
  }
  @Input() additionalProjectionFields: string[] = [];
  @Input() formOptions: any;
  @Input() reorderable: boolean = true;
  addAction: IAction = {
    id: 1,
    icon: 'pi pi-plus',
    buttonType: 'button',
    command: this.addPopup.bind(this),
  };
  editAction: IAction = {
    id: 2,
    label: 'Edit',
    icon: 'pi pi-pencil',
    permission: `${PermissionActions.Update}${this.moduleKeyword}`,
    command: this.editPopup.bind(this),
  };

  deleteAction: IAction = {
    id: 3,
    label: 'Delete',
    icon: 'pi pi-trash',
    permission: `${PermissionActions.Delete}${this.moduleKeyword}`,
    command: this.deletePopup.bind(this),
  };
  private dataService;
  constructor(private localeService: LocaleService,
    public appDialogService: AppDialogService,
    private pathResolverService: PathResolverService,
    private permissionService: NgxPermissionsService,
    private formMapper: FormMapperService,
    private jWTTokenService: JWTTokenService,
  ) {
    super();
  }

  setInputOptions(): void {
  }


  ngOnInit(): void {
    const jwtTokenService = AppInjector.get(JWTTokenService);

    this.editAction = {
      id: 2,
      label: 'Edit',
      icon: 'pi pi-pencil',
      iconPos: 'left',
      group: { id: 1, type: 'list' },
      permission: `${PermissionActions.Update}${this.moduleKeyword}`,
      displayCommand: (data: AcknowledgmentDto) =>
        (!data?.recordStatus || (data?.recordStatus && data?.recordStatus == 'ACTIVE')) &&
        (!data?.lockedForEdit || data?.lockedBy == jwtTokenService.getPreferredUsername()) &&
        (jwtTokenService.getSfAdmin() ||
          !data?.userAccessLevel ||
          data?.userAccessLevel?.accessLevel == 'OWNER' ||
          data?.userAccessLevel?.accessLevel == 'ADMIN' ||
          data?.userAccessLevel?.accessLevel == 'UPDATE'),
      command: this.editPopup.bind(this),
    };
    this.deleteAction = {
      id: 3,
      label: 'Delete',
      icon: 'pi pi-trash',
      iconPos: 'left',
      permission: `${PermissionActions.Delete}${this.moduleKeyword}`,
      group: { id: 1, type: 'list' },
      displayCommand: (data: AcknowledgmentDto) =>
        (!data?.recordStatus || (data?.recordStatus && data?.recordStatus == 'ACTIVE')) &&
        (!data?.lockedForEdit || data?.lockedBy == jwtTokenService.getPreferredUsername()) &&
        (jwtTokenService.getSfAdmin() ||
          !data?.userAccessLevel ||
          data?.userAccessLevel?.accessLevel == 'OWNER' ||
          data?.userAccessLevel?.accessLevel == 'ADMIN' ||
          data?.userAccessLevel?.accessLevel == 'UPDATE'),
      command: this.deletePopup.bind(this),
    };
    if (this.moduleKeyword) {
      if (this.colsFilter) {
        this.cols = this.pathResolverService.getMappingServiceByModuleKeyword(this.moduleKeyword).tableFields.filter(x => this.colsFilter.includes(x.key));
      } else {
        this.cols = this.pathResolverService.getMappingServiceByModuleKeyword(this.moduleKeyword).tableFields;
      }
    }
    this.fetchTableData();
  }

  fetchTableData() {
    if (this.moduleKeyword) {
      this.dataService = this.pathResolverService.getDataServiceByModuleKeyword(this.moduleKeyword);
    }
    //  this.tableEvent = { ...tableEvent };
    this.loading = true;

    const sort = [...(this.customSort ?? this.tableEvent?.pageInfo?.pagination?.sort ?? [])];
    const filters = [...(this.extraFilters ?? []), ...(this.tableEvent?.filters ?? [])];

    const exec = {
      next: (res: any) => {
        // this.inited = true;
        if (res?.length) {
          this.data = ((res as any) ?? []).sort((a, b) => a?.displayOrder && b?.displayOrder ? (a.displayOrder - b.displayOrder) : -Infinity);
        } else {
          this.data = ((res as any) ?? []);
        }
        this.control?.patchValue(this.data);

        // this.tableEvent.pageInfo.totalElements = res?.totalElements ?? 0;
        this.loading = false;
      },
      error: (error) => {
        this.loading = false;

        // this.isLoading = false;
      },
      complete: () => {
        this.loading = false;
      },
    };
    // const search =
    //   tableEvent?.textSearch; /*? { ...tableEvent?.textSearch, search: trim(tableEvent?.textSearch?.search, ' ') } : undefined*/

    this.dataService
      .search(
        { all: true, sort: sort?.length > 0 ? sort : this.defaultSort },
        {
          projectionFields: isNullObj(this.additionalProjectionFields)
            ? this.dataService.projectionFields
            : [...this.dataService.projectionFields, ...this.additionalProjectionFields],
          filters: filters?.length ? filters : null,
        }
      )
      .subscribe(exec);
  }

  onDropEvent(event) {
    const newList = [...(this.control?.value ?? [])];
    if (event?.dropIndex != undefined) {
      if (this.moduleKeyword) {
        this.dataService = this.pathResolverService.getDataServiceByModuleKeyword(this.moduleKeyword);
      }
      let newOrder = 0;
      if (event.dropIndex <= 0) {
        newOrder = (this.data?.[0]?.displayOrder ?? 1) / 2;
      } else if (event.dropIndex >= newList.length - 1) {
        newOrder = (this.data?.[this.data.length - 1]?.displayOrder ?? this.data?.length - 1) + 1;
      } else {
        newOrder = ((this.data?.[event.dropIndex - 1]?.displayOrder ?? event.dropIndex - 1) + (this.data?.[event.dropIndex + 1]?.displayOrder ?? event.dropIndex + 1)) / 2;
      }
      newList[event?.dropIndex] = { ...newList[event?.dropIndex], displayOrder: newOrder };
      this.dataService.patchSingleUpdate(
        [{ key: 'displayOrder', value: newOrder }],
        newList[event.dropIndex]?.code).subscribe(x => {
          if (x) {
            this.control.patchValue(newList);
            this.data = newList;
          }
        });
    }
  }

  addPopup() {
    this.appDialogService.showDialog(
      BaseFormPopupComponent,
      'Add ' + humanizeCasedString(this.moduleKeyword),
      (data) => {
        if (data) {
          this.dataService.create({ createItems: data }).subscribe(x => {
            if (this.addLast) {
              this.control?.patchValue([...this.control?.value, x?.data]);
              this.data = [...this.control?.value, x?.data];
            } else {
              this.control?.patchValue([x?.data, ...this.control?.value]);
              this.data = [x?.data, ...this.control?.value];
            }
          });
        }
      },
      {
        data: {
          dynamicViewComponent: this.formMapper.forms[this.moduleKeyword],
          dataService: this.dataService,
          filters: [],
          selectedRows: [],
          patchData: false,
          options: { ...this.formOptions, displayOrder: (this.control?.value?.length ?? 0) + 1 },
          formData: { create: true },
        },
      }
    );
  }

  editPopup(row: any) {
    this.appDialogService.showDialog(
      BaseFormPopupComponent,
      'Edit ' + humanizeCasedString(this.moduleKeyword),
      (data) => {
        if (data) {
          this.dataService.patchSingleUpdate(data, row?.code).subscribe(x => {
            this.fetchTableData();
          });
        }
      },
      {
        data: {
          dynamicViewComponent: this.formMapper.forms[this.moduleKeyword],
          dataService: this.dataService,
          filters: [],
          selectedRows: [],
          patchData: false,
          options: { ...this.formOptions, displayOrder: (this.control?.value?.length ?? 0) + 1 },
          formData: row,
        },
      }
    );
  }

  deletePopup(row: any) {
    this.appDialogService.confirm({
      accept: () => {
        this.subs.sink = this.dataService.delete((row as any).code || (row as any).id).subscribe({
          next: (res) => {
            this.fetchTableData();
          },
        });
      },
    });
  }
}