<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12 md:col-6">
      <app-basic-input
        [label]="moduleFieldString + '.name.label' | translate: 'Name'"
        [placeholder]="moduleFieldString + '.name.placeholder' | translate: 'Enter Name'"
        [control]="formGroup?.controls?.name"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>

    <div class="col-12 md:col-6">
      <app-target-code-selector
        [label]="moduleFieldString + '.type.label' | translate: 'Type'"
        [placeholder]="moduleFieldString + '.type.placeholder' | translate: 'Enter Type'"
        [control]="formGroup?.controls?.type"
        [viewMode]="fieldViewMode"
        [multi]="false"
        [optionLabel]="'name'"
        [optionValue]="'code'"
        [targetTypes]="['QUESTIONNAIRE_TYPE']"
      >
      </app-target-code-selector>
      <!-- <app-drop-down-input
        [label]="moduleFieldString + '.type.label' | translate: 'Type'"
        [placeholder]="moduleFieldString + '.type.placeholder' | translate: 'Enter Type'"
        [translationKeyPrefix]="moduleFieldString + '.type.enums'"
        [name]="'type'"
        [optionLabel]="'label'"
        [items]="listOfQuestionType"
        [optionValue]="'value'"
        [control]="formGroup?.controls?.type"
        [viewMode]="fieldViewMode"
        [badgeView]="true"
      ></app-drop-down-input> -->
    </div>

    <!-- <div class="col-12 md:col-6">
      <app-number-input
        [label]="'Passing Score'"
        [placeholder]="'Passing Score...'"
        [name]="'passScore'"
        [showButtons]="true"
        [control]="formGroup?.controls?.passScore"
        [type]="'number'"
        [viewMode]="fieldViewMode"
        [maxFractionDigits]="0"
        [min]="0"
      ></app-number-input>
    </div> -->
    <div class="col-12 md:col-12">
      <app-text-editor
        [height]="'100px'"
        [advanced]="true"
        [name]="'description'"
        [label]="moduleFieldString + '.description.label' | translate: 'Description'"
        [placeholder]="moduleFieldString + '.description.placeholder' | translate: 'Enter Description'"
        [control]="formGroup?.controls?.description"
        [viewMode]="fieldViewMode"
      ></app-text-editor>
    </div>
    @if (showQuestions) {
      <div class="col-12 md:col-12">
        <app-target-code-selector
          [label]="moduleFieldString + '.questions.label' | translate: 'Questions'"
          [placeholder]="moduleFieldString + '.questions.placeholder' | translate: 'Enter Questions'"
          [control]="formGroup?.controls?.questions"
          [viewMode]="fieldViewMode"
          [multi]="true"
          [showReorder]="true"
          [optionValue]="undefined"
          [targetTypes]="['QUESTION']"
          [customProjectionFields]="[
            'id',
            'question',
            'code',
            'recordStatus',
            'fields',
            'fieldDtos',
            'options',
            'label',
          ]"
          [dataKey]="'code'"
          (onChanges)="onTargetCodeSelectorChanges($event)"
          (onAddButtonClick)="onSearchQuestionClick($event)"
          [showAddButton]="true"
        >
        </app-target-code-selector>
      </div>
      <div class="col-12 md:col-12">
        <div class="flex flex-col w-full">
          <label [for]="'questions'" class="md:w-12rem md:max-w-12rem md:min-w-12rem w-full input-field-label">
            <!-- {{ 'Questions' }}
            @if (fieldViewMode != 'view') {
              <span class="text-red-500"> *</span>
            }
            <br />
            @if (formGroup?.controls?.questions?.dirty) {
              @if (formGroup?.controls?.questions?.hasError('required')) {
                <small class="p-error p-invalid font-normal">{{ 'Questions' }} {{ 'is Required' }}</small>
              }
              @if (formGroup?.controls?.questions?.hasError('minlength')) {
                <small class="p-error p-invalid font-normal">
                  {{ 'Questions' }} must have a minimum length of
                  {{ formGroup?.controls?.questions?.errors?.minlength?.requiredLength }}
                </small>
              }
              @if (formGroup?.controls?.questions?.hasError('maxlength')) {
                <small class="p-error p-invalid font-normal">
                  {{ 'Questions' }} must have a maximum length of
                  {{ formGroup?.controls?.questions?.errors?.maxlength?.requiredLength }}
                </small>
              }
            } -->
          </label>

          <!-- <div
            class="w-full questionnair-table mb-3"
            [class.ng-invalid]="formGroup?.controls?.questions?.invalid"
            [class.ng-dirty]="formGroup?.controls?.questions?.dirty"
          >
            <p-table [value]="tableQuestions" [columns]="cols" [reorderableColumns]="true" responsiveLayout="scroll">
              
              <ng-template pTemplate="header" let-columns>
                <tr>
                  @if (fieldViewMode != 'view') {
                    <th style="width: 3rem"></th>
                  }
                  @for (col of columns; track col) {
                    <th pReorderableColumn>
                      {{ col.header }}
                    </th>
                  }
                  @if (fieldViewMode != 'view') {
                    <th>{{ 'general.actions.actions' | translate: 'Actions' }}</th>
                  }
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
                <tr [pReorderableRow]="index">
                  @if (fieldViewMode != 'view') {
                    <td>
                      <span class="pi pi-bars" pReorderableRowHandle></span>
                    </td>
                  }
                  @for (col of columns; track col) {
                    <td>
                      {{ getElementValue(col.field, rowData) }}
                    </td>
                  }
                  @if (fieldViewMode != 'view') {
                    <td>
                      <ul class="list-none m-0">
                        @for (action of actions; track action) {
                          <li class="inline-block">
                            <button
                              pbutton=""
                              pripple=""
                              type="button"
                              (click)="
                                $event.stopPropagation();
                                onActionClick({
                                  row: { data: rowData },
                                  action: action,
                                  index: index,
                                })
                              "
                              icon="pi pi-times"
                              class="p-element p-ripple p-button-rounded p-button-danger p-button-text mr-2 mb-2 p-button p-component p-button-icon-only"
                            >
                              <span class="p-button-icon pi pi-trash" aria-hidden="true"></span>
                            </button>
                          </li>
                        }
                      </ul>
                   
                    </td>
                  }
                </tr>
              </ng-template>
            </p-table>
          </div> -->
        </div>
      </div>
    }
    <div class="col-12 md:col-6">
      <app-drop-down-input
        [label]="moduleFieldString + '.scoringType.label' | translate: 'Scoring Type'"
        [placeholder]="moduleFieldString + '.scoringType.placeholder' | translate: 'Select Scoring Type'"
        [translationKeyPrefix]="moduleFieldString + '.scoringType.enums'"
        [name]="'scoringType'"
        [optionLabel]="'label'"
        [items]="scoringTypes"
        [optionValue]="'value'"
        [control]="formGroup?.controls?.scoringType"
        [viewMode]="'view'"
        [canKeepValue]="false"
        [badgeView]="true"
      ></app-drop-down-input>
    </div>
    @if (formGroup?.controls?.scoringType.value == scoredType) {
      <div class="col-12 md:col-6">
        <app-p-slider-input
          [name]="'Passingscore'"
          [label]="moduleFieldString + '.passScore.label' | translate: 'Passing Score'"
          [placeholder]="moduleFieldString + '.passScore.placeholder' | translate: 'Enter Passing Score'"
          [control]="formGroup?.controls?.passScore"
          [viewMode]="fieldViewMode"
          [min]="0"
          [max]="maxPassingScore"
        ></app-p-slider-input>
      </div>
    }
  </div>
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create') {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create') {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
