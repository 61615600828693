<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid" style="max-height: 50vh; overflow-y: auto">
    @if (stepperService) {
      @switch (stepperService.operation) {
        @case (bulkOperations.Status) {
          <div class="flex flex-column gap-3 w-full mb-4">
            @for (item of items; track item; let j = $index) {
              <div class="w-full flex">
                <div class="col-4"><app-code-navigation [data]="item.code"></app-code-navigation></div>
                <div class="col-3">
                  <app-badge-view [data]="item?.oldStatus"></app-badge-view>
                </div>
                <div class="col-2">
                  <i class="pi pi-arrow-right"></i>
                </div>
                <div class="col-3">
                  <app-badge-view [data]="item?.newStatus"></app-badge-view>
                </div>
              </div>
            }
          </div>
        }
        @case (bulkOperations.Watchers) {
          <div class="col-12 mb-4 flex flex-column gap-2">
            <p>Adding/Removing Watchers:</p>
            @for (record of stepperService.items; track record; let i = $index) {
              <ng-container class="gap-2">
                <p-divider align="left" class="col-12">
                  <app-code-navigation [data]="record.code"></app-code-navigation>
                </p-divider>
                @for (item of items | keyvalue; track item) {
                  <div class="flex flex-row w-full justify-content-between align-items-center gap-2">
                    <div class="flex flex-row align-items-center gap-3">
                      <app-avatar-list [users]="item?.key"></app-avatar-list>
                      <!-- <p>{{ user?.name }}</p> -->
                    </div>
                    <div class="w-6rem" style="min-width: 6rem">
                      {{ item.value.accessLevel }}
                    </div>
                  </div>
                }
              </ng-container>
            }
          </div>
        }
        @case (bulkOperations.Access) {
          <div class="col-12 mb-4 flex flex-column gap-2">
            <p>Adding/Removing Access:</p>
            @for (record of stepperService.items; track record; let i = $index) {
              <ng-container class="gap-2">
                <p-divider align="left" class="col-12">
                  <app-code-navigation [data]="record.code"></app-code-navigation>
                </p-divider>
                @for (item of items | keyvalue; track item) {
                  <div class="flex flex-row w-full justify-content-between align-items-center gap-2">
                    <div class="flex flex-row align-items-center gap-3">
                      <app-avatar-list [users]="item?.key"></app-avatar-list>
                      <!-- <p>{{ user?.name }}</p> -->
                    </div>
                    <div class="w-6rem" style="min-width: 6rem">
                      {{ item.value.accessLevel }}
                    </div>
                  </div>
                }
              </ng-container>
            }
          </div>
        }
        @case (bulkOperations.PermenantDelete) {
          <div class="col-12 mb-4">
            <p>Permenant Deleting Items:</p>
            @for (item of stepperService.items; track item) {
              <div class="flex gap-2">
                <app-code-navigation [data]="item.code"></app-code-navigation>
              </div>
            }
          </div>
        }
        @case (bulkOperations.Restore) {
          <div class="col-12 mb-4">
            <p>Restoring Items:</p>
            @for (item of stepperService.items; track item) {
              <div class="flex gap-2">
                <app-code-navigation [data]="item.code"></app-code-navigation>
              </div>
            }
          </div>
        }
        @case (bulkOperations.Delete) {
          <div class="col-12 mb-4">
            <p>Deleting Items:</p>
            @for (item of stepperService.items; track item) {
              <div class="flex gap-2">
                <app-code-navigation [data]="item.code"></app-code-navigation>
              </div>
            }
          </div>
        }
        @case (bulkOperations.Update) {
          @for (item of items; track item; let i = $index) {
            <ng-container class="gap-2">
              <p-divider align="left" class="col-12">
                <app-code-navigation [data]="item.code"></app-code-navigation>
              </p-divider>
              @for (field of item.oldFields; track field; let j = $index) {
                <!-- <div class="col-2">
        <label>{{item.oldFields[j].options.name+' : '}}</label>
      </div> -->
                <div class="col-6">
                  <ng-template
                    appDynamicComponent
                    [mode]="'view'"
                    [dynamicComponentInfo]="item.oldFields[j]"
                  ></ng-template>
                </div>
                <div class="col-2">
                  <i class="pi pi-arrow-right"></i>
                </div>
                <div class="col-4">
                  @if (stepperService?.stepperItem[item.newFields[j]?.options?.name]) {
                    <ng-template
                      appDynamicComponent
                      [mode]="'view'"
                      [dynamicComponentInfo]="item.newFields[j]"
                    ></ng-template>
                  } @else {
                    <app-badge-view [data]="'EMPTY'"></app-badge-view>
                  }
                </div>
              }
            </ng-container>
          }
        }
        @case (bulkOperations.AddRelations) {
          <div class="col-4 mb-2 flex flex-column">
            @for (item of stepperService.items; track item) {
              <app-code-navigation [data]="item.code"></app-code-navigation>
            }
          </div>

          <!-- <app-view-switch [viewType]="dataTypes.Text"
          [data]="(checkSwitchCodes()?data.sourceType:data.targetType) | humanize">
      </app-view-switch> -->
          <div class="col-4 my-auto">
            <!-- <div class="w-12"> -->
            <app-view-switch
              [viewType]="dataTypes.RelationTypeCell"
              [data]="formGroup?.controls?.relationType.value"
              [options]="{ relationTypes: tagType }"
            >
            </app-view-switch>
          </div>
          <!-- <div class="w-12">
        <app-drop-down-input label="Target Type"
          [placeholder]="'Select Item'"
          [name]="'dropdown'"
          [optionLabel]="'label'"
          [items]="listOfTypes"
          [viewMode]="'view'"
[canKeepValue]="false"
          [optionValue]="'value'"
          [control]="formGroup?.controls?.targetType"
          [badgeView]="true"></app-drop-down-input>
      </div> -->
          <!-- </div> -->
          <!-- <app-view-switch [viewType]="dataTypes.Text"
          [data]="(checkSwitchCodes()?data.sourceType:data.targetType) | humanize">
      </app-view-switch> -->

          @if (selectedType) {
            <div class="col-4 flex flex-column">
              @for (item of formGroup?.controls?.targetCode.value; track item) {
                <app-view-switch [viewType]="dataTypes.CodeLink" [data]="item"> </app-view-switch>
              }
            </div>
          }
          <!-- <div class="col-12">
      <app-text-editor [height]="'100px'"
        [advanced]="true"
        [name]="'textarea'"
        [label]="''"
        [viewMode]="'view'"
[canKeepValue]="false"
        [placeholder]="'Enter Text...'"
        [control]="formGroup?.controls?.notes"></app-text-editor>
    </div> -->
        }
      }
    }
  </div>
  <ng-content></ng-content>
</form>
