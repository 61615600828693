<div class="h-full w-full">
  <p-table
    class="w-full fields-table p-datatable-scrollable"
    [value]="control?.value"
    [columns]="cols"
    [reorderableColumns]="false"
    [scrollable]="true"
    responsiveLayout="scroll"
    [paginator]="reorderable ? false : true"
    [rows]="10"
    [totalRecords]="control?.value?.length ?? 0"
    [rowsPerPageOptions]="rowsPerPageOptions"
    [styleClass]="'justify-content-start flex flex-column h-full'"
    (onRowReorder)="updateControl($event)"
  >
    <ng-template pTemplate="header" let-columns>
      <tr [style]="'background:var(--primary-bg-400);'">
        @if (viewMode != 'view' && reorderable) {
          <th></th>
        }
        @for (col of cols; track col; let ind = $index) {
          <th
            class="border-noround white-space-nowrap"
            [class.max-w-12rem]="col.key === 'code'"
            [class.w-12rem]="col.key === 'code'"
            pFrozenColumn
            [frozen]="col.frozen"
            [alignFrozen]="col.alignFrozen"
            pResizableColumn
          >
            <div class="flex justify-content-between align-items-center h-full custom-filter flex-grow-1">
              <!-- @TODO: Add component to handle dropdown, multiDropdown and slider -->
              <div class="flex flex-row align-items-center gap-1 flex-grow-1">
                <span class="flex w-full gap-2 justify-content-between flex-grow-1 align-items-baseline">
                  <span>
                    {{ col.name | humanize }}
                  </span>
                </span>
              </div>
            </div>
          </th>
        }
        @if (viewMode != 'view' && tableActions.length > 0) {
          <th
            class="border-noround"
            pFrozenColumn
            [frozen]="actionCol.frozen"
            [alignFrozen]="actionCol.alignFrozen"
            [style]="'right:0;'"
            class="max-w-16rem w-16rem right-0 left-0"
          >
            <div class="flex justify-content-center gap-3 align-items-center h-full">
              {{ actionCol.name | translate }}
              @if (headerAction && !disableAdd) {
                <app-button [action]="headerAction"></app-button>
              }
            </div>
          </th>
        }
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
      <tr [pReorderableRow]="index" class="my-2">
        @if (viewMode != 'view' && reorderable) {
          <td>
            <span class="pt-1 pi pi-bars" pReorderableRowHandle></span>
          </td>
        }
        @for (col of cols; track col; let ind = $index) {
          <td
            pFrozenColumn
            [frozen]="col.frozen"
            class="border-noround"
            [class.max-w-12rem]="col.key === 'code'"
            [class.w-12rem]="col.key === 'code'"
            [style]="'min-width: 11rem; ' + (cols?.length > 6 ? 'max-width: 24rem;' : '')"
          >
            <span class="p-column-title mr-2">{{ col?.name }}</span>

            <div class="w-full max-h-5rem overflow-y-auto">
              <!-- TODO: @Nabeeh check this part out i added it so the cell wont overflow outside -->
              <app-view-switch
                [viewType]="col.dataType"
                [data]="col.passRowAsData ? rowData : getElementValue(col.key, rowData)"
                [options]="getOptions(col.options, col.dataType)"
                [translationKeyPrefix]="col.translationKeyPrefix"
              >
              </app-view-switch>
            </div>
          </td>
        }
        @if (
          viewMode != 'view' && tableActions.length > 0 && (onlyLastisntViewMode ? index == items?.length - 1 : true)
        ) {
          <td
            pFrozenColumn
            [frozen]="actionCol.frozen"
            [alignFrozen]="actionCol.alignFrozen"
            class="border-noround white-space-nowrap justify-content-center right-0 left-0"
            [style]="'min-width: 6.5rem; right:0; '"
          >
            <app-button-list
              class="w-full"
              [itemClass]="'w-full'"
              [actionList]="tableActions"
              [listType]="'split'"
              [commandData]="rowData"
              [displayData]="rowData"
              [listTypeItemClass]="'m-auto'"
              [appendTo]="'body'"
            ></app-button-list>
          </td>
        }</tr
    ></ng-template>
  </p-table>
</div>
