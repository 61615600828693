@if (this.data || statusBasedViewMode == 'create') {
  <form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
    <!-- <p-divider class="w-full" align="left">
      <b>{{ moduleFieldString + '.GeneralInformation.label' | translate: 'General Information' }}</b>
    </p-divider> -->
    <div class="formgrid grid p-fluid">
      <div class="col-12 md:col-6">
        <app-basic-input
          [label]="moduleFieldString + '.name.label' | translate: 'Name'"
          [placeholder]="moduleFieldString + '.name.placeholder' | translate: 'Enter Name'"
          [control]="formGroup?.controls?.name"
          [viewMode]="statusBasedViewMode"
        ></app-basic-input>
      </div>
      <div class="col-12 md:col-6">
        <app-user-and-group-selector
          [label]="moduleFieldString + '.owner.label' | translate: 'Owner'"
          [placeholder]="moduleFieldString + '.owner.placeholder' | translate: 'Search...'"
          [principleMode]="true"
          [control]="formGroup?.controls?.owner"
          [optionValue]="undefined"
          [multi]="false"
          [viewMode]="statusBasedViewMode"
        >
        </app-user-and-group-selector>
      </div>
      <div class="col-12 md:col-6">
        <app-user-and-group-selector
          [label]="moduleFieldString + '.approver.label' | translate: 'Approver'"
          [placeholder]="moduleFieldString + '.approver.placeholder' | translate: 'Search...'"
          [principleMode]="true"
          [control]="formGroup?.controls?.approver"
          [optionValue]="undefined"
          [multi]="false"
          [viewMode]="statusBasedViewMode"
        >
        </app-user-and-group-selector>
      </div>
      <div class="col-12 md:col-12">
        <app-text-editor
          [height]="'100px'"
          [advanced]="true"
          [name]="'textarea'"
          [label]="moduleFieldString + '.description.label' | translate: 'Description'"
          [placeholder]="moduleFieldString + '.description.placeholder' | translate: 'Enter Text...'"
          [control]="formGroup?.controls?.description"
          [viewMode]="fieldViewMode"
        ></app-text-editor>
      </div>
    </div>

    <div class="flex flex-row-reverse justify-content-between align-items-center">
      <div class="flex flex-row-reverse gap-2">
        @if (showSaveButton) {
          <app-button [action]="submitButtonAction"></app-button>
        }
        @if (showSaveButton) {
          <app-button [action]="submitSaveButtonAction"></app-button>
        }
        @if (showInPlaceButton) {
          <app-button [action]="submitInPlaceButtonAction"></app-button>
        }
        @if (fieldViewMode == 'create') {
          <app-button [action]="cancelButtonAction"></app-button>
        }
      </div>
      <!-- @if (fieldViewMode == 'create') {
        <app-switch-input
          [label]="'general.messages.create another record' | translate"
          [name]="'inPlaceCreate'"
          [labelWidthFit]="true"
          [control]="inPlaceCreateControl"
          [viewMode]="'create'"
          [styleClass]="'mb-0'"
        >
        </app-switch-input>
      } -->
    </div>

    <!-- <div class="flex flex-row-reverse gap-2">
      @if (fieldViewMode != 'view') {
        <app-button [action]="submitButtonActionPopup"></app-button>
      }
    </div> -->
  </form>
}
