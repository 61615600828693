import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseForm, getEnumOptions, PDFRenderConfig, ReportConfigurationTemplateDto } from '@shared/classes';
import { ShadesPalette } from '@shared/components/ui/input-fields/palette-picker-input/palette-picker-input.component';
import { ViewModeService } from '@shared/services';

@Component({
  selector: 'app-report-configuration-template-item-form',
  templateUrl: './report-configuration-template-item-form.component.html',
  styleUrl: './report-configuration-template-item-form.component.scss',
})
export class ReportConfigurationTemplateItemFormComponent
  extends BaseForm<ReportConfigurationTemplateDto>
  implements OnInit
{
  reportFormatOptions = getEnumOptions(ReportConfigurationTemplateDto.ReportFormatEnum).map((x) => {
    return { ...x, disabled: x.value != ReportConfigurationTemplateDto.ReportFormatEnum.Pdf };
  });
  pdfSizeOptions = getEnumOptions(PDFRenderConfig.PdfSizeEnum);
  themeOptions = getEnumOptions(ReportConfigurationTemplateDto.ThemeEnum);
  pageLocationOptions = getEnumOptions(ReportConfigurationTemplateDto.PageLocationEnum);
  primarycolorPalleteOptions = Object.entries(ShadesPalette).map(([key, value]) => {
    return { label: key, value: value };
  });
  constructor(public viewModeService: ViewModeService) {
    super(viewModeService, 'REPORT_CONFIGURATION_TEMPLATE');
  }

  ngOnInit(): void {}

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat({ ...this.formGroup.getRawValue() })
      : this.getChangedFormValues({ ...this.formGroup.getRawValue() }).updateItems;
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      // description: new FormControl(null),
      reportFormat: new FormControl(ReportConfigurationTemplateDto.ReportFormatEnum.Pdf, Validators.required),
      runAs: new FormControl(null, Validators.required), //@TODO: add run
      language: new FormControl(null),
      theme: new FormControl(ReportConfigurationTemplateDto.ThemeEnum.Light),
      colorPalette: new FormControl(ShadesPalette?.[0], Validators.required),
      watermark: new FormControl(null),
      watermarkOpacity: new FormControl(0),
      pageLocation: new FormControl(ReportConfigurationTemplateDto.PageLocationEnum.BottomLeft, Validators.required),
      reportDetails: new FormGroup({
        generatedAt: new FormControl(false),
        generatedAs: new FormControl(false),
        generatedUser: new FormControl(false),
      }),
      pdfRenderConfig: new FormGroup({
        pdfSize: new FormControl(PDFRenderConfig.PdfSizeEnum.A4, Validators.required),
        header: new FormControl(null),
        footer: new FormControl(null),
        coverPage: new FormControl(null),
        includeHeader: new FormControl(false),
        includeFooter: new FormControl(false),
        includeCoverPage: new FormControl(false),
        includeTableOfContents: new FormControl(false),
        includeReportDetails: new FormControl(false),
        exportRaw: new FormControl(false),
        headerHeight: new FormControl(80),
        footerHeight: new FormControl(50),
      }),
    });
  }
  get pdfRenderConfigForm() {
    return this.formGroup.controls.pdfRenderConfig as FormGroup<{
      pdfSize: FormControl<any>;
      header: FormControl<any>;
      footer: FormControl<any>;
      coverPage: FormControl<any>;
      includeHeader: FormControl<any>;
      includeFooter: FormControl<any>;
      includeCoverPage: FormControl<any>;
      includeTableOfContents: FormControl<any>;
      includeReportDetails: FormControl<any>;
      exportRaw: FormControl<any>;
      headerHeight: FormControl<any>;
      footerHeight: FormControl<any>;
    }>;
  }
  get reportDetailsForm() {
    return this.formGroup.controls.reportDetails as FormGroup<{
      generatedAt: FormControl<boolean>;
      generatedAs: FormControl<boolean>;
      generatedUser: FormControl<boolean>;
    }>;
  }
}
