<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12 md:col-6">
      <app-basic-input
        [label]="moduleFieldString + '.subject.label' | translate: 'Subject'"
        [placeholder]="moduleFieldString + '.subject.placeholder' | translate: 'Enter Name'"
        [control]="formGroup?.controls?.subject"
        [viewMode]="canEditFeilds"
      ></app-basic-input>
    </div>
    <div class="col-12 md:col-6">
      <app-user-and-group-selector
        [label]="moduleFieldString + '.assignedTo.label' | translate: 'Assigned To'"
        [placeholder]="moduleFieldString + '.assignedTo.placeholder' | translate: 'Search...'"
        [principleMode]="true"
        [control]="formGroup?.controls?.assignedTo"
        [optionValue]="undefined"
        [multi]="false"
        [viewMode]="canEditFeilds"
      >
      </app-user-and-group-selector>
    </div>
    <div class="col-12">
      <app-attachment-input
        [label]="moduleFieldString + '.attachments.label' | translate: 'Attachments'"
        [control]="formGroup?.controls?.attachments"
        [viewMode]="canEditFeilds"
        [dragAndDropMode]="true"
        [multiple]="true"
        [uploadLocation]="'CODE'"
        [useGlobalPasteEventListener]="false"
        [staticUploadWidth]="false"
      ></app-attachment-input>
    </div>
    <div class="col-12 md:col-6">
      <app-suggestion-input
        [label]="moduleFieldString + '.dueDaysToResponse.label' | translate: 'Due Days To Response'"
        [placeholder]="moduleFieldString + '.dueDaysToResponse.placeholder' | translate: 'Ex: 7 days'"
        [control]="formGroup?.controls?.dueDaysToResponse"
        [viewMode]="canEditFeilds"
        [items]="[
          { label: 'Yearly', value: 365 },
          { label: 'Quarterly', value: 90 },
          { label: 'Monthly', value: 30 },
          { label: 'Weekly', value: 7 },
          { label: 'Daily', value: 1 },
        ]"
        [type]="'number'"
        [min]="0"
        [maxFractionDigits]="0"
      >
      </app-suggestion-input>
    </div>
    <div class="col-12 md:col-6">
      <app-date-input
        [label]="moduleFieldString + '.dueDate.label' | translate: 'Due Date'"
        [placeholder]="'2022-11-22'"
        [name]="'dueDate'"
        [showIcon]="true"
        [viewMode]="canEditFeilds"
        [control]="formGroup?.controls?.dueDate"
        [viewMode]="'view'"
        [canKeepValue]="false"
        [minDate]="minDate"
      ></app-date-input>
    </div>
    <div class="col-12">
      <app-text-editor
        [height]="'100px'"
        [name]="'textarea'"
        [label]="moduleFieldString + '.description.label' | translate: 'Description'"
        [placeholder]="moduleFieldString + '.description.placeholder' | translate: 'Enter Text...'"
        [control]="formGroup?.controls?.description"
        [viewMode]="canEditFeilds"
        [advanced]="true"
      ></app-text-editor>
    </div>
  </div>
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton && !popupOptions?.fromPopup) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create') {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create' && !popupOptions?.fromPopup) {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
