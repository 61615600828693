<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12 md:col-6">
      <app-basic-input
        label="Name"
        [placeholder]="'Enter Name'"
        [control]="formGroup?.controls?.name"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>
    <div class="col-12 md:col-6">
      <app-number-input
        [label]="'Due Days'"
        [placeholder]="'Due Days'"
        [name]="'dueDateDays'"
        [showButtons]="true"
        [control]="formGroup?.controls?.dueDateDays"
        [type]="'number'"
        [viewMode]="fieldViewMode"
        [maxFractionDigits]="0"
      ></app-number-input>
    </div>
    <div class="col-6 md:col-6">
      <app-switch-input
        [label]="'Reminder'"
        [name]="'reminder'"
        [control]="formGroup?.controls?.reminder"
        [viewMode]="fieldViewMode"
      >
      </app-switch-input>
    </div>
    <div class="col-12 md:col-6">
      <app-number-input
        [label]="'Send Before Days'"
        [placeholder]="'Send Before Days'"
        [name]="'sendBeforeDays'"
        [showButtons]="true"
        [control]="formGroup?.controls?.sendBeforeDays"
        [type]="'number'"
        [viewMode]="fieldViewMode"
        [maxFractionDigits]="0"
        [min]="1"
      ></app-number-input>
    </div>
    <!-- <div class="col-12 md:col-6">
    <app-basic-input
      label="Key"
      [placeholder]="'Enter Key'"
      [control]="formGroup?.controls?.key"
      [viewMode]="fieldViewMode"
    ></app-basic-input>
  </div> -->
    <!-- <div class="col-6 md:col-3">
  <app-switch-input
    [label]="'Is Default'"
    [name]="'isDefault'"
    [control]="formGroup?.controls?.isDefault"
    [viewMode]="fieldViewMode"
    >
  </app-switch-input>
</div>
<div class="col-6 md:col-3">
  <app-switch-input
    [label]="'System Generated'"
    [name]="'systemGenerated'"
    [control]="formGroup?.controls?.systemGenerated"
    [viewMode]="fieldViewMode"
    >
  </app-switch-input>
</div> -->
    <div class="col-12 md:col-6">
      <app-drop-down-input
        [label]="'Priority'"
        [placeholder]="'Select Item'"
        [name]="'priority'"
        [optionLabel]="'label'"
        [multi]="false"
        [items]="priorityOptions"
        [optionValue]="'value'"
        [control]="formGroup?.controls?.priority"
        [badgeView]="true"
        [viewMode]="fieldViewMode"
      ></app-drop-down-input>
    </div>
    <div class="col-12 md:col-6">
      <app-drop-down-input
        [label]="'Module'"
        [placeholder]="'Select Item'"
        [name]="'module'"
        [optionLabel]="'label'"
        [multi]="false"
        [items]="moduleList"
        [optionValue]="'value'"
        [control]="formGroup?.controls?.module"
        [badgeView]="true"
        [viewMode]="fieldViewMode"
      ></app-drop-down-input>
    </div>

    <div class="col-12">
      <app-text-input
        [rows]="2"
        [name]="'titleTemplate'"
        [label]="'Subject Template'"
        [placeholder]="'Enter Text...'"
        [control]="formGroup?.controls?.titleTemplate"
        [viewMode]="fieldViewMode"
      ></app-text-input>
    </div>
    <!-- <div class="col-12">
<app-text-input
  [rows]="8"
  [name]="'descriptionTemplate'"
  [label]="'Description Template'"
  [placeholder]="'Enter Text...'"
  [control]="formGroup?.controls?.descriptionTemplate"
  [viewMode]="fieldViewMode"
></app-text-input>
</div> -->
    <div class="col-12 md:col-12">
      <app-text-editor
        [name]="'descriptionTemplate'"
        [label]="'Body Template'"
        [placeholder]="'Enter Description Template...'"
        [control]="formGroup?.controls?.descriptionTemplate"
        [viewMode]="fieldViewMode"
        [advanced]="true"
        [sunEditorMode]="false"
        [customDropdownData]="currentCustomDropdownData"
        (onCustomDropdownOpen)="loadContextSuggestions()"
      >
        <ng-template let-editor #toolbarActions>
          <button
            type="button"
            class=""
            pTooltip="Context Values"
            tooltipPosition="top"
            aria-label="Context Values"
            (click)="typesOp.toggle($event); onEditorContextMenuClick($event, editor)"
          >
            <i class="text-700 text-lg font-bold pi pi-hashtag"></i>
          </button>
        </ng-template>
      </app-text-editor>
    </div>
  </div>
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create') {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create') {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
<p-overlayPanel #typesOp [showCloseIcon]="true">
  <ng-template pTemplate>
    @if (!data?.key) {
      <p>Please Fill Form First</p>
    }
    @if (!isLoadingContextSuggestions && !!data?.key && contextVariables) {
      <p-menu [style]="{ width: '15rem', height: '25rem', overflow: 'auto' }" [model]="contextVariables"></p-menu>
    }
    @if (isLoadingContextSuggestions) {
      <p-skeleton width="15rem" height="2rem" styleClass="mb-2"></p-skeleton>
    }
    @if (isLoadingContextSuggestions) {
      <p-skeleton width="15rem" height="2rem" styleClass="mb-2"></p-skeleton>
    }
    @if (isLoadingContextSuggestions) {
      <p-skeleton width="15rem" height="2rem" styleClass="mb-2"></p-skeleton>
    }
    @if (isLoadingContextSuggestions) {
      <p-skeleton width="15rem" height="2rem" styleClass="mb-2"></p-skeleton>
    }
    <!-- <p-listbox [options]="contextVariables"
    [checkbox]="false"
    [filter]="true"
    [multiple]="false"
    optionLabel="value"
    [optionValue]="'value'"
    [style]="{ width: '15rem',height:'25rem',overflow:'auto' }">
  </p-listbox> -->
  </ng-template>
</p-overlayPanel>
