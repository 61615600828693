import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseForm, FilterItem, ModuleKeywords, RiskMethodologyImpactFactorOptionDto } from '@shared/classes';
import { ViewModeService } from '@shared/services';

@Component({
  selector: 'app-risk-methodology-impact-factor-option-item-form',
  templateUrl: './risk-methodology-impact-factor-option-item-form.component.html',
  styleUrl: './risk-methodology-impact-factor-option-item-form.component.scss',
})
export class RiskMethodologyImpactFactorOptionItemFormComponent extends BaseForm<RiskMethodologyImpactFactorOptionDto> implements OnInit {
  constructor(public viewModeService: ViewModeService) {
    super(viewModeService, ModuleKeywords.RiskMethodologyImpactFactorOption);
  }
  extraFilters: FilterItem[] = [];
  ngOnInit(): void {
    this.extraFilters = [{ property: 'code', operation: 'IN', value: this.popupOptions?.options ? this.popupOptions?.options?.map(x => x.code) : [] },];
    if (this.popupOptions?.factor) {
      this.formGroup?.controls?.impactFactor.patchValue(this.popupOptions?.factor);
    }
  }

  getData() {
    return this.currentViewMode == 'create'
      ? this.getDataKeyValueFormat()
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.formGroup.patchValue({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      impactFactor: new FormControl(null, Validators.required),
      description: new FormControl(null),
    });
  }
}
