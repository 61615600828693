import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {
  DynamicComponentBase,
  RiskMethodologyImpactFactor,
  RiskMethodologyImpactFactorOption,
  RiskMethodologyImpactValue,
  isNullObj,
} from '@shared/classes';
// import { ImpactValue } from '@shared/classes/model/backend/entity/model/impactValue';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-impact-factor-with-value-selector',
  templateUrl: './impact-factor-with-value-selector.component.html',
  styleUrls: ['./impact-factor-with-value-selector.component.scss'],
})
export class ImpactFactorWithValueSelectorComponent extends DynamicComponentBase implements OnInit {
  originalFactors: RiskMethodologyImpactFactor[] = [];
  private _items: RiskMethodologyImpactFactor[] = [];
  set items(items) {
    this._items = items;
    //   this.originalFactors = impactFactors;
    //   this._items = impactFactors.map((item) => {
    //     return {
    //       label: item.name,
    //       // items: item.impactFactorItemsDto?.map((element) => {
    //       //   return {
    //       //     label: element.name,
    //       //     value: element,
    //       //   };
    //       // }),
    //       code: item.code,
    //     };
    //   });
    //   this.filteredItems = [...this._items];
    // }
  }
  @Input() set riskMeth(riskMeth) {
    let riskFactorsMap = {};
    for (let i = 0; i < riskMeth?.impactValuesDto?.length; i++) {
      const element: RiskMethodologyImpactValue = riskMeth?.impactValuesDto[i];
      for (let j = 0; j < element?.riskMethodologyImpactFactorOption?.length; j++) {
        const option: RiskMethodologyImpactFactorOption = element?.riskMethodologyImpactFactorOption[j];
        riskFactorsMap[option?.impactFactor] = {
          factor: option?.impactFactorDto,
          items: [...(riskFactorsMap[option?.impactFactor]?.items ?? []), option],
        };
      }
    }
    this.items = Object.values(riskFactorsMap).map((item: any) => {
      return {
        label: item.factor?.label,
        items: item.items?.map((element) => {
          return {
            label: element.name,
            value: element,
          };
        }),
        code: item.factor?.code,
      };
    });
  }
  get impactFactors() {
    return this._items;
  }
  valueItems = [];
  impactFactorValues: any[] = [];
  impactFactorControl = new FormControl(null, Validators.required);
  impactFactorValueControl = new FormControl(null);
  impactFactorNameControl = new FormControl(null);

  uiChange = false;
  filteredItems: any[] = [];
  constructor() {
    super();
  }

  ngOnInit(): void {
    this.setupSubscriptions();
  }
  setupSubscriptions() {
    this.impactFactorControl.patchValue(this.fControl?.getRawValue()?.factor || null, { emitEvent: false });
    this.impactFactorValueControl.patchValue(this.fControl?.getRawValue()?.value || null, { emitEvent: false });
    this.impactFactorNameControl.patchValue(this.fControl?.getRawValue()?.value?.value?.impactValueDto?.name || null, {
      emitEvent: false,
    });

    this.valueItems = this.fControl?.getRawValue()?.factor
      ? this.formatGetItems(this.impactFactors, this.fControl?.getRawValue()?.factor)
      : [];

    this.fControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      // this.impactFactors.find()
      this.impactFactorControl.patchValue(res?.factor || null, { emitEvent: false });
      this.impactFactorValueControl.patchValue(res?.value || null, { emitEvent: false });
      this.impactFactorNameControl.patchValue(res?.value?.value?.impactValueDto?.name || null, { emitEvent: false });

      if (!res?.factor) {
        this.valueItems = [];
        this.impactFactorValueControl?.removeValidators(Validators.required);
        this.impactFactorValueControl?.updateValueAndValidity({ emitEvent: false });
      } else {
        this.impactFactorValueControl?.addValidators(Validators.required);
        this.impactFactorValueControl?.updateValueAndValidity({ emitEvent: false });
      }
      if (this.fControl?.touched) {
        this.impactFactorControl?.markAsTouched();
        this.impactFactorControl?.markAsDirty();
        this.impactFactorValueControl?.markAsTouched();
        this.impactFactorValueControl?.markAsDirty();
      } else {
        this.impactFactorControl?.markAsUntouched();
        this.impactFactorControl?.markAsPristine();
        this.impactFactorValueControl?.markAsUntouched();
        this.impactFactorValueControl?.markAsPristine();
      }
    });
    this.impactFactorControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.valueItems = res ? this.formatGetItems(this.impactFactors, res) : [];
      this.impactFactorValueControl.patchValue(null);
      this.impactFactorValueControl.updateValueAndValidity();
      this.fControl.patchValue(
        res
          ? {
              factor: res,
              value: null,
            }
          : null
      );
      if (isNullObj(this.valueItems)) {
        this.impactFactorValueControl?.removeValidators(Validators.required);
        this.impactFactorValueControl?.updateValueAndValidity();
      } else {
        this.impactFactorValueControl?.addValidators(Validators.required);
        this.impactFactorValueControl?.updateValueAndValidity();
        this.fControl.setErrors({ impactFactorValue: true });
      }
    });
    this.impactFactorValueControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.impactFactorNameControl.patchValue(res?.value?.impactValueDto?.name || null, { emitEvent: false });
      console.log(res);
      this.fControl.patchValue(
        this.impactFactorControl.getRawValue()
          ? {
              factor: this.impactFactorControl.getRawValue(),
              value: res,
            }
          : null
      );
      if (isNullObj(res)) this.fControl.setErrors({ impactFactorValue: true });
    });
  }
  onSetLiveData(): void {
    const selectedFactorList = this.liveDynamicData as { factorVal: { factor: any; value: any } }[];
    if (!isNullObj(selectedFactorList)) {
      let filtered: any[] = [];
      for (let i = 0; i < this.impactFactors.length; i++) {
        let item = this.impactFactors[i];
        if (selectedFactorList.find((x) => x?.factorVal?.factor?.code == item?.code)) {
        } else {
          filtered.push(item);
        }
      }
      this.filteredItems = filtered;
    } else {
      this.filteredItems = [...this.impactFactors];
    }
  }
  setInputOptions(): void {
    this.items = this.inputOptions?.dropDownInput?.items ?? this.items;
    this.setupSubscriptions();
  }
  formatGetItems(items: any[], linkedControlValue: any) {
    if (isNullObj(items) || isNullObj(linkedControlValue)) return [];
    let value = items.find((item) => item.code === linkedControlValue?.code);

    if (value) {
      return value.items.map((item) => ({
        label: item?.label,
        value: item?.value,
        code: item?.code || item?.value?.code,
      }));
    } else {
      return [];
    }
  }
}
