<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12 md:col-6">
      <app-basic-input
        label="Name"
        [placeholder]="'Enter Name'"
        [control]="formGroup?.controls?.name"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>
    <div class="col-12 md:col-6">
      <app-user-and-group-selector
        [label]="'Run As'"
        [placeholder]="'Search...'"
        [principleMode]="true"
        [control]="$any(formGroup?.controls?.runAs)?.controls?.withoutGroups"
        [optionValue]="undefined"
        [multi]="true"
        [viewMode]="fieldViewMode"
      >
      </app-user-and-group-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-drop-down-input
        [label]="'Type'"
        [placeholder]="'Select Item'"
        [name]="'type'"
        [optionLabel]="'label'"
        [items]="targetTypeOptions"
        [optionValue]="'value'"
        [control]="formGroup?.controls?.type"
        [viewMode]="fieldViewMode"
        [badgeView]="true"
        (onChanges)="onTypeChanges($event)"
      ></app-drop-down-input>
    </div>
    @if (formGroup?.controls?.type?.value) {
      <div class="col-12 md:col-6">
        <app-target-code-selector
          [label]="'Filter'"
          [placeholder]="'Search Filters'"
          [control]="formGroup?.controls?.filter"
          [viewMode]="fieldViewMode"
          [multi]="false"
          [optionLabel]="'label'"
          [optionValue]="'code'"
          [targetTypes]="['FILTER']"
          [extraFilters]="[
            {
              property: 'type',
              operation: 'EQUAL',
              value: 'AGGREGATION',
            },
            { property: 'targetType', operation: 'EQUAL', value: formGroup?.controls?.type?.value },
          ]"
        >
        </app-target-code-selector>
      </div>
    }
    <div class="col-12 md:col-6">
      <app-suggestion-input
        [label]="'Frequency'"
        [placeholder]="'Ex: 7 days'"
        [control]="formGroup?.controls?.frequency"
        [viewMode]="fieldViewMode"
        [items]="[
          { label: 'Yearly', value: 365 },
          { label: 'Quarterly', value: 90 },
          { label: 'Monthly', value: 30 },
          { label: 'Weekly', value: 7 },
          { label: 'Daily', value: 1 },
        ]"
        [type]="'number'"
        [min]="0"
        [maxFractionDigits]="0"
      >
      </app-suggestion-input>
    </div>
    @if (fieldViewMode != 'create') {
      <div class="col-12 md:col-6">
        <app-date-input
          [label]="'Last Run Date'"
          [placeholder]="'2022-11-22'"
          [name]="'lastRunDate'"
          [showIcon]="false"
          [control]="formGroup?.controls?.lastRunDate"
          [viewMode]="fieldViewMode"
        ></app-date-input>
      </div>
    }
    <div class="col-12 md:col-12">
      <app-text-editor
        [name]="'description'"
        label="Description"
        [placeholder]="'Enter Text...'"
        [control]="formGroup?.controls?.description"
        [viewMode]="fieldViewMode"
        [advanced]="true"
      ></app-text-editor>
    </div>
  </div>
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create') {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create') {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
