import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { BucketDto, RequestHandlerOptions } from '@shared/classes';
import { BaseRequestControllerService } from '@shared/services/api/base-request-controller.service';
import { DataService } from '@shared/services/api/data.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BucketDataService extends BaseRequestControllerService<BucketDto> {
  private readonly getBucketApiUrl: string = '/getBuckets';
  constructor(
    private dataService: DataService,
    private router: Router
  ) {
    super(
      dataService,
      environment.fileManager,
      '/bucket',
      [
        'id',
        'tags',
        'tagsCodes',
        'uuid',
        'description',
        'recordStatus',
        'translations',
        'originalLanguage',
        'code',
        'label',
        'version',
        'lockedForEdit',
        'creatorName',
        'lastModifierName',
        'lockedBy',
        'userAccessLevel',
        'module',
        'systemGenerated',
        'parent',
        'parents',
        'creationDate',
        'lastModificationDate',
        'lockedUntil',
        'name',
        'physicalName',
      ],
      null
    ); //@todo add module
  }

  // navigateToListPage() {
  //     this.router.navigateByUrl(`file/list`);
  // }
  public getBucketsChildren(rootBucketId: string, options: RequestHandlerOptions = new RequestHandlerOptions()) {
    return this.dataService.getData<Array<BucketDto>>(
      `${this.url}${this.getBucketApiUrl}/${rootBucketId}`,
      options
    ) as Observable<Array<BucketDto>>;
  }
}
