import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LocaleService } from '@core/services/locale/locale.service';
import { DataTypeEnum, DynamicComponentBase, IAction, IColumn, getProp, isNullObj } from '@shared/classes';

@Component({
  selector: 'app-reorder-selector',
  templateUrl: './reorder-selector.component.html',
  styleUrl: './reorder-selector.component.scss'
})
export class ReorderSelectorComponent extends DynamicComponentBase implements OnInit {

  @Input() disableAdd: boolean = false;
  @Input() cols;
  @Input() items;
  @Output() onDropEvent: EventEmitter<any> = new EventEmitter();
  @Input() reorderable: boolean = true;
  @Input() tableActions: IAction[] = [];
  @Input() headerAction: IAction;
  @Input() onlyLastisntViewMode: boolean = false;
  @Input() rowsPerPageOptions: any[] = [5, 10, 25, 50, 100, 200];

  @Input() actionCol: IColumn = {
    name: 'general.actions.actions',
    key: 'actions',
    dataType: null,
    frozen: true,
    alignFrozen: 'right',
  };
  constructor(private localeService: LocaleService) {
    super();
  }



  ngOnInit(): void {

  }

  setInputOptions() {
  }

  updateControl(event) {
    let list = [...this.control?.value ?? []];
    // [list[event?.dragIndex], list[event?.dropIndex]] = [list[event?.dropIndex], list[event?.dragIndex]];
    this.items = [...list];
    this.control.patchValue(list);
    this.onDropEvent.emit(event);
  }

  getElementValue(key: string, data: any) {
    let value = data[key];
    const defaultValue = getProp(data, key);
    value = defaultValue;
    if (
      this.localeService?.language?.langCode &&
      data?.originalLanguage &&
      data?.originalLanguage != this.localeService?.language?.langCode
    ) {
      const translatedValue = getProp(data, `translations.${this.localeService?.language?.langCode}.${key}`);
      value = isNullObj(translatedValue) ? defaultValue : translatedValue;
    }
    return value;
  }

  getOptions(options, dataType): any {
    return {
      ...options,
      useStaticMaxWidth: dataType == DataTypeEnum.Text ? false : undefined,
    };
  }
}
