<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    @if (!questionsOnly && fieldViewMode == 'create') {
      <div class="col-12">
        <app-target-code-selector
          [label]="moduleFieldString + '.scoredQuestionnaire.label' | translate: 'Scored Questionnaire'"
          [placeholder]="moduleFieldString + '.scoredQuestionnaire.placeholder' | translate: 'Search Questionnaires'"
          [control]="formGroup?.controls?.questionnaire"
          [viewMode]="'create'"
          [multi]="false"
          [optionLabel]="'name'"
          [optionValue]="undefined"
          [dataKey]="'code'"
          [targetTypes]="['QUESTIONNAIRE']"
          [customProjectionFields]="['code', 'recordStatus', 'label', 'name', 'color']"
        >
        </app-target-code-selector>
      </div>
      <!-- <div class="col-12 md:col-6">
        <app-drop-down-input
          [label]="moduleFieldString + '.scoreStatus.label' | translate: 'Score Status'"
          [placeholder]="moduleFieldString + '.scoreStatus.placeholder' | translate: 'Select a Score Status'"
          [name]="'dropdown'"
          [optionLabel]="'label'"
          [optionValue]="'value'"
          [items]="scoringEnums"
          [control]="formGroup?.controls?.scoreStatus"
          [viewMode]="'view'"
[canKeepValue]="false"
          [badgeView]="true"
        ></app-drop-down-input>
      </div> -->
    }
    @if (!questionsOnly && fieldViewMode != 'create') {
      <div class="formgrid grid p-fluid">
        <div class="col-12 md:col-6">
          <app-basic-input
            [label]="moduleFieldString + '.name.label' | translate: 'Name'"
            [placeholder]="moduleFieldString + '.name.placeholder' | translate: 'Enter Name'"
            [control]="viewForm?.controls?.questionnaireName"
            [viewMode]="'view'"
            [canKeepValue]="false"
          ></app-basic-input>
        </div>
        <div class="col-12 md:col-6">
          <app-basic-input
            [label]="moduleFieldString + '.creatorName.label' | translate: 'Answered By'"
            [placeholder]="moduleFieldString + '.creatorName.placeholder' | translate: ''"
            [control]="viewForm?.controls?.creatorName"
            [viewMode]="'view'"
            [canKeepValue]="false"
          ></app-basic-input>
        </div>
        <div class="col-12 md:col-6">
          <app-target-code-selector
            [label]="moduleFieldString + '.scoredQuestionnaire.label' | translate: 'Scored Questionnaire'"
            [placeholder]="moduleFieldString + '.scoredQuestionnaire.placeholder' | translate: 'Search Questionnaires'"
            [control]="viewForm?.controls?.code"
            [viewMode]="'view'"
            [canKeepValue]="false"
            [multi]="false"
            [optionLabel]="'name'"
            [optionValue]="'code'"
            [targetTypes]="['QUESTIONNAIRE']"
          >
          </app-target-code-selector>
        </div>
        <div class="col-12 md:col-6">
          <app-target-code-selector
            [label]="moduleFieldString + '.type.label' | translate: 'Type'"
            [placeholder]="moduleFieldString + '.type.placeholder' | translate: 'Search Types'"
            [control]="formGroup?.controls?.questionnaireType"
            [viewMode]="fieldViewMode"
            [multi]="false"
            [optionLabel]="'name'"
            [optionValue]="'code'"
            [targetTypes]="['QUESTIONNAIRE_TYPE']"
          >
          </app-target-code-selector>
        </div>
        <div class="col-12 md:col-6">
          <app-target-code-selector
            [label]="moduleFieldString + '.campaign.label' | translate: 'Campaign'"
            [placeholder]="moduleFieldString + '.campaign.placeholder' | translate: 'Enter Campaign'"
            [control]="formGroup?.controls?.campaign"
            [viewMode]="'view'"
            [canKeepValue]="false"
            [multi]="false"
            [optionLabel]="'name'"
            [optionValue]="'code'"
            [targetTypes]="['CAMPAIGN']"
          >
          </app-target-code-selector>
        </div>
        <!-- <div class="col-12 md:col-6">
        <app-p-slider-input
          [name]="'score'"
          [label]="moduleFieldString + '.score.label' | translate: 'Score'"
          [placeholder]="moduleFieldString + '.score.placeholder' | translate: 'Enter Score'"
          [control]="formGroup?.controls?.score"
          [viewMode]="fieldViewMode"
          [min]="0"
          [max]="maxPassingScore"
        ></app-p-slider-input>
      </div>-->
        <!-- <div class="col-12 md:col-6">
        <app-drop-down-input
          [label]="moduleFieldString + '.scoreStatus.label' | translate: 'Score Status'"
          [placeholder]="moduleFieldString + '.scoreStatus.placeholder' | translate: 'Select a Score Status'"
          [name]="'dropdown'"
          [optionLabel]="'label'"
          [optionValue]="'value'"
          [items]="scoringEnums"
          [control]="formGroup?.controls?.scoreStatus"
          [viewMode]="'view'"
[canKeepValue]="false"
          [badgeView]="true"
        ></app-drop-down-input>
      </div> -->
      </div>
      <!-- <p-divider align="left" class="col-12">
      <b>Questionnaire</b>
    </p-divider>
    <div class="formgrid grid p-fluid">
      <div class="col-12 md:col-6">
        <app-basic-input
          [label]="moduleFieldString + '.name.label' | translate: 'Name'"
          [placeholder]="moduleFieldString + '.name.placeholder' | translate: 'Enter Name'"
          [control]="viewForm?.controls?.name"
          [viewMode]="'view'"
[canKeepValue]="false"
        ></app-basic-input>
      </div>
      <div class="col-12 md:col-6">
        <app-target-code-selector
          [label]="moduleFieldString + '.type.label' | translate: 'Questions'"
          [placeholder]="moduleFieldString + '.type.placeholder' | translate: 'Enter Questions'"
          [control]="viewForm?.controls?.type"
          [viewMode]="fieldViewMode"
          [multi]="false"
          [optionLabel]="'name'"
          [optionValue]="'code'"
          [targetTypes]="['QUESTIONNAIRE_TYPE']"
        >
        </app-target-code-selector>
      </div>
      <div class="col-12 md:col-6">
        <app-switch-input
          [label]="moduleFieldString + '.scoredQuestionnaire.label' | translate: 'Scored Questionnaire'"
          [name]="'scoredQuestionnaire'"
          [viewMode]="'view'"
[canKeepValue]="false"
          [control]="viewForm?.controls?.scoredQuestionnaire"
        ></app-switch-input>
      </div>
      @if (viewForm?.controls?.scoredQuestionnaire?.value == true) {
        <div class="col-12 md:col-6">
          <app-number-input
            [label]="moduleFieldString + '.scoredQuestionnaire.label' | translate: 'Passing Score'"
            [placeholder]="moduleFieldString + '.scoredQuestionnaire.label' | translate: 'Passing Score...'"
            [name]="'passScore'"
            [showButtons]="true"
            [control]="viewForm?.controls?.passScore"
            [type]="'number'"
            [viewMode]="'view'"
[canKeepValue]="false"
            [maxFractionDigits]="0"
            [min]="0"
          ></app-number-input>
        </div>
      }
      <div class="col-12 md:col-12">
        <app-text-editor
          [height]="'100px'"
          [advanced]="true"
          [name]="'description'"
          [label]="moduleFieldString + '.scoredQuestionnaire.label' | translate: 'Description'"
          [placeholder]="moduleFieldString + '.scoredQuestionnaire.label' | translate: 'Enter Text...'"
          [control]="viewForm?.controls?.description"
          [viewMode]="'view'"
[canKeepValue]="false"
        ></app-text-editor>
      </div>
    </div> -->
    }
    @if (!loading && questionnaire) {
      <!-- <p-divider align="left" class="col-12">
        <b>Answers</b>
      </p-divider> -->
      <div
        class="overflow-scroll-y"
        [class]="questionnaire?.scoringType == scoredType ? 'col-12 md:col-6' : 'col-12'"
        style="max-height: 24rem"
      >
        <app-dynamic-field-form-list-input
          [showScore]="fieldViewMode == 'edit' && questionnaire?.scoringType == scoredType"
          [control]="formGroup?.controls?.answers"
          [viewMode]="fieldViewMode"
          [config]="{ items: questions, values: data?.answers, scored: questionnaire?.scoringType == scoredType }"
          (scoreStatusChange)="onScoreStatusChange($event)"
          (answerValuesChanged)="onScoreDataChange($event)"
        ></app-dynamic-field-form-list-input>
      </div>
      @if (parsedGaugeDataQsv && questionnaire?.scoringType == scoredType) {
        <div class="col-12 md:col-6">
          <app-drop-down-input
            [label]="moduleFieldString + '.scoreStatus.label' | translate: 'Score Status'"
            [placeholder]="moduleFieldString + '.scoreStatus.placeholder' | translate: 'Select a Score Status'"
            [name]="'dropdown'"
            [optionLabel]="'label'"
            [optionValue]="'value'"
            [items]="scoringEnums"
            [control]="formGroup?.controls?.scoreStatus"
            [viewMode]="'view'"
            [canKeepValue]="false"
            [badgeView]="true"
          ></app-drop-down-input>

          <app-widget-chart
            [directConfig]="{
              dataObject: parsedGaugeDataQsv ?? {},
              chartType: 'GAUGE',
            }"
            [title]="'Scope'"
            [height]="'38vh'"
            [styleClass]="'relative m-0'"
            [showChartViewControls]="false"
          >
            <ng-template let-config #headerTemplate>
              <!-- <div class="flex justify-content-center w-full">Scope</div> -->
            </ng-template>
          </app-widget-chart>
        </div>
      }
    }
  </div>
  @if (loading) {
    <div class="custom-skeleton">
      <div class="flex mb-3">
        <!-- <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton> -->
        <div class="w-full mb-2">
          <p-skeleton styleClass="mb-2"></p-skeleton>
          <p-skeleton width="50%" styleClass="mb-2"></p-skeleton>
          <p-skeleton width="20%" styleClass="mb-2"></p-skeleton>
          <p-skeleton width="80%" height="2rem" styleClass="mb-2"></p-skeleton>
          <p-skeleton width="65%" height="4rem"></p-skeleton>
        </div>
      </div>
      <p-skeleton width="100%" height="150px"></p-skeleton>
      <div class="flex justify-content-end gap-2 mt-3">
        <p-skeleton width="8rem" height="2rem"></p-skeleton>
        <p-skeleton width="8rem" height="2rem"></p-skeleton>
      </div>
    </div>
  }

  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create') {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create') {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
