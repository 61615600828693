<!-- prime table from flat tree (sort ..etc doesnt work) -->
<!-- <p-table [value]="flatTree" [tableStyle]="{ 'min-width': '50rem' }" styleClass="p-datatable-gridlines">
  <ng-template pTemplate="header">
    <tr>
      @for (column of groupByFields; track column) {
        <th [pSortableColumn]="column">{{ column | humanize }} <p-sortIcon [field]="column" /></th>
      }
      @for (column of displayFields; track column) {
        <th [pSortableColumn]="column.key">{{ column.name | humanize }} <p-sortIcon [field]="column.key" /></th>
      }
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-row>
    <tr>
      @for (col of row; track col) {
        @if (col?.rowspan != 0) {
          <td [attr.rowspan]="col?.rowspan">{{ col?.key | humanize }}</td>
          @if (col?.data) {
            @for (column of displayFields; track column) {
              <td>{{ col?.data?.[column?.key] }}</td>
            }
          }
        }
      }
    </tr>
  </ng-template>
</p-table> -->
<!-- basic html table from flat tree -->
<!-- <table width="100%">
  <thead>
    <tr>
      @for (column of groupByFields; track column) {
        <th class="sf-statistics-table-cell">{{ column | humanize }}</th>
      }
      @for (column of displayFields; track column) {
        <th class="sf-statistics-table-cell">{{ column.name | humanize }}</th>
      }
    </tr>
  </thead>
  <tbody>
    @for (row of flatTree; track row) {
      <tr>
        @for (col of row; track col) {
          @if (col?.rowspan != 0) {
            <td class="sf-statistics-table-cell" [attr.rowspan]="col?.rowspan">{{ col?.key | humanize }}</td>
            @if (col?.data) {
              @for (column of displayFields; track column) {
                <th class="sf-statistics-table-cell">{{ col?.data?.[column?.key] }}</th>
              }
            }
          }
        }
      </tr>
    }
  </tbody>
</table> -->
@if (groupByFields?.length > 0) {
  <p-table
    [value]="rows"
    [rowHover]="true"
    [tableStyle]="{ 'min-width': '31rem' }"
    styleClass="p-datatable-gridlines flex flex-column h-full p-datatable-hoverable-rows"
  >
    <ng-template pTemplate="header">
      <tr>
        @for (column of groupByFieldsKeys; track column; let i = $index) {
          @if (i == 0) {
            <th class="border-no-round" [pSortableColumn]="column">
              {{ column | humanize }} <p-sortIcon [field]="column" />
            </th>
          } @else {
            <th class="border-no-round">{{ column | humanize }}</th>
          }
        }
        @for (column of displayFields; track column) {
          <th class="border-no-round">{{ column.name | humanize }}</th>
        }
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row>
      <tr>
        @for (column of groupByFieldsKeys; track column) {
          @if (row?.[column + '_rowspan'] != 0) {
            <td [attr.rowspan]="row?.[column + '_rowspan']">
              @if (isValidCode(row?.[column])) {
                <app-code-navigation [showTooltip]="true" [data]="row?.[column]"></app-code-navigation>
              } @else {
                {{ row?.[column] | humanize }}
              }
            </td>
            @if (row?.[column + '_data']) {
              @for (dCol of displayFields; track dCol) {
                <td>
                  {{ row?.[column + '_data']?.[dCol?.key] }}
                </td>
              }
            }
          }
        }
      </tr>
    </ng-template>
  </p-table>
}
