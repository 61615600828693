import { Injectable, Type } from '@angular/core';
import { BaseForm, ModuleKeywords } from '@shared/classes';
import { AcknowledgementRequestItemFormComponent } from 'app/modules/acknowledgement-module/acknowledgement-request/acknowledgement-request-item-form/acknowledgement-request-item-form.component';
import { AcknowledgementItemFormComponent } from 'app/modules/acknowledgement-module/acknowledgement/acknowledgement-item-form/acknowledgement-item-form.component';
import { EmailTemplateItemFormComponent } from 'app/modules/activity-feed/email-templates/email-template-item-form/email-template-item-form.component';
import { TaskTemplateItemFormComponent } from 'app/modules/activity-feed/task-templates/task-template-item-form/task-template-item-form.component';
import { TaskItemFormComponent } from 'app/modules/activity-feed/tasks/task-item-form/task-item-form.component';
import { AuditCaseResultItemFormComponent } from 'app/modules/audit-module/audit-case-result/audit-case-result-item-form/audit-case-result-item-form.component';
import { AuditCaseItemFormComponent } from 'app/modules/audit-module/audit-case/audit-case-item-form/audit-case-item-form.component';
import { AuditExpenseItemFormComponent } from 'app/modules/audit-module/audit-expense/audit-expense-item-form/audit-expense-item-form.component';
import { AuditObjectiveItemFormComponent } from 'app/modules/audit-module/audit-objective/audit-objective-item-form/audit-objective-item-form.component';
import { AuditProgramReportItemFormComponent } from 'app/modules/audit-module/audit-program-report/audit-program-report-item-form/audit-program-report-item-form.component';
import { AuditProgramTypeItemFormComponent } from 'app/modules/audit-module/audit-program-type/audit-program-type-item-form/audit-program-type-item-form.component';
import { AuditProgramItemFormComponent } from 'app/modules/audit-module/audit-program/audit-program-item-form/audit-program-item-form.component';
import { AuditProjectReportItemFormComponent } from 'app/modules/audit-module/audit-project-report/audit-project-report-item-form/audit-project-report-item-form.component';
import { AuditProjectItemFormComponent } from 'app/modules/audit-module/audit-project/audit-project-item-form/audit-project-item-form.component';
import { AuditRequestItemFormComponent } from 'app/modules/audit-module/audit-request/audit-request-item-form/audit-request-item-form.component';
import { AuditUniverseItemFormComponent } from 'app/modules/audit-module/audit-universe/audit-universe-item-form/audit-universe-item-form.component';
import { AssetsItemFormComponent } from 'app/modules/entity-module/assets/assets-item-form/assets-item-form.component';
import { EntityTypesItemFormComponent } from 'app/modules/entity-module/entity-types/entity-types-item-form/entity-types-item-form.component';
import { EventsItemFormComponent } from 'app/modules/entity-module/events/events-item-form/events-item-form.component';
import { GeographiesItemFormComponent } from 'app/modules/entity-module/geographies/geographies-item-form/geographies-item-form.component';
import { ObjectivesItemFormComponent } from 'app/modules/entity-module/objectives/objectives-item-form/objectives-item-form.component';
import { OrganizationsItemFormComponent } from 'app/modules/entity-module/organizations/organizations-item-form/organizations-item-form.component';
import { ProcessesItemFormComponent } from 'app/modules/entity-module/processes/processes-item-form/processes-item-form.component';
import { ResponsibilitiesItemFormComponent } from 'app/modules/entity-module/responsibilities/responsibilities-item-form/responsibilities-item-form.component';
import { FieldItemFormComponent } from 'app/modules/global-configuration/fields/field-item-form/field-item-form.component';
import { GlobalValueItemFormComponent } from 'app/modules/global-configuration/global-value-list/global-value-item-form/global-value-item-form.component';
import { LanguageItemFormComponent } from 'app/modules/global-configuration/language/language-item-form/language-item-form.component';
import { TagItemFormComponent } from 'app/modules/global-configuration/tag/tag-item-form/tag-item-form.component';
import { ExportGlobalPackageItemFormComponent } from 'app/modules/global-package-module/export-global-package/export-global-package-item-form/export-global-package-item-form.component';
import { ImportGlobalPackageItemFormComponent } from 'app/modules/global-package-module/import-global-package/import-global-package-item-form/import-global-package-item-form.component';
import { IssueTypesItemFormComponent } from 'app/modules/issues-module/issue-types/issue-types-item-form/issue-types-item-form.component';
import { IssuesItemFormComponent } from 'app/modules/issues-module/issues/issues-item-form/issues-item-form.component';
import { RemediationActionItemFormComponent } from 'app/modules/issues-module/remediation-action/remediation-action-item-form/remediation-action-item-form.component';
import { RemediationPlanItemFormComponent } from 'app/modules/issues-module/remediation-plan/remediation-plan-item-form/remediation-plan-item-form.component';
import { AttestationItemFormComponent } from 'app/modules/org-framework/attestation/attestation-item-form/attestation-item-form.component';
import { AuthorityItemFormComponent } from 'app/modules/org-framework/authority/authority-item-form/authority-item-form.component';
import { CitationTypeItemFormComponent } from 'app/modules/org-framework/citation-type/citation-type-item-form/citation-type-item-form.component';
import { CitationItemFormComponent } from 'app/modules/org-framework/citation/citation-item-form/citation-item-form.component';
import { ComplianceReportResultItemFormComponent } from 'app/modules/org-framework/compliance-report-result/compliance-report-result-item-form/compliance-report-result-item-form.component';
import { ComplianceReportItemFormComponent } from 'app/modules/org-framework/compliance-report/compliance-report-item-form/compliance-report-item-form.component';
import { ControlCategoryItemFormComponent } from 'app/modules/org-framework/control-category/control-category-item-form/control-category-item-form.component';
import { ControlGroupItemFormComponent } from 'app/modules/org-framework/control-group/control-group-item-form/control-group-item-form.component';
import { ControlObjectiveItemFormComponent } from 'app/modules/org-framework/control-objective/control-objective-item-form/control-objective-item-form.component';
import { ControlProcedureItemFormComponent } from 'app/modules/org-framework/control-procedure/control-procedure-item-form/control-procedure-item-form.component';
import { ControlItemFormComponent } from 'app/modules/org-framework/control/control-item-form/control-item-form.component';
import { ControlsGapReportResultItemFormComponent } from 'app/modules/org-framework/controls-gap-report-result/controls-gap-report-result-item-form/controls-gap-report-result-item-form.component';
import { ControlsGapReportItemFormComponent } from 'app/modules/org-framework/controls-gap-report/controls-gap-report-item-form/controls-gap-report-item-form.component';
import { EvidenceItemFormComponent } from 'app/modules/org-framework/evidence/evidence-item-form/evidence-item-form.component';
import { ExceptionFormComponent } from 'app/modules/org-framework/exception/exception-form/exception-form.component';
import { ExemptionItemFormComponent } from 'app/modules/org-framework/exemption/exemption-item-form/exemption-item-form.component';
import { GuidelineItemFormComponent } from 'app/modules/org-framework/guideline/guideline-item-form/guideline-item-form.component';
import { PolicyBodySectionTemplateItemFormComponent } from 'app/modules/org-framework/policy/policy-body-section-template/policy-body-section-template-item-form/policy-body-section-template-item-form.component';
import { PolicyBodySectionItemFormComponent } from 'app/modules/org-framework/policy/policy-body-section/policy-body-section-item-form/policy-body-section-item-form.component';
import { PolicyBodyTemplateItemFormComponent } from 'app/modules/org-framework/policy/policy-body-template/policy-body-template-item-form/policy-body-template-item-form.component';
import { PolicyBodyFormComponent } from 'app/modules/org-framework/policy/policy-body/policy-body-form/policy-body-form.component';
import { PolicyItemFormComponent } from 'app/modules/org-framework/policy/policy-item-form/policy-item-form.component';
import { PublishedGuidelineItemFormComponent } from 'app/modules/org-framework/published-guideline/published-guideline-item-form/published-guideline-item-form.component';
import { PublishedPolicyItemFormComponent } from 'app/modules/org-framework/published-policy/published-policy-item-form/published-policy-item-form.component';
import { ReportControlGroupItemItemFormComponent } from 'app/modules/org-framework/report-control-group-item/report-control-group-item-item-form/report-control-group-item-item-form.component';
import { ReportControlItemItemFormComponent } from 'app/modules/org-framework/report-control-item/report-control-item-item-form/report-control-item-item-form.component';
import { ReportControlObjectiveItemItemFormComponent } from 'app/modules/org-framework/report-control-objective-item/report-control-objective-item-item-form/report-control-objective-item-item-form.component';
import { StandardItemFormComponent } from 'app/modules/org-framework/standard/standard-item-form/standard-item-form.component';
import { MetricCategoryItemFormComponent } from 'app/modules/performance-module/metric-category/metric-category-item-form/metric-category-item-form.component';
import { MetricValueItemFormComponent } from 'app/modules/performance-module/metric-value/metric-value-item-form/metric-value-item-form.component';
import { MetricItemFormComponent } from 'app/modules/performance-module/metric/metric-item-form/metric-item-form.component';
import { CampaignItemFormComponent } from 'app/modules/questions/campaign/campaign-item-form/campaign-item-form.component';
import { QuestionItemFormComponent } from 'app/modules/questions/components/question-bank/question-item-form/question-item-form.component';
import { QuestionnaireTypeItemFormComponent } from 'app/modules/questions/components/questionnaire-type/questionnaire-type-item-form/questionnaire-type-item-form.component';
import { QuestionnaireFormComponent } from 'app/modules/questions/components/questionnaire/questionnaire-form/questionnaire-form.component';
import { QuestionnaireValueFormComponent } from 'app/modules/questions/components/questionnaireValue/component/questionnaire-value-form/questionnaire-value-form.component';
import { QuestionnaireCampaignRequestItemFormComponent } from 'app/modules/questions/questionnaire-campaign-request/questionnaire-campaign-request-item-form/questionnaire-campaign-request-item-form.component';
import { RelationTypeItemFormComponent } from 'app/modules/relation-module/relation-types/relation-type-item-form/relation-type-item-form.component';
import { DashboardItemFormComponent } from 'app/modules/reports-and-dashboards/dashboards/dashboard-item-form/dashboard-item-form.component';
import { FilterItemFormComponent } from 'app/modules/reports-and-dashboards/filter/filter-item-form/filter-item-form.component';
import { GeneratedReportItemFormComponent } from 'app/modules/reports-and-dashboards/generated-report/generated-report-item-form/generated-report-item-form.component';
import { GeneratedStatisticItemFormComponent } from 'app/modules/reports-and-dashboards/generated-statistics/generated-statistic-item-form/generated-statistic-item-form.component';
import { ReportConfigurationTemplateItemFormComponent } from 'app/modules/reports-and-dashboards/report-configuration-templates/report-configuration-template-item-form/report-configuration-template-item-form.component';
import { ReportSchedulerItemFormComponent } from 'app/modules/reports-and-dashboards/report-scheduler/report-scheduler-item-form/report-scheduler-item-form.component';
import { ReportItemFormComponent } from 'app/modules/reports-and-dashboards/reports/report-item-form/report-item-form.component';
import { StatisticCollectorItemFormComponent } from 'app/modules/reports-and-dashboards/statistic-collectors/statistic-collector-item-form/statistic-collector-item-form.component';
import { ViewItemFormComponent } from 'app/modules/reports-and-dashboards/view/view-item-form/view-item-form.component';
import { WorkspaceItemFormComponent } from 'app/modules/reports-and-dashboards/workspace/workspace-item-form/workspace-item-form.component';
import { RiskItemFormComponent } from 'app/modules/risk/risk-item/risk-item-form/risk-item-form.component';
import { RiskMethodologyFormComponent } from 'app/modules/risk/risk-methodology/component/risk-methodology-form/risk-methodology-form.component';
import { RiskMethodologyImpactFactorOptionItemFormComponent } from 'app/modules/risk/risk-methodology/risk-methodology-impact-factor-option/risk-methodology-impact-factor-option-item-form/risk-methodology-impact-factor-option-item-form.component';
import { RiskMethodologyImpactFactorItemFormComponent } from 'app/modules/risk/risk-methodology/risk-methodology-impact-factor/risk-methodology-impact-factor-item-form/risk-methodology-impact-factor-item-form.component';
import { RiskMethodologyImpactValueItemFormComponent } from 'app/modules/risk/risk-methodology/risk-methodology-impact-value/risk-methodology-impact-value-item-form/risk-methodology-impact-value-item-form.component';
import { RiskMethodologyLikelihoodValueItemFormComponent } from 'app/modules/risk/risk-methodology/risk-methodology-likelihood-value/risk-methodology-likelihood-value-item-form/risk-methodology-likelihood-value-item-form.component';
import { RiskMethodologyThresholdValueItemFormComponent } from 'app/modules/risk/risk-methodology/risk-methodology-threshold-value/risk-methodology-threshold-value-item-form/risk-methodology-threshold-value-item-form.component';
import { RiskStatementCategoryItemFormComponent } from 'app/modules/risk/risk-statement-category/risk-statement-category-item-form/risk-statement-category-item-form.component';
import { RiskStatementItemFormComponent } from 'app/modules/risk/risk-statement/risk-statement-item-form/risk-statement-item-form.component';
import { RiskThreatItemFormComponent } from 'app/modules/risk/risk-threat/risk-threat-item-form/risk-threat-item-form.component';
import { RiskVulnerabilityItemFormComponent } from 'app/modules/risk/risk-vulnerability/risk-vulnerability-item-form/risk-vulnerability-item-form.component';
// import { SlaItemFormComponent } from 'app/modules/third-party-management/sla/sla-item-form/sla-item-form.component';
// import { SloItemFormComponent } from 'app/modules/third-party-management/slo/slo-item-form/slo-item-form.component';
// import { TpClassificationFactorOptionItemFormComponent } from 'app/modules/third-party-management/tp-classification-factor-option/tp-classification-factor-option-item-form/tp-classification-factor-option-item-form.component';
// import { TpClassificationFactorItemFormComponent } from 'app/modules/third-party-management/tp-classification-factor/tp-classification-factor-item-form/tp-classification-factor-item-form.component';
// import { TpContactItemFormComponent } from 'app/modules/third-party-management/tp-contact/tp-contact-item-form/tp-contact-item-form.component';
// import { TpContractItemFormComponent } from 'app/modules/third-party-management/tp-contract/tp-contract-item-form/tp-contract-item-form.component';
// import { TpRiskAssessmentItemFormComponent } from 'app/modules/third-party-management/tp-risk-assessment/tp-risk-assessment-item-form/tp-risk-assessment-item-form.component';
// import { TpRiskMethodologyThresholdValueItemFormComponent } from 'app/modules/third-party-management/tp-risk-methodology-threshold-value/tp-risk-methodology-threshold-value-item-form/tp-risk-methodology-threshold-value-item-form.component';
// import { TpRiskMethodologyItemFormComponent } from 'app/modules/third-party-management/tp-risk-methodology/tp-risk-methodology-item-form/tp-risk-methodology-item-form.component';
// import { TpServiceCategoryItemFormComponent } from 'app/modules/third-party-management/tp-service-category/tp-service-category-item-form/tp-service-category-item-form.component';
// import { TpServiceItemFormComponent } from 'app/modules/third-party-management/tp-service/tp-service-item-form/tp-service-item-form.component';
// import { TpSloViolationItemFormComponent } from 'app/modules/third-party-management/tp-slo-violation/tp-slo-violation-item-form/tp-slo-violation-item-form.component';
// import { TpVendorItemFormComponent } from 'app/modules/third-party-management/tp-vendor/tp-vendor-item-form/tp-vendor-item-form.component';
import { TransparencyCaseCategoryItemFormComponent } from 'app/modules/transparency-management/transparency-case-category/transparency-case-category-item-form/transparency-case-category-item-form.component';
import { TransparencyCaseItemFormComponent } from 'app/modules/transparency-management/transparency-case/transparency-case-item-form/transparency-case-item-form.component';
import { GroupItemFormComponent } from 'app/modules/users/group/group-item-form/group-item-form.component';
import { RoleItemFormComponent } from 'app/modules/users/role/role-item-form/role-item-form.component';
import { UserItemFormComponent } from 'app/modules/users/user/user-item-form/user-item-form.component';

@Injectable({
  providedIn: 'root',
})
export class FormMapperService {
  forms: { [key in ModuleKeywords]: Type<BaseForm<any>> } = {
    QUESTION: undefined,
    QUESTIONNAIRE: undefined,
    QUESTIONNAIRE_VALUE: undefined,
    MY_QUESTIONNAIRE_VALUE: undefined,
    RISK_ITEM: undefined,
    RISK_PROJECT: undefined,
    RISK_STATEMENT: undefined,
    RISK_STATEMENT_CATEGORY: undefined,
    EVENT: undefined,
    GEOGRAPHY: undefined,
    RESPONSIBILITY: undefined,
    ASSET: undefined,
    PROCESS: undefined,
    OBJECTIVE: undefined,
    ENTITY_TYPE: undefined,
    POLICY: undefined,
    CONTROL: undefined,
    CITATION_TYPE: undefined,
    CITATION: undefined,
    ACKNOWLEDGMENT_REQUEST: undefined,
    ACKNOWLEDGMENT: undefined,
    TASK: undefined,
    MY_TASK: undefined,
    ATTESTATION: undefined,
    EXCEPTION: undefined,
    CHECK_ITEM: undefined,
    ISSUE: undefined,
    ISSUE_TYPE: undefined,
    REMEDIATION: undefined,
    NOTIFICATION: undefined,
    MY_NOTIFICATION: undefined,
    RISK_METHODOLOGY: undefined,
    SUBSCRIPTION: undefined,
    POLICY_BODY: undefined,
    FIELD_TEMPLATE: undefined,
    RISK_ASSESSMENT_REQUEST: undefined,
    DASHBOARD_LAYOUT: undefined,
    CRITERIA_FILTER: undefined,
    FIELD_SET: undefined,
    FORM_COMPONENT: undefined,
    RELATION_TYPE: undefined,
    ORGANIZATION: undefined,
    ENTITY_GROUP: undefined,
    EXPORT_PACKAGE: undefined,
    IMPORT_PACKAGE: undefined,
    EXEMPTION: undefined,
    SETTING: undefined,
    COMMENT: undefined,
    RELATION: undefined,
    RELATION_TYPE_ITEM: undefined,
    POLICY_BODY_SECTION: undefined,
    GLOBAL_VALUE_LIST: undefined,
    COMPLIANCE_REPORT: undefined,
    COMPLIANCE_REPORT_RESULT: undefined,
    USER: undefined,
    ROLE: undefined,
    GROUP: undefined,
    FINDING: undefined,
    RISK_REGISTER: undefined,
    STATIC_VALUE: undefined,
    MY_RISK_ASSESSMENT_REQUEST: undefined,
    EMAIL_TEMPLATE: undefined,
    TASK_TEMPLATE: undefined,
    MY_ACKNOWLEDGMENT: undefined,
    AUDIT_TRAIL: undefined,
    DOCUMENT: undefined,
    AUDIT_CASE: undefined,
    AUDIT_CASE_RESULT: undefined,
    AUDIT_UNIVERSE: undefined,
    AUDIT_PROJECT: undefined,
    AUDIT_PROGRAM: undefined,
    AUDIT_PROGRAM_TYPE: undefined,
    AUDIT_CASE_TEMPLATE: undefined,
    AUDIT_PROGRAM_REPORT: undefined,
    AUDIT_PROJECT_REPORT: undefined,
    AUDIT_REQUEST: undefined,
    EXPORT_GLOBAL_PACKAGE: undefined,
    IMPORT_GLOBAL_PACKAGE: undefined,
    RISK_VULNERABILITY: undefined,
    RISK_THREAT: undefined,
    FILTER: undefined,
    CONTENT_VIEW: undefined,
    DASHBOARD: undefined,
    REPORT: undefined,
    PUBLISHED_POLICY: undefined,
    STANDARD: undefined,
    PUBLISHED_STANDARD: undefined,
    GUIDELINE: undefined,
    PUBLISHED_GUIDELINE: undefined,
    CONTROL_GROUP: undefined,
    CONTROL_OBJECTIVE: undefined,
    AUTHORITY_DOCUMENT: undefined,
    CONTROL_CATEGORY: undefined,
    CONTROL_PROCEDURE: undefined,
    STATISTIC_COLLECTOR: undefined,
    GENERATED_STATISTIC: undefined,
    GENERATED_REPORT: undefined,
    REPORT_CONFIGURATION_TEMPLATE: undefined,
    TAG: undefined,
    AUDIT_EXPENSE: undefined,
    TRANSPARENCY_CASE: undefined,
    TRANSPARENCY_CASE_CATEGORY: undefined,
    REPORT_SCHEDULER: undefined,
    METRIC: undefined,
    METRIC_VALUE: undefined,
    METRIC_CATEGORY: undefined,
    LANGUAGE: undefined,
    CONTROLS_GAP_REPORT: undefined,
    CONTROLS_GAP_REPORT_RESULT: undefined,
    CAMPAIGN: undefined,
    QUESTIONNAIRE_REQUEST: undefined,
    WORKSPACE: undefined,
    QUESTIONNAIRE_TYPE: undefined,
    DRAFT_RISK: undefined,
    RISK_METHODOLOGY_THRESHOLD_VALUE: undefined,
    RISK_METHODOLOGY_LIKELIHOOD_VALUE: undefined,
    RISK_METHODOLOGY_IMPACT_VALUE: undefined,
    RISK_METHODOLOGY_IMPACT_FACTOR: undefined,
    AUDIT_OBJECTIVE: undefined,
    REMEDIATION_ACTION: undefined,
    EVIDENCE: undefined,
    POLICY_BODY_SECTION_TEMPLATE: undefined,
    POLICY_BODY_TEMPLATE: undefined,
    RISK_METHODOLOGY_IMPACT_FACTOR_OPTION: undefined,
    GLOBAL_VALUE_LIST_ITEM: undefined,
    REPORT_CONTROL_ITEM: undefined,
    REPORT_CONTROL_GROUP_ITEM: undefined,
    REPORT_CONTROL_OBJECTIVE_ITEM: undefined,
    TP_CLASSIFICATION_FACTOR: undefined,
    TP_CLASSIFICATION_FACTOR_OPTION: undefined,
    TP_CONTACT: undefined,
    TP_CONTRACT: undefined,
    TP_SERVICE: undefined,
    TP_SERVICE_CATEGORY: undefined,
    SLA: undefined,
    SLO: undefined,
    TP_SLO_VIOLATION: undefined,
    TP_RISK_ASSESSMENT: undefined,
    TP_RISK_METHODOLOGY: undefined,
    TP_RISK_METHODOLOGY_THRESHOLD_VALUE: undefined,
    TP_VENDOR: undefined,
  };
  constructor() {
    this.forms = {
      QUESTION: QuestionItemFormComponent,
      QUESTIONNAIRE: QuestionnaireFormComponent,
      QUESTIONNAIRE_VALUE: QuestionnaireValueFormComponent,
      MY_QUESTIONNAIRE_VALUE: QuestionnaireValueFormComponent, //duplicate warning
      RISK_ITEM: RiskItemFormComponent,
      RISK_PROJECT: undefined, //composit form warning
      RISK_STATEMENT: RiskStatementItemFormComponent,
      RISK_STATEMENT_CATEGORY: RiskStatementCategoryItemFormComponent,
      EVENT: EventsItemFormComponent,
      GEOGRAPHY: GeographiesItemFormComponent,
      RESPONSIBILITY: ResponsibilitiesItemFormComponent,
      ASSET: AssetsItemFormComponent,
      PROCESS: ProcessesItemFormComponent,
      OBJECTIVE: ObjectivesItemFormComponent,
      ENTITY_TYPE: EntityTypesItemFormComponent,
      POLICY: PolicyItemFormComponent,
      CONTROL: ControlItemFormComponent,
      CITATION_TYPE: CitationTypeItemFormComponent,
      CITATION: CitationItemFormComponent,
      ACKNOWLEDGMENT_REQUEST: AcknowledgementRequestItemFormComponent,
      ACKNOWLEDGMENT: AcknowledgementItemFormComponent,
      TASK: TaskItemFormComponent,
      MY_TASK: TaskItemFormComponent, //duplicate warning
      ATTESTATION: AttestationItemFormComponent,
      EXCEPTION: ExceptionFormComponent,
      CHECK_ITEM: undefined,
      ISSUE: IssuesItemFormComponent,
      ISSUE_TYPE: IssueTypesItemFormComponent,
      REMEDIATION: RemediationPlanItemFormComponent,
      NOTIFICATION: undefined,
      MY_NOTIFICATION: undefined,
      RISK_METHODOLOGY: RiskMethodologyFormComponent,
      SUBSCRIPTION: undefined,
      POLICY_BODY: PolicyBodyFormComponent,
      FIELD_TEMPLATE: FieldItemFormComponent,
      RISK_ASSESSMENT_REQUEST: undefined, //composit form warning
      DASHBOARD_LAYOUT: DashboardItemFormComponent,
      CRITERIA_FILTER: undefined,
      FIELD_SET: undefined,
      FORM_COMPONENT: undefined,
      RELATION_TYPE: RelationTypeItemFormComponent,
      ORGANIZATION: OrganizationsItemFormComponent,
      ENTITY_GROUP: undefined,
      EXPORT_PACKAGE: undefined,
      IMPORT_PACKAGE: undefined,
      EXEMPTION: ExemptionItemFormComponent,
      SETTING: undefined,
      COMMENT: undefined,
      RELATION: undefined,
      RELATION_TYPE_ITEM: undefined,
      POLICY_BODY_SECTION: PolicyBodySectionItemFormComponent,
      GLOBAL_VALUE_LIST: GlobalValueItemFormComponent,
      COMPLIANCE_REPORT: ComplianceReportItemFormComponent,
      COMPLIANCE_REPORT_RESULT: ComplianceReportResultItemFormComponent,
      USER: UserItemFormComponent,
      ROLE: RoleItemFormComponent,
      GROUP: GroupItemFormComponent,
      FINDING: undefined,
      RISK_REGISTER: RiskItemFormComponent, // duplicate form usage
      STATIC_VALUE: undefined,
      MY_RISK_ASSESSMENT_REQUEST: undefined,
      EMAIL_TEMPLATE: EmailTemplateItemFormComponent,
      TASK_TEMPLATE: TaskTemplateItemFormComponent,
      MY_ACKNOWLEDGMENT: undefined,
      AUDIT_TRAIL: undefined,
      DOCUMENT: undefined,
      AUDIT_CASE: AuditCaseItemFormComponent,
      AUDIT_CASE_RESULT: AuditCaseResultItemFormComponent,
      AUDIT_UNIVERSE: AuditUniverseItemFormComponent,
      AUDIT_PROJECT: AuditProjectItemFormComponent,
      AUDIT_PROGRAM: AuditProgramItemFormComponent,
      AUDIT_PROGRAM_TYPE: AuditProgramTypeItemFormComponent,
      AUDIT_CASE_TEMPLATE: undefined,
      AUDIT_PROGRAM_REPORT: AuditProgramReportItemFormComponent,
      AUDIT_PROJECT_REPORT: AuditProjectReportItemFormComponent,
      AUDIT_REQUEST: AuditRequestItemFormComponent,
      EXPORT_GLOBAL_PACKAGE: ExportGlobalPackageItemFormComponent,
      IMPORT_GLOBAL_PACKAGE: ImportGlobalPackageItemFormComponent,
      RISK_VULNERABILITY: RiskVulnerabilityItemFormComponent,
      RISK_THREAT: RiskThreatItemFormComponent,
      FILTER: FilterItemFormComponent,
      CONTENT_VIEW: ViewItemFormComponent,
      DASHBOARD: DashboardItemFormComponent,
      REPORT: ReportItemFormComponent,
      PUBLISHED_POLICY: PublishedPolicyItemFormComponent,
      STANDARD: StandardItemFormComponent,
      PUBLISHED_STANDARD: StandardItemFormComponent,
      GUIDELINE: GuidelineItemFormComponent,
      PUBLISHED_GUIDELINE: PublishedGuidelineItemFormComponent,
      CONTROL_GROUP: ControlGroupItemFormComponent,
      CONTROL_OBJECTIVE: ControlObjectiveItemFormComponent,
      AUTHORITY_DOCUMENT: AuthorityItemFormComponent,
      CONTROL_CATEGORY: ControlCategoryItemFormComponent,
      CONTROL_PROCEDURE: ControlProcedureItemFormComponent,
      STATISTIC_COLLECTOR: StatisticCollectorItemFormComponent,
      GENERATED_STATISTIC: GeneratedStatisticItemFormComponent,
      GENERATED_REPORT: GeneratedReportItemFormComponent,
      REPORT_CONFIGURATION_TEMPLATE: ReportConfigurationTemplateItemFormComponent,
      TAG: TagItemFormComponent,
      AUDIT_EXPENSE: AuditExpenseItemFormComponent,
      TRANSPARENCY_CASE: TransparencyCaseItemFormComponent,
      TRANSPARENCY_CASE_CATEGORY: TransparencyCaseCategoryItemFormComponent,
      REPORT_SCHEDULER: ReportSchedulerItemFormComponent,
      METRIC: MetricItemFormComponent,
      METRIC_VALUE: MetricValueItemFormComponent,
      METRIC_CATEGORY: MetricCategoryItemFormComponent,
      LANGUAGE: LanguageItemFormComponent,
      CONTROLS_GAP_REPORT: ControlsGapReportItemFormComponent,
      CONTROLS_GAP_REPORT_RESULT: ControlsGapReportResultItemFormComponent,
      CAMPAIGN: CampaignItemFormComponent,
      QUESTIONNAIRE_REQUEST: QuestionnaireCampaignRequestItemFormComponent,
      WORKSPACE: WorkspaceItemFormComponent,
      QUESTIONNAIRE_TYPE: QuestionnaireTypeItemFormComponent,
      DRAFT_RISK: undefined,
      RISK_METHODOLOGY_THRESHOLD_VALUE: RiskMethodologyThresholdValueItemFormComponent,
      RISK_METHODOLOGY_LIKELIHOOD_VALUE: RiskMethodologyLikelihoodValueItemFormComponent,
      RISK_METHODOLOGY_IMPACT_VALUE: RiskMethodologyImpactValueItemFormComponent,
      RISK_METHODOLOGY_IMPACT_FACTOR: RiskMethodologyImpactFactorItemFormComponent,
      AUDIT_OBJECTIVE: AuditObjectiveItemFormComponent,
      REMEDIATION_ACTION: RemediationActionItemFormComponent,
      EVIDENCE: EvidenceItemFormComponent,
      POLICY_BODY_SECTION_TEMPLATE: PolicyBodySectionTemplateItemFormComponent,
      POLICY_BODY_TEMPLATE: PolicyBodyTemplateItemFormComponent,
      RISK_METHODOLOGY_IMPACT_FACTOR_OPTION: RiskMethodologyImpactFactorOptionItemFormComponent,
      GLOBAL_VALUE_LIST_ITEM: GlobalValueItemFormComponent, //duplicate usage warning
      REPORT_CONTROL_ITEM: ReportControlItemItemFormComponent,
      REPORT_CONTROL_GROUP_ITEM: ReportControlGroupItemItemFormComponent,
      REPORT_CONTROL_OBJECTIVE_ITEM: ReportControlObjectiveItemItemFormComponent,
      TP_CLASSIFICATION_FACTOR: undefined,
      TP_CLASSIFICATION_FACTOR_OPTION: undefined,
      TP_CONTACT: undefined,
      TP_CONTRACT: undefined,
      TP_SERVICE: undefined,
      TP_SERVICE_CATEGORY: undefined,
      SLA: undefined,
      SLO: undefined,
      TP_SLO_VIOLATION: undefined,
      TP_RISK_ASSESSMENT: undefined,
      TP_RISK_METHODOLOGY: undefined,
      TP_RISK_METHODOLOGY_THRESHOLD_VALUE: undefined,
      TP_VENDOR: undefined,
    };
  }
}
