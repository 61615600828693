<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12 mb-2">
      <app-text-view [data]="'Completed ' + formGroup.controls.progress.value.toFixed(2) + '%'"></app-text-view>
    </div>
    <div class="col-12 mb-2">
      <app-stacked-progress-bar-view [data]="progressBarList"></app-stacked-progress-bar-view>
    </div>
    @if (operationStatusesList) {
      <div class="col-12 mb-2 flex-column-reverse" style="max-height: 50vh; overflow-y: auto">
        <p-table [value]="operationStatusesList">
          <ng-template pTemplate="header">
            <tr class="mb-3">
              @for (col of cols; track col) {
                <th class="align-items-start justify-content-start white-space-nowrap">
                  {{ col.name }}
                </th>
              }
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-product let-rowIndex="rowIndex">
            <tr class="">
              @for (col of cols; track col) {
                <td
                  class="align-items-start justify-content-start border-bottom-1 surface-border"
                  style="max-width: 24rem; min-width: 11rem"
                >
                  <div class="w-full">
                    <app-view-switch [data]="product[col.key]" [viewType]="col.dataType"></app-view-switch>
                  </div>
                </td>
              }
            </tr>
          </ng-template>
        </p-table>
        <!-- <app-form-repeater
          [viewMode]="'view'"
[canKeepValue]="false"
          [title]="''"
          [cols]="formRepeaterFields"
          [control]="statusesControl"
        ></app-form-repeater> -->
        <!-- @for (item of operationStatuses; track item) {
          <div class="flex gap-2">
            <app-code-navigation [data]="item.label"></app-code-navigation>
            <app-html-text-view [data]="item.value"></app-html-text-view>
          </div>
        } -->
      </div>
    }
  </div>
  <ng-content></ng-content>
</form>
