import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseForm, ModuleKeywords, RiskMethodologyThresholdValueDto } from '@shared/classes';
import { ViewModeService } from '@shared/services';

@Component({
  selector: 'app-risk-methodology-threshold-value-item-form',
  templateUrl: './risk-methodology-threshold-value-item-form.component.html',
  styleUrl: './risk-methodology-threshold-value-item-form.component.scss',
})
export class RiskMethodologyThresholdValueItemFormComponent extends BaseForm<RiskMethodologyThresholdValueDto> implements OnInit {
  constructor(public viewModeService: ViewModeService) {
    super(viewModeService, ModuleKeywords.RiskMethodologyThresholdValue);
  }

  thresholdList = [];
  min = 0;
  max = Infinity;
  disabled = false;

  minMaxControl = new FormControl(null);

  ngOnInit(): void {
    this.thresholdList = this.popupOptions?.thresholdList ?? [];
    this.min = this.thresholdList?.length ? (this.thresholdList[this.thresholdList.length - 1].max + 1) : (this.popupOptions?.min ?? this.min);
    this.max = this.popupOptions?.max;
    this.thresholdList.forEach((element, idx) => {
      if (this.data && element?.code == this.data?.code) {
        this.min = idx == 0 ? this.popupOptions?.min : this.thresholdList[idx - 1].max + 1
        // if ((idx + 1) == this.thresholdList?.length) {
        //   this.disabled = true;
        // }
      }
    });
    if (this.popupOptions?.displayOrder) {
      this.formGroup?.controls?.displayOrder.patchValue(this.popupOptions?.displayOrder);
    }
    if (this.popupOptions?.methodology) {
      this.formGroup?.controls?.methodology.patchValue(this.popupOptions?.methodology);
    }
    // if (this.disabled) {
    //   this.formGroup?.controls?.name.disable();
    //   this.formGroup?.controls?.value.disable();
    //   this.formGroup?.controls?.color.disable();
    //   this.formGroup?.controls?.description.disable();
    // }
    this.subs.sink = this.minMaxControl.valueChanges.subscribe(x => {
      if (x) {
        this.formGroup.controls.min.patchValue(x?.[0] ?? 1);
        this.formGroup.controls.max.patchValue(x?.[1] ?? 1);
      }
    });
    if (this.data?.min && this.data?.max) {
      this.minMaxControl.patchValue([this.data?.min, this.data?.max], { emitEvent: false });
    } else {
      this.minMaxControl.patchValue([this.thresholdList?.length ? this.thresholdList[this.thresholdList.length - 1].max + 1 : this.min, this.max], { emitEvent: false });
    }
  }

  getData() {
    return this.currentViewMode == 'create'
      ? this.getDataKeyValueFormat()
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.formGroup.patchValue({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      min: new FormControl(null),
      max: new FormControl(null),
      color: new FormControl(null),
      displayOrder: new FormControl(null, Validators.required),
      methodology: new FormControl(null, Validators.required),
      description: new FormControl(null),
    });
  }
}
