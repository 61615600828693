import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseForm, ModuleKeywords, RiskMethodologyLikelihoodValueDto, getEnumOptions } from '@shared/classes';
import { ViewModeService } from '@shared/services';

@Component({
  selector: 'app-risk-methodology-likelihood-value-item-form',
  templateUrl: './risk-methodology-likelihood-value-item-form.component.html',
  styleUrl: './risk-methodology-likelihood-value-item-form.component.scss',
})
export class RiskMethodologyLikelihoodValueItemFormComponent extends BaseForm<RiskMethodologyLikelihoodValueDto> implements OnInit {

  durationEnums = getEnumOptions(RiskMethodologyLikelihoodValueDto.OccurrenceDurationEnum);


  constructor(public viewModeService: ViewModeService) {
    super(viewModeService, ModuleKeywords.RiskMethodologyLikelihoodValue);
  }

  ngOnInit(): void {
    if (this.popupOptions?.displayOrder) {
      this.formGroup?.controls?.displayOrder.patchValue(this.popupOptions?.displayOrder);
    }
    if (this.popupOptions?.methodology) {
      this.formGroup?.controls?.methodology.patchValue(this.popupOptions?.methodology);
    }
  }

  getData() {
    return this.currentViewMode == 'create'
      ? this.getDataKeyValueFormat()
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {

    this.formGroup.patchValue({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      value: new FormControl(null, Validators.required),
      occuresFrom: new FormControl(null, Validators.required),
      occuresTo: new FormControl(null, Validators.required),
      occurrenceDuration: new FormControl(null, Validators.required),
      displayOrder: new FormControl(null),
      methodology: new FormControl(null, Validators.required),
      description: new FormControl(null),
    });
  }
}
