<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12 md:col-6">
      <app-target-code-selector
        [label]="'Report'"
        [placeholder]="'Search Reports'"
        [control]="formGroup?.controls?.report"
        [viewMode]="'view'"
        [canKeepValue]="false"
        [multi]="false"
        [optionLabel]="'label'"
        [optionValue]="'code'"
        [targetTypes]="['REPORT']"
      >
      </app-target-code-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-target-code-selector
        [label]="'Report Scheduler'"
        [placeholder]="'Search Report Schedulers'"
        [control]="formGroup?.controls?.reportScheduler"
        [viewMode]="'view'"
        [canKeepValue]="false"
        [multi]="false"
        [optionLabel]="'label'"
        [optionValue]="'code'"
        [targetTypes]="['REPORT_SCHEDULER']"
      >
      </app-target-code-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-drop-down-input
        [label]="'Report Generation'"
        [placeholder]="'Select Item'"
        [name]="'type'"
        [optionLabel]="'label'"
        [items]="reportGenerationOptions"
        [optionValue]="'value'"
        [control]="formGroup?.controls?.reportGeneration"
        [viewMode]="'view'"
        [canKeepValue]="false"
        [badgeView]="true"
      ></app-drop-down-input>
    </div>
    <div class="col-12 md:col-6">
      <app-drop-down-input
        [label]="'Format'"
        [placeholder]="'Select Item'"
        [name]="'type'"
        [optionLabel]="'label'"
        [items]="formatOptions"
        [optionValue]="'value'"
        [control]="formGroup?.controls?.format"
        [viewMode]="'view'"
        [canKeepValue]="false"
        [badgeView]="true"
      ></app-drop-down-input>
    </div>
    <app-run-as-input
      [name]="'runAs'"
      [control]="formGroup?.controls?.runAs"
      [viewMode]="'view'"
      [canKeepValue]="false"
      class="wrapped-input-component"
    >
    </app-run-as-input>
    <div class="col-12 md:col-6">
      <app-date-input
        [label]="'Date'"
        [placeholder]="'2022-11-22'"
        [name]="'date'"
        [showIcon]="false"
        [control]="formGroup?.controls?.date"
        [viewMode]="'view'"
        [canKeepValue]="false"
      ></app-date-input>
    </div>
    <div class="col-12">
      <app-text-editor
        [height]="'150px'"
        [name]="'notes'"
        label="Notes"
        [placeholder]="'Enter Text...'"
        [control]="formGroup?.controls?.notes"
        [viewMode]="fieldViewMode"
        [advanced]="true"
      ></app-text-editor>
    </div>
  </div>
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton && showSaveAndClose) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create' && showSaveAndClose) {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create' && showSaveAndClose) {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
