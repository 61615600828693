<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12 md:col-6">
      <app-basic-input
        [label]="moduleFieldString + '.name.label' | translate: 'Name'"
        [placeholder]="moduleFieldString + '.name.placeholder' | translate: 'Enter Name'"
        [control]="formGroup?.controls?.name"
        [viewMode]="'view'"
        [canKeepValue]="false"
      ></app-basic-input>
    </div>
    <div class="col-12 md:col-6">
      <app-user-and-group-selector
        [label]="moduleFieldString + '.owner.label' | translate: 'Owner'"
        [placeholder]="moduleFieldString + '.owner.placeholder' | translate: 'Search...'"
        [principleMode]="true"
        [control]="formGroup?.controls?.owner"
        [optionValue]="undefined"
        [multi]="false"
        [viewMode]="'view'"
        [canKeepValue]="false"
      >
      </app-user-and-group-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-user-and-group-selector
        [label]="moduleFieldString + '.respondent.label' | translate: 'Respondent'"
        [placeholder]="moduleFieldString + '.respondent.placeholder' | translate: 'Search...'"
        [principleMode]="true"
        [control]="formGroup?.controls?.respondent"
        [optionValue]="undefined"
        [multi]="false"
        [viewMode]="'view'"
        [canKeepValue]="false"
      >
      </app-user-and-group-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-user-and-group-selector
        [label]="moduleFieldString + '.auditor.label' | translate: 'Auditor'"
        [placeholder]="moduleFieldString + '.auditor.placeholder' | translate: 'Search...'"
        [principleMode]="true"
        [control]="formGroup?.controls?.auditor"
        [optionValue]="undefined"
        [multi]="false"
        [viewMode]="'view'"
        [canKeepValue]="false"
      >
      </app-user-and-group-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-user-and-group-selector
        [label]="moduleFieldString + '.auditee.label' | translate: 'Auditee'"
        [placeholder]="moduleFieldString + '.auditee.placeholder' | translate: 'Search...'"
        [principleMode]="true"
        [control]="formGroup?.controls?.auditee"
        [optionValue]="undefined"
        [multi]="false"
        [viewMode]="'view'"
        [canKeepValue]="false"
      >
      </app-user-and-group-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-target-code-selector
        [label]="moduleFieldString + '.auditCaseTemplate.label' | translate: 'Audit Case Template'"
        [placeholder]="moduleFieldString + '.auditCaseTemplate.placeholder' | translate: 'Search Templates'"
        [control]="formGroup?.controls?.auditCaseTemplate"
        [viewMode]="'view'"
        [canKeepValue]="false"
        [multi]="false"
        [optionLabel]="'name'"
        [optionValue]="'code'"
        [customProjectionFields]="['code', 'recordStatus', 'label', 'name']"
        [targetTypes]="['AUDIT_CASE_TEMPLATE']"
      >
      </app-target-code-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-target-code-selector
        [label]="moduleFieldString + '.auditQuestionnaire.label' | translate: 'Audit Questionnaire'"
        [placeholder]="moduleFieldString + '.auditQuestionnaire.placeholder' | translate: 'Search Questionnaire'"
        [control]="formGroup?.controls?.auditQuestionnaire"
        [viewMode]="'view'"
        [canKeepValue]="false"
        [multi]="false"
        [optionLabel]="'name'"
        [optionValue]="'code'"
        [customProjectionFields]="['code', 'recordStatus', 'label', 'name']"
        [targetTypes]="['QUESTIONNAIRE']"
      >
      </app-target-code-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-target-code-selector
        [label]="moduleFieldString + '.evidenceQuestionnaire.label' | translate: 'Evidence Questionnaire'"
        [placeholder]="moduleFieldString + '.evidenceQuestionnaire.placeholder' | translate: 'Search Questionnaire'"
        [control]="formGroup?.controls?.evidenceQuestionnaire"
        [viewMode]="'view'"
        [canKeepValue]="false"
        [multi]="false"
        [optionLabel]="'name'"
        [optionValue]="'code'"
        [customProjectionFields]="['code', 'recordStatus', 'label', 'name']"
        [targetTypes]="['QUESTIONNAIRE']"
      >
      </app-target-code-selector>
    </div>
    <div class="col-12">
      <app-text-editor
        [height]="'100px'"
        [name]="'textarea'"
        [label]="moduleFieldString + '.instruction.label' | translate: 'Instruction'"
        [placeholder]="moduleFieldString + '.instruction.placeholder' | translate: 'Enter Text...'"
        [control]="formGroup?.controls?.instruction"
        [viewMode]="fieldViewMode"
        [advanced]="true"
      ></app-text-editor>
    </div>
    <!-- <p-divider align="left"
      class="col-12">
      <b>Budgeting</b>
    </p-divider>

    <div class="col-12 md:col-6">
      <app-date-input [label]="'Planned Start Date'"
        [placeholder]="'2022-11-22'"
        [name]="'plannedStartDate'"
        [showIcon]="true"
        [control]="formGroup?.controls?.plannedStartDate"
        [viewMode]="fieldViewMode"
        [maxDate]="formGroup?.controls?.plannedEndDate?.value || null"></app-date-input>
    </div>
    <div class="col-12 md:col-6">
      <app-date-input [label]="'Planned End Date'"
        [placeholder]="'2022-11-22'"
        [name]="'plannedEndDate'"
        [showIcon]="true"
        [control]="formGroup?.controls?.plannedEndDate"
        [viewMode]="fieldViewMode"></app-date-input>
    </div>
    <div class="col-12 ">
      <app-number-input label="Planned Duration (Days)"
        [placeholder]="''"
        [control]="formGroup?.controls?.plannedDuration"
        [viewMode]="'view'"
[canKeepValue]="false"></app-number-input>
    </div>
    <div class="col-12 md:col-6">
      <app-date-input [label]="'Actual Start Date'"
        [placeholder]="'2022-11-22'"
        [name]="'actualStartDate'"
        [showIcon]="true"
        [control]="formGroup?.controls?.actualStartDate"
        [viewMode]="'view'"
[canKeepValue]="false"
        [maxDate]="formGroup?.controls?.actualEndDate?.value || null"></app-date-input>
    </div>
    <div class="col-12 md:col-6">
      <app-date-input [label]="'Actual End Date'"
        [placeholder]="'2022-11-22'"
        [name]="'actualEndDate'"
        [showIcon]="true"
        [control]="formGroup?.controls?.actualEndDate"
        [viewMode]="'view'"
[canKeepValue]="false"
        [maxDate]="formGroup?.controls?.actualEndDate?.value || null"></app-date-input>
    </div>
    <div class="col-12 ">
      <app-number-input label="Actual Duration (Days)"
        [placeholder]="''"
        [control]="formGroup?.controls?.actualDuration"
        [viewMode]="'view'"
[canKeepValue]="false"></app-number-input>
    </div>
    <div class="col-12">
      <app-number-input label="Actual Time Resources (Days)"
        [placeholder]="''"
        [control]="formGroup?.controls?.actualTimeResources"
        [viewMode]="fieldViewMode"></app-number-input>
    </div> -->
  </div>
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create') {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create') {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
