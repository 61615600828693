<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    @if (!hidePrePopulatedValues) {
      <div class="col-12 md:col-6">
        <app-target-code-selector
          [label]="moduleFieldString + '.acknowledgmentRequest.label' | translate: 'Acknowledgment Request'"
          [placeholder]="
            moduleFieldString + '.acknowledgmentRequest.placeholder' | translate: 'Search Acknowledgment Requests...'
          "
          [control]="formGroup?.controls?.acknowledgmentRequest"
          [viewMode]="fieldViewMode"
          [multi]="false"
          [optionValue]="undefined"
          [targetTypes]="[akgRequestType]"
          [customProjectionFields]="null"
          [dataKey]="'code'"
        >
        </app-target-code-selector>
      </div>
    }
    @if (!hidePrePopulatedValues) {
      <div class="col-12 md:col-6">
        <app-user-and-group-selector
          [label]="moduleFieldString + '.approver.label' | translate: 'Approver'"
          [placeholder]="moduleFieldString + '.approver.placeholder' | translate: 'Search Approver...'"
          [principleMode]="true"
          [control]="formGroup?.controls?.approver"
          [optionValue]="undefined"
          [multi]="false"
          [viewMode]="fieldViewMode"
        >
        </app-user-and-group-selector>
      </div>
    }
    <div class="col-12 md:col-6">
      <app-drop-down-input
        [label]="moduleFieldString + '.acknowledgmentType.label' | translate: 'Acknowledgment Type'"
        [placeholder]="moduleFieldString + '.acknowledgmentType.placeholder' | translate: 'Enter Acknowledgment Type'"
        [name]="'type'"
        [optionLabel]="'label'"
        [items]="acknowledgmentTypeOptions"
        [optionValue]="'value'"
        [control]="formGroup?.controls?.acknowledgmentType"
        [viewMode]="'view'"
        [canKeepValue]="false"
        [badgeView]="true"
        [showClear]="false"
      ></app-drop-down-input>
    </div>
    <!-- <div *ngIf="fieldViewMode != 'view'"
    class="col-12 md:col-6">
    <app-basic-input label="Signed Document Url"
      [placeholder]="'Enter Signed Document Url'"
      [control]="formGroup?.controls?.signedFileUrl"
    [viewMode]="fieldViewMode"></app-basic-input>
  </div>
  <div *ngIf="fieldViewMode != 'view'"
    class="col-12 md:col-6 flex gap-2">
    <p-fileUpload mode="basic"
      name="documentUrl[]"
      [styleClass]="'px-3'"
      [chooseLabel]="'Browse'"
      (uploadHandler)="onBasicUpload($event)"
      [customUpload]="true"
      (onSelect)="onSelectFile()"
    #fileBrowser></p-fileUpload>
    <app-button *ngIf="showCancelFile"
      [action]="cancelFileSelectionAction"
    [commandData]="fileBrowser"></app-button>
  </div> -->
    @if (acknowledgementRequestData) {
      @switch (acknowledgementRequestData?.acknowledgmentType) {
        @case (acknowledgmentTypes.Policy) {
          <app-published-policy-item-form
            [formData]="relatedTargetData"
            [viewModeOnly]="true"
            style="min-width: 0"
            class="col-12"
          ></app-published-policy-item-form>
          <div class="col-12 md:col-12">
            <div class="flex flex-row md:flex-nowrap flex-wrap gap-1 w-full align-items-start">
              <label class="md:w-12rem font-semibold text-500 md:max-w-12rem md:min-w-12rem w-full">
                {{ moduleFieldString + '.policySections.label' | translate: 'Policy Sections' }}
              </label>
              <app-policy-body-section-inline-tree
                [itemId]="relatedTargetData?.policyBody?.code"
                [policy]="relatedTargetData?.policyBody?.policy"
                [previousPublishedBodyCode]="relatedTargetData?.policyBody?.previousPublishedBodyCode"
                [data]="relatedTargetData?.policyBody?.sections"
                [policyData]="relatedTargetData"
                [showActions]="false"
                [showCard]="false"
                [startExpanded]="true"
                [showPrintAction]="true"
                class="w-full mb-3"
              >
              </app-policy-body-section-inline-tree>
            </div>
          </div>
        }
        @case (acknowledgmentTypes.Guideline) {
          <app-published-guideline-item-form
            [formData]="relatedTargetData"
            [viewModeOnly]="true"
            style="min-width: 0"
            class="col-12"
          ></app-published-guideline-item-form>
        }
        @case (acknowledgmentTypes.Standard) {
          <app-published-standard-item-form
            [formData]="relatedTargetData"
            [viewModeOnly]="true"
            style="min-width: 0"
            class="col-12"
          ></app-published-standard-item-form>
        }
        @case (acknowledgmentTypes.GeneralDocument) {
          <div class="col-12 md:col-12">
            <div class="flex flex-row md:flex-nowrap flex-wrap gap-1 w-full align-items-start">
              <p class="md:w-12rem md:max-w-12rem md:min-w-12rem w-full">
                {{ moduleFieldString + '.originalDocument.label' | translate: 'Original Document' }}
              </p>
              <app-attachment-item-view
                [data]="{ document: acknowledgementRequestData?.documentUrl || documentUrl }"
                [useCodeDownload]="false"
                [showDeleteButton]="false"
                [preivewMode]="'inline'"
                [style]="'width:100%;'"
              ></app-attachment-item-view>
            </div>
            <!-- <app-hyper-text-view [data]="acknowledgementRequestData?.documentUrl || documentUrl"></app-hyper-text-view> -->
          </div>
        }
      }
    }
    <!-- <div *ngIf="formGroup?.controls?.signedFileUrl?.value"
  class="col-12 md:col-6">
  <div class="flex flex-row md:flex-nowrap flex-wrap gap-1 w-full align-items-start">
    <p class="md:w-12rem md:max-w-12rem md:min-w-12rem w-full">Signed Document</p>
    <app-attachment-item-view
      [data]="{ document: formGroup?.controls?.signedFileUrl?.value }"
      [useCodeDownload]="false"
      [showDeleteButton]="false"
    ></app-attachment-item-view>
  </div>
</div> -->
    <!-- <app-hyper-text-view [data]="formGroup?.controls?.signedFileUrl?.value"></app-hyper-text-view> -->

    <div class="col-12">
      <app-check-box
        [label]="moduleFieldString + '.confirmed.label' | translate: 'Confirmation'"
        [messageWrapper]="true"
        [messageLabel]="
          (moduleFieldString + '.confirmed.message' | translate: 'I hereby confirm the') +
          ' ' +
          (formGroup?.controls?.acknowledgmentType?.value | humanize)
        "
        [labelWidthFit]="false"
        [name]="'accept-terms'"
        [control]="formGroup?.controls?.confirmed"
        [disabled]="viewModeService.viewMode == viewModes.create ? false : true"
      >
      </app-check-box>
    </div>
    @if (acknowledgementRequestData?.requireSignedDocument) {
      <div class="col-12">
        <app-attachment-input
          [label]="moduleFieldString + '.signedFileUrl.label' | translate: 'Signed Document'"
          [control]="formGroup?.controls?.signedFileUrl"
          [viewMode]="fieldViewMode"
          [dragAndDropMode]="true"
          [uploadLocation]="'CODE'"
          [useGlobalPasteEventListener]="false"
          [staticUploadWidth]="false"
        ></app-attachment-input>
      </div>
    }
    <div class="col-12">
      <app-text-editor
        [height]="'115px'"
        [name]="'notes'"
        [label]="moduleFieldString + '.notes.label' | translate: 'Notes'"
        [placeholder]="moduleFieldString + '.notes.placeholder' | translate: 'Enter Notes...'"
        [control]="formGroup?.controls?.notes"
        [viewMode]="fieldViewMode"
        [showOnFocus]="!formGroup?.controls?.notes?.value"
        [showOnFocusLabel]="moduleFieldString + '.notes.label' | translate: 'Notes'"
        [showCancelWithOnFocus]="false"
      ></app-text-editor>
    </div>
  </div>
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create') {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create' && !hidePrePopulatedValues) {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
