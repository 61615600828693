import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseForm, ModuleKeywords, QuestionnaireRequestDto } from '@shared/classes';
import { ViewModeService } from '@shared/services';

@Component({
  selector: 'app-questionnaire-campaign-request-item-form',
  templateUrl: './questionnaire-campaign-request-item-form.component.html',
  styleUrl: './questionnaire-campaign-request-item-form.component.scss',
})
export class QuestionnaireCampaignRequestItemFormComponent extends BaseForm<QuestionnaireRequestDto> implements OnInit {
  constructor(public viewModeService: ViewModeService) {
    super(viewModeService, ModuleKeywords.QuestionnaireRequest);
  }

  ngOnInit(): void { }

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat()
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      respondent: new FormControl(null),
      questionnaire: new FormControl(null),
      campaign: new FormControl(null),
      description: new FormControl(null),
    });
  }
}
