<app-reorder-selector
  style="display: block; height: 55vh; max-height: 55vh; min-height: 55vh"
  [cols]="cols"
  [control]="control"
  [items]="control?.value ?? []"
  (onDropEvent)="onDropEvent($event)"
  [viewMode]="viewMode"
  [reorderable]="reorderable"
  [headerAction]="addAction"
  [tableActions]="[editAction, deleteAction]"
  [disableAdd]="disableAdd"
  [onlyLastisntViewMode]="onlyLastisntViewMode"
></app-reorder-selector>
