import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseForm, ModuleKeywords, RiskStatement, TargetTypeEnum } from '@shared/classes';
import { ViewModeService } from '@shared/services/view-mode.service';

@Component({
  selector: 'app-risk-statement-item-form',
  templateUrl: './risk-statement-item-form.component.html',
  styleUrls: ['./risk-statement-item-form.component.scss'],
})
export class RiskStatementItemFormComponent extends BaseForm<RiskStatement> implements OnInit {
  @Input() set itemId(itemId) {
    this.parentFilter = itemId
      ? {
        left: { property: 'code', operation: 'NOT_EQUAL', value: itemId },
        right: { property: 'id', operation: 'NOT_EQUAL', value: itemId },
        operand: 'AND',
      }
      : null;
  }
  parentFilter = null;
  riskStatementCategoryType = TargetTypeEnum.RiskStatementCategory;
  constructor(public viewModeService: ViewModeService) {
    super(viewModeService, ModuleKeywords.RiskStatement);
  }

  ngOnInit(): void { }

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat()
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      category: new FormControl(null, Validators.required),
      parent: new FormControl(null),
      description: new FormControl(null),
      threats: new FormControl(null),
      vulnerabilities: new FormControl(null),
    });
  }
}
