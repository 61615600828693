import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseForm, getEnumOptions, StatisticCollectorDto, TargetTypeEnum } from '@shared/classes';
import { ViewModeService } from '@shared/services';

@Component({
  selector: 'app-statistic-collector-item-form',
  templateUrl: './statistic-collector-item-form.component.html',
  styleUrl: './statistic-collector-item-form.component.scss',
})
export class StatisticCollectorItemFormComponent extends BaseForm<StatisticCollectorDto> implements OnInit {
  targetTypeOptions = getEnumOptions(TargetTypeEnum);
  constructor(public viewModeService: ViewModeService) {
    super(viewModeService, 'STATISTIC_COLLECTOR');
  }

  ngOnInit(): void {}

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat()
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      filter: new FormControl(null, Validators.required),
      frequency: new FormControl(null, Validators.required),
      lastRunDate: new FormControl(null),
      description: new FormControl(null),
      type: new FormControl(null, Validators.required),
      runAs: new FormGroup({
        // withGroups: new FormControl(null),
        withoutGroups: new FormControl(null),
        // mainPrinciple: new FormControl(null),
        // mainPrincipleName: new FormControl(null),
      }),
    });
  }
  lastType = null;
  onTypeChanges(event) {
    if (this.lastType) {
      this.formGroup?.controls?.filter?.patchValue(null);
    }
    this.lastType = event;
  }
}
