import { Component, Input, OnInit } from '@angular/core';
import { DataTypeEnum, DynamicComponentBase } from '@shared/classes';

@Component({
  selector: 'app-palette-picker-input',
  templateUrl: './palette-picker-input.component.html',
  styleUrl: './palette-picker-input.component.scss',
})
export class PalettePickerInputComponent extends DynamicComponentBase implements OnInit {
  @Input() dataKey: string = undefined;
  @Input() multi: boolean = false;
  @Input() optionValue: string = 'value';
  @Input() optionLabel: string = 'value';
  @Input() appendTo: string = null;
  constructor() {
    super();
  }
  @Input() items: any[] = Object.entries(ChartsPalette).map(([key, value]) => {
    return { label: key, value: value };
  });
  dataType: DataTypeEnum = DataTypeEnum.PaletteView;

  ngOnInit(): void {}

  setInputOptions() {
    this.multi = this.inputOptions?.dropDownInput?.multi ?? this.multi;
    this.appendTo = this.inputOptions?.dropDownInput?.appendTo ?? this.appendTo;
  }
}
export const PrimaryColorPalettes = {
  default: ['#1E88E5', '#bbbbbb'],
  vintage: ['#d87c7c', '#919e8b'],
  darkv2: ['#dd6b66', '#759aa0'],
  westeros: ['#59c4e6', '#516b91'],
  essos: ['#d95850', '#893448'],
  purplePassion: ['#e098c7', '#9b8bba'],
  macarons: ['#2ec7c9', '#b6a2de'],
};
export const ColorPalettes = {
  //colors used in the tempaltes of the charge originally
  vintage: [
    '#d87c7c',
    '#919e8b',
    '#d7ab82',
    '#6e7074',
    '#61a0a8',
    '#efa18d',
    '#787464',
    '#cc7e63',
    '#724e58',
    '#4b565b',
  ],
  darkv2: [
    '#dd6b66',
    '#759aa0',
    '#e69d87',
    '#8dc1a9',
    '#ea7e53',
    '#eedd78',
    '#73a373',
    '#73b9bc',
    '#7289ab',
    '#91ca8c',
    '#f49f42',
  ],
  westeros: ['#516b91', '#59c4e6', '#edafda', '#93b7e3', '#a5e7f0', '#cbb0e3'],
  essos: ['#893448', '#d95850', '#eb8146', '#ffb248', '#f2d643', '#ebdba4'],
  wonderland: ['#4ea397', '#22c3aa', '#7bd9a5', '#d0648a', '#f58db2', '#f2b3c9'],
  walden: ['#3fb1e3', '#6be6c1', '#626c91', '#a0a7e6', '#c4ebad', '#96dee8'],
  chalk: ['#fc97af', '#87f7cf', '#f7f494', '#72ccff', '#f7c5a0', '#d4a4eb', '#d2f5a6', '#76f2f2'],
  infographic: [
    '#c1232b',
    '#27727b',
    '#fcce10',
    '#e87c25',
    '#b5c334',
    '#fe8463',
    '#9bca63',
    '#fad860',
    '#f3a43b',
    '#60c0dd',
    '#d7504b',
    '#c6e579',
    '#f4e001',
    '#f0805a',
    '#26c0c0',
  ],
  macarons: [
    '#2ec7c9',
    '#b6a2de',
    '#5ab1ef',
    '#ffb980',
    '#d87a80',
    '#8d98b3',
    '#e5cf0d',
    '#97b552',
    '#95706d',
    '#dc69aa',
    '#07a2a4',
    '#9a7fd1',
    '#588dd5',
    '#f5994e',
    '#c05050',
    '#59678c',
    '#c9ab00',
    '#7eb00a',
    '#6f5553',
    '#c14089',
  ],
  roma: [
    '#e01f54',
    '#001852',
    '#f5e8c8',
    '#b8d2c7',
    '#c6b38e',
    '#a4d8c2',
    '#f3d999',
    '#d3758f',
    '#dcc392',
    '#2e4783',
    '#82b6e9',
    '#ff6347',
    '#a092f1',
    '#0a915d',
    '#eaf889',
    '#6699ff',
    '#ff6666',
    '#3cb371',
    '#d5b158',
    '#38b6b6',
  ],
  shine: ['#c12e34', '#e6b600', '#0098d9', '#2b821d', '#005eaa', '#339ca8', '#cda819', '#32a487'],
  purplePassion: ['#9b8bba', '#e098c7', '#8fd3e8', '#71669e', '#cc70af', '#7cb4cc'],
  halloween: ['#ff715e', '#ffaf51', '#ffee51', '#8c6ac4', '#715c87'],
};
export const ChartsPalette = [
  ['#780000', '#c1121f', '#fdf0d5', '#003049', '#669bbc'],
  ['#264653', '#2a9d8f', '#e9c46a', '#f4a261', '#e76f51'],
  ['#8ecae6', '#219ebc', '#023047', '#ffb703', '#fb8500'],
  ['#f6bd60', '#f7ede2', '#f5cac3', '#84a59d', '#f28482'],
  ['#000000', '#14213d', '#fca311', '#e5e5e5', '#ffffff'],
  ['#ffbe0b', '#fb5607', '#ff006e', '#8338ec', '#3a86ff'],
  ['#6f1d1b', '#bb9457', '#432818', '#99582a', '#ffe6a7'],
  ['#2b2d42', '#8d99ae', '#edf2f4', '#ef233c', '#d90429'],
  ['#335c67', '#fff3b0', '#e09f3e', '#9e2a2b', '#540b0e'],
  ['#001219', '#005f73', '#0a9396', '#94d2bd', '#e9d8a6', '#ee9b00', '#ca6702', '#bb3e03', '#ae2012', '#9b2226'],
  ['#f4f1de', '#e07a5f', '#3d405b', '#81b29a', '#f2cc8f'],
  ['#ffbc42', '#d81159', '#8f2d56', '#218380', '#73d2de'],
  ['#00296b', '#003f88', '#00509d', '#fdc500', '#ffd500'],
  ['#0d3b66', '#faf0ca', '#f4d35e', '#ee964b', '#f95738'],
  ['#003049', '#d62828', '#f77f00', '#fcbf49', '#eae2b7'],
  ['#e63946', '#f1faee', '#a8dadc', '#457b9d', '#1d3557'],
  ['#0d1321', '#1d2d44', '#3e5c76', '#748cab', '#f0ebd8'],
  ['#05668d', '#427aa1', '#ebf2fa', '#679436', '#a5be00'],
  ['#3d5a80', '#98c1d9', '#e0fbfc', '#ee6c4d', '#293241'],
  ['#0a1128', '#001f54', '#034078', '#1282a2', '#fefcfb'],
  ['#202c39', '#283845', '#b8b08d', '#f2d492', '#f29559'],
  ['#ff6d00', '#ff7900', '#ff8500', '#ff9100', '#ff9e00', '#240046', '#3c096c', '#5a189a', '#7b2cbf', '#9d4edd'],
  ['#ff9f1c', '#ffbf69', '#ffffff', '#cbf3f0', '#2ec4b6'],
  ['#3d348b', '#7678ed', '#f7b801', '#f18701', '#f35b04'],
  ['#c9cba3', '#ffe1a8', '#e26d5c', '#723d46', '#472d30'],
  ['#f4d06f', '#ff8811', '#9dd9d2', '#fff8f0', '#392f5a'],
  ['#edae49', '#d1495b', '#00798c', '#30638e', '#003d5b'],
  ['#25ced1', '#ffffff', '#fceade', '#ff8a5b', '#ea526f'],
  ['#d62839', '#ba324f', '#175676', '#4ba3c3', '#cce6f4'],
  ['#f46036', '#5b85aa', '#414770', '#372248', '#171123'],
  ['#cfdbd5', '#e8eddf', '#f5cb5c', '#242423', '#333533'],
  ['#080708', '#3772ff', '#df2935', '#fdca40', '#e6e8e6'],
  ['#2b2d42', '#8d99ae', '#edf2f4', '#ef233c', '#d80032'],
  ['#283d3b', '#197278', '#edddd4', '#c44536', '#772e25'],
  ['#9381ff', '#b8b8ff', '#f8f7ff', '#ffeedd', '#ffd8be'],
  ['#001b2e', '#294c60', '#adb6c4', '#ffefd3', '#ffc49b'],
  ['#006ba6', '#0496ff', '#ffbc42', '#d81159', '#8f2d56'],
  ['#b8d8d8', '#7a9e9f', '#4f6367', '#eef5db', '#fe5f55'],
  ['#fb8b24', '#d90368', '#820263', '#291720', '#04a777'],
  ['#d3d4d9', '#4b88a2', '#bb0a21', '#252627', '#fff9fb'],
  ['#0a100d', '#b9baa3', '#d6d5c9', '#a22c29', '#902923'],
  ['#292f36', '#4ecdc4', '#f7fff7', '#ff6b6b', '#ffe66d'],
  ['#820263', '#d90368', '#eadeda', '#2e294e', '#ffd400'],
  ['#540d6e', '#ee4266', '#ffd23f', '#3bceac', '#0ead69'],
  ['#2f4858', '#33658a', '#86bbd8', '#f6ae2d', '#f26419'],
  ['#0b3954', '#087e8b', '#bfd7ea', '#ff5a5f', '#c81d25'],
  ['#011627', '#fdfffc', '#2ec4b6', '#e71d36', '#ff9f1c'],
  ['#ba1200', '#031927', '#9dd1f1', '#508aa8', '#c8e0f4'],
  ['#124e78', '#f0f0c9', '#f2bb05', '#d74e09', '#6e0e0a'],
  ['#1b998b', '#2d3047', '#fffd82', '#ff9b71', '#e84855'],
  ['#012a36', '#29274c', '#7e52a0', '#d295bf', '#e6bccd'],
  ['#474350', '#f8fff4', '#fcffeb', '#fafac6', '#fecdaa'],
  ['#2b2d42', '#8d99ae', '#f8f32b', '#ffffff', '#000000'],
  ['#0c0f0a', '#ff206e', '#fbff12', '#41ead4', '#ffffff'],
  ['#160c28', '#efcb68', '#e1efe6', '#aeb7b3', '#000411'],
  ['#f46036', '#2e294e', '#1b998b', '#e71d36', '#c5d86d'],
  ['#363636', '#242f40', '#cca43b', '#e5e5e5', '#ffffff'],
  ['#2c363f', '#e75a7c', '#f2f5ea', '#d6dbd2', '#bbc7a4'],
  ['#fefeff', '#d6efff', '#fed18c', '#fed99b', '#fe654f'],
  ['#2d3142', '#bfc0c0', '#ffffff', '#ef8354', '#4f5d75'],
  ['#b80c09', '#0b4f6c', '#01baef', '#fbfbff', '#040f16'],
  ['#30bced', '#303036', '#fffaff', '#fc5130', '#050401'],
  ['#af3800', '#fe621d', '#fd5200', '#00cfc1', '#00ffe7'],
  ['#080f0f', '#a4bab7', '#eff2c0', '#bea57d', '#a52422'],
  ['#a31621', '#bfdbf7', '#053c5e', '#1f7a8c', '#db222a'],
  ['#3a2e39', '#1e555c', '#f4d8cd', '#edb183', '#f15152'],
  ['#e8d6cb', '#d0ada7', '#ad6a6c', '#5d2e46', '#b58db6'],
  ['#ffa69e', '#ff7e6b', '#8c5e58', '#a9f0d1', '#fff7f8'],
  ['#0b132b', '#1c2541', '#3a506b', '#5bc0be', '#6fffe9'],
  ['#476a6f', '#519e8a', '#7eb09b', '#c5c9a4', '#ecbeb4'],
  ['#b9ffb7', '#abedc6', '#98d9c2', '#f19a3e', '#403233'],
  ['#1c6e8c', '#120d31', '#9dbbae', '#f2d7ee', '#804e49'],
  ['#ffe74c', '#ff5964', '#ffffff', '#6bf178', '#35a7ff'],
  ['#231123', '#82204a', '#558c8c', '#e8db7d', '#eff7ff'],
  ['#ed254e', '#f9dc5c', '#f4fffd', '#011936', '#465362'],
  ['#ff5e5b', '#d8d8d8', '#ffffea', '#00cecb', '#ffed66'],
  ['#1e1e24', '#92140c', '#fff8f0', '#ffcf99', '#111d4a'],
  ['#f8ffe5', '#06d6a0', '#1b9aaa', '#ef476f', '#ffc43d'],
  ['#f05d5e', '#0f7173', '#e7ecef', '#272932', '#d8a47f'],
  ['#151515', '#a63d40', '#e9b872', '#90a959', '#6494aa'],
  ['#588b8b', '#ffffff', '#ffd5c2', '#f28f3b', '#c8553d'],
  ['#1a1423', '#372549', '#774c60', '#b75d69', '#eacdc2'],
  ['#bebbbb', '#444054', '#2f243a', '#fac9b8', '#db8a74'],
  ['#223843', '#eff1f3', '#dbd3d8', '#d8b4a0', '#d77a61'],
  ['#33658a', '#86bbd8', '#2f4858', '#f6ae2d', '#f26419'],
  ['#f6511d', '#ffb400', '#00a6ed', '#7fb800', '#0d2c54'],
  ['#070707', '#40434e', '#c297b8', '#fdcff3', '#de89be'],
  ['#2e0e02', '#581908', '#983628', '#e2aedd', '#ebcbf4'],
  ['#29339b', '#74a4bc', '#b6d6cc', '#f1fec6', '#ff3a20'],
  ['#ffede1', '#f9fbf2', '#d7f9ff', '#afcbff', '#0e1c36'],
  ['#d6ffb7', '#f5ff90', '#ffc15e', '#ff9f1c', '#080357'],
  ['#090446', '#786f52', '#feb95f', '#f71735', '#c2095a'],
  ['#050505', '#1b9aaa', '#dddbcb', '#f5f1e3', '#ffffff'],
  ['#b7094c', '#a01a58', '#892b64', '#723c70', '#5c4d7d', '#455e89', '#2e6f95', '#1780a1', '#0091ad'],
  ['#faf3dd', '#b8f2e6', '#aed9e0', '#5e6472', '#ffa69e', '#7e6c6c'],
  ['#272727', '#fed766', '#009fb7', '#696773', '#eff1f3'],
  ['#52489c', '#4062bb', '#59c3c3', '#ebebeb', '#f45b69'],
  ['#171219', '#225560', '#edf060', '#f0803c', '#310d20'],
  ['#ffe74c', '#ff5964', '#ffffff', '#38618c', '#35a7ff'],
  ['#541388', '#d90368', '#f1e9da', '#2e294e', '#ffd400'],
  ['#c5d86d', '#261c15', '#f7f7f2', '#e4e6c3', '#f05d23'],
  ['#ed1c24', '#fdfffc', '#235789', '#f1d302', '#020100'],
  ['#ed6a5a', '#f4f1bb', '#9bc1bc', '#e6ebe0', '#36c9c6'],
  ['#fbfef9', '#191923', '#0e79b2', '#bf1363', '#f39237'],
  ['#042a2b', '#5eb1bf', '#cdedf6', '#ef7b45', '#d84727'],
  ['#a40e4c', '#2c2c54', '#acc3a6', '#f5d6ba', '#f49d6e'],
  ['#2d728f', '#3b8ea5', '#f5ee9e', '#f49e4c', '#ab3428'],
  ['#c33c54', '#254e70', '#37718e', '#8ee3ef', '#aef3e7'],
  ['#b8c480', '#d4e79e', '#922d50', '#501537', '#3c1b43'],
  ['#06070e', '#29524a', '#94a187', '#c5afa0', '#e9bcb7'],
  ['#bf4e30', '#c6ccb2', '#093824', '#e5eafa', '#78fecf'],
  ['#b0a1ba', '#a5b5bf', '#abc8c7', '#b8e2c8', '#bff0d4'],
  ['#3b0d11', '#6a3937', '#706563', '#748386', '#9dc7c8'],
  ['#ca054d', '#3b1c32', '#a4d4b4', '#ffcf9c', '#b96d40'],
  ['#ffa9e7', '#ff84e8', '#7f2ccb', '#414361', '#2a2d43'],
  ['#640884', '#ffbfb7', '#97c8eb', '#c2eabd', '#40798c', '#42cafd'],
  ['#ffc2ce', '#f292c0', '#9b779e', '#1f0c28', '#0f0b14'],
  ['#010c13', '#fffbeb', '#021927', '#36a1d3', '#156579', '#ffe999', '#ffa033', '#3d1c00', '#0a0aff', '#8f4baa'],
  ['#272932', '#114b5f', '#75b6c6', '#e8d7f1', '#ffc482'],
  ['#75b6c6', '#272932', '#ee6c4d', '#f38d68'],
  ['#405653', '#b7242e', '#c77f80', '#eccaf4', '#e7eafd'],
  ['#121212', '#262d34', '#525d66', '#627d98', '#a9b8c6', '#cfd6dd', '#bdbdbd', '#0e34a0', '#c62828', '#00a393'],
  ['#eec643', '#f49097', '#96c3ce', '#254441', '#0b5563'],
  ['#e6dee4', '#dbd7d6', '#b493ad', '#8f6684', '#0b54e5', '#120fae', '#0f095d', '#760e71'],
  ['#dffefd', '#c7defc', '#8a9ec0', '#1057b2', '#104696', '#bca9cd', '#ff4b2a', '#f38c41', '#76636b', '#2e2d32'],
  ['#fbcd60', '#f6ae29', '#f7a308', '#a23a06', '#731500', '#350d04'],
  ['#a4243b', '#1a535c', '#4ecdc4', '#f7f052', '#f28123'],
  ['#664e4c', '#c1d37f', '#006ba6', '#f19c79', '#0496ff'],
  ['#000000', '#e8eced', '#fcfafa', '#ffffff', '#ff005e'],
  ['#eaeaea', '#cbc5ea', '#73628a', '#313d5a', '#183642', '#ff6663', '#012a36'],
  ['#0d1b2a', '#1b263b', '#415a77', '#778da9', '#e0e1dd', '#8e4a49', '#7daa92', '#80ffec'],
  ['#ccc9a1', '#f0ffce', '#a53f2b', '#8783d1', '#393e41'],
  ['#2fff00', '#189655', '#0c6280', '#002daa', '#001755', '#000000'],
  ['#2e333b', '#f4d35e', '#d1f5ff', '#33ca7f', '#388659', '#b75d69', '#fab3a9'],
  ['#321325', '#5f0f40', '#9a031e', '#cb793a', '#fcdc4d', '#474747'],
  ['#e85f31', '#fe8c53', '#d4da76', '#d15725', '#e7d7c8', '#657d81', '#5c6a6a', '#432118'],
  ['#bfc3ba', '#a9aca9', '#60495a', '#3f3244', '#2f2235', '#f71735', '#da7422'],
  ['#e6120e', '#937b63', '#8a9b68', '#677345', '#d5ddbc', '#0077ff'],
  ['#ff8614', '#f08e23', '#e09632', '#c0a54f', '#a0b46d', '#80c38a', '#40e1c5', '#20f0e2', '#10f8f1', '#00ffff'],
  ['#ffffff', '#f9e8b4', '#f2cd5d', '#edb91d', '#3a4454', '#99f7ce'],
  ['#cd1d40', '#5b2c56', '#401e5b', '#0a0e11', '#768663'],
  ['#0e0312', '#3f1756', '#553577', '#b90103', '#e4a64d'],
  ['#57485b', '#b23a56', '#969971', '#934a63', '#763851'],
  ['#374c58', '#768ca3', '#b4c4d9', '#f1dfb9', '#e04d69'],
  ['#1f2421', '#216869', '#49a078', '#9cc5a1', '#dce1de', '#ee4266'],
  ['#fb9648', '#e7994c', '#d4661e', '#c94c42', '#932826', '#fafbff', '#eaf3fc', '#adc6df', '#a3c2de', '#aad2a0'],
  ['#d21f3c', '#801f32', '#2e1f27', '#f4c95d', '#e7e393', '#6b9080'],
  ['#d21f3c', '#782a4f', '#1d3461', '#1f487e', '#247ba0', '#f3d9dc'],
  ['#100d14', '#0d0f28', '#1d2146', '#91796d', '#7d6563', '#3c303e'],
  ['#3264ef', '#5ac09b', '#fedf9d', '#181918', '#272627'],
  ['#0b2027', '#40798c', '#70a9a1', '#dc9596', '#ee4266'],
  ['#303633', '#a62639', '#7ea2aa', '#888da7', '#9c7a97'],
  ['#c6c7bf', '#ada9a6', '#988a7f', '#926659', '#1c3b11', '#ffffff'],
  ['#00b0f4', '#eae8ff', '#d8d5db', '#2d3142', '#ff8841'],
  ['#0d253d', '#cfe6db', '#ffe0b5', '#edc380', '#9e2b2b', '#6b2c0c', '#332014'],
  ['#f13907', '#17256e', '#2338a9'],
  ['#fdfffc', '#c1292e', '#e1bc29', '#679436', '#0e9594', '#235789'],
  ['#fdfffc', '#235789', '#c1292e', '#e1bc29'],
  ['#bdd9bf', '#f87060', '#5cc8ff', '#2c6e49', '#1d3549', '#cacfc9', '#8b2635'],
  ['#5b0d2d', '#073b3a', '#7c87b2', '#869441', '#abe0a1'],
  ['#00b4ff', '#03256c', '#2541b2', '#efa00b', '#b4edd2'],
  ['#3a829e', '#36404a', '#792937', '#9c3344', '#e4e0df', '#72a1b0', '#a4b9b9'],
  ['#c2c2c2', '#d0c7cf', '#bfc4bf', '#55505c', '#6b5e62', '#786d70'],
  ['#331832', '#d81e5b', '#f0544f', '#fdf0d5', '#f2e86d', '#d3dfb8', '#c6d8d3'],
  ['#231f20', '#bb4430', '#7ebdc2', '#a3ca98', '#c7d66d', '#efe6dd', '#f3dfa2'],
  ['#dae0f2', '#f9cff2', '#010400', '#1c1f3a', '#f3f0e6'],
  ['#b0e298', '#ff4081', '#00bcd4', '#264244', '#fcfff5'],
  ['#684551', '#ff4081', '#00bcd4', '#264244', '#fcfff5'],
  ['#b8c480', '#d4e79e', '#d1495b', '#3aafb9', '#433a3f'],
  ['#232226', '#3e4240', '#639191', '#3dd1a2', '#ace8dc', '#fafaa7'],
  ['#fefffe', '#001b2e', '#294c60', '#ff8552', '#750d37'],
  ['#1c6bab', '#2b193d', '#c5979d', '#f2f7f2', '#f6ae2d'],
  ['#bab4a3', '#3c324c', '#e5bd50', '#33312d', '#2a722f'],
  ['#a37693', '#b792b1', '#c92179', '#908688', '#736868'],
  ['#fe5154', '#fda69c', '#994454', '#85beb2', '#b6d1a6'],
  ['#bebbbb', '#f6828c', '#444054', '#2f243a', '#fac9b8'],
  ['#d64045', '#e9fff9', '#9ed8db', '#467599', '#1d3354'],
  ['#ffffff', '#000000', '#f2ccc3', '#e78f8e', '#acd8aa'],
  ['#ef6461', '#e4b363', '#e8e9eb', '#e0dfd5', '#313638'],
  ['#4c5454', '#ff715b', '#ffffff', '#1ea896', '#523f38'],
  ['#6e2594', '#ecd444', '#808080', '#000000', '#ffffff'],
  ['#12355b', '#420039', '#d72638', '#ffffff', '#ff570a'],
  ['#ffff82', '#f5f7dc', '#b5d99c', '#0f0326', '#e65f5c'],
  ['#f6e8ea', '#ef626c', '#22181c', '#312f2f', '#84dccf'],
  ['#04151f', '#183a37', '#efd6ac', '#c44900', '#432534'],
  ['#071e22', '#1d7874', '#679289', '#f4c095', '#ee2e31'],
  ['#273043', '#9197ae', '#eff6ee', '#f02d3a', '#dd0426'],
  ['#ceec97', '#f4b393', '#fc60a8', '#7a28cb', '#494368'],
  ['#161032', '#faff81', '#ffc53a', '#e06d06', '#b26700'],
  ['#401f3e', '#3f2e56', '#453f78', '#759aab', '#faf2a1'],
  ['#247ba0', '#70c1b3', '#b2dbbf', '#f3ffbd', '#ff1654'],
  ['#faa275', '#ff8c61', '#ce6a85', '#985277', '#5c374c'],
];
export const ShadesPalette = [
  ['#263238', '#607D8B', '#CFD8DC'], //blue gray
  ['#212121', '#9E9E9E', '#F5F5F5'], //gray
  ['#CFD8DC', '#607D8B', '#263238'], //reverse blue gray
  ['#F5F5F5', '#9E9E9E', '#212121'], //reverse gray
  ['#3E2723', '#795548', '#D7CCC8'], //brown
  ['#BF360C', '#FF5722', '#FFCCBC'], //deep orange
  ['#E65100', '#FF9800', '#FFE0B2'], //orange
  ['#FF6F00', '#FFC107', '#FFECB3'], //amber
  ['#F57F17', '#FFEB3B', '#FFF9C4'], //yellow
  ['#827717', '#CDDC39', '#F0F4C3'], //lime
  ['#33691E', '#8BC34A', '#DCEDC8'], //lightgreen
  ['#1B5E20', '#4CAF50', '#C8E6C9'], //green
  ['#004D40', '#009688', '#B2DFDB'], //teal
  ['#006064', '#00BCD4', '#B2EBF2'], //cyan
  ['#01579B', '#03A9F4', '#B3E5FC'], //lightblue
  ['#0D47A1', '#2196F3', '#BBDEFB'], //blue
  ['#1A237E', '#3F51B5', '#C5CAE9'], //indigo
  ['#311B92', '#673AB7', '#D1C4E9'], //deep purple
  ['#4A148C', '#9C27B0', '#E1BEE7'], //purple
  ['#880E4F', '#E91E63', '#F8BBD0'], //pink
  ['#B71C1C', '#F44336', '#FFCDD2'], //red
];
