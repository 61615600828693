import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseForm, DataTypeEnum, GlobalValueList, IDynamicComponent } from '@shared/classes';
import { IconSelectorComponent } from '@shared/components/selectors/icon-selector/icon-selector.component';
import { TargetCodeSelectorComponent } from '@shared/components/selectors/target-code-selector/target-code-selector.component';
import { BasicInputComponent } from '@shared/components/ui/input-fields/basic-input/basic-input.component';
import { ColorInputComponent } from '@shared/components/ui/input-fields/color-input/color-input.component';
import { NumberInputComponent } from '@shared/components/ui/input-fields/number-input/number-input.component';
import { TextEditorComponent } from '@shared/components/ui/input-fields/text-editor/text-editor.component';
import { ViewModeService } from '@shared/services';
import { catchError, forkJoin, of } from 'rxjs';
import { GlobalValueListItemDataService } from '../../services/data/global-value-list-item-data.service';

@Component({
  selector: 'app-global-value-item-form',
  templateUrl: './global-value-item-form.component.html',
  styleUrls: ['./global-value-item-form.component.scss'],
})
export class GlobalValueItemFormComponent extends BaseForm<GlobalValueList> implements OnInit {
  formRepeaterFields: IDynamicComponent[] = [];
  constructor(public viewModeService: ViewModeService, private globalValueListItemsData: GlobalValueListItemDataService
  ) {
    super(viewModeService, 'GLOBAL_VALUE_LIST');
  }
  list = [];
  emitPaused = false;
  ngOnInit(): void {
    this.formRepeaterFields = [
      {
        componentType: TargetCodeSelectorComponent,
        options: {
          label: 'Id',
          name: 'code',
          control: new FormControl(''),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
          hideInFormRepeater: true,
        },
        viewModeOnly: false,
      },
      {
        componentType: BasicInputComponent,
        options: {
          label: 'Value',
          name: 'value',
          control: new FormControl(''),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
        viewModeOnly: false,
      },
      {
        componentType: NumberInputComponent,
        options: {
          label: 'Numeric',
          name: 'numericValue',
          control: new FormControl(null),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
        viewModeOnly: false,
      },
      {
        componentType: IconSelectorComponent,
        options: {
          label: 'Icon',
          name: 'icon',
          control: new FormControl(''),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
          inputOptions: {
            dropDownInput: {
              multi: false,
              items: undefined,
              optionLabel: undefined,
              optionValue: undefined,
              appendTo: 'body'
            },
          },
        },
        viewModeOnly: false,
      },
      {
        componentType: ColorInputComponent,
        options: {
          label: 'Color',
          name: 'color',
          control: new FormControl(null),
          dataType: DataTypeEnum.Color,
          showLabelInViewMode: false,
        },
        viewModeOnly: false,
      },
      {
        componentType: BasicInputComponent,
        options: {
          label: 'Help Text',
          name: 'helpText',
          control: new FormControl(''),
          dataType: DataTypeEnum.Text,
          showLabelInViewMode: false,
        },
        viewModeOnly: false,
      },
      {
        componentType: TextEditorComponent,
        options: {
          label: 'Description',
          name: 'description',
          control: new FormControl(null),
          dataType: DataTypeEnum.LongText,
          showLabelInViewMode: false,
          hideInFormRepeater: false,
        },
        viewModeOnly: false,

      },
    ];
    this.handleValueChanges(
      this.formGroup,
      'globalValueListItemsDto',
      this.list,
      this.getDataKeyValueFormatFromPrev.bind(this),
      this.getDataKeyValueFormat.bind(this),
      {
        patchSingleUpdate: this.globalValueListItemsData.patchSingleUpdate.bind(this.globalValueListItemsData),
        create: this.globalValueListItemsData.create.bind(this.globalValueListItemsData),
        updateValues: this.updateList.bind(this),
        getUpdatedValues: () => this.list,
      }
    );
  }

  getData() {
    const formattedData = { ...this.formGroup.getRawValue() };
    if (formattedData.globalValueListItemsDto) {
      formattedData.values = (formattedData.globalValueListItemsDto ?? []).map(x => x.code);
      delete formattedData.globalValueListItemsDto;
    }

    // return this.formGroup.getRawValue();
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat(formattedData)
      : this.getChangedFormValues(formattedData).updateItems;
  }

  setData(data: any) {
    this.emitPaused = true;
    this.patchFormData({ ...data },);
    setTimeout(() => { this.emitPaused = false; }, 100);
    this.list = data?.globalValueListItemsDto ?? [];
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      globalValueListItemsDto: new FormControl([]),
    });

  }

  handleValueChanges(
    formGroup,
    controlName,
    oldValues,
    getDataKeyValueFormatFromPrev,
    getDataKeyValueFormat,
    serviceMethods
  ) {
    formGroup.controls[controlName].valueChanges.subscribe(async (newValues) => {
      if (this.emitPaused) return;
      const oldVals = serviceMethods.getUpdatedValues();
      console.log('values changed');
      if (newValues?.length) {
        const forkList = [];

        newValues.forEach((element) => {
          let existedVal = null;

          if (element.code && oldVals?.length) {
            existedVal = oldVals.find((y) => y.code === element.code);

            if (existedVal) {
              const req = getDataKeyValueFormatFromPrev(element, existedVal);
              if (req?.length) {
                forkList.push(
                  serviceMethods.patchSingleUpdate(
                    req,
                    element.code
                  )
                );
              }
            }
          } else if (!element.code) {
            forkList.push(
              serviceMethods.create(
                getDataKeyValueFormat({ ...element, code: undefined })
              )
            );
          }
        });

        if (forkList.length) {
          forkJoin(
            forkList.map((obs) =>
              obs.pipe(
                catchError((err) => {
                  console.error('Error in processing request:', err);
                  return of(null); // Handle error and continue
                })
              )
            )
          ).subscribe({
            next: (responses) => {
              if (responses?.length) {
                responses.forEach((data) => {
                  if (data) {
                    serviceMethods.updateValues(data.data);
                  } else {
                    formGroup.controls[controlName].patchValue(
                      serviceMethods.getUpdatedValues(),
                      { emitEvent: false }
                    );
                  }
                });
              }
            },
          });
        }
      }
    });
  }




  updateList(newVal) {
    const list = this.formGroup.controls.globalValueListItemsDto.value ?? [];
    const map = {}

    list.forEach(element => {
      if (element.code) {
        map[element.code] = element;
      }
    });
    if (newVal.code)
      map[newVal.code] = newVal;
    this.list = map ? Object.values(map) : [];
    this.emitPaused = true;
    this.formGroup.controls.globalValueListItemsDto.patchValue(this.list);
    setTimeout(() => { this.emitPaused = false; }, 100);
  }
}
