@if (!loading) {
  <form [formGroup]="formGroup" class="" (ngSubmit)="onSubmitForm()">
    <app-dynamic-field-form-list-input
      [control]="formGroup?.controls?.answers"
      [viewMode]="fieldViewMode"
      [config]="{ items: questions, values: data?.answers, scored: questionnaire?.scoringType == scoredType }"
      (scoreStatusChange)="onScoreStatusChange($event)"
    ></app-dynamic-field-form-list-input>
    <div class="flex flex-row-reverse gap-2">
      @if (fieldViewMode != 'view') {
        <app-button [action]="compliantButtonAction"></app-button>
      }
      <!-- <app-button *ngIf="viewModeService.viewMode != 'view'" [action]="incompliantButtonAction"></app-button> -->
    </div>
  </form>
}
@if (loading) {
  <div class="custom-skeleton">
    <div class="flex mb-3">
      <!-- <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton> -->
      <div class="w-full mb-2">
        <p-skeleton styleClass="mb-2"></p-skeleton>
        <p-skeleton width="50%" styleClass="mb-2"></p-skeleton>
        <p-skeleton width="20%" styleClass="mb-2"></p-skeleton>
        <p-skeleton width="80%" height="2rem" styleClass="mb-2"></p-skeleton>
        <p-skeleton width="65%" height="4rem"></p-skeleton>
      </div>
    </div>
    <p-skeleton width="100%" height="150px"></p-skeleton>
    <div class="flex justify-content-end gap-2 mt-3">
      <p-skeleton width="8rem" height="2rem"></p-skeleton>
      <p-skeleton width="8rem" height="2rem"></p-skeleton>
    </div>
  </div>
}
