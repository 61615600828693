<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12 md:col-6">
      <app-basic-input
        label="Name"
        [placeholder]="'Enter Name'"
        [control]="formGroup?.controls?.name"
        [viewMode]="'view'"
        [canKeepValue]="false"
      ></app-basic-input>
    </div>
    <div class="col-12 md:col-6">
      <app-user-and-group-selector
        [label]="moduleFieldString + '.respondent.label' | translate: 'Respondent'"
        [placeholder]="moduleFieldString + '.respondent.placeholder' | translate: 'Search...'"
        [principleMode]="true"
        [control]="formGroup?.controls?.respondent"
        [optionValue]="undefined"
        [multi]="false"
        [viewMode]="'view'"
        [canKeepValue]="false"
      >
      </app-user-and-group-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-target-code-selector
        [label]="moduleFieldString + '.questionnaire.label' | translate: 'Questionnaire'"
        [placeholder]="moduleFieldString + '.questionnaire.placeholder' | translate: 'Search Questionnaires'"
        [control]="formGroup?.controls?.questionnaire"
        [viewMode]="'view'"
        [canKeepValue]="false"
        [multi]="false"
        [optionLabel]="'name'"
        [optionValue]="'code'"
        [customProjectionFields]="['code', 'recordStatus', 'label', 'name']"
        [targetTypes]="['QUESTIONNAIRE']"
      >
      </app-target-code-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-target-code-selector
        [label]="moduleFieldString + '.campaign.label' | translate: 'Campaign'"
        [placeholder]="moduleFieldString + '.campaign.placeholder' | translate: 'Search Campaigns'"
        [control]="formGroup?.controls?.campaign"
        [viewMode]="'view'"
        [canKeepValue]="false"
        [multi]="false"
        [optionLabel]="'name'"
        [optionValue]="'code'"
        [customProjectionFields]="['code', 'recordStatus', 'label', 'name']"
        [targetTypes]="['CAMPAIGN']"
      >
      </app-target-code-selector>
    </div>
    <!-- <div class="col-12">
      <app-text-editor
        [height]="'150px'"
        [name]="'description'"
        label="Description"
        [placeholder]="'Enter Text...'"
        [control]="formGroup?.controls?.description"
        [viewMode]="'view'"
[canKeepValue]="false"
        [advanced]="true"
      ></app-text-editor>
    </div> -->
  </div>
  <!-- <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton && showSaveAndClose) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create' && showSaveAndClose) {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create' && showSaveAndClose) {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div> -->
</form>
