import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseForm, FilterItem, ModuleKeywords, RiskMethodologyImpactValueDto } from '@shared/classes';
import { ViewModeService } from '@shared/services';
import { RiskMethodologyImpactFactorOptionMappingService } from '@shared/services/mappings/risk/risk-methodology-impact-factor-option-mapping.service';

@Component({
  selector: 'app-risk-methodology-impact-value-item-form',
  templateUrl: './risk-methodology-impact-value-item-form.component.html',
  styleUrl: './risk-methodology-impact-value-item-form.component.scss',
})
export class RiskMethodologyImpactValueItemFormComponent extends BaseForm<RiskMethodologyImpactValueDto> implements OnInit {
  factorsColumn;
  constructor(public viewModeService: ViewModeService, riskMethodologyImpactFactorOptionsMappingService: RiskMethodologyImpactFactorOptionMappingService) {
    super(viewModeService, ModuleKeywords.RiskMethodologyImpactValue);
    this.factorsColumn = riskMethodologyImpactFactorOptionsMappingService?.tableFields?.find(x => x.key == 'impactFactor');

  }
  extraFilters: FilterItem[] = [];

  ngOnInit(): void {
    // this.formGroup?.controls?.impactFactorOptions?.valueChanges.subscribe(x => {
    const factorOptions = [];
    if (this.popupOptions?.values) {
      for (let i = 0; i < this.popupOptions?.values.length; i++) {
        const element = this.popupOptions?.values[i];
        if (this.data?.code != element?.code) {
          for (let j = 0; j < element?.impactFactorOptions?.length; j++) {
            const option = element?.impactFactorOptions[j];
            factorOptions.push(option);
          }
        }
      }
    } this.extraFilters = [{ property: 'code', operation: 'NOT_IN', value: factorOptions }];

    if (this.popupOptions?.displayOrder) {
      this.formGroup?.controls?.displayOrder.patchValue(this.popupOptions?.displayOrder);
    }
    if (this.popupOptions?.methodology) {
      this.formGroup?.controls?.methodology.patchValue(this.popupOptions?.methodology);
    }
    // })
  }

  getData() {
    return this.currentViewMode == 'create'
      ? this.getDataKeyValueFormat()
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.formGroup.patchValue({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      impactFactorOptions: new FormControl(null),
      value: new FormControl(null, Validators.required),
      displayOrder: new FormControl(null, Validators.required),
      methodology: new FormControl(null, Validators.required),
      description: new FormControl(null),
    });
  }
}
