import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LocaleService } from '@core/services/locale/locale.service';
import { environment } from '@env/environment';
import {
  AcknowledgmentRequestDto,
  BaseForm,
  DataTypeEnum,
  IAction,
  ModuleKeywordRootPath,
  ModuleKeywords,
  TargetTypeEnum,
  getEnumOptions,
  routeToLocaleCase,
  toCamelCase,
} from '@shared/classes';
import { ViewModeService } from '@shared/services/view-mode.service';
import { FilesDataService } from 'app/modules/file-manager-module/services/files-data.service';
import { FileUpload } from 'primeng/fileupload';

@Component({
  selector: 'app-acknowledgement-request-item-form',
  templateUrl: './acknowledgement-request-item-form.component.html',
  styleUrls: ['./acknowledgement-request-item-form.component.scss'],
})
export class AcknowledgementRequestItemFormComponent extends BaseForm<AcknowledgmentRequestDto> implements OnInit {
  @ViewChild('fileBrowser', { static: false }) fileBrowser: FileUpload;

  dataTypeEnum = DataTypeEnum;
  akgTypeMapper = AcknowledgmentTypeMapper;
  targetTypes = getEnumOptions(TargetTypeEnum);
  acknowledgmentTypeOptions = getEnumOptions(AcknowledgmentRequestDto.AcknowledgmentTypeEnum);
  showCancelFile = false;
  cancelFileSelectionAction: IAction = {
    id: 2,
    label: 'Cancel',
    buttonType: 'button',
    command: this.clearFile.bind(this),
    icon: 'pi pi-times',
  };
  constructor(
    public viewModeService: ViewModeService,
    private fileService: FilesDataService,
    private localeService: LocaleService
  ) {
    super(viewModeService, ModuleKeywords.AcknowledgmentRequest);
  }

  ngOnInit(): void {}

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? {
          ...this.getDataKeyValueFormat(),
          linkedDocuments: this.formGroup.controls.documentUrl.value ? [this.formGroup.controls.documentUrl.value] : [],
        }
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      owner: new FormControl(null, Validators.required),
      acknowledgers: new FormControl(null, Validators.required),
      approver: new FormControl(null, Validators.required),
      targetType: new FormControl(null),
      targetCode: new FormControl(null),
      description: new FormControl(null),
      documentUrl: new FormControl(null, Validators.required),
      requireSignedDocument: new FormControl(false),
      acknowledgmentType: new FormControl('GENERAL_DOCUMENT', Validators.required),
    });
  }
  onTargetTypeChange(targetType) {}
  lastType = null;
  onAkgTypeChanges(akgType) {
    if (this.lastType) {
      this.formGroup.get('targetCode').patchValue(null);
    }
    this.lastType = akgType;
    if (this.targetCodeType) {
      this.formGroup.get('targetCode').addValidators([Validators.required]);
      this.formGroup.get('targetCode').updateValueAndValidity();
      this.formGroup.get('documentUrl').removeValidators([Validators.required]);
      this.formGroup.get('documentUrl').updateValueAndValidity();
    } else {
      this.formGroup.get('documentUrl').addValidators([Validators.required]);
      this.formGroup.get('documentUrl').updateValueAndValidity();
      this.formGroup.get('targetCode').removeValidators([Validators.required]);
      this.formGroup.get('targetCode').updateValueAndValidity();
    }
  }
  onBasicUpload(event) {
    for (let file of event.files) {
      this.fileService.uploadFile(file, { description: file.name, versionName: file.name }).subscribe((res) => {
        this.formGroup.controls.documentUrl.patchValue(
          `${environment.websiteUrl}/file-manager/documents/download/${res.fileId}`
        );
        this.clearFile(this.fileBrowser);
      });
    }
  }
  onSelectFile() {
    this.showCancelFile = true;
  }
  clearFile(fileBrowser) {
    fileBrowser?.clear();
    this.showCancelFile = false;
  }
  get akgCodes() {
    return this.data?.acknowledgers.map((x) => x.name);
  }
  get targetCodeLabel() {
    if (!this.targetCodeType)
      return this.localeService.translate(this.moduleFieldString + '.targetCode.label', 'Target');
    const targetCode =
      this.targetCodeType == TargetTypeEnum.PublishedPolicies ? ModuleKeywords.PublishedPolicy : this.targetCodeType;
    return this.localeService.translate(
      `modules.${routeToLocaleCase(ModuleKeywordRootPath[targetCode])}.${toCamelCase(targetCode)}.${toCamelCase(targetCode)}`
    );
  }
  get targetCodeType() {
    return AcknowledgmentTypeMapper[this.formGroup?.controls?.acknowledgmentType?.getRawValue()] || null;
  }
  get allowedViewMode() {
    return this.data?.status && this.data?.status != 'DRAFT' ? 'view' : this.fieldViewMode;
  }
}
export const AcknowledgmentTypeMapper: { [key in AcknowledgmentRequestDto.AcknowledgmentTypeEnum]: TargetTypeEnum } = {
  POLICY: TargetTypeEnum.PublishedPolicies,
  GUIDELINE: TargetTypeEnum.PublishedGuideline,
  STANDARD: TargetTypeEnum.PublishedStandard,
  GENERAL_DOCUMENT: null,
};
