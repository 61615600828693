<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12 md:col-6">
      <app-basic-input
        [label]="moduleFieldString + '.name.label' | translate: 'Name'"
        [placeholder]="moduleFieldString + '.name.placeholder' | translate: 'Enter Name'"
        [control]="formGroup?.controls?.name"
        [viewMode]="this.isPopupPropsNotEmpty ? fieldViewMode : 'view'"
      ></app-basic-input>
    </div>

    <div class="col-12 md:col-6">
      <app-number-input
        [label]="moduleFieldString + '.value.label' | translate: 'Value'"
        [placeholder]="moduleFieldString + '.value.placeholder' | translate: 'Enter Value'"
        [name]="'value'"
        [showButtons]="true"
        [control]="formGroup?.controls?.value"
        [type]="'number'"
        [viewMode]="this.isPopupPropsNotEmpty ? fieldViewMode : 'view'"
        [min]="0"
      ></app-number-input>
    </div>
    <div class="col-12 md:col-12">
      <app-target-code-selector
        [label]="moduleFieldString + '.impactFactorItems.label' | translate: 'Impact Factor Items'"
        [placeholder]="moduleFieldString + '.impactFactorItems.placeholder' | translate: 'Search Impact Factor Items'"
        [control]="formGroup?.controls?.impactFactorOptions"
        [viewMode]="this.isPopupPropsNotEmpty ? fieldViewMode : 'view'"
        [multi]="true"
        [optionLabel]="'name'"
        [optionValue]="'code'"
        [targetTypes]="['RISK_METHODOLOGY_IMPACT_FACTOR_OPTION']"
        [customFormOptions]="{ options: popupOptions }"
        [manualGroup]="{ mode: 'subheader', column: factorsColumn }"
        [extraFilters]="extraFilters"
        [showReorder]="true"
        [showEdit]="true"
        [showAdd]="true"
      >
      </app-target-code-selector>
    </div>
    <div class="col-12">
      <app-target-code-selector
        [label]="moduleFieldString + '.methodology.label' | translate: 'Risk Methodology'"
        [placeholder]="moduleFieldString + '.methodology.placeholder' | translate: 'Search Risk Methodology'"
        [control]="formGroup?.controls?.methodology"
        [viewMode]="this.popupOptions?.methodology ? 'view' : this.isPopupPropsNotEmpty ? fieldViewMode : 'view'"
        [multi]="false"
        [optionLabel]="'name'"
        [optionValue]="'code'"
        [targetTypes]="['RISK_METHODOLOGY']"
      >
      </app-target-code-selector>
    </div>
    <div class="col-12">
      <app-text-editor
        [height]="'150px'"
        [name]="'description'"
        [label]="moduleFieldString + '.description.label' | translate: 'Description'"
        [placeholder]="moduleFieldString + '.description.placeholder' | translate: 'Enter Description'"
        [control]="formGroup?.controls?.description"
        [viewMode]="this.isPopupPropsNotEmpty ? fieldViewMode : 'view'"
        [advanced]="true"
      ></app-text-editor>
    </div>
  </div>
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton && showSaveAndClose) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create' && showSaveAndClose) {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create' && showSaveAndClose) {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
