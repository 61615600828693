<span class="flex flex-row md:flex-nowrap flex-wrap gap-1 w-full align-items-start">
  @if (label) {
    <label [for]="label" class="md:w-12rem md:max-w-12rem md:min-w-12rem w-full input-field-label">{{ label }} </label>
  }
  <div class="w-full grid p-fluid">
    <div class="col-12 md:col-9 xxl:col-10" style="max-height: 30vh">
      <app-table
        [cols]="cols"
        [tableActions]="[]"
        [actionCol]="null"
        [inputData]="data"
        [offlineConfig]="offlineConfig"
        [passIndexWithRowDataToAction]="true"
        [sessionStorageKey]="undefined"
        [selectionMode]="null"
        class="w-full"
      ></app-table>
    </div>
    <div class="col-12 md:col-3 xxl:col-2">
      <app-widget-chart
        [directConfig]="{
          colors: myBadgeColors,
          dataObject: parsedChartData ?? {},
          chartType: chartViewConfig.ChartTypeEnum.Doughnut,
          filteredKeys: ['tooltip'],
        }"
        [title]="''"
        [height]="'40vh'"
        [styleClass]="'relative m-0'"
        [showChartViewControls]="false"
      >
        <ng-template let-config #headerTemplate>
          <!-- <div class="flex justify-content-center w-full">Scope</div> -->
        </ng-template>
      </app-widget-chart>
    </div>
  </div>
</span>
