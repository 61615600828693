<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <!-- <div class="col-12">
            <app-basic-input label="Name"
                [placeholder]="'Enter Name'"
                [control]="formGroup?.controls?.name"
                [viewMode]="fieldViewMode"></app-basic-input>
        </div> -->
    <div class="col-12">
      <app-basic-input
        label="Title"
        [placeholder]="'Enter Title'"
        [control]="formGroup?.controls?.title"
        [viewMode]="'view'"
        [canKeepValue]="false"
      ></app-basic-input>
    </div>
    <div class="col-6">
      <app-target-code-selector
        [label]="'Transparency Case Category'"
        [placeholder]="'Search Transparency Case Categories'"
        [control]="formGroup?.controls?.category"
        [viewMode]="'view'"
        [canKeepValue]="false"
        [multi]="false"
        [optionLabel]="'name'"
        [optionValue]="'code'"
        [extraFilters]="[{ property: 'status', operation: 'EQUAL', value: publishedWBCategory }]"
        [targetTypes]="['TRANSPARENCY_CASE_CATEGORY']"
      >
      </app-target-code-selector>
    </div>
    <div class="col-6">
      <app-target-code-selector
        [label]="'Questionnaire'"
        [placeholder]="'Search Questionnaire'"
        [control]="formGroup?.controls?.questionnaire"
        [viewMode]="'view'"
        [canKeepValue]="false"
        [optionLabel]="'name'"
        [optionValue]="'code'"
        [multi]="false"
        [targetTypes]="['QUESTIONNAIRE']"
      >
      </app-target-code-selector>
    </div>
    <div class="col-12">
      <app-assessment-field
        [questionnaireCode]="formGroup?.controls?.questionnaire?.value"
        [questionnaireValueCode]="formGroup?.controls?.questionnaireValue?.value"
        [label]="'Questionnaire Value'"
        [control]="answersControl"
        [viewModeOnly]="true"
      >
      </app-assessment-field>
    </div>
    <div class="col-12 md:col-6">
      <app-user-and-group-selector
        [label]="'Assignee'"
        [placeholder]="'Search...'"
        [principleMode]="true"
        [control]="formGroup?.controls?.assignee"
        [optionValue]="undefined"
        [multi]="false"
        [viewMode]="fieldViewMode"
      >
      </app-user-and-group-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-drop-down-input
        [label]="'Closure Status'"
        [placeholder]="'Select Item'"
        [name]="'Closure Status'"
        [optionLabel]="'label'"
        [multi]="false"
        [items]="closureStatausEnums"
        [optionValue]="'value'"
        [control]="formGroup?.controls?.closureStatus"
        [badgeView]="true"
        [viewMode]="fieldViewMode"
      ></app-drop-down-input>
    </div>
    <div class="col-12">
      <app-text-editor
        [height]="'100px'"
        [name]="'textarea'"
        label="Closure Note"
        [placeholder]="'Enter Text...'"
        [control]="formGroup?.controls?.closureNote"
        [viewMode]="fieldViewMode"
        [advanced]="true"
      ></app-text-editor>
    </div>
    <div class="col-12">
      <app-text-editor
        [height]="'100px'"
        [name]="'textarea'"
        label="Description"
        [placeholder]="'Enter Text...'"
        [control]="formGroup?.controls?.description"
        [viewMode]="fieldViewMode"
        [advanced]="true"
      ></app-text-editor>
    </div>
  </div>
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create') {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create') {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
