import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  BaseForm,
  DataTypeEnum,
  FilterItem,
  IAction,
  IDynamicComponent,
  RiskItem,
  RiskMethodology,
  RiskMethodologyImpactFactorOption,
  RiskMethodologyImpactValue,
  RiskMethodologyLikelihoodValue,
  getEnumOptions,
} from '@shared/classes';
import { ImpactFactorWithValueSelectorComponent } from '@shared/components/selectors/impact-factor-with-value-selector/impact-factor-with-value-selector.component';
import { RiskMethodologyImpactFactorOptionMappingService } from '@shared/services/mappings/risk/risk-methodology-impact-factor-option-mapping.service';
import { ViewModeService } from '@shared/services/view-mode.service';
import { EntityDataService } from 'app/modules/entity-module/entity/entity-data.service';
import { cloneDeep, find, maxBy } from 'lodash-es';
import { combineLatest, startWith, takeUntil } from 'rxjs';

@Component({
  selector: 'app-inherited-risk-value-form',
  templateUrl: './inherited-risk-value-form.component.html',
  styleUrls: ['./inherited-risk-value-form.component.scss'],
})
export class InheritedRiskValueFormComponent extends BaseForm<RiskItem> implements OnInit {
  private _riskMeth: RiskMethodology = null;
  items: any;
  impactList: RiskMethodologyImpactValue[] = [];
  likelihoodList: RiskMethodologyLikelihoodValue[] = [];
  optionsFields: IDynamicComponent[] = [];
  dataTypes = DataTypeEnum;
  // riskImpactObjectControl: FormControl = new FormControl({ value: null, disabled: true });

  strategyList: any[] = getEnumOptions(RiskItem.StrategyEnum);
  inheritedRiskAcceptanceList: any[] = getEnumOptions(RiskItem.InheritedRiskAcceptanceEnum);
  filteredItems: any[];
  selectedFactor;
  triggerRowEditIndex: number;
  testControl = new FormControl(null);
  addFormButtonAction: IAction = {
    id: 1,
    buttonStyle: 'raised',
    label: 'Add Item',
    buttonType: 'button',
    command: this.onSelectItem.bind(this),
    icon: 'pi pi-plus',
  };

  impactFactorOptionToValueMap = {};
  extraOptionsFilters: FilterItem[] = [];

  @Input() set riskMeth(riskMeth: RiskMethodology) {
    if (riskMeth) {
      this._riskMeth = riskMeth;
      this.impactList = riskMeth.impactValuesDto;

      for (let i = 0; i < this.impactList?.length; i++) {
        const element = this.impactList[i];
        for (let j = 0; j < element?.riskMethodologyImpactFactorOption?.length; j++) {
          const option = element?.riskMethodologyImpactFactorOption[j];
          this.impactFactorOptionToValueMap[option?.code] = element;
        }
      }
      const listOfOptionsCodes = [];
      Object.keys(this.impactFactorOptionToValueMap).map(x => {
        listOfOptionsCodes.push(x);
      });

      this.extraOptionsFilters = [{ property: 'code', operation: 'IN', value: listOfOptionsCodes },];

      this.likelihoodList = riskMeth.likelihoodValuesDto;
      // this. = riskMeth.impactFactors.map(item => {
      //     return {
      //         label: item.name,
      //         selectable:false,
      //         children: Object.keys(item.options).map(key => {
      //             const option: any = item.options[key];
      //             return {
      //                 label: key,
      //                 value: option
      //             }
      //         })
      //     }
      // })
      let riskFactorsMap = {};
      for (let i = 0; i < riskMeth?.impactValuesDto?.length; i++) {
        const element: RiskMethodologyImpactValue = riskMeth?.impactValuesDto[i];
        for (let j = 0; j < element?.riskMethodologyImpactFactorOption?.length; j++) {
          const option: RiskMethodologyImpactFactorOption = element?.riskMethodologyImpactFactorOption[j];
          riskFactorsMap[option?.impactFactor] = { factor: option?.impactFactorDto, items: [...(riskFactorsMap[option?.impactFactor]?.items ?? []), option] };
        }
      }
      this.items = Object.values(riskFactorsMap).map((item: any) => {
        return {
          label: item.factor?.label,
          items: item.items?.map((element) => {
            return {
              label: element.name,
              value: element,
            };
          }),
          code: item.factor?.code,
        };
      });
      // this.items = riskMeth.impactValuesDto.map((item) => {
      //   return {
      //     label: item.name,
      //     items: item.riskMethodologyImpactFactorOption.map((element) => {
      //       return {
      //         label: element.name,
      //         value: element,
      //       };
      //     }),
      //     code: item.code,
      //   };
      // });
      // this.items = riskMeth.impactFactors;
      this.optionsFields = [
        // {
        //     componentType: DropDownInputComponent,
        //     options: {
        //         label: 'Impact Factor',
        //         name: 'factor',
        //         control: new FormControl(null, Validators.required),
        //         inputOptions: {
        //             dropDownInput: {
        //                 multi: false,
        //                 showClear: true,
        //                 optionLabel: 'label',
        //                 optionValue: undefined,
        //                 items: this.items,
        //             }
        //         },
        //         dataType: DataTypeEnum.Text,
        //         showLabelInViewMode: false,
        //     },
        //     viewModeOnly:true
        // },
        {
          componentType: ImpactFactorWithValueSelectorComponent,
          options: {
            label: 'Impact Factor',
            name: 'factorVal',
            control: new FormControl(null, Validators.required),
            inputOptions: {
              dropDownInput: {
                multi: false,
                showClear: true,
                optionLabel: 'label',
                optionValue: undefined,
                items: [],
                // items: riskMeth?.impactFactorsDto,
                badgeView: false,
              },
            },
            dataType: DataTypeEnum.Text,
            showLabelInViewMode: false,
          },
          // viewModeOnly:true
        },
        // {
        //     componentType: DropDownInputComponent,
        //     options: {
        //         label: 'Factor Value',
        //         name: 'value',
        //         control: new FormControl(null, Validators.required),
        //         inputOptions: {
        //             linkedControlName: 'factor',
        //             dropDownInput: {
        //                 multi: false,
        //                 showClear: true,
        //                 optionLabel: 'label',
        //                 optionValue: undefined,
        //                 items: this.items,
        //                 dataKey:'code',
        //                 formatGetItems: (items: any[],linkedControlValue:any) => {
        //                     let value = items.find(item => item.code === linkedControlValue?.code);

        //                     if(value){
        //                         return value.items.map(item => ({
        //                             label: item.label,
        //                             value: item.value,
        //                             code: item?.code || item?.value?.code
        //                         }))
        //                     }else{
        //                         return [];
        //                     }
        //                 },
        //             }
        //         },
        //         dataType: DataTypeEnum.Text,
        //         showLabelInViewMode: false,
        //     },
        // },
      ];
    }
  }
  get riskMeth() {
    return this._riskMeth;
  }
  factorsColumn;
  @Input() readOnlyName: boolean = false;
  constructor(
    public viewModeService: ViewModeService,
    public riskMethodologyImpactFactorOptionsMappingService: RiskMethodologyImpactFactorOptionMappingService,
    private entityService: EntityDataService
  ) {
    super(viewModeService, null);
    this.factorsColumn = riskMethodologyImpactFactorOptionsMappingService?.tableFields?.find(x => x.key == 'impactFactor');
  }

  ngOnInit(): void {
    this.riskValueSubscriber();
    this.formGroup?.controls?.riskMethodologyImpactFactorOption?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      const listOfValues = value?.map(x => this.impactFactorOptionToValueMap[x?.code]);
      let a: any = maxBy(listOfValues, 'value');
      if (a) {
        this.impactList = this.impactList.map(x => { return { ...x, disabled: x?.value < a?.value } });
      }
      // this.riskImpactObjectControl.patchValue(a);
      this.formGroup.controls.inheritedRiskImpactDto.patchValue(a);
    });
    // this.formGroup.controls.factors.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((values) => {
    //   this.handleFactors(values);
    // });
  }

  handleFactors(values) {
    let a: any = maxBy(values, 'factorVal.value.value.impactValueDto.value');
    a = { ...a?.factorVal };

    // (this.formGroup.controls?.inheritedRiskImpactDto).patchValue(
    //   a?.value?.value?.code
    //     ? {
    //       value: a?.value?.value?.impactValueDto ?? null,
    //       code: a?.value?.value?.impactValue ?? null,
    //       name: a?.value?.value?.name ?? null,
    //       description: a?.value?.value?.description ?? null,
    //       factors:
    //         values?.map((item) => {
    //           let options = {};
    //           item?.factorVal?.factor?.items?.forEach((option) => {
    //             options[option?.label] = {
    //               ...option?.value,
    //               selected: option?.impactValueDto?.code == item?.factorVal?.value?.value?.impactValueDto?.code,
    //             };
    //           });
    //           return {
    //             name: item?.factorVal?.factor?.label,
    //             code: item?.factorVal?.factor?.code,
    //             // description:   item?.factor?.description,
    //             options: options,
    //           };
    //         }) ?? null,
    //     }
    //     : null
    // );
    const patchVal = a?.value?.value?.code
      ? {
        value: a?.value?.value?.impactValueDto ?? null,
        code: a?.value?.value?.impactValue ?? null,
        name: a?.value?.value?.name ?? null,
        description: a?.value?.value?.description ?? null,

      }
      : null;
    this.formGroup.controls.impactFactors.patchValue(
      values?.map((item) => {
        return {
          riskMethodologyImpactFactor: item?.factorVal?.factor?.code,
          riskMethodologyImpactFactorOption: item?.factorVal?.value?.code,
          riskMethodologyImpactValue: item?.factorVal?.value.value.impactValue
        };
      }) ?? null);
    // this.riskImpactObjectControl.patchValue(patchVal?.value);
    this.formGroup.controls.inheritedRiskImpactDto.patchValue({ label: a?.value?.value?.impactValueDto?.label, code: a?.value?.value?.impactValueDto?.code });
    // this.formGroup.patchValue({
    //     inheritedRiskImpactDto:a?.value?.value
    // })
  }

  getData() {
    const formData = cloneDeep(this.formGroup.getRawValue());
    const tempForm = cloneDeep(formData);


    if (formData?.riskMethodologyImpactFactorOption) {
      delete formData?.riskMethodologyImpactFactorOption;
    }
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat({
        ...formData.getRawValue(),
        entityId: tempForm?.getRawValue()?.entityId?.code,
        project: tempForm?.getRawValue()?.project?.code,
        impactFactorOptions: tempForm?.getRawValue()?.riskMethodologyImpactFactorOption?.map(x => x.code)
        // factors: (this.formGroup?.getRawValue()?.factors as { factorVal: any }[])?.map((x) => x.factorVal),
        // inheritedRisk:{...this.formGroup.getRawValue().inheritedRisk,factors:this.factorsRepeaterControl.value},
        // residualRisk:{...this.formGroup.getRawValue().residualRisk,factors:this.factorsRepeaterControl.value},
      })
      : this.getChangedFormValues({
        ...formData.getRawValue(),
        entityId: tempForm?.getRawValue()?.entityId?.code,
        project: tempForm?.getRawValue()?.project?.code,
        impactFactorOptions: tempForm?.getRawValue()?.riskMethodologyImpactFactorOption?.map(x => x.code)
        // factors: (this.formGroup?.getRawValue()?.factors as { factorVal: any }[])?.map((x) => x.factorVal),
        // inheritedRisk:{...this.formGroup.getRawValue().inheritedRisk,factors:this.factorsRepeaterControl.value},
        // residualRisk:{...this.formGroup.getRawValue().residualRisk,factors:this.factorsRepeaterControl.value},
      }).updateItems;
  }

  setData(data: any) {
    // let factors = data?.inheritedRiskImpactDto?.factors?.map((item) => {
    //   let selectedValue;
    //   Object.keys(item.options).forEach((key) => {
    //     if (item?.options?.[key]?.selected) {
    //       selectedValue = { key: key, value: item?.options?.[key] };
    //     }
    //   });
    //   return {
    //     factorVal: {
    //       factor: {
    //         label: item.name,
    //         items: Object.keys(item.options).map((key) => {
    //           const option: any = item.options[key];
    //           return {
    //             label: key,
    //             value: option,
    //           };
    //         }),
    //         code: item?.code || item?.value?.code,
    //       },
    //       value: {
    //         label: selectedValue?.key,
    //         value: selectedValue?.value,
    //         code: selectedValue?.value?.code,
    //       },
    //     },
    //   };
    // });
    let factors = data?.impactFactors?.map((item) => {
      let selectedValue;
      return {
        factorVal: {
          factor: {
            label: item.riskMethodologyImpactFactorDto?.label,
            items: item.riskMethodologyImpactFactorDto?.impactFactorItemsDto?.map((ifItem) => {
              return {
                label: ifItem.label,
                value: ifItem,
              };
            }),
            code: item.riskMethodologyImpactFactorDto?.code,
          },
          value: {
            label: item.riskMethodologyImpactFactorOptionDto?.label,
            value: item.riskMethodologyImpactFactorOptionDto,
            code: item.riskMethodologyImpactFactorOptionDto?.code,
          },
        },
      };
    });
    // this.handleFactors(factors);
    // this.formGroup.controls.factors.patchValue(factors || []);
    this.formGroup.patchValue({ ...data });
    this.data = { ...data };
    // this.factorsRepeaterControl.patchValue([...data?.factors]);
    // this.factorsRepeaterControl.patchValue(this.data.residualImpact.factors);
    // if (data?.entityId) {
    //     this.entityService.getById<any>(data?.entityId).pipe(take(1)).subscribe(res => {
    //         this.formGroup.patchValue({ entityId: { ...res.data } })
    //     })
    // }
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      code: new FormControl(null),
      name: new FormControl({ value: '', disabled: false }, Validators.required),
      description: new FormControl({ value: '', disabled: false }),

      inheritedRiskImpactDto: new FormControl(null, Validators.required),
      // new FormGroup({
      //     value: new FormControl(null),
      //     code: new FormControl(null),
      //     name: new FormControl(null),
      //     description: new FormControl(null),
      //     factors: new FormControl([]),
      // }) ,//new FormControl({ value: null, disabled: true }),
      inheritedLikelihoodDto: new FormControl({ value: null, disabled: false }),
      inheritedRisk: new FormControl({ value: null, disabled: true }),
      inheritedRiskAcceptance: new FormControl({ value: null, disabled: true }),
      inheritedClassification: new FormControl({ value: null, disabled: true }),
      riskMethodologyImpactFactorOption: new FormControl(null, [Validators.required, Validators.minLength(1)]),
      // strategy: new FormControl(null),
      // factors: new FormControl([]),
      // impactFactors: new FormControl([]),

      // factors2: new FormControl([]),
    });
  }

  riskValueSubscriber() {
    combineLatest([
      this.formGroup.controls.inheritedLikelihoodDto.valueChanges.pipe(
        startWith(this.formGroup.controls.inheritedLikelihoodDto.value)
      ),
      this.formGroup.controls.inheritedRiskImpactDto.valueChanges.pipe(
        startWith(this.formGroup.controls.inheritedRiskImpactDto.value)
      ),
    ])
      .pipe(
        takeUntil(this.destroy$)
        // ,filter(([inheritedLikelihoodDto, inheritedRiskImpactDto]) => inheritedLikelihoodDto && inheritedRiskImpactDto)
      )
      .subscribe(([inheritedLikelihoodDto, inheritedRiskImpactDto]) => {
        // let a:any = maxBy(inheritedRiskImpactDto, "value.value");

        this.formGroup
          .get('inheritedRisk')
          .setValue(
            inheritedLikelihoodDto && inheritedRiskImpactDto ? inheritedLikelihoodDto?.value * inheritedRiskImpactDto?.value : null
          );
      });
    this.formGroup?.controls?.inheritedRisk?.valueChanges.subscribe((res) => {
      if (res) {
        this.formGroup?.controls?.inheritedRiskAcceptance?.patchValue(
          res > this.riskMeth?.acceptableRisk
            ? RiskItem.InheritedRiskAcceptanceEnum.NotAcceptable
            : RiskItem.InheritedRiskAcceptanceEnum.Acceptable
        );
        this.formGroup?.controls?.inheritedClassification?.patchValue(this.getCellThreshold(this.riskMeth, res));
      } else {
        this.formGroup?.controls?.inheritedRiskAcceptance?.patchValue(null);
        this.formGroup?.controls?.inheritedClassification?.patchValue(null);
      }
    });
  }

  filterItems(event) {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < this.items.length; i++) {
      let item = this.items[i];
      if (item.label.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        if ((this.formGroup.controls.factors.value as any[]).find((x) => x?.factor?.code == item?.code)) {
        } else {
          filtered.push(item);
        }
      }
    }

    this.filteredItems = filtered;
  }
  onSelectItem(event) {
    this.selectedFactor = '';
    this.formGroup.controls.factors.patchValue([
      { factor: event, value: null },
      ...this.formGroup.controls.factors.value,
    ]);
    this.triggerRowEditIndex = 0;
    setTimeout(() => {
      this.triggerRowEditIndex = undefined;
    }, 10);
  }
  getCellThreshold(meth: RiskMethodology, cellValue) {
    let threshold = find(meth?.thresholdValuesDto, function (o) {
      return o.max >= cellValue && o.min <= cellValue;
    });

    if (threshold != undefined) return threshold.name;
    else return '';
  }
}
