<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12 md:col-6">
      <app-basic-input
        [label]="moduleFieldString + '.name.label' | translate: 'Name'"
        [placeholder]="moduleFieldString + '.name.placeholder' | translate: 'Enter Name'"
        [control]="formGroup?.controls?.name"
        [viewMode]="this.isPopupPropsNotEmpty ? fieldViewMode : 'view'"
      ></app-basic-input>
    </div>

    <div class="col-12 md:col-6">
      <app-color-input
        [label]="moduleFieldString + '.color.label' | translate: 'Color'"
        [placeholder]="moduleFieldString + '.color.placeholder' | translate: 'Enter Color'"
        [control]="formGroup?.controls?.color"
        [viewMode]="this.isPopupPropsNotEmpty ? fieldViewMode : 'view'"
        [disabled]="disabled"
      >
      </app-color-input>
    </div>
    <div class="col-12">
      <app-p-slider-input
        [label]="moduleFieldString + '.range.label' | translate: 'Range'"
        [placeholder]="moduleFieldString + '.range.placeholder' | translate: 'Enter Ralue'"
        [name]="'value'"
        [control]="minMaxControl"
        [type]="'number'"
        [viewMode]="this.isPopupPropsNotEmpty ? fieldViewMode : 'view'"
        [min]="min"
        [max]="max"
        [range]="true"
        [disabledMin]="true"
        [disabled]="disabled"
      ></app-p-slider-input>
    </div>
    <div class="col-12">
      <app-target-code-selector
        [label]="moduleFieldString + '.methodology.label' | translate: 'Risk Methodology'"
        [placeholder]="moduleFieldString + '.methodology.placeholder' | translate: 'Search Risk Methodology'"
        [control]="formGroup?.controls?.methodology"
        [viewMode]="this.popupOptions?.methodology ? 'view' : this.isPopupPropsNotEmpty ? fieldViewMode : 'view'"
        [multi]="false"
        [optionLabel]="'name'"
        [optionValue]="'code'"
        [targetTypes]="['RISK_METHODOLOGY']"
      >
      </app-target-code-selector>
    </div>
    <div class="col-12">
      <app-text-editor
        [height]="'150px'"
        [name]="'description'"
        [label]="moduleFieldString + '.description.label' | translate: 'Description'"
        [placeholder]="moduleFieldString + '.description.placeholder' | translate: 'Enter Description'"
        [control]="formGroup?.controls?.description"
        [viewMode]="this.isPopupPropsNotEmpty ? fieldViewMode : 'view'"
        [advanced]="true"
      ></app-text-editor>
    </div>
  </div>
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton && showSaveAndClose) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create' && showSaveAndClose) {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create' && showSaveAndClose) {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
