<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <p-divider class="w-full" align="left">
      <b>General Information</b>
    </p-divider>
    <div class="col-12 md:col-6">
      <app-basic-input
        label="Name"
        [placeholder]="'Enter Name'"
        [control]="formGroup?.controls?.name"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>
    <!-- displayConfig -->
    <!-- <div class="col-12 md:col-6">
            <app-number-input
            [label]="'Cols'"
            [placeholder]="'Ex: 6'"
            [name]="'cols'"
            [showButtons]="true"
            [control]="displayConfigForm?.controls?.cols"
            [type]="'number'"
            [max]="12"
            [min]="1"
            [maxFractionDigits]="0"
            [viewMode]="fieldViewMode"
            ></app-number-input>
        </div>
        <div class="col-12 md:col-6">
            <app-number-input
            [label]="'Rows'"
            [placeholder]="'Ex: 3'"
            [name]="'rows'"
            [showButtons]="true"
            [control]="displayConfigForm?.controls?.rows"
            [type]="'number'"
            [max]="100"
            [min]="1"
            [maxFractionDigits]="0"
            [viewMode]="fieldViewMode"
            ></app-number-input>
        </div> -->

    <!-- reportConfig -->
    <div class="col-12 md:col-6">
      <app-drop-down-input
        [label]="'Type'"
        [placeholder]="'Select Item'"
        [name]="'type'"
        [optionLabel]="'label'"
        [items]="reportTypeOptions"
        [optionValue]="'value'"
        [control]="reportForm?.controls?.type"
        [viewMode]="allowedFieldViewMode"
        [badgeView]="true"
        (onChanges)="onViewTypeChange()"
      ></app-drop-down-input>
    </div>
    <div class="col-12 md:col-6">
      <app-drop-down-input
        [label]="'Available In'"
        [placeholder]="'Select Items'"
        [name]="'type'"
        [optionLabel]="'label'"
        [items]="reportForm?.controls?.type?.value != 'CUSTOM' ? availableInOptions : availableInReportOptions"
        [optionValue]="'value'"
        [control]="formGroup?.controls?.availableIn"
        [viewMode]="allowedFieldViewMode"
        [badgeView]="true"
        [multi]="true"
      ></app-drop-down-input>
    </div>
    <div class="col-12 md:col-6">
      <app-icon-selector [control]="formGroup?.controls?.icon" [viewMode]="fieldViewMode"> </app-icon-selector>
    </div>

    <div class="col-12 md:col-6">
      <app-color-input label="Color" name="Color" [control]="formGroup?.controls?.color" [viewMode]="fieldViewMode">
      </app-color-input>
    </div>
    @if (reportForm?.controls?.type?.value) {
      <p-divider class="w-full" align="left">
        <b>{{ reportForm?.controls?.type?.value | humanize }} Configuration</b>
      </p-divider>
    }
    @if (reportForm?.controls?.type?.value == 'STATISTICS') {
      <div class="col-12 md:col-6">
        <app-target-code-selector
          [label]="'Record'"
          [placeholder]="'Search Records'"
          [control]="statisticViewConfigForm?.controls?.collectorCode"
          [viewMode]="fieldViewMode"
          [multi]="false"
          [optionLabel]="'label'"
          [optionValue]="'code'"
          [targetTypes]="['STATISTIC_COLLECTOR']"
        >
        </app-target-code-selector>
      </div>
      <div class="col-12 md:col-6">
        <app-drop-down-input
          [label]="'Chart Type'"
          [placeholder]="'Select Item'"
          [name]="'type'"
          [optionLabel]="'label'"
          [items]="statisticChartTypeOptions"
          [optionValue]="'value'"
          [control]="statisticViewConfigForm?.controls?.chartType"
          [viewMode]="fieldViewMode"
          [badgeView]="true"
          [showClear]="false"
        ></app-drop-down-input>
      </div>
      @if (statisticChartVariationOptions?.length > 0) {
        <div class="col-12 md:col-6">
          <app-drop-down-input
            [label]="'Variation'"
            [placeholder]="'Select Item'"
            [name]="'type'"
            [optionLabel]="'label'"
            [items]="statisticChartVariationOptions"
            [optionValue]="'value'"
            [control]="statisticViewConfigForm?.controls?.chartVariation"
            [viewMode]="fieldViewMode"
            [badgeView]="true"
            [showClear]="false"
          ></app-drop-down-input>
        </div>
      }
      <div class="col-12 md:col-6">
        <app-palette-picker-input
          [label]="'Color Palette'"
          [placeholder]="'Select Palette'"
          [name]="'colorPalette'"
          [control]="statisticViewConfigForm?.controls?.colorPalette"
          [viewMode]="fieldViewMode"
        ></app-palette-picker-input>
      </div>
      <div class="col-12 md:col-6" style="min-height: 46px">
        <app-switch-input
          [label]="'Use System Colors'"
          [name]="'useSystemColors'"
          [control]="statisticViewConfigForm?.controls?.useSystemColors"
          [viewMode]="fieldViewMode"
        >
        </app-switch-input>
      </div>
      <!-- <div class="col-12 md:col-6">
        <app-drop-down-input
          [label]="'Chart View Type'"
          [placeholder]="'Select Item'"
          [name]="'type'"
          [optionLabel]="'label'"
          [items]="chartViewTypeOptions"
          [optionValue]="'value'"
          [control]="statisticViewConfigForm?.controls?.viewType"
          [viewMode]="fieldViewMode"
          [badgeView]="true"
          [showClear]="false"
        ></app-drop-down-input>
      </div>
      @if (statisticViewConfigForm?.controls?.viewType?.value == 'CHART_AND_DATA') {
        <div class="col-12 md:col-6">
          <app-drop-down-input
            [label]="'Data Position'"
            [placeholder]="'Select Item'"
            [name]="'type'"
            [optionLabel]="'label'"
            [items]="dataPositionOptions"
            [optionValue]="'value'"
            [control]="statisticViewConfigForm?.controls?.dataPosition"
            [viewMode]="fieldViewMode"
            [badgeView]="true"
            [showClear]="false"
          ></app-drop-down-input>
        </div>
      } -->
    }
    @if (reportForm?.controls?.type?.value && reportForm?.controls?.type?.value == 'LINKS') {
      <div class="col-12">
        <app-form-repeater
          [viewMode]="fieldViewMode"
          [title]="'Links'"
          [cols]="linksFields"
          [control]="formGroup?.controls.linkFields"
          [showAddFormAsPopup]="true"
        ></app-form-repeater>
      </div>
    }
    @if (
      reportForm?.controls?.type?.value &&
      reportForm?.controls?.type?.value != 'STATIC' &&
      reportForm?.controls?.type?.value != 'LINKS' &&
      reportForm?.controls?.type?.value != 'STATISTICS' &&
      reportForm?.controls?.type?.value != 'CUSTOM'
    ) {
      <div class="col-12 md:col-6">
        <app-drop-down-input
          [label]="'Target Type'"
          [placeholder]="'Select Item'"
          [name]="'type'"
          [optionLabel]="'label'"
          [items]="targetTypeOptions"
          [optionValue]="'value'"
          [control]="reportForm?.controls?.targetType"
          [viewMode]="fieldViewMode"
          [badgeView]="true"
          (onChanges)="clearFilterControl(); onChangeTargetType($event)"
        ></app-drop-down-input>
      </div>
      @if (reportForm?.controls?.type?.value != 'RECORD' && reportForm?.controls?.targetType?.value) {
        <div class="col-12 md:col-6">
          <app-target-code-selector
            [label]="'Filter'"
            [placeholder]="'Search Filters'"
            [control]="reportForm?.controls?.filter"
            [viewMode]="fieldViewMode"
            [multi]="false"
            [optionLabel]="'label'"
            [optionValue]="'code'"
            [targetTypes]="['FILTER']"
            [customProjectionFields]="[
              'code',
              'recordStatus',
              'label',
              'name',
              'filter',
              'projectionFields',
              'aggregationBody',
              'filters',
              'sortFields',
              'groupByFields',
              'aggregationFields',
              'projectionFieldsNames',
            ]"
            [extraFilters]="[
              {
                property: 'type',
                operation: 'EQUAL',
                value:
                  reportForm?.controls?.type?.value == 'CHART' || reportForm?.controls?.type?.value == 'DATA_COUNT'
                    ? 'AGGREGATION'
                    : 'SEARCH',
              },
              { property: 'targetType', operation: 'EQUAL', value: reportForm?.controls?.targetType?.value },
            ]"
            [tableMode]="false"
            (onChangesObject)="clearTableConfigColumns(); onFilterChange($event)"
          >
          </app-target-code-selector>
        </div>
      }
      @if (reportForm?.controls?.type?.value == 'RECORD' && reportForm?.controls?.targetType?.value) {
        <div class="col-12 md:col-6">
          <app-target-code-selector
            [label]="'Record'"
            [placeholder]="'Search Records'"
            [control]="formGroup?.controls?.recordCode"
            [viewMode]="fieldViewMode"
            [multi]="false"
            [optionLabel]="'label'"
            [optionValue]="'code'"
            [targetTypes]="[formGroup?.controls?.targetType?.value]"
            [customProjectionFields]="['code', 'recordStatus', 'label', 'name']"
          >
          </app-target-code-selector>
        </div>
      }
    }
    <!-- chartViewConfig -->
    @if (reportForm?.controls?.type?.value == 'CUSTOM') {
      <div class="col-12 md:col-6">
        <app-drop-down-input
          [label]="'Type'"
          [placeholder]="'Select Item'"
          [name]="'type'"
          [optionLabel]="'label'"
          [items]="customComponentTypeOptions"
          [optionValue]="'value'"
          [control]="customViewConfigForm?.controls?.componentType"
          [viewMode]="fieldViewMode"
          [badgeView]="true"
          [showClear]="false"
        ></app-drop-down-input>
      </div>
    }
    @if (reportForm?.controls?.type?.value == 'CHART') {
      <p-divider class="w-full" align="left">
        <b>Chart View Config</b>
      </p-divider>
      <div class="col-12 md:col-6">
        <app-drop-down-input
          [label]="'Chart View Type'"
          [placeholder]="'Select Item'"
          [name]="'type'"
          [optionLabel]="'label'"
          [items]="chartViewTypeOptions"
          [optionValue]="'value'"
          [control]="chartViewConfigForm?.controls?.viewType"
          [viewMode]="fieldViewMode"
          [badgeView]="true"
          [showClear]="false"
        ></app-drop-down-input>
      </div>
      @if (chartViewConfigForm?.controls?.viewType?.value == 'CHART_AND_DATA') {
        <div class="col-12 md:col-6">
          <app-drop-down-input
            [label]="'Data Position'"
            [placeholder]="'Select Item'"
            [name]="'type'"
            [optionLabel]="'label'"
            [items]="dataPositionOptions"
            [optionValue]="'value'"
            [control]="chartViewConfigForm?.controls?.dataPosition"
            [viewMode]="fieldViewMode"
            [badgeView]="true"
            [showClear]="false"
          ></app-drop-down-input>
        </div>
      }
      @if (chartViewConfigForm?.controls?.viewType?.value != 'DATA') {
        <div class="col-12 md:col-6">
          <app-drop-down-input
            [label]="'Chart Type'"
            [placeholder]="'Select Item'"
            [name]="'type'"
            [optionLabel]="'label'"
            [items]="chartTypeOptions"
            [optionValue]="'value'"
            [control]="chartViewConfigForm?.controls?.chartType"
            [viewMode]="fieldViewMode"
            [badgeView]="true"
            [showClear]="false"
            (onChanges)="onChangeChartType($event)"
          ></app-drop-down-input>
        </div>
        <!-- @if (chartVariationOptions?.length > 1) { -->
        <div class="col-12 md:col-6">
          <app-drop-down-input
            [label]="'Variation'"
            [placeholder]="'Select Item'"
            [name]="'type'"
            [optionLabel]="'label'"
            [items]="chartVariationOptions"
            [optionValue]="'value'"
            [control]="chartViewConfigForm?.controls?.chartVariation"
            [viewMode]="fieldViewMode"
            [badgeView]="true"
            [showClear]="false"
          ></app-drop-down-input>
        </div>
        <!-- } -->
      }
      @if (chartViewConfigForm?.controls?.viewType?.value != 'DATA' && drillDownAggregationFieldItems?.length > 1) {
        <div class="col-12 md:col-6">
          <app-drop-down-input
            [label]="'Aggregation Field'"
            [placeholder]="'Aggregation Field'"
            [name]="'type'"
            [optionLabel]="'label'"
            [items]="drillDownAggregationFieldItems"
            [optionValue]="'value'"
            [control]="chartViewConfigForm?.controls?.drillDownAggregationField"
            [viewMode]="'create'"
            [showClear]="false"
            [showStatusBadge]="true"
            [showStatusBadgeStatusField]="'operation'"
            [showStatusBadgeTextField]="'operation'"
            [appendTo]="'body'"
          ></app-drop-down-input>
        </div>
      }
      <div class="col-12 md:col-6">
        <app-palette-picker-input
          [label]="'Color Palette'"
          [placeholder]="'Select Palette'"
          [name]="'colorPalette'"
          [control]="chartViewConfigForm?.controls?.colorPalette"
          [viewMode]="fieldViewMode"
        ></app-palette-picker-input>
      </div>
      <!-- <div class="col-12 md:col-6" style="min-height: 46px">
        <app-switch-input
          [label]="'Use System Colors'"
          [name]="'useSystemColors'"
          [control]="chartViewConfigForm?.controls?.useSystemColors"
          [viewMode]="fieldViewMode"
        >
        </app-switch-input>
      </div> -->
      <!-- <div class="col-12 md:col-6">
        <app-drop-down-input
          [label]="'Scale Type'"
          [placeholder]="'Select Item'"
          [name]="'type'"
          [optionLabel]="'label'"
          [items]="scaleTypeOptions"
          [optionValue]="'value'"
          [control]="chartViewConfigForm?.controls?.scaleType"
          [viewMode]="fieldViewMode"
          [badgeView]="true"
        ></app-drop-down-input>
      </div> -->
    }
    @if (reportForm?.controls?.type?.value == 'STATIC') {
      <div class="col-12 md:col-12">
        <app-text-editor
          [name]="'staticViewData'"
          label="Static Data"
          [placeholder]="'Enter Text...'"
          [control]="formGroup?.controls?.staticViewData"
          [viewMode]="fieldViewMode"
          [advanced]="true"
        ></app-text-editor>
      </div>
    }
    @if (reportForm?.controls?.type?.value == 'RECORD') {
      <div class="col-12">
        <app-drop-down-input
          [label]="'Record Fields'"
          [placeholder]="'Search...'"
          [name]="'Record Fields'"
          [optionLabel]="'name'"
          [items]="formattedRecordFields"
          [optionValue]="undefined"
          [control]="formGroup?.controls?.recordField"
          [multi]="true"
          [dataKey]="'key'"
          [appendTo]="'body'"
          [floatLabel]="false"
          [showClear]="false"
          [showStatusBadge]="false"
          [badgeView]="false"
          [listBoxMode]="true"
          [viewMode]="fieldViewMode"
        >
          <!-- <ng-template let-node #preItemTemplate>
            @if (node?._pickListTargetItem) {
              <div class="" style="z-index: 10">
                <p-checkbox
                  [binary]="true"
                  [pTooltip]="'Full Width'"
                  [tooltipPosition]="'top'"
                  (onClick)="$event?.stopPropagation(); $event?.preventDefault()"
                  (ngModelChange)="onChangeFormattedRecord(node?.key, node?.fullWidth)"
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="node.fullWidth"
                  inputId="switch1"
                />
              </div>
            }
          </ng-template> -->
          <ng-template let-node #itemTemplate>
            <app-text-view [data]="node.name"></app-text-view>
          </ng-template>
        </app-drop-down-input>
      </div>
    }
    @if (reportForm?.controls?.type?.value == 'TABLE') {
      <p-divider class="w-full" align="left">
        <b>Table View Config</b>
      </p-divider>
      <!-- <app-table [cols]="[node]"
            [tableActions]="[]"
            [actionCol]="null"
            [inputData]="[]"
            [offlineConfig]="offlineConfig"
            [passIndexWithRowDataToAction]="true"
            [sessionStorageKey]="undefined"
            [selectionMode]="null"
            class="filter-builder-table w-full"
            [useTableToSearchFilterMappingV2]="true"
            [useAutoUpdate]="false"
            [resizableColumns]="false"
            ></app-table> -->
      <div class="col-12">
        <app-drop-down-input
          [label]="'Table Columns'"
          [placeholder]="'Search...'"
          [name]="'columns'"
          [optionLabel]="'name'"
          [items]="formattedColumns"
          [optionValue]="undefined"
          [control]="tableViewConfigForm?.controls?.columns"
          [multi]="true"
          [dataKey]="'key'"
          [appendTo]="'body'"
          [floatLabel]="false"
          [showClear]="false"
          [showStatusBadge]="false"
          [badgeView]="false"
          [listBoxMode]="true"
          [viewMode]="fieldViewMode"
        >
          <!-- <ng-template let-node #preItemTemplate>
            @if (node?._pickListTargetItem) {
              <div class="" style="z-index: 10">
                <p-checkbox
                  [binary]="true"
                  [pTooltip]="'Visible'"
                  [tooltipPosition]="'top'"
                  (onClick)="$event?.stopPropagation(); $event?.preventDefault()"
                  (ngModelChange)="onChangeFormattedColumn(node?.key, node?.visible)"
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="node.visible"
                  inputId="switch1"
                />
              </div>
            }
          </ng-template> -->
          <ng-template let-node #itemTemplate>
            <app-text-view [data]="node.name"></app-text-view>
          </ng-template>
        </app-drop-down-input>
      </div>
      <div class="col-12 md:col-6" style="min-height: 46px">
        <app-switch-input
          [label]="'Show Global Filter'"
          [name]="'showGlobalFilter'"
          [control]="tableViewConfigForm?.controls?.showGlobalFilter"
          [viewMode]="fieldViewMode"
        >
        </app-switch-input>
      </div>
      <div class="col-12 md:col-6" style="min-height: 46px">
        <app-switch-input
          [label]="'Show Actions'"
          [name]="'showActions'"
          [control]="tableViewConfigForm?.controls?.showActions"
          [viewMode]="fieldViewMode"
        >
        </app-switch-input>
      </div>
      @if (tableViewConfigForm?.controls?.showActions?.value == true) {
        <div class="col-12 md:col-12" style="min-height: 46px">
          <app-switch-input
            [label]="'Show Settings Link'"
            [name]="'showSettingsLink'"
            [control]="tableViewConfigForm?.controls?.showSettingsLink"
            [viewMode]="fieldViewMode"
          >
          </app-switch-input>
        </div>

        <div
          [class]="
            'col-12' +
            (tableViewConfigForm?.controls?.showOperationActions?.value == true ? ' md:col-6 ' : ' md:col-12 ')
          "
          style="min-height: 46px"
        >
          <app-switch-input
            [label]="'Show Operation Actions'"
            [name]="'showOperationActions'"
            [control]="tableViewConfigForm?.controls?.showOperationActions"
            [viewMode]="fieldViewMode"
          >
          </app-switch-input>
        </div>
        @if (tableViewConfigForm?.controls?.showOperationActions?.value == true) {
          <div class="col-12 md:col-6">
            <app-drop-down-input
              [label]="'Operation Actions'"
              [placeholder]="'Select Actions'"
              [name]="'operationsActionsOptions'"
              [optionLabel]="'label'"
              [items]="operationsActionsOptions"
              [optionValue]="'value'"
              [control]="operationsControl"
              [viewMode]="fieldViewMode"
              [badgeView]="false"
              [multi]="true"
              (onChanges)="onChangeOperationActions($event)"
            ></app-drop-down-input>
          </div>
        }
        <div
          [class]="
            'col-12' + (tableViewConfigForm?.controls?.showStatusActions?.value == true ? ' md:col-6 ' : ' md:col-12 ')
          "
          style="min-height: 46px"
        >
          <app-switch-input
            [label]="'Show Status Actions'"
            [name]="'showStatusActions'"
            [control]="tableViewConfigForm?.controls?.showStatusActions"
            [viewMode]="fieldViewMode"
          >
          </app-switch-input>
        </div>
        @if (tableViewConfigForm?.controls?.showStatusActions?.value == true) {
          <div class="col-12 md:col-6">
            <app-drop-down-input
              [label]="'Status Actions'"
              [placeholder]="'Select Actions'"
              [name]="'statusActionsOptions'"
              [optionLabel]="'label'"
              [items]="statusActionsOptions"
              [optionValue]="'value'"
              [control]="statusControl"
              [viewMode]="fieldViewMode"
              [badgeView]="false"
              [multi]="true"
              (onChanges)="onChangeStatusActions($event)"
            ></app-drop-down-input>
          </div>
        }
      }
    }
    @if (reportForm?.controls?.type?.value == 'CHART') {
      <!-- <app-table [cols]="[node]"
            [tableActions]="[]"
            [actionCol]="null"
            [inputData]="[]"
            [offlineConfig]="offlineConfig"
            [passIndexWithRowDataToAction]="true"
            [sessionStorageKey]="undefined"
            [selectionMode]="null"
            class="filter-builder-table w-full"
            [useTableToSearchFilterMappingV2]="true"
            [useAutoUpdate]="false"
            [resizableColumns]="false"
            ></app-table> -->
      <p-divider class="w-full" align="left">
        <b>Drilldown Table Config</b>
      </p-divider>
      <div class="col-12">
        <app-drop-down-input
          [label]="'Drilldown Table Columns'"
          [placeholder]="'Search...'"
          [name]="'columns'"
          [optionLabel]="'name'"
          [items]="formattedColumns"
          [optionValue]="undefined"
          [control]="drillDownTableViewConfigForm?.controls?.columns"
          [multi]="true"
          [dataKey]="'key'"
          [appendTo]="'body'"
          [floatLabel]="false"
          [showClear]="false"
          [showStatusBadge]="false"
          [badgeView]="false"
          [listBoxMode]="true"
          [viewMode]="fieldViewMode"
        >
          <!-- <ng-template let-node #preItemTemplate>
            @if (node?._pickListTargetItem) {
              <div class="" style="z-index: 10">
                <p-checkbox
                  [binary]="true"
                  [pTooltip]="'Visible'"
                  [tooltipPosition]="'top'"
                  (onClick)="$event?.stopPropagation(); $event?.preventDefault()"
                  (ngModelChange)="onChangeFormattedColumn(node?.key, node?.visible)"
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="node.visible"
                  inputId="switch1"
                />
              </div>
            }
          </ng-template> -->
          <ng-template let-node #itemTemplate>
            <app-text-view [data]="node.name"></app-text-view>
          </ng-template>
        </app-drop-down-input>
      </div>
      <!-- @if (formRepeaterFields?.length > 0) {
        <div class="col-12">
          <app-form-repeater
            [viewMode]="fieldViewMode"
            [title]="'Sort Fields'"
            [label]="''"
            [cols]="formRepeaterFields"
            [control]="drillDownTableViewConfigForm?.controls?.sortFields"
            [showOptionsAction]="false"
            [showDeleteButton]="fieldViewMode != 'view'"
            [showAddForm]="fieldViewMode != 'view'"
            [showReorder]="false"
            [showSelection]="false"
            [flyMode]="false"
            [showAddFormAsPopup]="false"
          ></app-form-repeater>
        </div>
      } -->
      <div class="col-12 md:col-6" style="min-height: 46px">
        <app-switch-input
          [label]="'Show Global Filter'"
          [name]="'showGlobalFilter'"
          [control]="drillDownTableViewConfigForm?.controls?.showGlobalFilter"
          [viewMode]="fieldViewMode"
        >
        </app-switch-input>
      </div>
      <div class="col-12 md:col-6" style="min-height: 46px">
        <app-switch-input
          [label]="'Show Actions'"
          [name]="'showActions'"
          [control]="drillDownTableViewConfigForm?.controls?.showActions"
          [viewMode]="fieldViewMode"
        >
        </app-switch-input>
      </div>
      @if (drillDownTableViewConfigForm?.controls?.showActions?.value == true) {
        <div class="col-12 md:col-12" style="min-height: 46px">
          <app-switch-input
            [label]="'Show Settings Link'"
            [name]="'showSettingsLink'"
            [control]="drillDownTableViewConfigForm?.controls?.showSettingsLink"
            [viewMode]="fieldViewMode"
          >
          </app-switch-input>
        </div>

        <div
          [class]="
            'col-12' +
            (drillDownTableViewConfigForm?.controls?.showOperationActions?.value == true ? ' md:col-6 ' : ' md:col-12 ')
          "
          style="min-height: 46px"
        >
          <app-switch-input
            [label]="'Show Operation Actions'"
            [name]="'showOperationActions'"
            [control]="drillDownTableViewConfigForm?.controls?.showOperationActions"
            [viewMode]="fieldViewMode"
          >
          </app-switch-input>
        </div>
        @if (drillDownTableViewConfigForm?.controls?.showOperationActions?.value == true) {
          <div class="col-12 md:col-6">
            <app-drop-down-input
              [label]="'Operation Actions'"
              [placeholder]="'Select Actions'"
              [name]="'operationsActionsOptions'"
              [optionLabel]="'label'"
              [items]="operationsActionsOptions"
              [optionValue]="'value'"
              [control]="operationsControlChart"
              [viewMode]="fieldViewMode"
              [badgeView]="false"
              [multi]="true"
              (onChanges)="onChangeDrilldownOperationActions($event)"
            ></app-drop-down-input>
          </div>
        }
        <div
          [class]="
            'col-12' +
            (drillDownTableViewConfigForm?.controls?.showStatusActions?.value == true ? ' md:col-6 ' : ' md:col-12 ')
          "
          style="min-height: 46px"
        >
          <app-switch-input
            [label]="'Show Status Actions'"
            [name]="'showStatusActions'"
            [control]="drillDownTableViewConfigForm?.controls?.showStatusActions"
            [viewMode]="fieldViewMode"
          >
          </app-switch-input>
        </div>
        @if (drillDownTableViewConfigForm?.controls?.showStatusActions?.value == true) {
          <div class="col-12 md:col-6">
            <app-drop-down-input
              [label]="'Status Actions'"
              [placeholder]="'Select Actions'"
              [name]="'statusActionsOptions'"
              [optionLabel]="'label'"
              [items]="statusActionsOptions"
              [optionValue]="'value'"
              [control]="statusControlChart"
              [viewMode]="fieldViewMode"
              [badgeView]="false"
              [multi]="true"
              (onChanges)="onChangeDrilldownStatusActions($event)"
            ></app-drop-down-input>
          </div>
        }
      }
    }
  </div>
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveAndClose) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create') {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create') {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
