import { Component, Input, OnInit } from '@angular/core';
import { BaseViewItem } from '@shared/classes';

@Component({
  selector: 'app-time-view',
  templateUrl: './time-view.component.html',
  styleUrls: ['./time-view.component.scss'],
})
export class TimeViewComponent extends BaseViewItem implements OnInit {
  timeString: string = '';
  private _year: number = 365;
  private _month: number = 30;
  private _week: number = 7;
  private _day: number = 8;
  private _hour: number = 60;

  @Input()
  set year(value: number) {
    if (value) {
      this._year = value;
    }
  }
  get year(): number {
    return this._year;
  }

  @Input()
  set month(value: number) {
    if (value) {
      this._month = value;
    }
  }
  get month(): number {
    return this._month;
  }

  @Input()
  set week(value: number) {
    if (value) {
      this._week = value;
    }
  }
  get week(): number {
    return this._week;
  }

  @Input()
  set day(value: number) {
    if (value) {
      this._day = value;
    }
  }
  get day(): number {
    return this._day;
  }

  @Input()
  set hour(value: number) {
    if (value) {
      this._hour = value;
    }
  }
  get hour(): number {
    return this._hour;
  }

  minutesInHour: number;
  minutesInDay: number;
  minutesInWeek: number;
  minutesInMonth: number;
  minutesInYear: number;

  constructor() {
    super();
  }

  ngOnInit(): void { }

  onSetData(): void {
    this.minutesInHour = this.hour;
    this.minutesInDay = this.day * this.minutesInHour;
    this.minutesInWeek = this.week * this.minutesInDay;
    this.minutesInMonth = this.month * this.minutesInDay;
    this.minutesInYear = this.year * this.minutesInDay;
    this.timeString = this.minutesToTimeFormat(this.data?.effectiveTime != undefined ? this.data?.effectiveTime : this.data);
  }

  minutesToTimeFormat(minutes: number) {
    let remainingMinutes: number = minutes;
    let years: number = Math.floor(remainingMinutes / this.minutesInYear);
    remainingMinutes %= this.minutesInYear;

    let months: number = Math.floor(remainingMinutes / this.minutesInMonth);
    remainingMinutes %= this.minutesInMonth;

    let weeks: number = Math.floor(remainingMinutes / this.minutesInWeek);
    remainingMinutes %= this.minutesInWeek;

    let days: number = Math.floor(remainingMinutes / this.minutesInDay);
    remainingMinutes %= this.minutesInDay;

    let hours: number = Math.floor(remainingMinutes / this.minutesInHour);
    remainingMinutes %= this.minutesInHour;

    let timeFormat: string = '';

    if (years > 0) {
      timeFormat += `${years}y `;
    }
    if (months > 0) {
      timeFormat += `${months}M `;
    }
    if (weeks > 0) {
      timeFormat += `${weeks}w `;
    }
    if (days > 0) {
      timeFormat += `${days}d `;
    }
    if (hours > 0) {
      timeFormat += `${hours}h `;
    }
    if (remainingMinutes > 0) {
      timeFormat += `${remainingMinutes}m `;
    }
    const retStr = timeFormat.trim();
    return retStr == '' ? '0m' : retStr;
  }
}
