<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12 md:col-6">
      <app-basic-input
        [label]="moduleFieldString + '.name.label' | translate: 'Name'"
        [placeholder]="moduleFieldString + '.name.placeholder' | translate: 'Enter Name'"
        [control]="formGroup?.controls?.name"
        [viewMode]="fieldViewMode == 'create' ? fieldViewMode : canEditField"
      ></app-basic-input>
    </div>
    <div class="col-12 md:col-6">
      <app-user-and-group-selector
        [label]="moduleFieldString + '.owner.label' | translate: 'Owner'"
        [placeholder]="moduleFieldString + '.owner.placeholder' | translate: 'Enter Owner'"
        [principleMode]="true"
        [control]="formGroup?.controls?.owner"
        [optionValue]="undefined"
        [multi]="false"
        [viewMode]="fieldViewMode"
      >
      </app-user-and-group-selector>
      <!-- <app-entity-category-tree-selector [label]="'Owner'" [categoryList]="respList"
      [placeholder]="'Search Entities'" [control]="formGroup?.controls?.owner" [multi]="false"
      [selectedEntities]="[data?.owner]" [viewMode]="fieldViewMode" [dropDown]="true">
    </app-entity-category-tree-selector> -->
    </div>
    <div class="col-12 md:col-6">
      <app-user-and-group-selector
        [label]="moduleFieldString + '.attestationRespondent.label' | translate: 'Respondent'"
        [placeholder]="moduleFieldString + '.attestationRespondent.placeholder' | translate: 'Enter Respondent'"
        [principleMode]="true"
        [control]="formGroup?.controls?.attestationRespondent"
        [optionValue]="undefined"
        [multi]="false"
        [viewMode]="data?.controlStatus == 'ATTEST' ? 'view' : fieldViewMode"
      >
      </app-user-and-group-selector>
      <!-- <app-entity-category-tree-selector
    [label]="'Respondent'"
    [categoryList]="respList"
    [placeholder]="'Search Responsibilities'"
    [control]="formGroup?.controls?.attestationRespondent"
    [multi]="false"
    [viewMode]="fieldViewMode"
    [selectedEntities]="[data?.attestationRespondent]"
    [dropDown]="true"
    >
  </app-entity-category-tree-selector> -->
    </div>
    <div class="col-12 md:col-6">
      <app-basic-input
        [label]="moduleFieldString + '.sourceOfControl.label' | translate: 'Source Of Control'"
        [placeholder]="moduleFieldString + '.sourceOfControl.placeholder' | translate: 'Enter Source Of Control'"
        [control]="formGroup?.controls?.sourceOfControl"
        [viewMode]="fieldViewMode == 'create' ? fieldViewMode : canEditField"
      ></app-basic-input>
    </div>
    <div class="col-12 md:col-6">
      <app-basic-input
        [label]="moduleFieldString + '.sourceReference.label' | translate: 'Source Reference'"
        [placeholder]="moduleFieldString + '.sourceReference.placeholder' | translate: 'Enter Source Reference'"
        [control]="formGroup?.controls?.sourceReference"
        [viewMode]="fieldViewMode == 'create' ? fieldViewMode : canEditField"
      ></app-basic-input>
    </div>
    <div class="col-12 md:col-6">
      <app-basic-input
        [label]="moduleFieldString + '.uniqueIdentifier.label' | translate: 'Unique Identifier'"
        [placeholder]="moduleFieldString + '.uniqueIdentifier.placeholder' | translate: 'Enter Unique Identifier'"
        [control]="formGroup?.controls?.uniqueIdentifier"
        [viewMode]="fieldViewMode == 'create' ? fieldViewMode : canEditField"
      ></app-basic-input>
    </div>

    @if (fieldViewMode != 'create') {
      <p-divider class="w-full" align="left">
        <b>{{ 'modules.framework.control.objective' | translate: 'Objective' }}</b>
      </p-divider>
    }
    @if (fieldViewMode != 'create') {
      <div class="col-12 md:col-6">
        <app-target-code-selector
          [label]="moduleFieldString + '.controlGroup.label' | translate: 'Control Group'"
          [placeholder]="moduleFieldString + '.controlGroup.placeholder' | translate: 'Enter Control Group'"
          [control]="formGroup?.controls?.controlGroup"
          [viewMode]="'view'"
          [canKeepValue]="false"
          [multi]="false"
          [optionLabel]="'name'"
          [optionValue]="undefined"
          [dataKey]="'code'"
          [customProjectionFields]="['code', 'recordStatus', 'label', 'name']"
          [targetTypes]="['CONTROL_GROUP']"
        >
        </app-target-code-selector>
      </div>
    }
    <p-divider class="w-full" align="left">
      <b>{{ 'modules.framework.control.controlScope' | translate: 'Control Scope' }}</b>
    </p-divider>
    <div class="col-12 md:col-6">
      <app-target-code-selector
        [label]="moduleFieldString + '.controlEntity.label' | translate: 'Entity'"
        [placeholder]="moduleFieldString + '.controlEntity.placeholder' | translate: 'Enter Entity'"
        [control]="formGroup?.controls?.controlEntity"
        [viewMode]="canEditField"
        [multi]="false"
        [optionLabel]="'name'"
        [optionValue]="'code'"
        [targetTypes]="['RESPONSIBILITY', 'ASSET', 'OBJECTIVE', 'PROCESS', 'EVENT', 'GEOGRAPHY', 'ORGANIZATION']"
      >
      </app-target-code-selector>
      <!-- <app-entity-category-tree-selector
  [label]="'Entity'"
  [categoryList]="categoryList"
  [placeholder]="'Search Entities'"
  [control]="formGroup?.controls?.controlEntity"
  [multi]="false"
  [viewMode]="fieldViewMode"
  [selectedEntities]="[data?.controlEntity]"
  [dropDown]="true"
  >
</app-entity-category-tree-selector> -->
    </div>
    <p-divider class="w-full" align="left">
      <b>{{ 'modules.framework.control.governance' | translate: 'Governance' }}</b>
    </p-divider>
    <!-- <div class="col-12 md:col-6"
      *ngxPermissionsOnly="mapperService?.mappedFields['standard']?.permissions">
      <app-target-code-selector [label]="'Standard'"
        [placeholder]="'Search Standard'"
        [control]="formGroup?.controls?.standard"
        [viewMode]="canEditField"
        [multi]="false"
        [optionLabel]="'name'"
        [optionValue]="undefined"
        [dataKey]="'code'"
        [customProjectionFields]="['code','recordStatus','label','name','color','icon','options']"
        [targetTypes]="['STANDARD']"
        [extraFilters]="[{'property': 'status', 'operation': 'EQUAL', 'value': 'APPROVED'}]"> -->
    <!-- <app-target-code-selector [label]="'Policy'"
    [placeholder]="'Search Policies'"
    [control]="formGroup?.controls?.policy"
    [viewMode]="canEditField"
    [multi]="false"
    [optionLabel]="'name'"
    [optionValue]="undefined"
    [dataKey]="'code'"
    [customProjectionFields]="['code','recordStatus','label','name','color','icon','options','policyBody']"
    [targetTypes]="['POLICY']"
    [extraFilters]="[{'property': 'policyStatus', 'operation': 'EQUAL', 'value': 'APPROVED'}]"> -->
    <!-- </app-target-code-selector> -->
    <!-- </div> -->
    <div class="col-12 md:col-6" *ngxPermissionsOnly="mapperService?.mappedFields['controlObjectives']?.permissions">
      <app-target-code-selector
        [label]="moduleFieldString + '.controlObjectives.label' | translate: 'Control Objectives'"
        [placeholder]="moduleFieldString + '.controlObjectives.placeholder' | translate: 'Enter Control Objectives'"
        [control]="formGroup?.controls?.controlObjectives"
        [viewMode]="canEditField"
        [multi]="true"
        [optionLabel]="'name'"
        [optionValue]="'code'"
        [targetTypes]="['CONTROL_OBJECTIVE']"
      >
        <!-- <app-target-code-selector [label]="'Policy Body Section'"
        [placeholder]="'Search Sections'"
        [control]="formGroup?.controls?.policySection"
        [viewMode]="canEditField"
        [multi]="false"
        [targetTypes]="['POLICY_BODY_SECTION']"
        [extraFilters]="(formGroup?.controls?.policy?.value?.policyBody?.code) ? [policyBodySectionFilter,{'property': 'policyBody', 'operation': 'EQUAL', 'value': (formGroup?.controls?.policy?.value?.policyBody?.code)}] : [policyBodySectionFilter]"
        [linkedControl]="formGroup?.controls?.policy"> -->
      </app-target-code-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-drop-down-input
        [label]="moduleFieldString + '.controlClassification.label' | translate: 'Classification'"
        [placeholder]="moduleFieldString + '.controlClassification.placeholder' | translate: 'Enter Classification'"
        [translationKeyPrefix]="moduleFieldString + '.controlClassification.enums'"
        [name]="'type'"
        [optionLabel]="'label'"
        [items]="listOfTypes"
        [optionValue]="'value'"
        [control]="formGroup?.controls?.controlClassification"
        [viewMode]="canEditField"
        [badgeView]="true"
      ></app-drop-down-input>
    </div>
    <p-divider class="w-full" align="left">
      <b>{{ 'modules.framework.attestation.attestation' | translate: 'Attestation' }}</b>
    </p-divider>
    <div class="col-12 md:col-6">
      <app-target-code-selector
        [label]="moduleFieldString + '.controlAttestationTemplate.label' | translate: 'Questionnaire'"
        [placeholder]="moduleFieldString + '.controlAttestationTemplate.placeholder' | translate: 'Enter Questionnaire'"
        [control]="formGroup?.controls?.controlAttestationTemplate"
        [viewMode]="canEditField"
        [multi]="false"
        [optionLabel]="'name'"
        [optionValue]="'code'"
        [targetTypes]="['QUESTIONNAIRE']"
      >
      </app-target-code-selector>
    </div>
    <div class="col-12 md:col-6">
      <!-- <app-number-input
  [label]="'Frequency'"
  [placeholder]="'Ex: 7 days'"
  [name]="'frequency'"
  [showButtons]="true"
  [control]="formGroup?.controls?.controlFrequency"
  [type]="'number'"
  [viewMode]="fieldViewMode"
></app-number-input>
<app-auto-complete-input
  [control]="formGroup?.controls?.controlFrequency"
  [viewMode]="fieldViewMode"
  [suggestions] = "[{label:'Yearly',value:365},{label:'Monthly',value:30},{label:'Weekly',value:7},{label:'Daily',value:1}]"
></app-auto-complete-input> -->
      <app-suggestion-input
        [label]="moduleFieldString + '.controlFrequency.label' | translate: 'Frequency'"
        [placeholder]="moduleFieldString + '.controlFrequency.placeholder' | translate: 'Ex: 7 day'"
        [control]="formGroup?.controls?.controlFrequency"
        [viewMode]="canEditField"
        [items]="[
          { label: 'Yearly', value: 365 },
          { label: 'Quarterly', value: 90 },
          { label: 'Monthly', value: 30 },
          { label: 'Weekly', value: 7 },
          { label: 'Daily', value: 1 },
        ]"
        [type]="'number'"
        [min]="0"
        [maxFractionDigits]="0"
      >
      </app-suggestion-input>
      <!-- <app-drop-down-input
[label]="'Frequency'"
[placeholder]="'Select Item'"
[name]="'type'"
[optionLabel]="'label'"
[items]="listOfControlFrequencyTypes"
[optionValue]="'value'"
[control]="formGroup?.controls?.controlFrequency"
[viewMode]="fieldViewMode"
[badgeView]="true"
></app-drop-down-input> -->
    </div>
    <div *ngIf="fieldViewMode != 'create'" class="col-12 md:col-6">
      <app-drop-down-input
        [label]="moduleFieldString + '.controlComplianceStatus.label' | translate: 'Compliance Status'"
        [placeholder]="
          moduleFieldString + '.controlComplianceStatus.placeholder' | translate: 'Enter Compliance Status'
        "
        [translationKeyPrefix]="moduleFieldString + '.controlComplianceStatus.enums'"
        [name]="'type'"
        [optionLabel]="'label'"
        [items]="listOfTypes"
        [optionValue]="'value'"
        [control]="formGroup?.controls?.controlComplianceStatus"
        [viewMode]="'view'"
        [canKeepValue]="false"
        [badgeView]="true"
      ></app-drop-down-input>
    </div>
    <div class="col-12 md:col-12">
      <app-text-editor
        [height]="'100px'"
        [advanced]="true"
        [name]="'textarea'"
        [label]="moduleFieldString + '.description.label' | translate: 'Description'"
        [placeholder]="moduleFieldString + '.description.placeholder' | translate: 'Enter Description'"
        [control]="formGroup?.controls?.description"
        [viewMode]="canEditField"
      ></app-text-editor>
    </div>
  </div>
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create') {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create') {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
