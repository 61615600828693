<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="flex flex-wrap align-content-start w-6 xxl:w-8">
      <div class="col-12">
        <app-basic-input
          [label]="moduleFieldString + '.name.label' | translate: 'Name'"
          [placeholder]="moduleFieldString + '.name.placeholder' | translate: 'Enter Name'"
          [control]="disabledFormGroup?.controls?.creatorName"
          [viewMode]="'edit'"
        ></app-basic-input>
      </div>
      <div class="col-12">
        <app-date-input
          [label]="moduleFieldString + '.creationDate.label' | translate: 'Creation Date'"
          [placeholder]="'2022-11-22'"
          [name]="'date'"
          [showIcon]="false"
          [control]="disabledFormGroup?.controls?.creationDate"
          [viewMode]="'edit'"
        ></app-date-input>
      </div>
      @if (!defaultViewMode) {
        <div class="col-12">
          <app-basic-input
            [label]="moduleFieldString + '.versionName.label' | translate: 'Version Name'"
            [placeholder]="moduleFieldString + '.versionName.placeholder' | translate: 'Enter Version Name'"
            [control]="formGroup?.controls?.versionName"
            [viewMode]="fieldViewMode"
          ></app-basic-input>
        </div>
      }
      <div class="col-12">
        <app-target-code-selector
          [label]="moduleFieldString + '.policy.label' | translate: 'Policy'"
          [placeholder]="moduleFieldString + '.policy.placeholder' | translate: 'Enter Policy'"
          [control]="formGroup?.controls?.policy"
          [viewMode]="'view'"
          [canKeepValue]="false"
          [readOnly]="true"
          [multi]="false"
          [optionLabel]="'name'"
          [optionValue]="'code'"
          [targetTypes]="['POLICY']"
        >
        </app-target-code-selector>
      </div>
      @if (data?.policyBody?.documentUrl && !defaultViewMode) {
        <div class="col-12 field">
          <p class="mb-0 font-bold">Document</p>
          <app-hyper-text-view [data]="data?.policyBody?.documentUrl"></app-hyper-text-view>
        </div>
      }
      @if (defaultViewMode) {
        <div class="col-12">
          <app-basic-input
            [label]="moduleFieldString + '.versionName.label' | translate: 'Version Name'"
            [placeholder]="moduleFieldString + '.versionName.placeholder' | translate: 'Enter Version Name'"
            [control]="formGroup?.controls?.versionName"
            [viewMode]="fieldViewMode"
          ></app-basic-input>
        </div>
      }
      @if (defaultViewMode) {
        <div class="col-12 md:col-6">
          @if (fieldViewMode != 'view') {
            <app-basic-input
              [label]="moduleFieldString + '.documentUrl.label' | translate: 'Document Url'"
              [placeholder]="moduleFieldString + '.documentUrl.placeholder' | translate: 'Enter Document Url'"
              [control]="formGroup?.controls?.documentUrl"
              [viewMode]="fieldViewMode"
            ></app-basic-input>
          }
          @if (fieldViewMode == 'view') {
            @if (formGroup?.controls?.documentUrl?.value) {
              <p class="font-bold">{{ moduleFieldString + '.documentUrl.label' | translate: 'Document Url' }}</p>
              <app-hyper-text-view [data]="formGroup?.controls?.documentUrl?.value"></app-hyper-text-view>
            }
          }
        </div>
      }

      @if (defaultViewMode && fieldViewMode != 'view') {
        <div class="col-12 md:col-6 flex gap-2">
          <p-fileUpload
            mode="basic"
            name="documentUrl[]"
            [styleClass]="'px-3'"
            [chooseLabel]="'Browse'"
            (uploadHandler)="onBasicUpload($event)"
            [customUpload]="true"
            (onSelect)="onSelectFile()"
            #fileBrowser
          ></p-fileUpload>
          @if (showCancelFile) {
            <app-button [action]="cancelFileSelectionAction" [commandData]="fileBrowser"></app-button>
          }
        </div>
      }
    </div>

    <div class="w-6 xxl:w-4 formgrid grid text-center justify-content-evenly">
      <div class="col-12 md:col-6 widget-country-graph">
        <p-chart type="doughnut" [data]="approveData" [options]="basicOptions"></p-chart>
        <h5>{{ moduleString + '.approversProgress' | translate }}</h5>
      </div>

      <div class="col-12 md:col-6 widget-country-graph">
        <p-chart type="doughnut" [data]="reviewData" [options]="basicOptions"></p-chart>
        <h5>{{ moduleString + '.reviewersProgress' | translate }}</h5>
      </div>
    </div>

    <!-- <div class="col-12" *ngIf="defaultViewMode">
    <app-policy-body-section-inline-tree
      *ngIf="data?.policyBody?.code"
      [itemId]="data?.policyBody?.code"
      [policy]="data?.policyBody?.policy"
      [data]="data?.policyBody?.sections"
      [showActions]="defaultViewMode"
      >
    </app-policy-body-section-inline-tree>
  </div> -->
    <!-- <div class="col-12 md:col-12" *ngIf="defaultViewMode">
  <app-text-editor [height]="textEditorHeight" [advanced]="true" [name]="'textarea'" label="Policy Body"
    [placeholder]="'Enter Text...'" [control]="formGroup?.controls?.body"
  [viewMode]="fieldViewMode"></app-text-editor>
</div> -->
  </div>
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create') {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create') {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
