import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  BaseForm,
  ExportGlobalPackageDto,
  ModuleKeywords,
  TypesWithName,
  getEnumOptions,
  getOptionsFromStringArray,
  isNullObj,
} from '@shared/classes';
import { PathResolverService, ViewModeService } from '@shared/services';
import { GenericInfoDataService } from 'app/modules/reports-and-dashboards/services/data/generic-info-data.service';
import { omit } from 'lodash-es';

@Component({
  selector: 'app-export-global-package-item-form',
  templateUrl: './export-global-package-item-form.component.html',
  styleUrls: ['./export-global-package-item-form.component.scss'],
})
export class ExportGlobalPackageItemFormComponent extends BaseForm<ExportGlobalPackageDto> implements OnInit {
  isLoadingTypes: boolean = false;
  listOfTypesToInclude = [];
  selectedTypedToInclude = [];
  modules = getEnumOptions(ExportGlobalPackageDto.ModuleEnum);

  constructor(
    public viewModeService: ViewModeService,
    private genericInfoService: GenericInfoDataService,
    private pathResolverService: PathResolverService
  ) {
    super(viewModeService, ModuleKeywords.ExportGlobalPackage);
  }

  ngOnInit(): void {
    // this.formGroup.controls.module.valueChanges.subscribe(module => {
    //   if (module) {
    this.isLoadingTypes = true;
    this.genericInfoService
      .getTypes({ showLoading: false, showMsg: false, extras: { cacheKey: 'generic-info-types' } })
      .subscribe((types) => {
        const typesList = getEnumOptions(TypesWithName).map((x) => x.value);
        this.listOfTypesToInclude = getOptionsFromStringArray(
          types?.data
            .filter((x) => {
              try {
                if (x.name.includes('REPORT_RESULT') || !x.exportable) return false;
                this.pathResolverService.getDataServiceByTargetType(x.name);
                return true;
              } catch (e) {
                return false;
              }
            })
            .map((x) => x.name)
        );
        this.isLoadingTypes = false;
      });
    // }
    // });
    this.formGroup.controls.typesToInclude.valueChanges.subscribe((types) => {
      this.selectedTypedToInclude = types;
      const codes = [];
      this.formGroup.controls?.codesToExport?.value?.forEach((element) => {
        const module = this.pathResolverService.getModuleKeywordByCode(element);
        if (types?.includes(module)) {
          codes.push(element);
        }
      });
      this.formGroup.controls.codesToExport.patchValue(codes);
    });
  }

  getData() {
    const types: { [x: string]: string[] } = {};
    this.formGroup?.getRawValue()?.codesToExport?.forEach((code) => {
      const mKey = this.pathResolverService.getModuleKeywordByCode(code);
      if (mKey) {
        types[mKey] = types[mKey] ? [...types[mKey], code] : [code];
      }
    });
    const ret = {
      ...omit(this.formGroup.getRawValue(), 'codesToExport'),
      filters: Object.entries(types).map(([key, value]) => {
        return {
          filter: {
            page: 0,
            size: 10,
            all: true,
            targetType: key as any,
            filter: { projectionFields: [], filters: [{ property: 'code', operation: 'IN', value: value }] },
          },
        };
      }),
    };
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat(ret)
      : this.getChangedFormValues(ret).updateItems;
  }

  setData(data: ExportGlobalPackageDto) {
    const codesToExport = [];
    data?.filters?.forEach((filter) => {
      if (!isNullObj(filter?.filter?.filter?.filters?.[0]?.value)) {
        codesToExport.push(...filter?.filter?.filter?.filters?.[0]?.value);
      }
    });
    this.formGroup.patchValue({ ...data, codesToExport: codesToExport });
    this.data = data;
  }
  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, [Validators.required, Validators.minLength(3)]),
      // module: new FormControl(null, Validators.required),
      typesToInclude: new FormControl([], [Validators.required, Validators.minLength(1)]),
      codesToExport: new FormControl([], [Validators.required, Validators.minLength(1)]),
      exportManualRelations: new FormControl(false),
      exportReferenceRelations: new FormControl(false),
      description: new FormControl(null),
      errorMessage: new FormControl(null),
    });
    this.formGroup.controls.codesToExport.valueChanges.subscribe((x) => {
      x;
    });
  }
}
