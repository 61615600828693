<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12 md:col-6">
      <app-basic-input
        [label]="moduleFieldString + '.name.label' | translate: 'Name'"
        [placeholder]="moduleFieldString + '.name.placeholder' | translate: 'Enter Name'"
        [control]="formGroup?.controls?.name"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>
    <div class="col-12 md:col-6">
      <app-target-code-selector
        [label]="moduleFieldString + '.category.label' | translate: 'Statement Category'"
        [placeholder]="moduleFieldString + '.category.placeholder' | translate: 'Search Risk Statement Categories'"
        [control]="formGroup?.controls?.category"
        [viewMode]="fieldViewMode"
        [multi]="false"
        [optionLabel]="'name'"
        [optionValue]="'code'"
        [targetTypes]="[riskStatementCategoryType]"
      >
      </app-target-code-selector>
    </div>
    <!-- <div class="col-12 md:col-4">
    <app-target-code-selector
      [label]="'Parent Statement'"
      [placeholder]="'Search Risk Statements'"
      [control]="formGroup?.controls?.parent"
      [viewMode]="fieldViewMode"
      [multi]="false"
      [optionLabel]="'name'"
      [optionValue]="'code'"
      [extraFilters]="parentFilter ? [parentFilter] : []"
      [targetTypes]="['RISK_STATEMENT']"
      >
    </app-target-code-selector>
  </div> -->
    <div class="col-12 md:col-6">
      <app-target-code-selector
        [label]="moduleFieldString + '.parent.label' | translate: 'Parent Statement'"
        [placeholder]="moduleFieldString + '.parent.placeholder' | translate: 'Search Risk Statements'"
        [control]="formGroup?.controls?.parent"
        [viewMode]="fieldViewMode"
        [multi]="false"
        [targetTypes]="['RISK_STATEMENT']"
        [extraFilters]="parentFilter ? [parentFilter] : []"
      >
      </app-target-code-selector>
    </div>
    <div class="col-12 md:col-12">
      <app-text-editor
        [height]="'100px'"
        [advanced]="true"
        [name]="'textarea'"
        [label]="moduleFieldString + '.description.label' | translate: 'Description'"
        [placeholder]="moduleFieldString + '.description.placeholder' | translate: 'Enter Text...'"
        [control]="formGroup?.controls?.description"
        [viewMode]="fieldViewMode"
      ></app-text-editor>
    </div>
    <p-divider class="w-full" align="left">
      <b>{{ moduleFieldString + '.threatsAndVulnerabilities.label' | translate: 'Threats & Vulnerabilities' }}</b>
    </p-divider>
    <div class="col-12 md:col-12">
      <app-target-code-selector
        [label]="moduleFieldString + '.riskThreats.label' | translate: 'Risk Threats'"
        [placeholder]="moduleFieldString + '.riskThreats.placeholder' | translate: 'Search Risk Threats'"
        [control]="formGroup?.controls?.threats"
        [viewMode]="fieldViewMode"
        [multi]="true"
        [optionLabel]="'name'"
        [optionValue]="'code'"
        [customProjectionFields]="['code', 'recordStatus', 'label', 'name']"
        [targetTypes]="['RISK_THREAT']"
      >
      </app-target-code-selector>
    </div>
    <div class="col-12 md:col-12">
      <app-target-code-selector
        [label]="moduleFieldString + '.riskVulnerabilities.label' | translate: 'Risk Vulnerabilities'"
        [placeholder]="
          moduleFieldString + '.riskVulnerabilities.placeholder' | translate: 'Search Risk Vulnerabilities'
        "
        [control]="formGroup?.controls?.vulnerabilities"
        [viewMode]="fieldViewMode"
        [multi]="true"
        [optionLabel]="'name'"
        [optionValue]="'code'"
        [customProjectionFields]="['code', 'recordStatus', 'label', 'name']"
        [targetTypes]="['RISK_VULNERABILITY']"
      >
      </app-target-code-selector>
    </div>
  </div>
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create') {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create') {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
