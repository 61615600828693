import { Component, Input, OnInit } from '@angular/core';
import { LocaleService } from '@core/services/locale/locale.service';
import { BaseViewItem, ChartViewConfig, DataTypeEnum, ModuleKeywordRootPath, ModuleKeywords, OfflineConfig, routeToLocaleCase, toCamelCase } from '@shared/classes';

@Component({
  selector: 'app-risk-acceptance-view',
  templateUrl: './risk-acceptance-view.component.html',
  styleUrl: './risk-acceptance-view.component.scss'
})
export class RiskAcceptanceViewComponent extends BaseViewItem implements OnInit {
  chartViewConfig = ChartViewConfig;
  myBadgeColors = [
    { key: 'ACCEPT', color: '#9CCC65' },
    { key: 'REJECT', color: '#EF5350' },
  ];
  parsedChartData = {};
  cols;
  offlineConfig: OfflineConfig = {
    lazy: false,
    paginator: true,
    showActionBar: false,
  };

  @Input() label;
  constructor(public localeService: LocaleService) {
    super();
  }

  ngOnInit(): void {
    this.cols = [
      {
        name: this.translateFields('user', 'User'),
        key: 'user',
        dataType: DataTypeEnum.Text,
        sortDisabled: true,
      },
      {
        name: this.translateFields('acceptDate', 'Accept Date'),
        key: 'acceptDate',
        dataType: DataTypeEnum.Date,
        sortDisabled: true,
      },
      {
        name: this.translateFields('acceptMode', 'Accept Mode'),
        key: 'acceptMode',
        dataType: DataTypeEnum.Badge,
      },
    ];
  }
  onSetData() {
    this.calculateChartData();
  }

  translateFields(key, fallback) {
    return this.localeService.translate(
      `modules.${routeToLocaleCase(ModuleKeywordRootPath[ModuleKeywords.RiskItem])}.${toCamelCase(ModuleKeywords.RiskItem)}.acceptedBy.` +
      key +
      '.label',
      fallback
    );
  }


  calculateChartData() {
    if (this.data?.length) {
      this.parsedChartData = {
        'ACCEPT': 0,
        'REJECT': 0,
        tooltip: {
          trigger: 'item',
          appendToBody: true,
          position: null,
          formatter: '{c}',
        },
      };
    }
    this.data?.forEach(x => {
      if (!this.parsedChartData?.[x?.acceptMode]) {
        this.parsedChartData[x?.acceptMode] = 1;
      } else {
        this.parsedChartData[x?.acceptMode] = this.parsedChartData[x?.acceptMode] + 1;
      }
    })
  }


}
