import { Component, Input, OnInit } from '@angular/core';
import { AggregationField, fixAggregateId, GroupingField, isValidCode } from '@shared/classes';
import { AggregateDataOptions } from '@shared/classes/view/echart-helpers/echart-base';
import {
  getAggregationFieldIdKey,
  getGroupFieldIdKey,
  humanizeCasedString,
} from '@shared/classes/view/echart-helpers/helpers/general-functions';

@Component({
  selector: 'app-statistics-table',
  templateUrl: './statistics-table.component.html',
  styleUrl: './statistics-table.component.scss',
})
export class StatisticsTableComponent implements OnInit {
  private _options: AggregateDataOptions;
  @Input() set options(options: AggregateDataOptions) {
    this._options = {
      ...options,
      response: fixAggregateId(
        options?.payload?.groupByFields?.map((x) => x.fieldName),
        options?.response
      ),
    };
    this.onSetOptions();
  }
  get options() {
    return this._options;
  }
  private _groupByFields: GroupingField[] = [];
  public get groupByFields(): GroupingField[] {
    return this._groupByFields;
  }
  public set groupByFields(value: GroupingField[]) {
    this._groupByFields = value;
    this.groupByFieldsKeys = value?.map((x) => getGroupFieldIdKey(x)) || [];
  }
  groupByFieldsKeys: string[] = [];
  displayFields: { key: string; name: string }[] = [];
  groupedData: any[] = [];
  flatTree: any[] = [];
  rows: any[] = [];
  ngOnInit() {}
  clearData() {
    this.groupByFields = [];
    this.displayFields = [];
    this.groupedData = [];
    this.flatTree = [];
    this.rows = [];
  }
  onSetOptions() {
    if (this.options?.payload && this.options?.response) {
      this.clearData();
      this.groupByFields = this.options?.payload?.groupByFields;
      this.displayFields = this.getDisplayFields(this.options?.payload?.aggregationFields);
      this.initData();
    } else {
      this.clearData();
    }
  }
  initData() {
    this.groupedData = groupData(this.options?.response?.aggregation_value, this.groupByFields);

    this.flatTree = this.getRows(this.groupedData);
    this.rows = this.formatRowsToPrimeTable(this.flatTree, this.groupByFields);
  }
  getDisplayFields(aggregationFields: AggregationField[]) {
    const fields = [...aggregationFields];
    // Filter out all 'COUNT' operations
    // const nonCountFields = fields.filter((field) => field.operation !== AggregationField.OperationEnum.Count);

    // Find the first 'COUNT' operation if it exists
    // const countField = fields.find((field) => field.operation === AggregationField.OperationEnum.Count);

    // If a 'COUNT' operation exists, add it to the result
    // if (countField) {
    //   nonCountFields.push(countField);
    // }

    return fields?.map((x) => {
      return {
        key: getAggregationFieldIdKey(x),
        name: humanizeCasedString(getAggregationFieldIdKey(x)),
      };
    });
  }
  // Recursive function to flatten the tree structure for table rendering
  getRows(treeNode: StatisticTableNode[]) {
    const rows = [];
    treeNode.forEach((node, index) => {
      for (let i = 0; i < node?.rowspan; i++) {
        rows.push([{ key: node?.key, rowspan: i == 0 ? node?.rowspan : 0 }]);
      }
      traverse(node, rows.length - node?.rowspan);
    });
    function traverse(node: StatisticTableNode, start: number) {
      if (node?.children?.length > 0) {
        let currentRowSpanOffset = 0;
        node.children.forEach((child, i) => {
          for (let j = 0; j < child?.rowspan; j++) {
            rows[start + currentRowSpanOffset + j].push({
              key: child?.key,
              rowspan: j == 0 ? child?.rowspan : 0,
            });
          }
          if (child?.rowspan === null || child?.rowspan === undefined) {
            // aggregation values are stored as children [{...values}] in last depth and have no rowspan
            rows[start + currentRowSpanOffset][rows[start + currentRowSpanOffset]?.length - 1].data = child;
          }
          traverse(child, start + currentRowSpanOffset);
          currentRowSpanOffset += child.rowspan;
        });
      }
    }
    return rows;
  }
  formatRowsToPrimeTable(rows: Rows, groupByFields = this.groupByFields) {
    const retRows = [];
    rows.forEach((row, i) => {
      const objRow = {};
      groupByFields.forEach((col, j) => {
        objRow[getGroupFieldIdKey(col)] = row?.[j]?.key;
        objRow[getGroupFieldIdKey(col) + '_rowspan'] = row?.[j]?.rowspan;
        objRow[getGroupFieldIdKey(col) + '_data'] = row?.[j]?.data;
      });
      retRows.push({ ...objRow });
    });
    return retRows;
  }
  isValidCode(code) {
    return isValidCode(code);
  }
}
// interface AggregateDataOptions {
//   response: { aggregation_value: AggregationValue[] };
//   payload: AggregationBody;
// }
// interface InitOptions {
//   viewCardItem: ViewCardItem;
//   aggregationResult: AggregateDataOptions;
// }
type Row = { [column: string]: { key: string; rowspan: number; data?: any } };
type Rows = Row[];

interface StatisticTableNode {
  key: string;
  rowspan: number;
  children: StatisticTableNode[];
}
interface AggregationValue {
  group_id: { [key: string]: any };
  [key: string]: any;
}
function groupData(data: AggregationValue[], fields: GroupingField[]): any[] {
  const grouped = [];

  data.forEach((item) => {
    let currentLevel = grouped;

    fields.forEach((field) => {
      const key = item?.group_id?.[getGroupFieldIdKey(field)] || 'EMPTY_VALUE';
      let group = currentLevel.find((g) => g.key === key);

      if (!group) {
        group = { key, children: [], rows: [], rowspan: 0 };
        currentLevel.push(group);
      }
      //increase rowspan per item match
      group.rowspan++;
      // group.value += item?.[field?.fieldName + '_count'] || 0; // if this is needed we should add a deciding factor to know which aggregation type we should take into account
      //proceed into inner level in order to find the final assign location for the item
      currentLevel = group.children;
    });

    // Add the item to the last level
    currentLevel.push(item);
  });

  return grouped;
}
