import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Optional, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LocaleService } from '@core/services/locale/locale.service';
import {
  AuthorityDocument,
  BaseTablePage,
  ButtonColor,
  Citation,
  DataTypeEnum,
  IAction,
  MessageKeys,
  ModuleKeywords,
  ModuleRoutePrefix,
  PermissionActions,
  PolicyBodySectionDto,
  PolicyDto,
  RecordStatusEnumFilterWithoutDeleted,
  UpdateItem,
  arrayToTree,
  getEnumOptions,
} from '@shared/classes';
import { BaseFormPopupComponent } from '@shared/components/misc/base-form-popup/base-form-popup.component';
import { UserSelectorComponent } from '@shared/components/selectors/user-selector/user-selector.component';
import {
  AppDialogService,
  ExportDataService,
  IConfirmationConfig,
  LoaderService,
  ToastService,
} from '@shared/services';
import { PolicyBodySectionsMappingService } from '@shared/services/mappings/framework/policy-body-sections-mapping.service';
import { ControlDataService } from 'app/modules/org-framework/service/data/ControlDataService';
import { PolicyBodyDataService } from 'app/modules/org-framework/service/data/policy-body-data.service';
import { PolicyBodySectionDataService } from 'app/modules/org-framework/service/data/policy-body-section-data.service';
import { PolicyBodyTemplateDataService } from 'app/modules/org-framework/service/data/policy-body-template-data.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { groupBy, sortBy } from 'lodash-es';
import { TreeDragDropService, TreeNode } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { PolicyBodyTemplateSelectorComponent } from '../../../policy-body-template/policy-body-template-selector/policy-body-template-selector.component';
import { PolicyBodySectionItemFormComponent } from '../policy-body-section-item-form/policy-body-section-item-form.component';

@Component({
  selector: 'app-policy-body-section-inline-tree',
  templateUrl: './policy-body-section-inline-tree.component.html',
  styleUrls: ['./policy-body-section-inline-tree.component.scss'],
})
export class PolicyBodySectionInlineTreeComponent extends BaseTablePage<PolicyBodySectionDto> implements OnInit {
  @Output() relinkSubmit: EventEmitter<any> = new EventEmitter();
  @Input() set showActions(val: boolean) {
    setTimeout(() => {
      this._showActions = val;
      this.cdr.detectChanges();
    });
  }
  private _showActions: boolean = true;
  get showActions() { return this._showActions; }
  @Input() showTitle: boolean = true;
  @Input() showCard: boolean = true;
  @Input() showExpandButton: boolean = true;
  @Input() startExpanded: boolean = false;
  @Input() showPrintAction: boolean = false;
  @Input() itemId: string = null;
  @Input() policy: string = null;
  @Input() policyData: PolicyDto = null;
  @Input() previousPublishedBodyCode: string = null;
  @Input() currentBodySections: PolicyBodySectionDto[] = [];
  private _data: PolicyBodySectionDto[] = [];
  get data(): PolicyBodySectionDto[] {
    return this._data;
  }
  @Input() set data(data: PolicyBodySectionDto[]) {
    this._data = !data ? [] : data;
    this.loadData();
  }
  private _showRelink: boolean = false;
  get showRelink(): boolean {
    return this._showRelink;
  }
  @Input() set showRelink(showRelink: boolean) {
    this._showRelink = showRelink;
    if (showRelink) {
      this.getLinkedPolicyBodySections();
    }
  }
  // authID: string = null;
  // authUID: string = null;
  nodes: TreeNode[];
  selectedFile: TreeNode | TreeNode[] = [];
  authDoc: AuthorityDocument = null;
  groupedNodes: any;
  tempNodes: TreeNode[];
  formattedNodes: TreeNode[];
  visited: any = {};
  badgesToggled = true;
  listOfStatuses = getEnumOptions(CitationStatusFilter.RecordStatusEnum);
  statusFilter: FormControl = new FormControl(null);
  addCitationAction: IAction = {
    id: 1,
    label: 'Add',
    buttonType: 'button',
    command: this.onOpenAddDialog.bind(this),
    icon: 'pi pi-plus',
    color: ButtonColor.Primary,
    iconPos: 'left',
    permission: PermissionActions.Create + ModuleKeywords.PolicyBodySection,
  };
  expandAllAction: IAction = {
    id: 2,
    label: 'Expand All',
    buttonType: 'button',
    command: this.expandAll.bind(this),
    icon: 'pi pi-chevron-down',
    color: ButtonColor.Primary,
    iconPos: 'left',
    buttonStyle: 'outlined',
  };
  collapseAllAction: IAction = {
    id: 3,
    label: 'Collapse All',
    buttonType: 'button',
    command: this.collapseAll.bind(this),
    icon: 'pi pi-chevron-up',
    color: ButtonColor.Primary,
    iconPos: 'left',
    buttonStyle: 'outlined',
  };
  editNodeAction: IAction = {
    id: 4,
    label: '',
    buttonStyle: 'text',
    buttonType: 'button',
    command: this.onRowEdit.bind(this),
    icon: 'pi pi-pencil',
    color: ButtonColor.Primary,
    iconPos: 'left',
    permission: PermissionActions.Update + ModuleKeywords.PolicyBodySection,
  };
  saveEditNodeAction: IAction = {
    id: 5,
    label: '',
    buttonStyle: 'text',
    buttonType: 'button',
    command: this.onRowEditSave.bind(this),
    icon: 'pi pi-check',
    color: ButtonColor.Success,
    iconPos: 'left',
  };
  cancelEditNodeAction: IAction = {
    id: 6,
    label: '',
    buttonStyle: 'text',
    buttonType: 'button',
    command: this.onRowEditCancel.bind(this),
    icon: 'pi pi-times',
    color: ButtonColor.Danger,
    iconPos: 'left',
  };
  addNodeAction: IAction = {
    id: 7,
    label: '',
    buttonStyle: 'text',
    buttonType: 'button',
    command: this.onRowAddChild.bind(this),
    icon: 'pi pi-plus',
    color: ButtonColor.Primary,
    iconPos: 'left',
    permission: PermissionActions.Create + ModuleKeywords.PolicyBodySection,
  };
  deleteNodeAction: IAction = {
    id: 8,
    label: '',
    buttonStyle: 'text',
    buttonType: 'button',
    command: this.onRowDelete.bind(this),
    icon: 'pi pi-trash',
    color: ButtonColor.Danger,
    iconPos: 'left',
    permission: PermissionActions.Delete + ModuleKeywords.PolicyBodySection,
  };
  fixOrdersAction: IAction = {
    id: 9,
    label: 'Fix Backend Ordering',
    buttonType: 'button',
    command: this.fixOrdering.bind(this),
    icon: 'pi pi-check',
    color: ButtonColor.Primary,
    iconPos: 'left',
    permission: PermissionActions.Update + ModuleKeywords.PolicyBodySection,
  };
  toggleBadgesAction: IAction = {
    id: 10,
    label: 'Toggle Badges',
    buttonType: 'button',
    command: this.toggleBadges.bind(this),
    icon: 'pi pi-eye',
    color: ButtonColor.Primary,
    iconPos: 'left',
  };
  relinkButtonAction: IAction = {
    id: 12,
    label: 'Relink',
    buttonType: 'button',
    command: this.onRelinkControls.bind(this),
    icon: 'pi pi-link',
  };
  printPdfAction: IAction = {
    id: 13,
    label: 'Export',
    buttonType: 'button',
    command: this.printPdf.bind(this),
    icon: 'pi pi-angle-right',
  };
  exportTreePdf: IAction = {
    id: 14,
    icon: 'pi pi-file',
    color: 'secondary',
    command: this.downloadTreePdf.bind(this),
    label: 'Print Tree as Pdf',
  };
  applyTemplate: IAction = {
    id: 15,
    label: 'Import Template',
    buttonType: 'button',
    command: this.onApplyTemplate.bind(this),
    icon: 'pi pi-link',
  };
  fromPopup = false;
  expandedState = false;
  textType: DataTypeEnum.Text = DataTypeEnum.Text;
  constructor(
    public requestService: PolicyBodySectionDataService,
    public mappingService: PolicyBodySectionsMappingService,
    public policyBodyTemplateService: PolicyBodyTemplateDataService,
    private controlRequestService: ControlDataService,
    private policyBodyRequestService: PolicyBodyDataService,
    exportService: ExportDataService,
    appDialogService: AppDialogService,
    router: Router,
    private route: ActivatedRoute,
    public loaderService: LoaderService,
    public dragService: TreeDragDropService,
    private toastService: ToastService,
    public localeService: LocaleService,
    private cdr: ChangeDetectorRef,
    @Optional() public config?: DynamicDialogConfig
  ) {
    super(
      requestService,
      exportService,
      appDialogService,
      router,
      {
        moduleKeyword: ModuleKeywords.Policy,
        routePrefix: ModuleRoutePrefix.Framework,
      },
      localeService
    );

    this.fromPopup = this?.config?.data?.fromPopup ?? this.fromPopup;
    this.itemId = this?.config?.data?.itemId ?? this.itemId;
    this.policy = this?.config?.data?.policy ?? this.policy;
    this.policyData = this?.config?.data?.policyData ?? this.policyData;
    this.previousPublishedBodyCode = this?.config?.data?.previousPublishedBodyCode ?? this.previousPublishedBodyCode;
    this.data = this?.config?.data?.data ?? this.data;
    this.showActions = this?.config?.data?.showActions ?? this.showActions;

    this.subs.sink = this.route.params.subscribe({
      next: (params) => {
        // this.authID = params['id'];
        // this.authUID = params['policy'];
        // this.SetPageValues(
        //     {
        //         breadCrumb: {
        //             items: [
        //                 {
        //                     label: "Policy",
        //                     routerLink: [`${this.routePrefix ? this.routePrefix : toKebabCase(this.moduleKeyword)}`],
        //                 },
        //                 {
        //                     label: "Policy Body",
        //                     routerLink: [`/${toKebabCase(ModuleKeywords.PolicyBodySection)}/list`],
        //                 },
        //                 {
        //                     label: this.authID,//shortenString(this.authUID),
        //                     routerLink: [
        //                         `/${toKebabCase(ModuleKeywords.PolicyBodySection)}/view/${this.authID}`
        //                     ],
        //                     // tooltipOptions: {
        //                     //     tooltipLabel: this.authUID
        //                     // },
        //                 },
        //                 {
        //                     label: "Policy Body Sections",
        //                     routerLink: [`/${this.routePrefix}${toKebabCase(this.moduleKeyword)}/list/${this.authID}`],
        //                 },
        //             ],
        //         }
        //     }
        // );
      },
    });
    this.subs.sink = this.statusFilter.valueChanges.subscribe((res) => {
      this.loadData(res);
    });
  }

  ngOnInit(): void {
    this.nodes = [];
    // this.loadData();
    // this.getLinkedPolicyBodySections();
  }
  isLoading = true;
  loadData(status = null) {
    this.isLoading = true;
    this.formatData(status ? this.data.filter((d) => d.recordStatus === status) : this.data);
    this.isLoading = false;
    // let citationProjectionFields = ["id", "code", "name", "title", "policyBody", "parent", "description", "notes", "status", "citationType","recordStatus","displayOrder","body","subject"]
    // let citationFilters: any[] = [...this.tableEvent.filters, { "property": "policyBody", "operation": "EQUAL", "value": this.itemId }]
    // if (status) citationFilters.push({ "property": "recordStatus", "operation": "EQUAL", "value": status })
    // this.subs.sink = this.requestService
    //     .search<Citation>(
    //         { ...this.tableEvent.pageInfo.pagination },
    //         { projectionFields: citationProjectionFields, filters: citationFilters },
    //         { showLoading: true, showMsg: false, params: { all: true } }
    //     )
    //     .subscribe({
    //         next: (res: any) => {
    //             this.tableData = res;
    //             // this.tableData = sortBy(this.tableData,['displayOrder']);
    //             this.groupedNodes = groupBy(this.tableData, 'parent');
    //             this.visited = {};
    //             this.formatDataForTreeViewRecursive(this.tableData)
    //         },
    //         error: (error) => {

    //         },
    //         complete: () => { },
    //     });
    // if (this.authDoc) {
    // } else {
    //     this.subs.sink = this.authorityRequestService.getByIdOrCode<AuthorityDocument>(this.authID).subscribe({
    //         next: (res) => {
    //             this.authDoc = res.data;
    //             let citationFilters: any[] = [...this.tableEvent.filters, { "property": "authorityDocument", "operation": "EQUAL", "value": this.authDoc.id }]
    //             if (status) citationFilters.push({ "property": "status", "operation": "EQUAL", "value": status })
    //             this.subs.sink = this.requestService
    //                 .search<Citation>(
    //                     { ...this.tableEvent.pageInfo.pagination },
    //                     { projectionFields: citationProjectionFields, filters: citationFilters },
    //                     { showLoading: true, showMsg: false, params: { all: true } }
    //                 )
    //                 .subscribe({
    //                     next: (res: any) => {

    //                         this.tableData = res;
    //                         this.groupedNodes = groupBy(this.tableData, 'parent.id');
    //                         this.visited = {};
    //                         this.formatDataForTreeViewRecursive(this.tableData)
    //                     },
    //                     error: (error) => {

    //                     },
    //                     complete: () => { },
    //                 });
    //         }
    //     })
    // }
  }
  formatData(data: PolicyBodySectionDto[]) {
    if (!data) data = [];
    this.tableData = [...data];
    this.tableData = sortBy(this.tableData, ['displayOrder']);
    this.groupedNodes = groupBy(this.tableData, 'parent');
    this.visited = {};
    this.expandedState = !!this.startExpanded;
    this.formatDataForTreeViewRecursive(this.tableData);
  }
  setCols() {
    this.cols = [
      {
        name: 'Id',
        key: 'code',
        dataType: DataTypeEnum.CodeWithStatus,
        passRowAsData: true,
        filter: {
          type: 'text',
          matchMode: 'startsWith',
        },
        // frozen: true,
        alignFrozen: 'left',
      },
      {
        name: 'Record Status',
        key: 'recordStatus',
        dataType: DataTypeEnum.Badge,
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: RecordStatusEnumFilterWithoutDeleted,
        },
      },
      {
        name: 'Name',
        key: 'name',
        dataType: DataTypeEnum.Text,
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      {
        name: 'Creator Name',
        key: 'creatorName',
        dataType: DataTypeEnum.UserListView,
        filter: {
          type: 'multiDropdown',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          dynamicInput: {
            componentType: UserSelectorComponent,
            options: {
              label: '',
              name: '',
              control: new FormControl(null),
            },
          },
        },
      },
      {
        name: 'Creation Date',
        key: 'creationDate',
        dataType: DataTypeEnum.DateLong,
        filter: {
          type: 'date',
          matchMode: 'dateBefore',
        },
      },
      {
        name: 'Last Modifier',
        key: 'lastModifierName',
        dataType: DataTypeEnum.UserListView,
        filter: {
          type: 'multiDropdown',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          dynamicInput: {
            componentType: UserSelectorComponent,
            options: {
              label: '',
              name: '',
              control: new FormControl(null),
            },
          },
        },
      },
      {
        name: 'Last Modification Date',
        key: 'lastModificationDate',
        dataType: DataTypeEnum.DateLong,
        filter: {
          type: 'date',
          matchMode: 'dateBefore',
        },
      },
    ];
  }
  addWithParent(row: Citation) {
    // this.router.navigateByUrl(`${this.routePrefix}${toKebabCase(this.moduleKeyword)}/create/${this.authID}/${(row as any).id}`);
  }
  editItem(row: Citation): any {
    // this.router.navigateByUrl(`${this.routePrefix}${toKebabCase(this.moduleKeyword)}/edit/${(row as any).code || (row as any).id}/${this.authID}/${this.authUID}`);
  }
  viewItem(row: Citation): any {
    // this.router.navigateByUrl(`${this.routePrefix}${toKebabCase(this.moduleKeyword)}/view/${(row as any).code || (row as any).id}/${this.authID}/${this.authUID}`);
  }
  addNewItem(): any {
    // this.router.navigateByUrl(`${this.routePrefix}${toKebabCase(this.moduleKeyword)}/create/${this.authID}`);
  }
  downloadTreePdf() {
    this.appDialogService.showDialog(
      PolicyBodySectionInlineTreeComponent,
      'Export Policy Body Section Tree',
      (e) => {},
      {
        data: {
          itemId: this.itemId,
          policy: this.policy,
          policyData: this.policyData,
          previousPublishedBodyCode: this.previousPublishedBodyCode,
          data: this.data,
          showActions: false,
          fromPopup: true,
        },
      }
    );
  }
  formatDataForTreeViewRecursive(data: PolicyBodySectionDto[]) {
    this.formattedNodes = [];
    this.groupedNodes['null']?.forEach((el, index) => {
      if (!!!this.visited[el.code]) {
        this.visited[el.code] = true;
        this.formattedNodes.push(this.getFormattedChildren(el.code, el, index + 1 + '.'));
      }
    });
    this.tempNodes = [...this.formattedNodes];
  }
  getFormattedChildren(code, data, idx, level = 0): TreeNode {
    let node = {
      label: data.name,
      data: { ...data, index: idx, editMode: 'view', level: level },
      //   expandedIcon: "pi pi-folder-open",
      //   collapsedIcon: "pi pi-folder",
      //   icon: 'pi pi-file',
      children: [],
      expanded: this.fromPopup ? true : this.startExpanded,
      selectable: false,
      leaf: false,
    };
    if (!!this.groupedNodes[code]) {
      this.groupedNodes[code].forEach((el, index) => {
        if (!!!this.visited[el.code]) {
          this.visited[el.code] = true;
          let ret = this.getFormattedChildren(el.code, el, idx + (index + 1 + '.'), level + 1);
          node.children.push(ret);
        } else {
        }
      });
    } else {
      if (!data.body) {
        node.leaf = true;
      }
      return node;
    }
    if (!node?.children?.length && !data.body) {
      node.leaf = true;
    }
    return node;
  }

  fixNodeIndiciesRecursive(list, index, level = 0) {
    list = list.filter((x) => x.data.recordStatus != 'DELETED');

    list.forEach((el, idx) => {
      const newIndex = index + (idx + 1 + '.');
      list[idx].data.index = newIndex;
      list[idx].data.level = level;
      if (list[idx].children?.length)
        list[idx].children = this.fixNodeIndiciesRecursive(list[idx].children, newIndex, level + 1);
      else if (!list[idx]?.data?.body) list[idx].leaf = true;
    });
    return list;
  }

  expandAll() {
    this.formattedNodes.forEach((node, index) => {
      setTimeout(() => {
        this.expandRecursive(node, true);
      }, 5);
    });
    this.expandedState = true;
  }

  collapseAll() {
    this.formattedNodes.forEach((node, index) => {
      setTimeout(() => {
        this.expandRecursive(node, false);
      }, 5);
    });
    this.expandedState = false;
  }

  private expandRecursive(node: TreeNode, isExpand: boolean) {
    node.expanded = isExpand;
    if (node.children) {
      node.children.forEach((childNode) => {
        this.expandRecursive(childNode, isExpand);
      });
    }
  }
  rowCopy;
  onRowEdit(rowData: TreeNode) {
    this.rowCopy = rowData;
    let node = this.findNodeByCode(this.formattedNodes, rowData.data.code);
    node.draggable = false;
    node.data.editMode = 'edit';
    // Do something with the edited row data
  }
  onRowAddChild(rowData: TreeNode) {
    this.rowCopy = rowData;
    let node = this.findNodeByCode(this.formattedNodes, rowData.data.code);
    node.draggable = false;
    node.data.editMode = 'create';
    // Do something with the edited row data
  }
  onRowDelete(rowData: TreeNode) {
    let config = new IConfirmationConfig();
    config.message = 'Are you sure you want to delete this policy section?';
    this.appDialogService.confirm(
      {
        accept: () => {
          this.requestService.delete(rowData?.data?.code).subscribe((res) => {
            let node = this.findNodeByCode(this.formattedNodes, rowData.data.code);
            node.data.recordStatus = 'DELETED';
            this.formattedNodes = this.fixNodeIndiciesRecursive(this.formattedNodes, '');
            this.tempNodes = [...this.formattedNodes];
          });
        },
      },
      config
    );
  }
  onRowEditSave(data, code) {
    let node = this.findNodeByCode(this.formattedNodes, code);
    node.draggable = true;
    if (node?.data?.editMode == 'edit') {
      this.subs.sink = this.requestService.patchSingleUpdate(data, node?.data?.code).subscribe((res) => {
        (data as Array<UpdateItem>).forEach((element) => {
          node.data[element.key] = element.value;
        });
        node.data = { ...node?.data };
        this.formattedNodes = this.fixNodeIndiciesRecursive(this.formattedNodes, '');
        node.data.editMode = 'view';
      });
    } else {
      data?.createItems?.push({ key: 'policyBody', value: this.itemId });
      data?.createItems?.push({ key: 'displayOrder', value: (node?.children?.length ?? 0) + 1 });
      this.subs.sink = this.requestService.create(data).subscribe((res) => {
        let childData = res.data as any;
        // (data.createItems as Array<UpdateItem>).forEach(element => {
        //     childData[element.key] = element.value;
        // });
        let childNode = {
          label: data.name,
          data: { ...childData, editMode: 'view' },
          //   expandedIcon: "pi pi-folder-open",
          //   collapsedIcon: "pi pi-folder",
          //   icon: 'pi pi-file',
          children: [],
          expanded: this.fromPopup ? true : false,
          selectable: false,
          leaf: false,
        };
        node?.children?.push(childNode);
        node.data.editMode = 'view';
        this.fixNodeIndiciesRecursive(this.formattedNodes, '');
      });
    }
    // node = {...data,data:{...data.data,editMode:false}};
  }
  onRowEditCancel(data, code) {
    let node = this.findNodeByCode(this.formattedNodes, data?.data?.code ?? code);
    node.data.editMode = 'view';
  }
  onDrop(event) {
    let config = new IConfirmationConfig();
    config.message = 'Are you sure you want to move this policy section?';
    this.appDialogService.confirm(
      {
        accept: () => {
          let node = this.getParentNode(this.formattedNodes, event.dragNode?.data?.code);
          let list = node ? [...node.children] : [...this.tempNodes];
          let newOrder = 0;
          if (node?.data?.code && event.dropNode?.data?.code == node?.data?.code) {
            // if the dropped place is a node we want to
            newOrder = list.length && list.length - 1 ? list[list.length - 1].data?.displayOrder + 1 : 1;
          } else if (event.index <= 0) {
            newOrder = list[node ? 1 : event.index].data?.displayOrder / 2;
          } else if (event.index >= list.length - 1) {
            newOrder = list[node ? list.length - 2 : list.length - 1].data?.displayOrder + 1;
          } else {
            newOrder =
              (list[event.index - 1].data?.displayOrder +
                list[node ? event.index + 1 : event.index].data?.displayOrder) /
              2;
          }
          this.subs.sink = this.requestService
            .patchSingleUpdate(
              [
                { key: 'displayOrder', value: newOrder as any },
                { key: 'parent', value: node?.data?.code },
              ],
              event.dragNode?.data?.code
            )
            .subscribe(
              (res) => {
                let draggedNode = this.findNodeByCode(this.formattedNodes, event.dragNode?.data?.code);
                if (draggedNode) {
                  draggedNode.data.displayOrder = newOrder;
                  draggedNode.data.parent = node?.data?.code;
                  this.fixNodeIndiciesRecursive(this.formattedNodes, '');
                }
                this.tempNodes = [...this.formattedNodes];
              },
              (error) => {
                this.formattedNodes = [...this.tempNodes];
              }
            );
        },
        cancel: () => {
          this.formattedNodes = [...this.tempNodes];
        },
        reject: () => {
          this.formattedNodes = [...this.tempNodes];
        },
      },
      config
    );
  }
  findNodeByCode(node: TreeNode[], searchCode: string): TreeNode {
    for (let i = 0; i < node.length; i++) {
      if (node[i].data && node[i].data.code === searchCode) {
        return node[i];
      }
      if (node[i].children) {
        const result = this.findNodeByCode(node[i].children, searchCode);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }
  getParentNode(nodes: TreeNode[], code: string, parent: TreeNode = null): TreeNode {
    for (let node of nodes) {
      if (node.children) {
        // Recursively check child nodes
        let parentNode = this.getParentNode(node.children, code, node);
        if (parentNode) {
          return parentNode;
        }
      }
      // Check if node's code matches input code
      if (node.data?.code === code) {
        return parent;
      }
    }
    // No match found
    return null;
  }
  onOpenAddDialog() {
    this.appDialogService.showDialog(
      BaseFormPopupComponent,
      'Add Policy Body Section',
      (data) => {
        if (data) {
          data.push({ key: 'policyBody', value: this.itemId });
          data.push({ key: 'displayOrder', value: (this.formattedNodes?.length ?? 0) + 1 });
          let resObj: any = {};
          data.forEach((element) => {
            resObj[element.key] = element.value;
          });
          // this.formGroup.patchValue({ ...resObj });
          this.subs.sink = this.requestService.create({ createItems: data }).subscribe((res) => {
            let childData: any = { ...res?.data, code: (res?.data as any)?.code, policyBody: this.itemId };
            (data as Array<UpdateItem>).forEach((element) => {
              childData[element.key] = element.value;
            });
            let childNode = {
              label: data.name,
              data: { ...childData, editMode: 'view' },
              //   expandedIcon: "pi pi-folder-open",
              //   collapsedIcon: "pi pi-folder",
              //   icon: 'pi pi-file',
              children: [],
              expanded: this.fromPopup ? true : false,
              selectable: false,
              leaf: false,
            };
            if (resObj?.parent) {
              let node = this.findNodeByCode(this.formattedNodes, resObj?.parent);
              if (node) {
                node?.children?.push(childNode);
                node.data.editMode = 'view';
              }
            } else {
              this.formattedNodes.push(childNode); // requested to be added at bottom
            }
            this.fixNodeIndiciesRecursive(this.formattedNodes, '');
          });
        }
      },
      {
        data: {
          dynamicViewComponent: PolicyBodySectionItemFormComponent,
          dataService: this.requestService,
          filters: [],
          selectedRows: [],
          patchData: false,
          formData: { create: true },
        },
      }
    );
  }
  linkedSections: PolicyBodySectionDto[] = [];
  treeLinkedSections: TreeNode[] = [];
  linkedSectionsForm: FormGroup;
  getLinkedPolicyBodySections() {
    // this.controlRequestService.search({all:true},{
    //     projectionFields:['policySection'],filters:[
    //         { "property": "policy", "operation": "EQUAL", "value": this.policy },
    //         // { "property": "policySection", "operation": "isnotnull" },
    //     ]
    // }).subscribe((res:any)=>{

    // const codeArray = res.filter(x=> !!x.policySection && (this?.data?.findIndex(d=> d?.code == x?.policySection) == -1)).map(x=> x.policySection);
    this.reqService
      .search(
        { all: true },
        {
          projectionFields: ['code', 'parent', 'subject', 'recordStatus'],
          filters: [{ property: 'policyBody', operation: 'EQUAL', value: this.previousPublishedBodyCode }],
        }
      )
      .subscribe((res2: any) => {
        this.data = res2;
        this.linkedSections = [...this.currentBodySections];
        const { tree, linkedMap } = arrayToTree(this.linkedSections, true, true, null, 'subject');
        this.treeLinkedSections = tree;

        const a = this.linkedSections.map((x) => {
          return { [x.code]: new FormControl(null) };
        });
        const b: {
          [x: string]: FormControl<any>;
        } = {};
        const patchVal = {};
        this.data.forEach((element) => {
          const controlLink = this.linkedSections?.find((x) => x?.subject == element?.subject);
          b[element.code] = new FormControl(null);
          patchVal[element.code] = controlLink ? linkedMap.get(controlLink?.code) : null;
        });
        this.linkedSectionsForm = new FormGroup({
          ...b,
        });
        setTimeout(() => {
          this.linkedSectionsForm.patchValue(patchVal);
        }, 50);
      });
    // })
  }

  onApplyTemplate() {
    this.appDialogService.showDialog(
      BaseFormPopupComponent,
      'Import Policy Body Template',
      (data) => {
        if (data) {
          let resObj: any = {};
          data.forEach((element) => {
            resObj[element.key] = element.value;
          });
          this.policyBodyRequestService.applyTemplate(this.itemId, resObj?.code).subscribe((x) => {
            if (x) {
              this.data = x.data?.sections;
            }
          });
        }
      },
      {
        data: {
          dynamicViewComponent: PolicyBodyTemplateSelectorComponent,
          dataService: this.policyBodyTemplateService,
          filters: [],
          selectedRows: [],
          patchData: false,
          formData: null,
        },
      }
    );
  }

  onRelinkControls() {
    const tempBody = this.linkedSectionsForm.getRawValue();
    const reverseBody = {};
    const body = {};
    Object.keys(tempBody).forEach((key) => {
      if (tempBody?.[key]?.data?.code) reverseBody[tempBody?.[key]?.data?.code] = key;
      body[key] = tempBody?.[key]?.data?.code;
    });

    this.relinkSubmit.emit(body);
  }

  toggleBadges() {
    this.badgesToggled = !this.badgesToggled;
    this.toggleBadgesAction = {
      id: 10,
      label: 'Toggle Badges',
      buttonType: 'button',
      command: this.toggleBadges.bind(this),
      icon: this.badgesToggled ? 'pi pi-eye' : 'pi pi-eye-slash',
      color: this.badgesToggled ? ButtonColor.Primary : ButtonColor.Secondary,
      iconPos: 'left',
    };
  }

  fixOrdering() {
    this.fixBackendOrdering(this.formattedNodes);
  }

  async fixBackendOrdering(list) {
    await list.forEach(async (el, idx) => {
      this.subs.sink = await this.requestService
        .patchSingleUpdate([{ key: 'displayOrder', value: (idx + 1) as any }], list[idx].data.code)
        .subscribe(
          (res) => {
            this.toastService.success(MessageKeys.success, 'Order Updated');
          },
          (error) => {
            this.toastService.error(MessageKeys.errorHappened);
          },
          () => {
            if (list[idx].children?.length) this.fixBackendOrdering(list[idx].children);
          }
        );
    });
  }

  applyTemporaryStyles(element: HTMLElement, styles: { [key: string]: string }) {
    const originalStyles: { [key: string]: string } = {};
    for (const [key, value] of Object.entries(styles)) {
      originalStyles[key] = element.style[key as any];
      element.style[key as any] = value;
    }
    return originalStyles;
  }

  resetStyles(element: HTMLElement, originalStyles: { [key: string]: string }) {
    for (const [key, value] of Object.entries(originalStyles)) {
      element.style[key as any] = value;
    }
  }

  printPdf() {
    const doc = new jsPDF();
    const content = document.getElementById('tree');

    if (!content) {
      console.error('Element with ID "tree" not found.');
      return;
    }

    // Convert HTMLCollection to an array
    const pTags = Array.from(content.getElementsByTagName('p'));

    // Convert HTMLCollection to an array
    const h2Tags = Array.from(content.getElementsByTagName('h2'));

    const originalPStyles = pTags.map((p) =>
      this.applyTemporaryStyles(p, {
        color: 'black', // Example of forcing color
      })
    );

    const originalH2Styles = h2Tags.map((h2) =>
      this.applyTemporaryStyles(h2, {
        color: 'black', // Example of forcing color
      })
    );

    html2canvas(content)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = 210; // A4 size width in mm
        const pageHeight = 297; // A4 size height in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 10;

        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        doc.save(this.itemId + '.pdf');
      })
      .catch((error) => {
        console.error('Error generating PDF:', error);
      })
      .finally(() => {
        // Reset styles after capturing
        pTags.forEach((p, i) => this.resetStyles(p, originalPStyles[i]));
        h2Tags.forEach((h2, i) => this.resetStyles(h2, originalH2Styles[i]));
      });
  }
}
namespace CitationStatusFilter {
  export type RecordStatusEnum = 'LOCKED' | 'ACTIVE' | 'IN_ACTIVE';
  export const RecordStatusEnum = {
    Locked: 'LOCKED' as RecordStatusEnum,
    Active: 'ACTIVE' as RecordStatusEnum,
    InActive: 'IN_ACTIVE' as RecordStatusEnum,
  };
}
