<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12 md:col-6">
      <app-basic-input
        [label]="moduleFieldString + '.name.label' | translate: 'Name'"
        [placeholder]="moduleFieldString + '.name.placeholder' | translate: 'Enter Name'"
        [control]="formGroup?.controls?.name"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>
    <div class="col-12 md:col-6">
      <app-user-and-group-selector
        [label]="moduleFieldString + '.owner.label' | translate: 'Owner'"
        [placeholder]="moduleFieldString + '.owner.placeholder' | translate: 'Search...'"
        [principleMode]="true"
        [control]="formGroup?.controls?.owner"
        [optionValue]="undefined"
        [multi]="false"
        [viewMode]="fieldViewMode"
      >
      </app-user-and-group-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-user-and-group-selector
        [label]="moduleFieldString + '.auditor.label' | translate: 'Auditor'"
        [placeholder]="moduleFieldString + '.auditor.placeholder' | translate: 'Search...'"
        [principleMode]="true"
        [control]="formGroup?.controls?.auditor"
        [optionValue]="undefined"
        [multi]="false"
        [viewMode]="fieldViewMode"
      >
      </app-user-and-group-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-user-and-group-selector
        [label]="moduleFieldString + '.auditee.label' | translate: 'Auditee'"
        [placeholder]="moduleFieldString + '.auditee.placeholder' | translate: 'Search...'"
        [principleMode]="true"
        [control]="formGroup?.controls?.auditee"
        [optionValue]="undefined"
        [multi]="false"
        [viewMode]="fieldViewMode"
      >
      </app-user-and-group-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-target-code-selector
        [label]="moduleFieldString + '.auditProgram.label' | translate: 'Audit Program'"
        [placeholder]="moduleFieldString + '.auditProgram.placeholder' | translate: 'Search Programs'"
        [control]="formGroup?.controls?.auditProgram"
        [viewMode]="fieldViewMode"
        [multi]="false"
        [targetTypes]="['AUDIT_PROGRAM']"
      >
      </app-target-code-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-target-code-selector
        [label]="moduleFieldString + '.auditProject.label' | translate: 'Audit Project'"
        [placeholder]="moduleFieldString + '.auditProject.placeholder' | translate: 'Search Projects'"
        [control]="formGroup?.controls?.auditProject"
        [viewMode]="fieldViewMode"
        [multi]="false"
        [targetTypes]="['AUDIT_PROJECT']"
      >
      </app-target-code-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-target-code-selector
        [label]="moduleFieldString + '.auditeeQuestionnaire.label' | translate: 'Auditee Questionnaire'"
        [placeholder]="moduleFieldString + '.auditeeQuestionnaire.placeholder' | translate: 'Search Questionnaire'"
        [control]="formGroup?.controls?.auditeeQuestionnaire"
        [viewMode]="fieldViewMode"
        [multi]="false"
        [targetTypes]="['QUESTIONNAIRE']"
      >
      </app-target-code-selector>
    </div>
    @if (this.data?.auditeeQuestionnaire || formGroup?.controls?.auditeeQuestionnaire?.getRawValue()) {
      <div class="col-12 md:col-6">
        <app-assessment-field
          [viewModeOnly]="true"
          [questionnaireCode]="
            this.data?.auditeeQuestionnaire || formGroup?.controls?.auditeeQuestionnaire?.getRawValue()
          "
          [questionnaireValueCode]="this.data?.auditeeQuestionnaireValue"
          [label]="moduleFieldString + '.auditeeQuestionnaireValue.label' | translate: 'Auditee Questionnaire Answers'"
          [control]="answersControl"
        >
        </app-assessment-field>
      </div>
    }
    <div class="col-12 md:col-6">
      <app-target-code-selector
        [label]="moduleFieldString + '.auditorQuestionnaire.label' | translate: 'Auditor Questionnaire'"
        [placeholder]="moduleFieldString + '.auditorQuestionnaire.placeholder' | translate: 'Search Questionnaire'"
        [control]="formGroup?.controls?.auditorQuestionnaire"
        [viewMode]="fieldViewMode"
        [multi]="false"
        [targetTypes]="['QUESTIONNAIRE']"
      >
      </app-target-code-selector>
    </div>
    @if (this.data?.auditorQuestionnaire || formGroup?.controls?.auditorQuestionnaire?.getRawValue()) {
      <div class="col-12 md:col-6">
        <app-assessment-field
          [viewModeOnly]="true"
          [questionnaireCode]="
            this.data?.auditorQuestionnaire || formGroup?.controls?.auditorQuestionnaire?.getRawValue()
          "
          [questionnaireValueCode]="this.data?.auditorQuestionnaireValue"
          [label]="moduleFieldString + '.auditorQuestionnaireValue.label' | translate: 'Auditor Questionnaire Answers'"
          [control]="answersControl"
        >
        </app-assessment-field>
      </div>
    }

    <p-divider align="left" class="col-12">
      <b>{{ 'modules.' + moduleRouteToLocaleCase + '.auditCaseResult.findings' | translate }}</b>
    </p-divider>
    <div class="col-12 md:col-6">
      <app-target-code-selector
        [label]="moduleFieldString + '.cases.label' | translate: 'Cases'"
        [placeholder]="moduleFieldString + '.cases.placeholder' | translate: 'Search Cases'"
        [control]="formGroup?.controls?.cases"
        [viewMode]="fieldViewMode"
        [multi]="true"
        [targetTypes]="['AUDIT_CASE']"
      >
      </app-target-code-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-target-code-selector
        [label]="moduleFieldString + '.issues.label' | translate: 'Issues'"
        [placeholder]="moduleFieldString + '.issues.placeholder' | translate: 'Search Issues'"
        [control]="formGroup?.controls?.issues"
        [viewMode]="fieldViewMode"
        [multi]="true"
        [targetTypes]="['ISSUE']"
      >
      </app-target-code-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-target-code-selector
        [label]="moduleFieldString + '.auditRequests.label' | translate: 'Audit Requests'"
        [placeholder]="moduleFieldString + '.auditRequests.placeholder' | translate: 'Search Requests'"
        [control]="formGroup?.controls?.auditRequests"
        [viewMode]="fieldViewMode"
        [multi]="true"
        [targetTypes]="['AUDIT_REQUEST']"
      >
      </app-target-code-selector>
    </div>
    <p-divider align="left" class="col-12">
      <b>{{ 'modules.' + moduleRouteToLocaleCase + '.budgeting' | translate }}</b>
    </p-divider>

    <!-- <div class="col-12 md:col-6">
      <app-date-input [label]="'Planned Start Date'"
        [placeholder]="'2022-11-22'"
        [name]="'plannedStartDate'"
        [showIcon]="true"
        [control]="formGroup?.controls?.plannedStartDate"
        [viewMode]="fieldViewMode"
      [maxDate]="formGroup?.controls?.plannedEndDate?.value || null"></app-date-input>
    </div>
    <div class="col-12 md:col-6">
      <app-date-input [label]="'Planned End Date'"
        [placeholder]="'2022-11-22'"
        [name]="'plannedEndDate'"
        [showIcon]="true"
        [control]="formGroup?.controls?.plannedEndDate"
      [viewMode]="fieldViewMode"></app-date-input>
    </div>
    <div class="col-12 ">
      <app-number-input label="Planned Duration (Days)"
        [placeholder]="''"
        [control]="formGroup?.controls?.plannedDuration"
      [viewMode]="'view'"
[canKeepValue]="false"></app-number-input>
    </div> -->
    <div class="col-12 md:col-6">
      <app-date-input
        [label]="moduleFieldString + '.actualStartDate.label' | translate: 'Actual Start Date'"
        [placeholder]="'2022-11-22'"
        [name]="'actualStartDate'"
        [showIcon]="true"
        [control]="formGroup?.controls?.actualStartDate"
        [viewMode]="'view'"
        [canKeepValue]="false"
        [maxDate]="formGroup?.controls?.actualEndDate?.value || null"
      ></app-date-input>
    </div>
    <div class="col-12 md:col-6">
      <app-date-input
        [label]="moduleFieldString + '.actualEndDate.label' | translate: 'Actual End Date'"
        [placeholder]="'2022-11-22'"
        [name]="'actualEndDate'"
        [showIcon]="true"
        [control]="formGroup?.controls?.actualEndDate"
        [viewMode]="'view'"
        [canKeepValue]="false"
        [maxDate]="formGroup?.controls?.actualEndDate?.value || null"
      ></app-date-input>
    </div>
    <div class="col-12">
      <app-number-input
        [label]="moduleFieldString + '.actualDuration.label' | translate: 'Actual Duration (Days)'"
        [placeholder]="''"
        [control]="formGroup?.controls?.actualDuration"
        [viewMode]="fieldViewMode"
      ></app-number-input>
    </div>
    <div class="col-12">
      <app-number-input
        [label]="moduleFieldString + '.actualTimeResources.label' | translate: 'Actual Time Resources (Days)'"
        [placeholder]="''"
        [control]="formGroup?.controls?.actualTimeResources"
        [viewMode]="'view'"
        [canKeepValue]="false"
      ></app-number-input>
    </div>
  </div>
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create') {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create') {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
