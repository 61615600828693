import { Component, Input, OnInit } from '@angular/core';
import { DataTypeEnum, DynamicComponentBase, ITablePageable } from '@shared/classes';
import { GlobalValueListDataService } from 'app/modules/global-configuration/services/data/global-value-list-data.service';

@Component({
  selector: 'app-global-value-list-suggestions-selector',
  templateUrl: './global-value-list-suggestions-selector.component.html',
  styleUrls: ['./global-value-list-suggestions-selector.component.scss'],
})
export class GlobalValueListSuggestionsSelectorComponent extends DynamicComponentBase implements OnInit {
  @Input() multi: boolean = false;
  @Input() optionValue: string = 'value';
  @Input() dataKey = null;
  private _listName = null;
  get listName() {
    return this._listName;
  }
  @Input() set listName(listName) {
    this._listName = listName;
    this.getOptions();
  }

  constructor(private service: GlobalValueListDataService) {
    super();
  }

  items: any[] = [];
  pageInfo: ITablePageable = new ITablePageable();
  isLoading: boolean = false;
  dataType: DataTypeEnum = DataTypeEnum.Text;
  ngOnInit(): void {
    this.getOptions();
  }

  setInputOptions() {
    this.multi = this.inputOptions?.dropDownInput?.multi ?? this.multi;
  }
  getOptions(filters: any[] = []) {
    this.isLoading = true;
    if (this.listName) {
      filters.push({ property: 'name', operation: 'EQUAL', value: this.listName });
    }
    this.subs.sink = this.service
      .search(this.pageInfo.pagination, { filters: filters }, { showLoading: false, showMsg: false })
      .subscribe({
        next: (res) => {
          this.items = res.content?.[0]?.values; //|| [{"value":"some value","numericValue":null,"icon":null,"color":"","helpText":"help text ????","description":"some description"},{"value":"val 2","numericValue":null,"icon":null,"color":null,"helpText":null,"description":null},{"value":"non because why not","numericValue":null,"icon":null,"color":null,"helpText":null,"description":null}];
          this.pageInfo.totalElements = res?.totalElements?.[0]?.values?.length || 0;
        },
        complete: () => {
          this.isLoading = false;
        },
      });
  }
  onFilter(event) {
    // this.getOptions([
    //     { property: "values.value", operation: "CONTAINS", value: event },
    // ]);
  }
}
