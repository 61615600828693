import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  BaseForm,
  IViewMode,
  ModuleKeywords,
  RiskMethodology,
  RiskMethodologyDto
} from '@shared/classes';
import { AppDialogService } from '@shared/services/app-dialog.service';
import { ViewModeService } from '@shared/services/view-mode.service';
import { RiskMethodologyImpactFactorDataService } from 'app/modules/risk/services/data/risk-methodology-impact-factor-data.service';
import { RiskMethodologyImpactValueDataService } from 'app/modules/risk/services/data/risk-methodology-impact-value-data.service';
import { RiskMethodologyLikelihoodValueDataService } from 'app/modules/risk/services/data/risk-methodology-likelihood-value-data.service';
import { RiskMethodologyThresholdValueDataService } from 'app/modules/risk/services/data/risk-methodology-threshold-value-data.service';
import { RiskMethodologyDataService } from '../../../services/data/risk-methodology-data.service';

@Component({
  selector: 'app-risk-methodology-form',
  templateUrl: './risk-methodology-form.component.html',
  styleUrls: ['./risk-methodology-form.component.scss'],
})
export class RiskMethodologyFormComponent extends BaseForm<RiskMethodologyDto> implements OnInit, AfterViewInit {
  @Input() acceptableRiskControl = new FormControl(0);


  constructor(
    public viewModeService: ViewModeService,
    public appDialogService: AppDialogService,
    public impactFactorService: RiskMethodologyImpactFactorDataService,
    public impactValueService: RiskMethodologyImpactValueDataService,
    public likelihoodValueService: RiskMethodologyLikelihoodValueDataService,
    public thresholdValueService: RiskMethodologyThresholdValueDataService,
    private riskMethodologyDataService: RiskMethodologyDataService
  ) {
    super(viewModeService, ModuleKeywords.RiskMethodology);
  }

  ngOnInit(): void {

  }


  getData() {
    const retData = {
      ...this.formGroup.getRawValue(),
      acceptableRisk: this.acceptableRiskControl?.value,
    };

    let riskMeth: RiskMethodology = retData;

    return this.viewModeService.viewMode == 'create' ?
      this.getDataKeyValueFormat(riskMeth)
      : this.getChangedFormValues(riskMeth).updateItems;
  }

  setData(data: any) {
    this.formGroup.patchValue({ ...data }, { emitEvent: false });
    this.data = data;
    this.acceptableRiskControl.patchValue(this.data?.acceptableRisk ?? 0);
  }


  ngAfterViewInit(): void {
    this._setupSubscriptions();
    // this.setupFormSubscribers();
  }


  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      owner: new FormControl(null, Validators.required),
      approver: new FormControl(null, Validators.required),
      description: new FormControl(null),
      acceptableRisk: new FormControl(null),
    });
  }
  get statusBasedViewMode(): IViewMode {
    return !this.data || this.data?.status == 'DRAFT' ? this.fieldViewMode : 'view';
  }
}
