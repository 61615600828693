import {
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { LocaleService } from '@core/services/locale/locale.service';
import {
  AggregateType,
  AggregationField,
  ChartViewConfig,
  ContentViewDto,
  DashboardDto,
  EChartTypeMapper,
  GroupingField,
  IAction,
  ReportDto,
  RunAs,
  StatisticViewConfig,
  UnsubscribeOnDestroyAdapter,
  ViewCardItem,
  fixAggregateId,
  getEnumOptions,
  getOptionsFromStringArray,
  humanizeCasedString,
  isNullObj,
  isProductionEnv,
  parseAggregateDataV2,
  setChartNameAndData,
} from '@shared/classes';
import { AggregateDataOptions, EchartBase } from '@shared/classes/view/echart-helpers/echart-base';
import { EchartFactory } from '@shared/classes/view/echart-helpers/echart-factory';
import { EchartVariationBase } from '@shared/classes/view/echart-helpers/echart-variation-base';
import {
  getAggregationFieldIdKey,
  getDrillDownFilterField,
  getGroupFieldIdKey,
} from '@shared/classes/view/echart-helpers/helpers/general-functions';
import { AppDialogService, BaseFieldDefinitionsService, PathResolverService } from '@shared/services';
import { AppComponent } from 'app/app.component';
import * as echarts from 'echarts';
import { cloneDeep } from 'lodash-es';
import { NgxPermissionsService } from 'ngx-permissions';
import { MenuItem } from 'primeng/api';
import { of } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
export const chartTypesToExclude: (ChartViewConfig.ChartTypeEnum | StatisticViewConfig.ChartTypeEnum)[] = [
  'TIME_SCALE',
  'BAR_RACE',
  'LINE_RACE',
];
@Component({
  selector: 'app-widget-chart',
  templateUrl: './widget-chart.component.html',
  styleUrl: './widget-chart.component.scss',
})
export class WidgetChartComponent extends UnsubscribeOnDestroyAdapter implements OnInit, AfterViewInit {
  @ViewChild('eChartContainer', { static: true }) eChartContainer: ElementRef;
  @ContentChild('headerTemplate') headerTemplate: TemplateRef<any>;
  @Input() showChartViewControls = true;
  @Input() styleClass: string = 'relative card m-0';
  @Input() title: string = 'some title';
  @Input() reportObject: ReportDto;
  @Input() runAs: RunAs;
  chartId = null;
  private _cardContent: ViewCardItem = null;
  aggregationResult: {
    groupByFields: GroupingField[];
    fields: AggregationField[];
    value: any;
    formattedValues: { [x: string]: any };
  };
  aggregateDataOptions: AggregateDataOptions;
  @Input() set cardContent(cardContent: ViewCardItem) {
    this._cardContent = cardContent;
    this.initData();
  }
  get cardContent() {
    return this._cardContent;
  }
  mappingService: BaseFieldDefinitionsService = null;
  mergeOptions: any;
  factorOptions: any;
  factoryChart: EchartBase;
  directConfigOptions: any;
  @Input() chartType: any = 'DOUGHNUT';
  @Input() data: any[] = [];
  @Input() height = '100vh';
  @Input() dashboardObject: DashboardDto;
  chartTypeItems = getEnumOptions(ChartViewConfig.ChartTypeEnum).filter((x) => !chartTypesToExclude.includes(x.value));
  chartViewTypeItems = getEnumOptions(ChartViewConfig.ViewTypeEnum);
  chartVariationItems = getEnumOptions(ChartViewConfig.ChartVariationEnum);
  tablePostionItems = getEnumOptions(ChartViewConfig.DataPositionEnum);
  drillDownAggregationFieldItems: { label: string; value: string; operation: AggregationField.OperationEnum }[] = [];
  formGroup = new FormGroup({
    chartType: new FormControl('DOUGHNUT'),
    viewType: new FormControl('CHART'),
    chartVariation: new FormControl(null),
    dataPosition: new FormControl('DOWN'),
    colorPalette: new FormControl(null),
    drillDownAggregationField: new FormControl(null),
    panelSizes: new FormControl([50, 50]),
  });
  isLoadingImport = true;
  isLoading = true;
  activeDataIndex = 0;
  activeDataFilter = null;
  chartTitle;
  drillDownPreviousOptions: DrillDownMenuItem[] = [];
  @Input() tableCardContent: ContentViewDto;
  @Input() filteredKeys = [];

  home: MenuItem = { icon: 'pi pi-home' };
  drillDownDataSet = null;
  initiatedComp = false;
  disabledDrillDownCharts = DisabledDrillDownCharts;
  private _directConfig: { dataObject: any; chartType: ChartViewConfig.ChartTypeEnum | 'TREE' | 'GAUGE' };
  colorPallete = null;
  tooltipObject = null;
  labelObject = null;
  @Input() set directConfig(directConfig: {
    dataObject: any;
    chartType: ChartViewConfig.ChartTypeEnum | 'TREE' | 'GAUGE';
    colors?: { key: string; color: string }[];
    filteredKeys?: [string];
  }) {
    if (directConfig?.colors?.length > 0) {
      this.colorPallete = directConfig.colors.map((x) => x.color);
    }
    this.chartType = directConfig.chartType;
    if (directConfig?.dataObject?.tooltip) {
      this.tooltipObject = directConfig?.dataObject?.tooltip;
    }
    if (directConfig?.dataObject?.label) {
      this.labelObject = directConfig?.dataObject?.label;
    }
    if (directConfig.chartType == 'SANKEY' || directConfig.chartType == 'TREE' || directConfig.chartType == 'GAUGE') {
      this.data = directConfig.dataObject;
    } else if (directConfig.chartType == 'TIME_SCALE') {
      this.data = directConfig.dataObject;
    } else {
      if (directConfig?.filteredKeys) {
        this.data = Object.entries(directConfig.dataObject)
          ?.filter((x) => !directConfig.filteredKeys.includes(x[0]))
          .map(([key, value]) => {
            return { name: humanizeCasedString(key), value: value };
          });
      } else {
        this.data = Object.entries(directConfig.dataObject)?.map(([key, value]) => {
          return { name: humanizeCasedString(key), value: value };
        });
      }
    }
    // if (this.options) {
    //     this.mergeOptions = setChartNameAndData(this.options, this.chartType, this.chartTitle || this.title, this.data);
    //     this.chartId = uuidv4();
    // } else {
    if (this.initiatedComp) {
      this.initEChart();
    }
    this._directConfig = directConfig;
    // }
  }
  get directConfig() {
    return this._directConfig;
  }
  @Output() onChartElementClick: EventEmitter<any> = new EventEmitter();
  @Output() onChartOptionsChange: EventEmitter<any> = new EventEmitter();
  editOptionsAction: IAction = {
    label: '',
    id: 1,
    color: 'secondary',
    icon: 'pi pi-pencil',
    iconPos: 'left',
    tooltipText: 'Edit Chart Options',
    tooltipPosition: 'top',
    tooltipOptions: {
      tooltipLabel: 'Edit Chart Options',
    },
    command: this.showEditOptions.bind(this),
  };
  @ViewChild('chartViewControlsDiv', { static: false }) chartViewControlsDiv!: ElementRef;
  constructor(
    public appDialogService: AppDialogService,
    public router: Router,
    private pathResolverService: PathResolverService,
    private permissionService: NgxPermissionsService,
    public app: AppComponent,
    public localeService: LocaleService
  ) {
    super();
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.initEChart();
      setTimeout(() => {
        this.initiatedComp = true;
      }, 20);
    });
  }
  ngOnInit(): void {
    import('echarts').then((echarts) => {
      this.isLoadingImport = false;
    });
    this.formGroup.valueChanges.subscribe((values) => {
      this.overrideOptions = null;
      if (this.chartType != values.chartType) {
        this.chartType = values.chartType;
        values.chartVariation = this.chartVariationOptions?.[0]?.value || 'STANDARD';
        this.formGroup.patchValue({ chartVariation: this.chartVariationOptions?.[0]?.value || 'STANDARD' }); //reset variation after chartType change
      }
      if (values?.viewType == ChartViewConfig.ViewTypeEnum.ChartAndData && isNullObj(values?.dataPosition)) {
        values.dataPosition = ChartViewConfig.DataPositionEnum.Down;
        this.formGroup.controls.dataPosition.patchValue(ChartViewConfig.DataPositionEnum.Down, { emitEvent: false });
      }
      if (values?.viewType == ChartViewConfig.ViewTypeEnum.Data) {
        values.dataPosition = ChartViewConfig.DataPositionEnum.Down;
        this.formGroup.controls.dataPosition.patchValue(ChartViewConfig.DataPositionEnum.Down, { emitEvent: false });
      }
      this.onChartOptionsChange.emit(values);
      // this.initEChart();
      this.factoryChart = EchartFactory.createChart({
        chartType: this.chartType,
        chartVariation:
          values?.chartVariation ||
          this.cardContent?.contentViewConfig?.chartVariation ||
          this.cardContent?.contentViewObject?.chartViewConfig?.chartVariation,
      });
      this.resetDrillDown();

      // this.factorOptions = this.factoryChart.assignDataToChartOptions({
      //   chartOptions: this.factorOptions,
      //   name: this.chartTitle || this.title,
      //   data: this.data,
      // });
    });
  }
  initEChart() {
    type EChartsOption = echarts.EChartsOption;
    let option: EChartsOption;
    option = cloneDeep({ ...EChartTypeMapper?.[this.chartType] });
    if (
      this.cardContent?.contentViewConfig?.colorPalette?.length > 0 ||
      this.dashboardObject?.defaultColorPalette?.length > 0 ||
      this.cardContent?.contentViewObject?.chartViewConfig?.colorPalette?.length > 0 ||
      this.colorPallete?.length > 0
    ) {
      option.color =
        this.cardContent?.contentViewConfig?.colorPalette ||
        this.dashboardObject?.defaultColorPalette ||
        this.cardContent?.contentViewObject?.chartViewConfig?.colorPalette ||
        this.colorPallete;
    }
    option = setChartNameAndData(option, this.chartType, this.chartTitle || this.title, this.data);
    option.tooltip = this.tooltipObject ?? option.tooltip;
    option.label = this.labelObject ?? option.label;

    this.directConfigOptions = { ...option };
    this.chartId = uuidv4();
  }
  initData() {
    if (this.cardContent) {
      this.chartType =
        this.cardContent?.contentViewConfig?.chartType ||
        this.cardContent?.contentViewObject?.chartViewConfig?.chartType;
      this.mappingService = this.pathResolverService.getMappingServiceByTargetType(
        this.cardContent?.contentViewObject?.targetType as any
      );
      this.drillDownAggregationFieldItems =
        this.cardContent?.contentViewObject?.filterView?.aggregationBody?.aggregationFields?.map((x) => {
          return {
            operation: x.operation,
            label: humanizeCasedString(getAggregationFieldIdKey(x)),
            value: getAggregationFieldIdKey(x),
          };
        });
      this.formGroup.patchValue(
        {
          chartType:
            this.cardContent?.contentViewConfig?.chartType ||
            this.cardContent?.contentViewObject?.chartViewConfig?.chartType,
          viewType:
            this.cardContent?.contentViewConfig?.viewType ||
            this.cardContent?.contentViewObject?.chartViewConfig?.viewType,
          chartVariation:
            this.cardContent?.contentViewConfig?.chartVariation ||
            this.cardContent?.contentViewObject?.chartViewConfig?.chartVariation,
          dataPosition:
            this.cardContent?.contentViewConfig?.dataPosition ||
            this.cardContent?.contentViewObject?.chartViewConfig?.dataPosition,
          colorPalette:
            this.cardContent?.contentViewConfig?.colorPalette ||
            this.dashboardObject?.defaultColorPalette ||
            this.cardContent?.contentViewObject?.chartViewConfig?.colorPalette,
          drillDownAggregationField:
            this.drillDownAggregationFieldItems?.find(
              (x) => x.value == this.cardContent?.contentViewConfig?.drillDownAggregationField
            )?.value ||
            this.drillDownAggregationFieldItems?.find(
              (x) => x.value == this.cardContent?.contentViewObject?.chartViewConfig?.drillDownAggregationField
            )?.value ||
            this.drillDownAggregationFieldItems?.[0]?.value,
          panelSizes:
            this.cardContent?.contentViewConfig?.panelSizes ||
            this.cardContent?.contentViewObject?.chartViewConfig?.panelSizes,
        },
        { emitEvent: false }
      );
      this.fetchData();
    }
  }
  fetchData() {
    this.isLoading = true;
    this.permissionService.hasPermission(this.cardContent?.contentViewObject?.permissions).then((isAllowed) => {
      const dataService = this.pathResolverService.getDataServiceByTargetType(
        this.cardContent?.contentViewObject?.targetType as any
      );
      const mappingService = this.pathResolverService.getMappingServiceByTargetType(
        this.cardContent?.contentViewObject?.targetType as any
      );
      this.subs.sink = (
        isAllowed
          ? dataService.aggregate({
            ...this.cardContent?.contentViewObject?.filterView?.aggregationBody,
            runAs: this?.runAs ?? this.cardContent?.contentViewObject?.filterView?.aggregationBody?.runAs,
          })
          : of([] as any)
      ).subscribe((response) => {
        this.isLoading = false;
        const res = fixAggregateId(
          this.cardContent?.contentViewObject?.filterView?.aggregationBody?.groupByFields?.map((x) => x.fieldName),
          response
        );
        const aggregateField =
          this.cardContent?.contentViewObject?.filterView?.aggregationBody?.groupByFields?.[0]?.fieldName;
        const aggregationFieldOperation: any =
          this.cardContent?.contentViewObject?.filterView?.aggregationBody?.aggregationFields
            ?.find((x) => x?.fieldName == aggregateField)
            ?.operation?.toLowerCase() || AggregateType.Count;
        let chartDataList: { [x: string]: any } = {};
        this.cardContent?.contentViewObject?.filterView?.aggregationBody?.aggregationFields?.forEach((field) => {
          chartDataList[field.fieldName] = parseAggregateDataV2(
            res?.aggregation_value,
            field.fieldName,
            aggregationFieldOperation,
            mappingService?.mappedFields?.[aggregateField]?.tableDefinition?.filter?.enumClass || null,
            this.cardContent?.contentViewObject?.filterView?.aggregationBody?.aggregationFields?.length > 1
              ? field.fieldName
              : null
          );
        });

        this.aggregationResult = {
          fields: this.cardContent?.contentViewObject?.filterView?.aggregationBody?.aggregationFields,
          value: res?.aggregation_value,
          formattedValues: chartDataList,
          groupByFields: this.cardContent?.contentViewObject?.filterView?.aggregationBody?.groupByFields,
        };
        // this.initialChartFormatData();
        this.aggregateDataOptions = {
          response: res,
          payload: this.cardContent?.contentViewObject?.filterView?.aggregationBody,
        };
        this.factoryChart = EchartFactory.createChart({
          chartType:
            this.cardContent?.contentViewConfig?.chartType ||
            this.cardContent?.contentViewObject?.chartViewConfig?.chartType,
          chartVariation:
            this.cardContent?.contentViewConfig?.chartVariation ||
            this.cardContent?.contentViewObject?.chartViewConfig?.chartVariation,
        });

        this.factorOptions = this.factoryChart.getEChartOptions({
          aggregationResult: { ...this.aggregateDataOptions },
          viewCardItem: {
            contentViewCode: this.cardContent?.contentViewCode || this.cardContent?.contentViewObject?.code,
            contentViewConfig:
              this.cardContent?.contentViewConfig || this.cardContent?.contentViewObject?.chartViewConfig,
            contentViewObject: this.cardContent?.contentViewObject,
          },
        });
        // if (this.factorOptions.title) {
        //   this.factorOptions.title = {
        //     ...this.factorOptions.title,
        //     text: this.localeService.translate(this.factorOptions?.title?.text, this.factorOptions?.title?.text),
        //     subtext: this.localeService.translate(
        //       this.factorOptions?.title?.subtext,
        //       this.factorOptions?.title?.subtext
        //     ),
        //   };
        // }

        // this.chartCObjClassificationsData = parseAggregateDataV2(res?.aggregation_value, aggregateField, aggregationFieldOperation, mappingService?.mappedFields?.[aggregateField]?.tableDefinition?.filter?.enumClass,this.cardContent?.filterView?.aggregationBody?.aggregationFields?.length > 1 ? 'recordStatus' : null);
        // this.chartCObjClassificationsTotal = this.getTotalAmount(this.chartCObjClassificationsData);
        // this.chartCObjClassificationsDetails = this.getChartData(this.chartCObjClassificationsData);
      });
    });
  }
  // initialChartFormatData() {
  //   if (
  //     isNullObj(this.aggregationResult?.value) ||
  //     isNullObj(this.aggregationResult?.fields) ||
  //     isNullObj(this.aggregationResult?.formattedValues)
  //   )
  //     return;
  //   const currentField = this.aggregationResult.groupByFields[0]; //this.activeDataIndex
  //   if (isNullObj(currentField)) return;

  //   this.formatAggregateData(this.aggregationResult?.formattedValues?.[currentField], currentField);
  // }
  // formatAggregateData(dataObject, title) {
  //   const humanizeKeys = !!(this.mappingService?.mappedFields?.[title]?.tableDefinition?.filter?.enumClass || null);
  //   const formattedData = [];
  //   Object.entries(dataObject).forEach(([key, val], index) => {
  //     formattedData.push({ name: humanizeKeys ? humanizeCasedString(key) : key, value: val });
  //   });
  //   this.data = [...formattedData];
  //   this.chartTitle = humanizeCasedString(title);
  //   this.mergeOptions = setChartNameAndData(this.directConfigOptions, this.chartType, this.chartTitle || this.title, this.data);

  // }
  onChartEvent(event: any, type: string) {
    this.onChartElementClick.emit(event);
    if (isNullObj(this.directConfig) && !DisabledDrillDownCharts[this.factoryChart.chartType]) {
      if (event?.componentSubType == 'sankey' || event?.componentSubType == 'graph') {
        if (event?.dataType == 'node') {
          this.instantDrillDownView([
            { field: event?.data?.nodeData?.fieldData, value: event?.data?.nodeData?.fieldGroupByValue },
          ]);
        } else if (event?.dataType == 'edge') {
          this.instantDrillDownView([
            {
              field: event?.data?.extraData?.sourceNode?.fieldData,
              value: event?.data?.extraData?.sourceNode?.fieldGroupByValue,
            },
            {
              field: event?.data?.extraData?.targetNode?.fieldData,
              value: event?.data?.extraData?.targetNode?.fieldGroupByValue,
            },
          ]);
        }
      } else if (event?.dimensionNames?.length > 0 && event?.componentSubType != 'bar') {
        if (this.factorOptions?.xAxis && this.factorOptions?.yAxis) {
          if (event?.seriesName && !event?.seriesName?.toString()?.toLowerCase()?.startsWith('series')) {
            this.changeDrillDownView(event?.seriesName, false);
          }
          if (Array.isArray(event?.value)) {
            this.changeDrillDownView(this.factorOptions?.xAxis?.[0]?.data?.[event?.value?.[0]], false);
            this.changeDrillDownView(this.factorOptions?.yAxis?.[0]?.data?.[event?.value?.[1]], false);
          } else if (this.factorOptions?.xAxis?.[0]?.data) {
            this.changeDrillDownView(this.factorOptions?.xAxis?.[0]?.data?.[event?.value], false);
          }
        }
        if (this.factorOptions?.angleAxis && this.factorOptions?.radiusAxis) {
          if (event?.seriesName && !event?.seriesName?.toString()?.toLowerCase()?.startsWith('series')) {
            this.changeDrillDownView(event?.seriesName, false);
          }
          this.changeDrillDownView(this.factorOptions?.radiusAxis?.[0]?.data?.[event?.value?.[0]], false);
          this.changeDrillDownView(this.factorOptions?.angleAxis?.[0]?.data?.[event?.value?.[1]], false);
        }
      } else if (
        (this.factoryChart.chartType == 'VERTICAL_BAR' || this.factoryChart.chartType == 'HORIZONTAL_BAR') &&
        (this.factoryChart as EchartVariationBase).variation != 'DRILL_DOWN'
      ) {
        // dodge for now
      } else if (this.factoryChart.chartType == 'RADAR') {
        // dodge for now
      } else if (event?.treePathInfo?.length - 1 == this.aggregationResult?.groupByFields?.length) {
        if (event?.treePathInfo) {
          let pathInfo = event?.treePathInfo;
          let clickedData = this.factorOptions?.series[0]?.data; // Get the full data tree
          let resolvedPathes = [];
          // Traverse the hierarchy to get the exact clicked item
          for (let i = 0; i < pathInfo.length; i++) {
            if (pathInfo?.[i]?.name) {
              let nodeName = pathInfo?.[i]?.name;
              clickedData = clickedData?.find((item) => item?.name === nodeName);
              // console.log(clickedData);
              resolvedPathes.push(clickedData);
              if (clickedData && clickedData?.children) {
                clickedData = clickedData?.children; // Move deeper into the tree
              }
            } else {
              resolvedPathes.push({ key: null, label: null });
            }
          }
          (event?.treePathInfo as { name: string }[]).forEach((item, index) => {
            if (item.name) {
              this.changeDrillDownView(
                {
                  label: resolvedPathes?.[index]?.key || item?.name,
                  translation: resolvedPathes?.[index]?.key,
                },
                false
              );
            }
          });
        }
      } else {
        const formattedLabel = (event?.data?.originalFieldKey || event?.name).toUpperCase().replace(/ /g, '_');
        this.activeDataFilter = formattedLabel;
        this.activeDataIndex++;
        this.changeDrillDownView({ label: event?.data?.originalFieldKey || event?.name, translation: event?.name });
      }
    }
  }
  instantShowTable = false;
  instantDrillDownView(options: { field: GroupingField; value: string }[]) {
    this.drillDownPreviousOptions.push({
      label: humanizeCasedString(options.map((x) => x.value)?.join(' & ')),
      currentField: null,
      currentFieldObject: null,
      currentLabelValue: null,
      currentLabel: null,
      tooltip: `${humanizeCasedString(options.map((x) => x.value)?.join(' & '))}`,
    });
    this.tableCardContent = {
      ...this.cardContent?.contentViewObject,
      tableViewConfig: {
        ...this.cardContent?.contentViewObject?.drillDownTableViewConfig,
      },
      filterView: {
        ...this.cardContent?.contentViewObject?.filterView,
        filter: {
          ...this.cardContent?.contentViewObject?.filterView.filter,
          filters: [
            ...(this.cardContent?.contentViewObject?.filterView?.filter?.filters
              ? this.cardContent?.contentViewObject?.filterView?.filter?.filters
              : []),
            ...options.map((x) => {
              return getDrillDownFilterField(x?.field, x?.value?.toUpperCase()?.replace(/ /g, '_'));
              // x?.value === 'EMPTY_VALUE'
              //   ? ({ property: x?.field, operation: 'IS_NULL' } as any)
              //   : ({
              //       property: x?.field,
              //       operation: 'EQUAL',
              //       value: x?.value,
              //       // typeShape: x?.currentFieldObject?.typeShape,
              //       // dateTimePart: x?.currentFieldObject?.dateTimePart,
              //     } as any);
            }),
          ],
        },
      },
    };
    this.instantShowTable = true;
  }
  changeDrillDownView(label: { label: string; translation?: string }, changeChartOptions = true) {
    if (this.aggregationResult?.groupByFields?.length > this.drillDownPreviousOptions?.length && label) {
      const nextGroupingFieldName = getGroupFieldIdKey(
        this.aggregationResult?.groupByFields?.[this.drillDownPreviousOptions?.length + 1]
      );
      //should switch old fields map<fieldKey,drillDownValue> and do checks on the whole list
      const drillDownPreviousFields =
        this.drillDownPreviousOptions?.map((x) => {
          return { key: x.currentField, value: x.currentLabelValue };
        }) || [];
      const currentGroupingFieldObject = this.aggregationResult?.groupByFields?.[this.drillDownPreviousOptions?.length];
      const currentGroupingField = getGroupFieldIdKey(currentGroupingFieldObject);
      const formattedLabel = (label.label.toString() as string).toUpperCase().replace(/ /g, '_');

      const nextGroupConditionFields = [
        ...drillDownPreviousFields,
        { key: currentGroupingField, value: formattedLabel },
      ];
      // const extraLabels = ['No ' + fieldName];
      // const humanizedFieldName = humanizeCasedString(fieldName);
      const selectedAggregationField = this.formGroup?.controls?.drillDownAggregationField?.value;
      let ret = {};
      this.aggregationResult?.value
        ?.filter((x) => checkListInGroup(nextGroupConditionFields, x.group_id))
        .forEach((item) => {
          if (item?.group_id?.[nextGroupingFieldName]) {
            ret[item?.group_id?.[nextGroupingFieldName]] = ret[item?.group_id?.[nextGroupingFieldName]]
              ? ret[item?.group_id?.[nextGroupingFieldName]] + item?.[selectedAggregationField]
              : item?.[selectedAggregationField];
          } else {
            ret['EMPTY_VALUE'] = ret['EMPTY_VALUE']
              ? ret['EMPTY_VALUE'] + item?.[selectedAggregationField]
              : item?.[selectedAggregationField];
          }
        });
      if (changeChartOptions) {
        const agData = this.factoryChart.finalizeAggregateData(ret, nextGroupingFieldName);
        this.factorOptions.title.text = humanizeCasedString(nextGroupingFieldName);
        this.factorOptions = this.factoryChart.assignDataToChartOptions({
          chartOptions: this.factorOptions,
          name: agData.chartTitle,
          data: agData.data,
        });
      }
      // this.formatAggregateData(ret, fieldName);
      // this.drillDownDataSet = {
      //     datasets:[{
      //         backgroundColor:['#6c6a76', '#4caf50'],
      //         borderColor:'transparent',
      //         data: Object.entries(ret).map(x=> x[1]),
      //         fill:true,
      //     }],
      //     labels:Object.entries(ret).map(x=> x[0])
      // }

      this.drillDownPreviousOptions.push({
        label: humanizeCasedString(label.translation),
        currentField: currentGroupingField,
        currentFieldObject: currentGroupingFieldObject,
        currentLabelValue: formattedLabel,
        currentLabel: label.label,
        tooltip: `${humanizeCasedString(currentGroupingField)}: ${humanizeCasedString(label.translation)}`,
      });

      const currentNullCompareField = 'Empty Value'.toUpperCase().replace(/ /g, '_');

      this.drillDownPreviousOptions = [...this.drillDownPreviousOptions];
      this.tableCardContent = {
        ...this.cardContent?.contentViewObject,
        tableViewConfig: {
          ...this.cardContent?.contentViewObject?.drillDownTableViewConfig,
        },
        filterView: {
          ...this.cardContent?.contentViewObject?.filterView,
          filter: {
            ...this.cardContent?.contentViewObject?.filterView.filter,
            filters: [
              ...(this.cardContent?.contentViewObject?.filterView?.filter?.filters
                ? this.cardContent?.contentViewObject?.filterView?.filter?.filters
                : []),
              ...this.drillDownPreviousOptions.map((x) => {
                return getDrillDownFilterField(x?.currentFieldObject, x?.currentLabelValue, currentNullCompareField);
                // x?.currentLabelValue === currentNullCompareField
                //   ? ({ property: x?.currentField, operation: 'IS_NULL' } as any)
                //   : ({
                //       property: x?.currentFieldObject?.fieldName,
                //       operation: 'EQUAL',
                //       value: x?.currentFieldObject?.dateTimePart
                //         ? parseInt(x?.currentLabelValue)
                //         : x?.currentLabelValue,
                //       typeShape: x?.currentFieldObject?.typeShape,
                //       dateTimePart: x?.currentFieldObject?.dateTimePart,
                //     } as any);
              }),
            ],
          },
        },
      };
    }
  }
  onDrillDownItemClick(event) {
    const item = event.item;
    if (item.icon) {
      this.resetDrillDown();
    } else {
      const index = this.drillDownPreviousOptions.indexOf(item);
      if (index != this.drillDownPreviousOptions?.length - 1) {
        this.drillDownPreviousOptions.splice(index);
        this.changeDrillDownView({ label: item?.currentLabel, translation: item?.label });
      }
    }
  }
  resetDrillDown() {
    this.drillDownPreviousOptions = [];
    // this.initialChartFormatData();
    this.factorOptions = this.factoryChart.getEChartOptions({
      aggregationResult: { ...this.aggregateDataOptions },
      viewCardItem: {
        contentViewCode: this.cardContent?.contentViewCode || this.cardContent?.contentViewObject?.code,
        contentViewConfig: this.cardContent?.contentViewConfig || this.cardContent?.contentViewObject?.chartViewConfig,
        contentViewObject: this.cardContent?.contentViewObject,
      },
    });
    this.instantShowTable = false;
  }
  showOptionsEditor = false;
  showEditOptions() {
    this.tempOptions = JSON.stringify(this.overrideOptions || this.factorOptions);
    this.showOptionsEditor = true;
    this.tempOptionsFormControl.patchValue(null);
    setTimeout(() => {
      this.tempOptionsFormControl.patchValue(this.tempOptions);
    }, 100);
  }
  overrideOptions = null;
  tempOptions = null;
  tempOptionsFormControl = new FormControl(null);
  assignNewOptions() {
    this.overrideOptions = JSON.parse(this.tempOptionsFormControl.value);
    this.showOptionsEditor = false;
  }
  getChartVariations(chartType, dimension) {
    return (
      ChartVariationMapper[chartType][dimension] ??
      ChartVariationMapper[chartType][chartVariationConditions.THREE_D] ??
      ChartVariationMapper[chartType][chartVariationConditions.TWO_D]
    );
  }
  get chartVariationOptions() {
    const grouingLength = this.cardContent?.contentViewObject?.filterView?.aggregationBody?.groupByFields?.length;
    return (
      this.getChartVariations(
        this.formGroup?.controls?.chartType?.value ||
        this.cardContent?.contentViewConfig?.chartType ||
        this.cardContent?.contentViewObject?.chartViewConfig?.chartType ||
        'DOUGHNUT',
        grouingLength == 2
          ? chartVariationConditions.THREE_D_ONLY
          : grouingLength == 3
            ? chartVariationConditions.FOUR_D_ONLY
            : grouingLength >= 3
              ? chartVariationConditions.THREE_D
              : chartVariationConditions.TWO_D
      ) || []
    );
  }
  get chartTypeOptions() {
    const grouingLength = this.cardContent?.contentViewObject?.filterView?.aggregationBody?.groupByFields?.length;
    return this.chartTypeItems.filter(
      (x) =>
        this.getChartVariations(
          x.value,
          grouingLength == 2
            ? chartVariationConditions.THREE_D_ONLY
            : grouingLength == 3
              ? chartVariationConditions.FOUR_D_ONLY
              : grouingLength >= 2
                ? chartVariationConditions.THREE_D
                : chartVariationConditions.TWO_D
        )?.length > 0
    );
  }
  get tableHeight() {
    const baseHeight = this.chartViewControlsDiv?.nativeElement?.offsetHeight || 40.5;
    return this.formGroup?.controls?.viewType?.value == 'CHART_AND_DATA' &&
      (this.formGroup?.controls?.dataPosition?.value == 'UP' ||
        (this.formGroup?.controls?.dataPosition?.value || ChartViewConfig.DataPositionEnum.Down) == 'DOWN')
      ? `height:calc(50% - ${(this.showChartViewControls ? baseHeight : 0) + (this.aggregationResult?.fields?.length > 1 ? 24.5 : 0)}px);min-height:calc(50% - ${(this.showChartViewControls ? baseHeight : 0) + (this.aggregationResult?.fields?.length > 1 ? 24.5 : 0)}px);`
      : '';
  }
  get chartContainerHeight() {
    const baseHeight = this.chartViewControlsDiv?.nativeElement?.offsetHeight || 40.5;
    return {
      height: `calc(100% - ${this.showChartViewControls ? baseHeight : 0}px)`,
      minHeight: `calc(100% - ${this.showChartViewControls ? baseHeight : 0}px)`,
    };
  }
  get flexDirection() {
    return PositionFlexDirectionMapper[
      this.formGroup?.controls?.viewType?.value == ChartViewConfig.ViewTypeEnum.Data
        ? ChartViewConfig.DataPositionEnum.Up
        : this.formGroup?.controls?.dataPosition?.value || ChartViewConfig.DataPositionEnum.Down
    ];
  }
  get splitterViewDirection() {
    return PositionSplitterDirectionMapper[
      this.formGroup?.controls?.viewType?.value == ChartViewConfig.ViewTypeEnum.Data
        ? ChartViewConfig.DataPositionEnum.Up
        : this.formGroup?.controls?.dataPosition?.value || ChartViewConfig.DataPositionEnum.Down
    ];
  }
  get splitterChartFirst() {
    const position =
      this.formGroup?.controls?.viewType?.value == ChartViewConfig.ViewTypeEnum.Data
        ? ChartViewConfig.DataPositionEnum.Up
        : this.formGroup?.controls?.dataPosition?.value || ChartViewConfig.DataPositionEnum.Down;
    return position == ChartViewConfig.DataPositionEnum.Down || position == ChartViewConfig.DataPositionEnum.Right;
  }
  get showChartOnly() {
    return this.formGroup?.controls?.viewType?.value == ChartViewConfig.ViewTypeEnum.Chart;
  }
  get showDataOnly() {
    return this.formGroup?.controls?.viewType?.value == ChartViewConfig.ViewTypeEnum.Data;
  }
  isProductionEnv() {
    return isProductionEnv();
  }
  onSplitterResizeEnd(event) {
    console.log(event);
    this.formGroup?.controls?.panelSizes?.patchValue(event?.sizes);
  }
}
const PositionFlexDirectionMapper = {
  [ChartViewConfig.DataPositionEnum.Up]: 'flex-column',
  [ChartViewConfig.DataPositionEnum.Down]: 'flex-column-reverse',
  [ChartViewConfig.DataPositionEnum.Left]: 'flex-row',
  [ChartViewConfig.DataPositionEnum.Right]: 'flex-row-reverse',
};
const PositionSplitterDirectionMapper = {
  [ChartViewConfig.DataPositionEnum.Up]: 'vertical',
  [ChartViewConfig.DataPositionEnum.Down]: 'vertical',
  [ChartViewConfig.DataPositionEnum.Left]: 'horizontal',
  [ChartViewConfig.DataPositionEnum.Right]: 'horizontal',
};
export enum chartVariationConditions {
  THREE_D_ONLY = 'THREE_D_ONLY',
  FOUR_D_ONLY = 'FOUR_D_ONLY',
  THREE_D = 'THREE_D',
  TWO_D_ONLY = 'TWO_D_ONLY',
  TWO_D = 'TWO_D',
}
export const DisabledDrillDownCharts: { [key in ChartViewConfig.ChartTypeEnum]: boolean } = {
  VERTICAL_BAR: false,
  HORIZONTAL_BAR: false,
  PIE: false,
  DOUGHNUT: false,
  LINE: true,
  AREA: true,
  RADAR: true,
  TREE_MAP: false,
  SUN_BURST: false,
  SCATTERED: false,
  BUBBLE: false,
  SEMI_CIRCLE_DOUGHNUT: false,
  FUNNEL: false,
  HEATMAP: false,
  SANKEY: false,
  ROSE: false,
  TIME_SCALE: true,
  BAR_RACE: true,
  LINE_RACE: true,
};
export const ChartVariationMapper: any = {
  [ChartViewConfig.ChartTypeEnum.Line]: {
    [chartVariationConditions.THREE_D_ONLY]: getOptionsFromStringArray([
      ChartViewConfig.ChartVariationEnum.Standard,
      ChartViewConfig.ChartVariationEnum.Curved,
      ChartViewConfig.ChartVariationEnum.Logarithmic,
      ChartViewConfig.ChartVariationEnum.CurvedLogarithmic,
    ]),
  },
  [ChartViewConfig.ChartTypeEnum.Area]: {
    [chartVariationConditions.THREE_D_ONLY]: getOptionsFromStringArray([
      ChartViewConfig.ChartVariationEnum.Standard,
      ChartViewConfig.ChartVariationEnum.Curved,
      ChartViewConfig.ChartVariationEnum.Logarithmic,
      ChartViewConfig.ChartVariationEnum.CurvedLogarithmic,
    ]),
  },
  [ChartViewConfig.ChartTypeEnum.Scattered]: {
    [chartVariationConditions.THREE_D_ONLY]: getOptionsFromStringArray([
      ChartViewConfig.ChartVariationEnum.Standard,
      // ChartViewConfig.ChartVariationEnum.Curved,
      ChartViewConfig.ChartVariationEnum.Logarithmic,
      // ChartViewConfig.ChartVariationEnum.CurvedLogarithmic,
      // ChartViewConfig.ChartVariationEnum.DrillDown,
    ]),
  },
  [ChartViewConfig.ChartTypeEnum.VerticalBar]: {
    [chartVariationConditions.THREE_D_ONLY]: getOptionsFromStringArray([
      ChartViewConfig.ChartVariationEnum.Standard,
      ChartViewConfig.ChartVariationEnum.Stacked,
      ChartViewConfig.ChartVariationEnum.FullStacked,
      // ChartViewConfig.ChartVariationEnum.Polar,
      // ChartViewConfig.ChartVariationEnum.PolarLogarithmic,
      ChartViewConfig.ChartVariationEnum.DrillDown,
    ]),
    [chartVariationConditions.TWO_D]: getOptionsFromStringArray([ChartViewConfig.ChartVariationEnum.DrillDown]),
  },
  [ChartViewConfig.ChartTypeEnum.HorizontalBar]: {
    [chartVariationConditions.THREE_D_ONLY]: getOptionsFromStringArray([
      ChartViewConfig.ChartVariationEnum.Standard,
      ChartViewConfig.ChartVariationEnum.Stacked,
      ChartViewConfig.ChartVariationEnum.FullStacked,
      // ChartViewConfig.ChartVariationEnum.Polar,
      // ChartViewConfig.ChartVariationEnum.PolarLogarithmic,
      ChartViewConfig.ChartVariationEnum.DrillDown,
    ]),
    [chartVariationConditions.TWO_D]: getOptionsFromStringArray([ChartViewConfig.ChartVariationEnum.DrillDown]),
  },
  // [ChartViewConfig.ChartTypeEnum.StackedBar]: [],
  [ChartViewConfig.ChartTypeEnum.Funnel]: {
    [chartVariationConditions.TWO_D]: getOptionsFromStringArray([ChartViewConfig.ChartVariationEnum.Standard]),
  },
  //missing variation enum : POLAR FULL_STACKED
  [ChartViewConfig.ChartTypeEnum.Radar]: {
    [chartVariationConditions.THREE_D_ONLY]: getOptionsFromStringArray([
      ChartViewConfig.ChartVariationEnum.Area,
      ChartViewConfig.ChartVariationEnum.Line,
    ]),
  },
  [ChartViewConfig.ChartTypeEnum.Bubble]: {
    [chartVariationConditions.FOUR_D_ONLY]: getOptionsFromStringArray([
      ChartViewConfig.ChartVariationEnum.Standard,
      ChartViewConfig.ChartVariationEnum.Polar,
    ]),
    [chartVariationConditions.THREE_D_ONLY]: getOptionsFromStringArray([
      ChartViewConfig.ChartVariationEnum.Standard,
      ChartViewConfig.ChartVariationEnum.Polar,
    ]),
  },
  [ChartViewConfig.ChartTypeEnum.Heatmap]: {
    [chartVariationConditions.THREE_D_ONLY]: getOptionsFromStringArray([
      ChartViewConfig.ChartVariationEnum.Standard,
      ChartViewConfig.ChartVariationEnum.Polar,
    ]),
  },
  [ChartViewConfig.ChartTypeEnum.Pie]: {
    [chartVariationConditions.TWO_D]: getOptionsFromStringArray([ChartViewConfig.ChartVariationEnum.DrillDown]),
  },
  [ChartViewConfig.ChartTypeEnum.Doughnut]: {
    [chartVariationConditions.TWO_D]: getOptionsFromStringArray([ChartViewConfig.ChartVariationEnum.DrillDown]),
  },
  [ChartViewConfig.ChartTypeEnum.SemiCircleDoughnut]: {
    [chartVariationConditions.TWO_D]: getOptionsFromStringArray([ChartViewConfig.ChartVariationEnum.DrillDown]),
  },
  [ChartViewConfig.ChartTypeEnum.Rose]: {
    [chartVariationConditions.TWO_D]: getOptionsFromStringArray([ChartViewConfig.ChartVariationEnum.DrillDown]),
  },
  [ChartViewConfig.ChartTypeEnum.TreeMap]: {
    [chartVariationConditions.TWO_D]: getOptionsFromStringArray([
      ChartViewConfig.ChartVariationEnum.DrillDown,
      ChartViewConfig.ChartVariationEnum.Groups,
    ]),
  },
  [ChartViewConfig.ChartTypeEnum.SunBurst]: {
    [chartVariationConditions.TWO_D]: getOptionsFromStringArray([
      ChartViewConfig.ChartVariationEnum.Standard,
      ChartViewConfig.ChartVariationEnum.AncestorEmphasis,
    ]),
  },
  [ChartViewConfig.ChartTypeEnum.Sankey]: {
    [chartVariationConditions.THREE_D]: getOptionsFromStringArray([
      ChartViewConfig.ChartVariationEnum.Standard,
      ChartViewConfig.ChartVariationEnum.Wheel,
    ]),
  },
  // [ChartViewConfig.ChartTypeEnum.Timescale]: {
  //   [chartVariationConditions.TWO_D]: getOptionsFromStringArray([
  //     ChartViewConfig.ChartVariationEnum.Line,
  //     ChartViewConfig.ChartVariationEnum.Area,
  //     ChartViewConfig.ChartVariationEnum.BarRace,
  //   ]),
  // },
  [StatisticViewConfig.ChartTypeEnum.TimeScale]: {
    [chartVariationConditions.TWO_D]: getOptionsFromStringArray([
      StatisticViewConfig.ChartVariationEnum.Line,
      StatisticViewConfig.ChartVariationEnum.Area,
    ]),
  },
  [StatisticViewConfig.ChartTypeEnum.BarRace]: {
    [chartVariationConditions.TWO_D]: getOptionsFromStringArray([StatisticViewConfig.ChartVariationEnum.Standard]),
  },
};
export interface DrillDownMenuItem extends MenuItem {
  currentField: string;
  currentLabelValue: string;
  currentLabel: string;
}
function checkListInGroup(
  list: { key: string; value: any }[],
  group_id: any,
  emptyValueKey: 'EMPTY_VALUE' = 'EMPTY_VALUE'
): boolean {
  for (const item of list) {
    const { key, value } = item;
    if (group_id.hasOwnProperty(key)) {
      // Key exists in group_id, now compare values
      if (group_id[key] != value) {
        // Values don't match, return false
        return false;
      }
    } else {
      // Key doesn't exist in group_id, return false if the value exists (EMPTY_VALUE string means we are in a null drilldown)
      return value == emptyValueKey;
    }
  }
  // All keys and values match, return true
  return true;
}
