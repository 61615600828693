import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { LocaleService } from '@core/services/locale/locale.service';
import {
  BaseForm,
  ChartViewConfig,
  DynamicFieldConfig,
  FieldDto,
  McqSelectionModes,
  ModuleKeywordRootPath,
  ModuleKeywords,
  QuestionAnswer,
  QuestionFieldsDto,
  QuestionnaireAnswerDto,
  QuestionnaireQuestionsDto,
  RequestHandlerOptions,
  getEnumOptions,
  routeToLocaleCase,
  toCamelCase
} from '@shared/classes';
import { ViewModeService } from '@shared/services/view-mode.service';
import { QuestionnaireDataService } from 'app/modules/questions/service/data/questionnaire-data.service';
import { QuestionsValueDataService } from 'app/modules/questions/service/data/questionnaire-value-data.service';
import { cloneDeep, isNumber } from 'lodash-es';

@Component({
  selector: 'app-questionnaire-value-form',
  templateUrl: './questionnaire-value-form.component.html',
  styleUrls: ['./questionnaire-value-form.component.scss'],
})
export class QuestionnaireValueFormComponent extends BaseForm<QuestionnaireAnswerDto> implements OnInit {

  scoringEnums = getEnumOptions(QuestionnaireAnswerDto.ScoreStatusEnum);
  maxPassingScore = 0;

  @Input()
  questionsOnly: boolean = false;

  @Input()
  itemId: string;

  @Input()
  editMode: Boolean = false;

  chartViewConfig = ChartViewConfig;
  parsedGaugeDataQsv;


  _questions: QuestionFieldsDto[];
  get questions() {
    return this._questions;
  }
  @Input() set questions(value: QuestionFieldsDto[]) {
    this._questions = value;
    if (this.questions) {
      this.initDynamicFields();
    }
  };

  _questionnaire: QuestionnaireQuestionsDto;
  get questionnaire() {
    return this._questionnaire;
  }
  @Input() set questionnaire(value: QuestionnaireQuestionsDto) {
    this._questionnaire = value;

    if (this.questionnaire) {
      this.calcMinMaxPassingScore();
      // if (this.formGroup.controls.score.value >= this.maxPassingScore) {
      //   this.formGroup.controls.scoreStatus.patchValue(QuestionnaireAnswerDto.ScoreStatusEnum.Pass);
      // } else {
      //   this.formGroup.controls.scoreStatus.patchValue(QuestionnaireAnswerDto.ScoreStatusEnum.Fail);
      // }
      this.viewForm.patchValue({ ...this.questionnaire, questionnaireName: this.questionnaire?.name ?? '' });
    }
    if (!this.draftedQsvAnswers) {
      this.draftedQsvAnswers = this._questionnaire?.questions?.map(x => { return { dynamics: {} }; });
    }
    this.parseGaugeData();

  };

  draftedQsvAnswers: QuestionAnswer[];

  _questionnaireValue: QuestionnaireAnswerDto;
  get questionnaireValue() {
    return this._questionnaireValue;
  }
  @Input()
  set questionnaireValue(value: QuestionnaireAnswerDto) {
    this.draftedQsvAnswers = cloneDeep(value?.answers);
    this._questionnaireValue = value;
    // this.viewForm.controls.questionnaireName.patchValue(this._questionnaireValue.name);
  }

  dynamicFields: FieldDto[] = [];
  @Input()
  loading: boolean = true;
  @Output() scoreStatusChange: EventEmitter<any> = new EventEmitter();
  @Output() selectItem: EventEmitter<QuestionnaireQuestionsDto> = new EventEmitter();

  viewForm = new FormGroup({
    code: new FormControl(),
    name: new FormControl(null),
    // scoreStatus: new FormControl(),
    creatorName: new FormControl(null),
    type: new FormControl(null),
    description: new FormControl(null),
    passScore: new FormControl(null),
    scoringType: new FormControl(null),

    questionnaireName: new FormControl(null),
  });
  failLabelSet = false;
  passLabelSet = false;
  constructor(
    private activatedRoute: ActivatedRoute,
    private questionnaireDataService: QuestionnaireDataService,
    private questionsValueDataService: QuestionsValueDataService,
    public viewModeService: ViewModeService,
    public localeService: LocaleService
  ) {
    super(viewModeService, ModuleKeywords.QuestionnaireValue);
  }

  apiOptions: RequestHandlerOptions = {
    showLoading: true,
    showMsg: true,
  };

  ngOnInit(): void { }


  // ngAfterViewInit(): void {
  //   if (this.editMode) {
  //     this.questionsValueDataService.getByIdOrCode<any>(this.itemId, this.apiOptions).subscribe((res) => {
  //       this.questions = res.data.answers;
  //       this.questionnaire = { code: res.data.questionnaire };
  //       this.questionnaireValue = res.data;
  //       this.id = res.data.id;
  //       this.data = res.data;
  //       // this.initDynamicFields();
  //       this.getQuestionnaire();
  //     });
  //   } else {
  //     // this.initDynamicFields();
  //     this.getQuestionnaire();
  //   }
  //   this._setupSubscriptions();
  // }

  // getQuestionnaire() {
  //   if (!this.questionnaire.code) {
  //     this.loading = false;
  //     return;
  //   }
  //   this.loading = true;
  //   this.questionnaireDataService.getByIdOrCode(this.questionnaire.code).subscribe({
  //     next: (res) => {
  //       this.loading = false;
  //       this.questions = res.data.questions;
  //       this.questionnaire = res.data;
  //       this.initDynamicFields();
  //       this.viewForm.patchValue({ ...this.questionnaire, questionnaireName: this.data?.name });
  //     },
  //     error: (err) => {
  //       this.loading = false;
  //     },
  //   });
  // }

  initDynamicFields() {
    // let exampleDynamicFieldNames = this.questions.map((q) => q.question);

    // exampleDynamicFieldNames.forEach((cName) => {
    //     this.formGroup.addControl(
    //         cName,
    //         new FormControl(null, Validators.required)
    //     );
    // });

    //@NOTE you can do custom logic to handle how to get the names and
    // how to instantiate the components based and what data you have
    // this is just a simple example
    this.dynamicFields = [];
    this.questions.forEach((q, i) => {
      q.fields?.forEach((f) => {
        this.dynamicFields.push(f);
      });
    });
    // this.dynamicFields = this.questions.map((q, i) => {
    //     switch (q?.dynamicField?.type) {
    //         case DynamicField.TypeEnum.String: {
    //             return {
    //                 componentType: BasicInputComponent,
    //                 options: {
    //                     label: q.question,
    //                     name: "dynamic-basic-input" + i,
    //                     control:
    //                         this.formGroup.controls[
    //                             exampleDynamicFieldNames[i]
    //                         ],
    //                 },
    //             };
    //         }

    //         case DynamicField.TypeEnum.Number: {
    //             return {
    //                 componentType: NumberInputComponent,
    //                 options: {
    //                     label: q.question,
    //                     name: "dynamic-basic-input" + i,
    //                     control:
    //                         this.formGroup.controls[
    //                             exampleDynamicFieldNames[i]
    //                         ],
    //                     inputOptions: {
    //                         numberInput: {
    //                             showButtons: true,
    //                         },
    //                     },
    //                 },
    //             };
    //         }

    //         case DynamicField.TypeEnum.Mcq: {
    //             return {
    //                 componentType: MCQComponent,
    //                 options: {
    //                     label: q.question,
    //                     name: "dynamic-basic-input" + i,
    //                     control:
    //                         this.formGroup.controls[
    //                             exampleDynamicFieldNames[i]
    //                         ],
    //                     inputOptions: {
    //                         radioInput: {
    //                             groupItems: q?.dynamicField?.options?.LIST_OPTIONS?.map((o) => {
    //                                 return {
    //                                     label: o,
    //                                     value: o,
    //                                 };
    //                             }),
    //                         },
    //                     },
    //                 },
    //             };
    //         }

    //         case DynamicField.TypeEnum.Boolean: {
    //             return {
    //                 componentType: SwitchInputComponent,
    //                 options: {
    //                     label: q.question,
    //                     name: "dynamic-basic-input" + i,
    //                     control:
    //                         this.formGroup.controls[
    //                             exampleDynamicFieldNames[i]
    //                         ],
    //                     inputOptions: {},
    //                 },
    //             };
    //         }
    //     }
    // });

    // if (!!this.editMode) {
    //     this.questions.forEach((q) => {
    //         if (!!q?.answer) {
    //             let obj = {};

    //             obj[q.question] = q.answer;

    //             this.formGroup.patchValue({
    //                 ...obj,
    //             });
    //         }
    //     });
    // }
  }

  getData() {
    let qs = this.questions;
    let formData = this.formGroup.getRawValue();

    // let answers: DynamicFiledValue[] = qs.map((q:DynamicFiledValue) => {
    //     return {
    //         question: q.question,
    //         questionType: q.type,
    //         options: q.options,
    //         answer: formData[q.question],
    //     };
    // });

    let data: QuestionnaireAnswerDto = {
      answers: formData.answers,
      // code: null,
      // id:  this.questionnaireValue?.id,
      questionnaire: this.questionnaire?.code,
      // questionnaireCode: this.questionnaire?.code,
      scoreStatus: formData.scoreStatus,
      questionnaireType: this.questionnaire?.type,
      // questionnaireName: this.questionnaire?.name,
    };

    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat(data)
      : this.getChangedFormValues(data).updateItems;
  }

  setData(data: QuestionnaireAnswerDto) {
    this.formGroup.patchValue({ ...data });
    this.data = data;
    this.viewForm.patchValue({ ...this.questionnaire, questionnaireName: this.data?.name });
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      answers: new FormArray([new FormGroup({})]),
      questionnaire: new FormControl(null),
      scoreStatus: new FormControl(QuestionnaireAnswerDto.ScoreStatusEnum.None),
      score: new FormControl(0),
      campaign: new FormControl(null),
      questionnaireType: new FormControl(null)
    });
    this.formGroup.controls.questionnaire.valueChanges.subscribe(x => {
      this.selectItem.emit(x);
    });
  }
  scoredType = QuestionnaireQuestionsDto.ScoringTypeEnum.Scored;

  onScoreStatusChange(event) {
    this.scoreStatusChange.emit(this.questionnaire?.scoringType == this.scoredType ? event : 'NONE');
    this.formGroup?.controls?.scoreStatus?.patchValue(event);
  }

  onScoreDataChange(event) {
    if (event?.data && event?.index != undefined && this.draftedQsvAnswers?.length) {
      this.draftedQsvAnswers[event.index].dynamics = { ...event?.data };
      setTimeout(() => {
        this.parseGaugeData();
      }, 100);
    }
  }
  calcMinMaxPassingScore() {
    let total = 0;
    let minTotal = 0;
    let maxTotal = 0;
    this.questionnaire?.questions.forEach((qst) => {
      qst?.fields?.forEach((field) => {
        let localMax = 0;

        if (field?.options?.[DynamicFieldConfig.MCQ_SELECTION_MODE] == McqSelectionModes.scored) {
          let minVal = Infinity;
          field?.options?.[DynamicFieldConfig.MCQ_SELECTION_OPTIONS]?.forEach((element) => {
            total += element?.value;
            if (element?.value < minVal) {
              minVal = element?.value;
            }
            if (element?.value > localMax) {
              localMax = element?.value;
            }
          });
          minTotal += minVal;
          maxTotal += localMax;
        }
      });
    });
    this.maxPassingScore = maxTotal;
  }
  parseGaugeData() {
    let failSet = this.failLabelSet = false;
    let passSet = this.passLabelSet = false;
    const passingScore = this.questionnaire?.passScore ?? 0;
    const passingLabel = this.localeService.translate(`modules.${routeToLocaleCase(ModuleKeywordRootPath[ModuleKeywords.QuestionnaireValue])}.${toCamelCase(ModuleKeywords.QuestionnaireValue)}.fields.scoreStatus.enums.PASS`);
    const failLabel = this.localeService.translate(`modules.${routeToLocaleCase(ModuleKeywordRootPath[ModuleKeywords.QuestionnaireValue])}.${toCamelCase(ModuleKeywords.QuestionnaireValue)}.fields.scoreStatus.enums.FAIL`);
    const dataLabel = this.localeService.translate(`modules.${routeToLocaleCase(ModuleKeywordRootPath[ModuleKeywords.QuestionnaireValue])}.${toCamelCase(ModuleKeywords.QuestionnaireValue)}.fields.scoreStatus.label`, 'Score Status');
    let score = 0;
    const failStep = this.maxPassingScore ? this.questionnaire.passScore / this.maxPassingScore : 0;
    const calculatedPassingScore = this.maxPassingScore ? passingScore / this.maxPassingScore : 0;
    if (this.draftedQsvAnswers) {
      this.draftedQsvAnswers.forEach(answer => {
        if (answer?.dynamics) {
          Object.values(answer?.dynamics).forEach(dynamic => {
            if (dynamic?.value?.value && isNumber(dynamic?.value?.value)) {
              score += dynamic.value?.value;
            }
          });
        }
      });
    }
    const calculatedgScore = this.maxPassingScore ? score / this.maxPassingScore : 0;

    this.parsedGaugeDataQsv = {
      series: [
        {
          type: 'gauge',
          startAngle: 180,
          endAngle: 0,
          center: ['50%', '75%'],
          radius: '100%',
          min: 0,
          max: 1,
          splitNumber: 8,
          axisLine: {
            lineStyle: {
              width: 6,
              color: [
                [failStep - (passingScore == this.maxPassingScore ? 0.00001 : 0), '#EF5350'],
                [1, '#9CCC65']
              ]
            }
          },
          pointer: {
            icon: 'arrow',
            length: '12%',
            width: 20,
            offsetCenter: [0, '-60%'],
            itemStyle: {
              color: 'auto'
            }
          },
          axisTick: {
            length: 12,
            lineStyle: {
              color: 'auto',
              width: 2
            }
          },
          splitLine: {
            length: 20,
            lineStyle: {
              color: 'auto',
              width: 5
            }
          },
          axisLabel: {
            color: '#e0dede',
            fontSize: 20,
            distance: -75,
            rotate: 0,
            formatter: function (value) {
              const failMidpoint = calculatedPassingScore / 2;
              const passingMidpoint = ((1 - calculatedPassingScore) / 2) + calculatedPassingScore;

              if (!failSet && Math.abs(value - failMidpoint) < 0.1) {
                failSet = true;
                return failLabel;
              }
              if (!passSet && Math.abs(value - passingMidpoint) < 0.1) {
                passSet = true;
                return passingLabel;
              }

              return ' ';
            }
          },
          title: {
            offsetCenter: [0, '-10%'],
            fontSize: 20
          },
          detail: {
            fontSize: 30,
            offsetCenter: [0, '-35%'],
            valueAnimation: true,
            formatter: function (value) {
              return score;
            },
            color: 'inherit'
          },
          tooltip: false,
          data: [
            {
              value: calculatedgScore,
              name: dataLabel
            }
          ]
        }
      ]
    };
  }
}
