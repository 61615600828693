import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BaseForm, UserPreference } from '@shared/classes';
import { ViewModeService } from '@shared/services';

@Component({
  selector: 'app-user-preference-item-form',
  templateUrl: './user-preference-item-form.component.html',
  styleUrl: './user-preference-item-form.component.scss',
})
export class UserPreferenceItemFormComponent extends BaseForm<UserPreference> implements OnInit {
  @Input() showSaveAndClose = false;
  constructor(public viewModeService: ViewModeService) {
    super(viewModeService, 'USER');
  }
  ngOnInit(): void {}

  getData() {
    return this.getChangedFormValues(this.formGroup.getRawValue()).updateItems;
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      sendEmails: new FormControl(false),
      lang: new FormControl(null),
    });
  }
}
