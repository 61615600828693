import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BasePage, TargetTypeEnum, getEnumOptions } from '@shared/classes';
import { PageableOptions } from '@shared/classes/model/frontend/pageable-options';
import { TagsSearchDataService } from '@shared/services/hub-services/tags-search-data.service';
import { TagDataService } from 'app/modules/global-configuration/services/data/tag-data.service';
import { Paginator } from 'primeng/paginator';
import { debounceTime, takeUntil } from 'rxjs';

@Component({
  selector: 'app-global-search-page',
  templateUrl: './global-search-page.component.html',
  styleUrl: './global-search-page.component.scss',
})
export class GlobalSearchPageComponent extends BasePage implements OnInit {
  @ViewChild('p', { static: false }) paginatorElement: Paginator;

  formGroup;
  excludedTypes = [
    'USER',
    'ROLE',
    'PUBLISHED_GUIDELINE',
    'PUBLISHED_POLICIES',
    'PUBLISHED_STANDARD',
    'MY_ACKNOWLEDGMENT',
    'MY_NOTIFICATION',
    'MY_QUESTIONNAIRE_VALUE',
    'MY_TASK',
    'DOCUMENT_VERSION',
    'DOCUMENT',
    'CHECK_ITEM',
    'BUCKET',
    'GROUP',
  ];
  targetTypesList = getEnumOptions(TargetTypeEnum);
  items = [];
  selectedTypes = [];
  selectedMap = {};
  tagsStringControl = new FormControl(null);
  paginator = new PageableOptions();
  totalElements = 0;
  loading = false;
  tagsSet = true;
  inited = false;
  allSelected = true;
  searchable = true;
  constructor(
    public requestService: TagsSearchDataService,
    public tagsRequestService: TagDataService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super();
    this.SetPageValues({
      breadCrumb: {
        items: [{ label: 'Global Search' }],
      },
    });
    this.targetTypesList = this.targetTypesList?.filter((x) => !this.excludedTypes?.includes(x.value));
    this.targetTypesList.sort(function (a, b) {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    this.targetTypesList.forEach((x) => {
      this.selectedMap[x.value] = true;
    });

    this.initFormStructure();
    this.route.queryParams.subscribe((params) => {
      if (!this.inited) {
        this.inited = true;
        this.loading = true;
        const searchQuery = params['q']; // Extract the search query
        const tags = params['tags'] ? params['tags'].split(',') : null; // Convert back to an array
        if (searchQuery) {
          this.formGroup.controls.query.patchValue(searchQuery, { emitEvent: false });
        }
        if (tags) {
          this.tagsSet = false;
          this.tagsRequestService
            .search(
              { all: true },
              {
                projectionFields: ['name', 'code', 'id'],
                filters: [{ property: 'name', operation: 'IN', value: tags }],
              }
            )
            .subscribe((res) => {
              if (res) {
                const result = res as any;
                this.formGroup.controls.tags.patchValue(
                  result.map((x) => x.code),
                  { emitEvent: false }
                );
                this.tagsStringControl.patchValue(
                  result.map((x) => x.name),
                  { emitEvent: false }
                );
                this.paginator.page = 0;
                // this.paginatorElement.changePage(0);
                this.searchFiltered(
                  this.formGroup.controls?.query?.value ?? null,
                  this.tagsStringControl?.value?.length ? this.tagsStringControl?.value : null
                );
              }
              this.tagsSet = true;
              this.loading = false;
            });
        } else {
          this.paginator.page = 0;
          // this.paginatorElement.changePage(0);
          this.searchFiltered(
            this.formGroup.controls?.query?.value ?? null,
            this.tagsStringControl?.value?.length ? this.tagsStringControl?.value : null
          );
          this.loading = false;
        }
      }
    });
  }
  ngOnInit(): void {}
  initFormStructure(): void {
    this.formGroup = new FormGroup({
      query: new FormControl(null),
      tags: new FormControl(null),
    });
    this.tagsStringControl = new FormControl(null);
    this.formGroup.controls.query.valueChanges.pipe(debounceTime(500), takeUntil(this.destroy$)).subscribe((x) => {
      this.paginator.page = 0;
      this.paginatorElement.first = 0;
      this.searchFiltered(x, this.tagsStringControl?.value?.length ? this.tagsStringControl?.value : null);
      this.updateQueryParams();
    });

    this.formGroup.controls.tags.valueChanges.pipe(debounceTime(500), takeUntil(this.destroy$)).subscribe((x) => {
      this.paginator.page = 0;
      this.paginatorElement.first = 0;
      this.searchFiltered(
        this.formGroup.controls?.query?.value ?? null,
        this.tagsStringControl?.value?.length ? this.tagsStringControl?.value : null
      );
      this.updateQueryParams();
    });
  }

  toggleAll(event: any) {
    this.allSelected = event.checked;
    // if (this.allSelected) {
    //   // Select all checkboxes
    //   this.selectedTypes = this.targetTypesList.map(item => item.value);
    // } else {
    //   // Deselect all checkboxes
    //   this.selectedTypes = [];
    // }
    Object.keys(this.selectedMap).forEach((x) => {
      this.selectedMap[x] = this.allSelected;
    });
    this.selectedMap = { ...this.selectedMap };
    this.selectedTypes = [
      ...Object.entries(this.selectedMap)
        .filter(([k, v]) => {
          return v;
        })
        .map(([k, v]) => {
          return k;
        }),
    ];
    if (this.allSelected) {
      this.selectedTypes = [];
    }
    // Only fire one request here
    this.paginator.page = 0;
    this.paginatorElement.first = 0;
    this.searchFiltered(
      this.formGroup.controls?.query?.value ?? null,
      this.tagsStringControl?.value?.length ? this.tagsStringControl?.value : null
    );
  }
  filterChecked(event, type) {
    // if (event?.checked) {
    //   this.selectedTypes.push(type);
    // } else {
    //   this.selectedTypes = [...this.selectedTypes.filter(x => x != type)];
    // }
    this.selectedMap = { ...this.selectedMap, [type]: event?.checked };
    this.selectedTypes = [
      ...Object.entries(this.selectedMap)
        .filter(([k, v]) => {
          return v;
        })
        .map(([k, v]) => {
          return k;
        }),
    ];

    if (Object.values(this.selectedMap).filter((x) => x).length == this.targetTypesList?.length) {
      this.allSelected = true;
      this.selectedTypes = [];
    } else {
      this.allSelected = false;
    }
    this.paginator.page = 0;
    this.paginatorElement.first = 0;
    this.searchFiltered(
      this.formGroup.controls?.query?.value ?? null,
      this.tagsStringControl?.value?.length ? this.tagsStringControl?.value : null
    );
  }

  searchFiltered(keyword, tags) {
    if (keyword || tags?.length) {
      this.searchable = true;
      this.loading = true;
      this.requestService.globalSearch(keyword, tags, this.selectedTypes, this.paginator).subscribe((res) => {
        if (res?.content) {
          this.paginator.size = res?.size;
          this.totalElements = res?.totalElements;
          this.items = res?.content;
        }
        this.loading = false;
      });
    } else {
      this.searchable = false;
      this.paginator.size = 0;
      this.totalElements = 0;
      this.items = [];
    }
  }

  onPageChange(event) {
    this.paginator.page = event.page;
    if (event?.rows) {
      this.paginator.size = event.rows;
    }
    this.searchFiltered(
      this.formGroup.controls?.query?.value ?? null,
      this.tagsStringControl?.value?.length ? this.tagsStringControl?.value : null
    );
  }

  updateQueryParams() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        q: this.formGroup.controls?.query?.value ?? null,
        tags: this.tagsStringControl?.value?.length ? this.tagsStringControl?.value?.join(',') : null,
      },
      queryParamsHandling: 'merge',
    });
  }
}
