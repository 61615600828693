import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import {
  DataTypeEnum,
  DynamicComponentBase,
  DynamicFieldConfig,
  FieldDto,
  IDynamicComponent,
  QuestionAnswer,
  QuestionFieldsDto,
  QuestionnaireAnswerDto,
} from '@shared/classes';
import { Subject, takeUntil } from 'rxjs';
import { DynamicFieldListInputComponent } from '../dynamic-field-list-input/dynamic-field-list-input.component';

@Component({
  selector: 'app-dynamic-field-form-list-input',
  templateUrl: './dynamic-field-form-list-input.component.html',
  styleUrls: ['./dynamic-field-form-list-input.component.scss'],
  providers: [{ provide: DynamicComponentBase, useExisting: DynamicFieldFormListInputComponent }],
})
export class DynamicFieldFormListInputComponent extends DynamicComponentBase implements OnInit {
  dataType: DataTypeEnum = DataTypeEnum.Text;
  private formValid = new Subject<boolean>();
  private _config;
  @ViewChildren(DynamicFieldListInputComponent) dynamicFieldLists!: QueryList<DynamicFieldListInputComponent>;

  @Input() showScore: boolean = false;
  @Input() set config(conf: { items: QuestionFieldsDto[]; values: QuestionAnswer[]; scored: boolean }) {
    this._config = conf;
    this.initInputFields();
  }
  @Output() scoreStatusChange: EventEmitter<any> = new EventEmitter();
  @Output() answerValuesChanged: EventEmitter<any> = new EventEmitter();

  get config() {
    return this._config;
  }
  formList: { item: QuestionFieldsDto; value: QuestionAnswer }[] = [];
  constructor(private fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    // this.initInputFields();
    this.subs.sink = this.formArray.valueChanges.subscribe((values) => {
      this.getScoreStatuses();
    });
  }
  setInputOptions() { }
  initInputFields() {
    let fields: IDynamicComponent[] = [];

    this.fControl.statusChanges.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.formValid.next(res == 'VALID');
    });
    this.formArray?.reset();
    this.formArray?.clear();
    this.formList = [];

    this.config.items.forEach((item) => {
      const itemValue = this.config?.values?.find((x) => x.question === item.code) || null;
      this.formList.push({ item: item, value: itemValue });
      this.formArray.push(
        new FormGroup({
          question: new FormControl(item.code),
          dynamics: new FormGroup({}),
        })
      );
      this.formArray.controls;
    });
    setTimeout(() => {
      this.formValid.next((this.fControl as any as FormArray).valid);
      // this.fControl.markAsTouched  = () => updateFormControlTree(((this.fControl as any) as FormArray));
    }, 1);
    this.scoredFields = {};
    this.formList?.forEach((item, index) => {
      this.totalScores[index] = 0;
      if (item?.value) {
        Object?.entries(item?.value?.dynamics)?.forEach(([key, val]: any) => {
          const field = item?.item?.fields?.find((x) => x.key == key);
          if (field && field.options?.[DynamicFieldConfig.MCQ_SELECTION_MODE] == 'scored') {
            this.totalScores[index] += val.value;
          }
        });
      }
      item?.item?.fields?.forEach((field) => {
        if (field && field.options?.[DynamicFieldConfig.MCQ_SELECTION_MODE] == 'scored') {
          this.scoredFields[field?.key] = true;
        }
      });
    });
    this.getScoreStatuses();

    if (this.config.scored) {
      const scoreValidator = DynamicFieldFormListInputComponent.scoredValidatorFn(this.scoredFields);
      this.fControl.addValidators(scoreValidator);
      // this.fControl.addValidators(DynamicFieldFormListInputComponent.scoredValidator);
      this.fControl.updateValueAndValidity();
    }
  }
  get formArray() {
    return this.fControl as any as FormArray;
  }
  totalScores: { [x: string]: number } = {};
  onChildControlChanges(event, fields: FieldDto[], index) {
    this.totalScores[index] = 0;
    Object?.entries(event)?.forEach(([key, val]: any) => {
      const field = fields?.find((x) => x.key == key);
      if (field && field.options?.[DynamicFieldConfig.MCQ_SELECTION_MODE] == 'scored') {
        this.totalScores[index] += val.value;
      }
    });
    this.answerValuesChanged.emit({ data: { ...event }, index: index });
    this.getScoreStatuses();
  }
  questionnaireFailed = false;
  scoredFields: { [x: string]: boolean } = {};
  getScoreStatuses() {
    let scored = false;
    this.questionnaireFailed = false;
    this.formList.forEach((item, index) => {
      if (this.formArray?.controls?.[index]) {
        Object?.entries((this.formArray?.controls?.[index] as any)?.controls?.dynamics?.value)?.forEach(
          ([key, val]: any) => {
            const field = item?.item?.fields?.find((x) => x.key == key);
            if (field && field.options?.[DynamicFieldConfig.MCQ_SELECTION_MODE] == 'scored') {
              scored = true;
              if (
                field.options?.[DynamicFieldConfig.MCQ_PASSING_SCORE] &&
                field.options?.[DynamicFieldConfig.MCQ_REQUIRE_SCORE]
              ) {
                if (field.options?.[DynamicFieldConfig.MCQ_PASSING_SCORE] > val.value) {
                  this.questionnaireFailed = true;
                }
              } else if (val?.value == undefined || val?.value == null) {
                this.questionnaireFailed = true;
              }
            }
          }
        );
      }
    });

    this.scoreStatusChange.emit(
      scored ? (
        this.questionnaireFailed
          ? QuestionnaireAnswerDto.ScoreStatusEnum.Fail
          : QuestionnaireAnswerDto.ScoreStatusEnum.Pass
      ) : QuestionnaireAnswerDto.ScoreStatusEnum.None
    );
  }
  static scoredValidator = (control: AbstractControl): ValidationErrors | null => {
    console.log(control.value);
    return { blablaerr: true };
  };
  static scoredValidatorFn(scoredFields: { [x: string]: boolean }): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      // console.log(control.value);
      // console.log('scoredFields', scoredFields);
      let hasValue = false;
      const scoredError = { scoredFieldRequired: true };
      if (control.value) {
        (control.value as { dynamics: { [x: string]: { field: string; value: any } }; question: string }[])?.forEach(
          (qst) => {
            if (qst?.dynamics) {
              Object.entries(qst.dynamics).forEach(([key, value]) => {
                if (scoredFields[key] && value.value !== null && value.value !== undefined) {
                  hasValue = true;
                }
              });
            }
          }
        );
      }
      return hasValue ? null : scoredError;
    };
  }
}
